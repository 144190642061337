import React, { useEffect } from 'react';
import { Card, Tooltip } from 'antd';
import { Form, Input, Select } from 'formik-antd';
import { validateLength } from '../../../../utils/validation';
import { useFormikContext } from 'formik';
import { PublicAidTestQuestions } from '../../PublicAidTestQuestions';

function PublicAidTest() {
  const { values, setFieldValue }: { values: any; setFieldValue: any } = useFormikContext();

  const validQuestions = PublicAidTestQuestions.filter((question, index) => {
    if (index !== 1) return true;
    if (!values.public_aid_test) return false;
    return values.public_aid_test.is_business === 'PARTIALLY';
  });

  useEffect(() => {
    if (!values.public_aid_test) {
      validQuestions.forEach((question) => {
        question.default_value && setFieldValue(`public_aid_test.${question.field}`, question.default_value);
        question.default_reason && setFieldValue(`public_aid_test.${question.field}_reason`, question.default_reason);
      });
    }
  }, [setFieldValue, validQuestions, values.public_aid_test]);

  return (
    <>
      {validQuestions.map((question, index) => {
        return (
          <Card
            key={index}
            className="question-card"
            title={
              <Tooltip overlayClassName="question-tooltip" placement="bottom" title={question.tooltip}>
                <span>{question.question}</span>
              </Tooltip>
            }
            bordered={false}
            size="small"
            extra={
              <Form.Item name={`public_aid_test.${question.field}`}>
                <Select name={`public_aid_test.${question.field}`}>
                  <Select.Option value="YES">TAK</Select.Option>
                  <Select.Option value="NO">NIE</Select.Option>
                  {index === 0 && <Select.Option value="PARTIALLY">CZĘŚCIOWO</Select.Option>}
                </Select>
              </Form.Item>
            }
          >
            <Form.Item
              label="Uzasadnienie wybranej opcji (limit 1000 znaków):"
              name={`public_aid_test.${question.field}_reason`}
              validate={validateLength(1000)}
            >
              <Input.TextArea name={`public_aid_test.${question.field}_reason`} autoSize={{ minRows: 5 }} />
            </Form.Item>
          </Card>
        );
      })}
    </>
  );
}

export default PublicAidTest;
