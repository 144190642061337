import React, { SVGProps } from 'react';
import './style.scss';

const SuccessIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="success-icon"
      {...props}
    >
      <g transform="translate(-1497 -90)">
        <path
          className="a"
          d="M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0ZM9,16.364A7.364,7.364,0,1,1,16.364,9,7.372,7.372,0,0,1,9,16.364Z"
          transform="translate(1497 90)"
        />
        <g transform="translate(1502 97)">
          <line className="b" x2="3" y2="3" transform="translate(0 2)" />
          <line className="b" x1="5" y2="5" transform="translate(3)" />
        </g>
      </g>
    </svg>
  );
};

export default SuccessIcon;
