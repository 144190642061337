import React from 'react';
import { Card, Collapse } from 'antd';
import { activityDetails } from '../../../redux/selectors/activity/activityDetails';
import { connect } from 'react-redux';

const { Panel } = Collapse;

type Props = ReturnType<typeof activityDetails>;

function DetailedInfoSection(props: Props) {
  const { activity } = props;

  const collapsePanels = [
    { header: 'Krótki opis', content: activity.description },
    { header: 'Planowane produkty', content: activity.products },
    { header: 'Planowane rezultaty', content: activity.results },
    { header: 'Beneficjenci bezpośredni', content: activity.direct_beneficiaries },
    { header: 'Beneficjenci końcowi', content: activity.eventual_beneficiaries },
    { header: 'Standardy dostępności', content: activity.accessibility_standards },
    { header: 'Włączenie społeczności lokalnej', content: activity.community_engagement },
    { header: 'Informacja i promocja', content: activity.promotion },
    { header: 'Trwałość działania', content: activity.sustainability },
  ];

  return (
    <Card id="detailed-info" className="top-level-card" title="Szczegóły">
      {collapsePanels.map((panel, index) => (
        <Collapse key={index} className="collapse-info" defaultActiveKey={index === 0 ? ['1'] : []} bordered={false}>
          <Panel className="collapse-info-panel" key="1" header={panel.header}>
            <p>{panel.content}</p>
          </Panel>
        </Collapse>
      ))}
    </Card>
  );
}

export default connect(activityDetails)(DetailedInfoSection);
