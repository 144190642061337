import React from 'react';
import { connect } from 'react-redux';
import { setProjectSearchArchiveFilter } from '../../../../redux/actions/index';
import ArchiveFilterDropdown from '../../../Common/Filters/ArchiveFilterDropdown';
import { archiveFilter } from '../../../../redux/selectors/project/projectList';

type Props = ReturnType<typeof archiveFilter> & typeof mapDispatchToProps;

const ArchiveProjectFilter = ({ setProjectSearchArchiveFilter, archiveFilter }: Props) => {
  return <ArchiveFilterDropdown archiveFilter={archiveFilter} setSearchArchiveFilter={setProjectSearchArchiveFilter} />;
};

const mapDispatchToProps = {
  setProjectSearchArchiveFilter,
};

export default connect(archiveFilter, mapDispatchToProps)(ArchiveProjectFilter);
