import React, { SVGProps } from 'react';
import './style.scss';

const NotStartedIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      className="not-started-status-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g transform="translate(-0.077 0)">
        <g transform="translate(2 7)">
          <circle className="b" cx="2" cy="2" r="2" transform="translate(0.077 0)" />
          <circle className="b" cx="2" cy="2" r="2" transform="translate(5.077 0)" />
          <circle className="b" cx="2" cy="2" r="2" transform="translate(10.077 0)" />
        </g>
      </g>
    </svg>
  );
};

export default NotStartedIcon;
