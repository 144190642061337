import React, { SVGProps } from 'react';
import './style.scss';

const PencilIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" className="pencil-icon">
      <path
        className="a"
        d="M11.89,2.735,9.265.11a.375.375,0,0,0-.53,0L.111,8.735A.375.375,0,0,0,0,9v2.626A.375.375,0,0,0,.375,12H3a.375.375,0,0,0,.265-.11L11.89,3.265a.375.375,0,0,0,0-.53ZM2.845,11.25H.749v-2.1L6.937,2.968,9.032,5.063,2.845,11.25ZM9.562,4.533,7.468,2.438,9,.906,11.095,3Z"
        transform="translate(0 -0.001)"
      />
    </svg>
  );
};

export default PencilIcon;
