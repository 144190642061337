import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { entities } from '../dictionaries';

export const activityListBranch = (state: RootStateType) => state.activityList;
export const activities = createSelector(activityListBranch, (branch) => branch.activities || []);

export const pagination = createSelector(activityListBranch, (branch) => ({
  page: branch.page,
  pageSize: branch.pageSize,
  total: branch.activitiesCount,
}));

export const filters = createSelector(activityListBranch, (branch) => branch.filters);
export const archiveFilter = createSelector(filters, (filters) => ({ archiveFilter: filters.archive }));

export const ordering = createSelector(activityListBranch, (branch) => branch.ordering);
export const search = createSelector(activityListBranch, (branch) => branch.search);

export const activityList = createSelector(
  [activities, pagination, entities, filters, ordering, search],
  (activities, pagination, entities, filters, ordering, search) => ({
    activities,
    pagination,
    entities,
    filters,
    ordering,
    search,
  }),
);
