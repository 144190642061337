import { createReducer } from '@reduxjs/toolkit';
import { Activity, ActivityFinancialEffects, Risk, ValidationErrors } from '../../../types';
import _ from 'lodash';
import { getYearRange } from '../../../utils/dates';
import {
  closeEditFinancialEffectModal,
  closeEditRiskModal,
  deleteRiskSuccess,
  editFinancialEffect,
  editRisk,
  fetchActivityFinancialEffectsOptionsSuccess,
  fetchActivitySuccess,
  fetchRiskEntriesSuccess,
  fetchRiskInfluencesSuccess,
  fetchRiskProbabilitiesSuccess,
  initNewActivity,
  locationChange,
  saveActivityFailed,
  saveActivitySuccess,
  saveRiskFailed,
  saveRiskSuccess,
} from '../../actions';

const DEFAULT_STATE = {
  activity: new Activity(),
  planYears: [] as Array<number>,
  activityErrors: {} as ValidationErrors<Activity>,
  riskInfluences: [] as any,
  riskProbabilities: [] as any,
  riskEntries: [] as any,
  editRiskModalOpened: false as boolean,
  editFinancialEffectModalOpened: false as boolean,
  editedRisk: {} as Risk,
  editedFinancialEffect: {} as ActivityFinancialEffects,
  activityFinancialEffectsOptions: [] as Array<any>,
};

const activityFormReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(initNewActivity, (state, action) => {
      state.activity = new Activity();
      if (action.payload === 'pri') state.activity.kind = 'PRI';
      else state.activity.kind = 'PRL';
    })
    .addCase(saveActivitySuccess, (state, action) => {
      state.activity = action.payload;
      state.editedFinancialEffect = action.payload.financial_effects as ActivityFinancialEffects;
      state.editFinancialEffectModalOpened = false;
      state.activityErrors = {} as ValidationErrors<Activity>;
    })
    .addCase(fetchActivitySuccess, (state, action) => {
      state.activity = action.payload;
      state.activityErrors = {} as ValidationErrors<Activity>;
      if (action.payload.plan_time && action.payload.plan_time.length === 2) {
        state.planYears = getYearRange(action.payload.plan_time[0], action.payload.plan_time[1]);
      }
    })
    .addCase(saveActivityFailed, (state, action) => {
      state.activityErrors = action.payload;
    })
    .addCase(locationChange, (state) => {
      state.activityErrors = {} as ValidationErrors<Activity>;
    })
    .addCase(fetchRiskInfluencesSuccess, (state, action) => {
      state.riskInfluences = action.payload;
    })
    .addCase(fetchRiskProbabilitiesSuccess, (state, action) => {
      state.riskProbabilities = action.payload;
    })
    .addCase(fetchRiskEntriesSuccess, (state, action) => {
      state.riskEntries = action.payload;
    })
    .addCase(editRisk, (state, action) => {
      state.editedRisk = action.payload;
      state.editRiskModalOpened = true;
    })
    .addCase(closeEditRiskModal, (state, action) => {
      state.editRiskModalOpened = false;
    })
    .addCase(saveRiskSuccess, (state, action) => {
      const payload = action.payload as unknown as Risk;
      const index = _.findIndex(state.activity.risks, { id: payload.id });
      if (index >= 0) state.activity.risks.splice(index, 1, payload);
      else state.activity.risks.push(payload);
      state.editRiskModalOpened = false;
    })
    .addCase(deleteRiskSuccess, (state, action) => {
      state.activity.risks = state.activity.risks.filter((risk) => !_.isEqual(risk.id, action.payload));
    })
    .addCase(saveRiskFailed, (state, action) => {
      state.editRiskModalOpened = false;
    })
    .addCase(editFinancialEffect, (state, action) => {
      state.editedFinancialEffect = action.payload;
      state.editFinancialEffectModalOpened = true;
    })
    .addCase(closeEditFinancialEffectModal, (state, action) => {
      state.editFinancialEffectModalOpened = false;
    })
    .addCase(fetchActivityFinancialEffectsOptionsSuccess, (state, action: any) => {
      try {
        state.activityFinancialEffectsOptions = action.payload.actions.POST.financial_effect.choices;
      } catch (e) {
        console.log(e);
      }
    }),
);

export default activityFormReducer;
