import { formatValue } from 'react-currency-input-field';

export const currency = (value: string | number | undefined) =>
  formatValue({
    value: value?.toString(),
    groupSeparator: ' ',
    decimalSeparator: ',',
    suffix: ' zł',
    decimalScale: 2,
  });

export const currencyInput = (value: string | number | undefined) =>
  formatValue({
    value: value?.toString(),
    groupSeparator: ' ',
    decimalSeparator: ',',
  });

export const formatNumber = (value: string | number | undefined) =>
  formatValue({
    value: Number(value)?.toLocaleString(undefined, { maximumFractionDigits: 4 }),
    groupSeparator: ' ',
    decimalSeparator: ',',
  });

export const parseBinaryIndicatorValue = (value: number) => (value === 0 ? 'NIE' : 'TAK');
