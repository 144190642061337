import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row, Tabs } from 'antd';
import { connect } from 'react-redux';

import {
  fetchActivity,
  fetchAllProjects,
  fetchEntities,
  fetchEntityOptions,
  fetchImpacts,
  fetchStrategicGoals,
  initNewActivity,
  saveActivity,
  fetchRiskInfluences,
  fetchRiskProbabilities,
  fetchRiskEntries,
  fetchActivityFinancialEffectsOptions,
} from '../../../redux/actions';
import FormTabPane from '../../Common/FormTabPane';
import { activityForm } from '../../../redux/selectors/activity/activityForm';
import BasicInfoForm from './Tabs/BasicInfoForm';
import RiskAnalysisForm from './Tabs/RiskAnalysisForm';
import ScopeDetailsForm from './Tabs/ScopeDetailsForm';
import ModalContext from '../../Common/AddObjectModals/ModalContext';
import AddObjectModal from '../../Common/AddObjectModals/AddObjectModal';
import { tabFieldsMap } from './Tabs/tabFieldsMap';
import UnstyledLink from '../../Common/UnstyledLink';
import EditRiskModal from './EditRiskModal';
import FinancialEffectsForm from './Tabs/FinancialEffectsForm';
import back from '../../../assets/back.svg';
import EditFinancialEffectModal from './EditFinancialEffectModal';

type Props = ReturnType<typeof activityForm> & typeof mapDispatchToProps;

/**
 * This form handles both new activity creation and updates of
 * existing activities. The two cases can be distinguished by url params:
 * match.params.id present - updating existing activity with given id
 * match.params.id not present - creating new activity
 */
const ActivityForm: React.FunctionComponent<Props> = (props) => {
  const {
    activity,
    activityErrors,
    fetchActivity,
    initNewActivity,
    saveActivity,
    fetchEntities,
    fetchImpacts,
    fetchStrategicGoals,
    fetchAllProjects,
    fetchEntityOptions,
    fetchRiskInfluences,
    fetchRiskProbabilities,
    fetchRiskEntries,
    fetchActivityFinancialEffectsOptions,
  } = props;

  const { id, kind, tab } = useParams();

  useEffect(() => {
    fetchAllProjects();
    fetchEntities();
    fetchEntityOptions();
    fetchImpacts();
    fetchStrategicGoals();
    fetchRiskInfluences();
    fetchRiskProbabilities();
    fetchRiskEntries();
    if (id) {
      // editing existing activity
      fetchActivity(parseInt(id));
    } else {
      // creating new activity
      initNewActivity(kind);
    }
    fetchActivityFinancialEffectsOptions();
  }, [
    fetchActivity,
    initNewActivity,
    fetchEntities,
    fetchEntityOptions,
    fetchImpacts,
    fetchStrategicGoals,
    fetchAllProjects,
    id,
    fetchRiskInfluences,
    fetchRiskProbabilities,
    fetchRiskEntries,
    kind,
    fetchActivityFinancialEffectsOptions,
  ]);

  const [isAddObjectModalVisible, setAddObjectModalVisible] = useState(false);
  const [addObjectModalTitle, setAddObjectModalTitle] = useState('');
  const [addObjectModalContent, setAddObjectModalContent] = useState<any>();

  const showAddObjectModal = (title: string, form: ReactElement) => {
    setAddObjectModalTitle(title);
    setAddObjectModalContent(form);
    setAddObjectModalVisible(true);
  };

  const closeAddObjectModal = () => {
    setAddObjectModalVisible(false);
  };

  const onSubmit = (values: any) => {
    saveActivity({
      ...activity,
      ...values,
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Form validation failed:', errorInfo);
  };

  const hasErrors = (tabKey: string) => _.intersection(tabFieldsMap[tabKey], _.keys(activityErrors)).length > 0;

  // FIXME: strict typing
  // show tab validation status: https://github.com/ant-design/ant-design/issues/17146
  const renderTabBar = (tabBarProps: any, DefaultTabBar: any) => (
    <DefaultTabBar {...tabBarProps}>
      {(node: any) => (
        <React.Fragment key={node.key}>
          {hasErrors(node.key)
            ? React.cloneElement(node, {
                className: `${node.props.className} tab-with-errors`,
              })
            : node}
        </React.Fragment>
      )}
    </DefaultTabBar>
  );

  const url = id ? `/activity/${id}/edit` : `/activity/new/${kind}`;

  const history = useHistory();
  const goBack = () => {
    history.push(id ? `/activity/${id}` : '/activity');
  };

  return (
    <>
      <Row className="title-bar form">
        <Col className="title" span={24}>
          <Button className="back-button" size="large" onClick={goBack}>
            <img src={back} alt="edit icon" />
            Powrót
          </Button>
          <div>{activity.title}</div>
        </Col>
      </Row>
      <ModalContext.Provider
        value={{
          showModal: showAddObjectModal,
          closeModal: closeAddObjectModal,
        }}
      >
        <Tabs
          className="form-tabs"
          defaultActiveKey="basic"
          activeKey={tab || 'basic'}
          renderTabBar={renderTabBar}
          style={{ textAlign: 'center' }}
          destroyInactiveTabPane={true}
          tabPosition="left"
          tabBarStyle={{ border: 'none' }}
        >
          <FormTabPane
            tab={<UnstyledLink to={`${url}/basic`}>Informacje ogólne</UnstyledLink>}
            key="basic"
            initialValues={activity}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <BasicInfoForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/details`} disabled={!activity.id}>
                Szczegóły
              </UnstyledLink>
            }
            key="details"
            initialValues={activity}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <ScopeDetailsForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/risk-analysis`} disabled={!activity.id}>
                Analiza ryzyka
              </UnstyledLink>
            }
            key="risk-analysis"
            initialValues={activity}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <RiskAnalysisForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/financial-effects`} disabled={!activity.id}>
                Skutki finansowe
              </UnstyledLink>
            }
            key="financial-effects"
            initialValues={activity}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <FinancialEffectsForm />
          </FormTabPane>
        </Tabs>
        <AddObjectModal
          title={addObjectModalTitle}
          content={addObjectModalContent}
          isVisible={isAddObjectModalVisible}
          closeModal={closeAddObjectModal}
        />
        <EditRiskModal />
        <EditFinancialEffectModal />
      </ModalContext.Provider>
    </>
  );
};

const mapDispatchToProps = {
  fetchActivity,
  initNewActivity,
  saveActivity,
  fetchEntities,
  fetchImpacts,
  fetchStrategicGoals,
  fetchAllProjects,
  fetchEntityOptions,
  fetchRiskInfluences,
  fetchRiskProbabilities,
  fetchRiskEntries,
  fetchActivityFinancialEffectsOptions,
};
export default connect(activityForm, mapDispatchToProps)(ActivityForm);
