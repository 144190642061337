import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './index.scss';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import AppLayout from './components/AppLayout';
import { store, history } from './redux/store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { getAuthToken } from './utils/storage';
import { loginSuccess } from './redux/actions';
import { QueryClient, QueryClientProvider } from 'react-query';

const token = getAuthToken();
if (token) {
  store.dispatch(loginSuccess({ token }));
}

// axios will set xsrf headers for us in all future requests
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const queryClient = new QueryClient();

const App: React.FunctionComponent = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppLayout>
          <Routes />
        </AppLayout>
      </ConnectedRouter>
    </Provider>
  </QueryClientProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
