import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import SearchIcon from '../../../../assets/icons/SearchIcon/SearchIcon';
import './style.scss';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  loading?: boolean;
}

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} color="#5e5e5e" spin />;

const SearchInput = React.forwardRef<HTMLInputElement, Props>(({ children, loading, ...rest }, ref) => {
  return (
    <div className="custom-search">
      <input ref={ref} {...rest} />
      {loading ? <Spin indicator={antIcon} /> : <SearchIcon />}
    </div>
  );
});

export default SearchInput;
