import React, { ReactElement } from 'react';
import { Modal } from 'antd';

function AddObjectModal({
  title,
  content,
  isVisible,
  closeModal,
}: {
  title: string;
  content: ReactElement | undefined | null;
  isVisible: boolean;
  closeModal: any;
}) {
  if (!content) return null;

  return (
    <Modal title={title} visible={isVisible} onCancel={closeModal} footer={null}>
      {content}
    </Modal>
  );
}

export default AddObjectModal;
