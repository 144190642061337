import React from 'react';
import { Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ProgressStatusFilter } from '../../../enums';
import OpenProjectProgressStatus from '../../OpenProject/utilComponents/OpenProjectProgressStatus';

type Props = {
  progressStatusFilter: ProgressStatusFilter;
  setSearchProgressStatusFilter: Function;
};

const ProgressStatusFilterDropdown = ({ setSearchProgressStatusFilter, progressStatusFilter }: Props) => {
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setSearchProgressStatusFilter(ProgressStatusFilter.InPreparation);
        }}
      >
        <OpenProjectProgressStatus progressStatus="IN_PREPARATION" size={18} showTooltip={false} />W przygotowaniu
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setSearchProgressStatusFilter(ProgressStatusFilter.InProgress);
        }}
      >
        <OpenProjectProgressStatus progressStatus="IN_PROGRESS" size={18} showTooltip={false} />W realizacji
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setSearchProgressStatusFilter(ProgressStatusFilter.Realized);
        }}
      >
        <OpenProjectProgressStatus progressStatus="REALIZED" size={18} showTooltip={false} />
        Zrealizowany
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          setSearchProgressStatusFilter(ProgressStatusFilter.None);
        }}
      >
        <span style={{ marginLeft: 26 }}>Brak filtru</span>
      </Menu.Item>
    </Menu>
  );

  const displayText = () => {
    switch (progressStatusFilter) {
      case ProgressStatusFilter.InPreparation:
        return (
          <>
            <OpenProjectProgressStatus progressStatus="IN_PREPARATION" size={18} marginTop={2} showTooltip={false} />
            <span style={{ marginRight: 5 }}>W przygotowaniu</span>
          </>
        );
      case ProgressStatusFilter.InProgress:
        return (
          <>
            <OpenProjectProgressStatus progressStatus="IN_PROGRESS" size={18} marginTop={2} showTooltip={false} />
            <span style={{ marginRight: 5 }}>W realizacji</span>
          </>
        );
      case ProgressStatusFilter.Realized:
        return (
          <>
            <OpenProjectProgressStatus progressStatus="REALIZED" size={18} marginTop={2} showTooltip={false} />
            <span style={{ marginRight: 5 }}>Zrealizowany</span>
          </>
        );
      default:
        return 'Brak filtru';
    }
  };

  return (
    <div>
      <span style={{ marginRight: 10, marginLeft: 15 }}>Etap:</span>
      <Dropdown overlay={menu}>
        <Button>
          {displayText()} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ProgressStatusFilterDropdown;
