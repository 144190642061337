import React from 'react';
import { connect } from 'react-redux';
import { setPlanSearchArchiveFilter } from '../../../../redux/actions/index';
import { archiveFilter } from '../../../../redux/selectors/activity/activityList';
import ArchiveFilterDropdown from '../../../Common/Filters/ArchiveFilterDropdown';

type Props = ReturnType<typeof archiveFilter> & typeof mapDispatchToProps;

const ArchivePlanFilter = ({ setPlanSearchArchiveFilter, archiveFilter }: Props) => {
  return <ArchiveFilterDropdown archiveFilter={archiveFilter} setSearchArchiveFilter={setPlanSearchArchiveFilter} />;
};

const mapDispatchToProps = {
  setPlanSearchArchiveFilter,
};

export default connect(archiveFilter, mapDispatchToProps)(ArchivePlanFilter);
