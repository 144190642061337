import React, { useState } from 'react';
import { Button, Card } from 'antd';
import { projectScheduleSelector } from '../../../../redux/selectors/project/projectDetails';
import { connect } from 'react-redux';
import RealizationModal from './RealizationModal/RealizationModal';
import { fetchProjectSummary } from '../../../../redux/actions';
import ScheduleRealizationTable from './ScheduleRealizationTable/ScheduleRealizationTable';
import ScheduleRealizationTasksTable from './ScheduleRealizationTasksTable';

type Props = ReturnType<typeof projectScheduleSelector> & typeof mapDispatchToProps;

const ScheduleSection: React.FC<Props> = ({ tasks, taskFieldDescriptions, project, fetchProjectSummary }) => {
  const [realizationModalVisible, setRealizationModalVisible] = useState(false);
  const refreshProject = () => {
    fetchProjectSummary(Number(project.id));
  };

  return (
    <Card
      id="schedule"
      className="top-level-card project-schedule-card"
      title="Harmonogram i finansowanie"
      extra={
        <div className="extra">
          <Button
            className="realization"
            disabled={!(tasks.length > 0)}
            onClick={() => setRealizationModalVisible(true)}
          >
            Realizacja
          </Button>
        </div>
      }
    >
      <div className="labeled-content">
        <label>Zadania</label>
        <ScheduleRealizationTasksTable tasks={tasks} taskFieldDescriptions={taskFieldDescriptions} />
      </div>
      <div className="labeled-content">
        <label>Realizacja</label>
        <ScheduleRealizationTable project={project} tasks={tasks} />
      </div>
      <RealizationModal
        visible={realizationModalVisible}
        onCancel={() => setRealizationModalVisible(false)}
        onCreate={() => {
          setRealizationModalVisible(false);
          refreshProject();
        }}
      />
    </Card>
  );
};

const mapDispatchToProps = {
  fetchProjectSummary,
};

export default connect(projectScheduleSelector, mapDispatchToProps)(ScheduleSection);
