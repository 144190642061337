import React from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  to: string;
  disabled?: boolean;
};

const UnstyledLink: React.FunctionComponent<Props> = ({ to, disabled, children }) => {
  const history = useHistory();

  return (
    <span
      className={`unstyled-link ${disabled ? 'disabled' : ''}`}
      onClick={disabled ? undefined : () => history.push(to)}
    >
      {children}
    </span>
  );
};

export default UnstyledLink;
