import React from 'react';
import { Card, Divider, Table } from 'antd';
import { connect } from 'react-redux';
import { activityDetails } from '../../../redux/selectors/activity/activityDetails';

type Props = ReturnType<typeof activityDetails>;

function FundingSourcesSection(props: Props) {
  const { projectFunding } = props;

  return (
    <Card id="funding-sources" className="top-level-card" title="Finansowanie">
      {projectFunding.map((project: any, index: number) => (
        <React.Fragment key={index}>
          <h2>{project.project}</h2>
          <Table
            className="funding-table"
            columns={project.columns}
            dataSource={project.rows}
            size="small"
            pagination={false}
            rowClassName={(record) => (record.funding_source === 'Razem' ? 'summary-row' : '')}
          />
          <Divider />
        </React.Fragment>
      ))}
    </Card>
  );
}

export default connect(activityDetails)(FundingSourcesSection);
