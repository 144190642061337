import { createReducer } from '@reduxjs/toolkit';
import { ProjectSummary } from '../../../types';
import {
  fetchProject,
  fetchProjectOptionsSuccess,
  fetchProjectSummarySuccess,
  fetchTaskOptionsSuccess,
  locationChange,
} from '../../actions';

const DEFAULT_STATE = {
  project: {} as ProjectSummary,
  projectOptions: {} as any,
  taskOptions: {} as any,
  isLoading: true,
};

const projectDetailsReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(fetchProject, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchProjectSummarySuccess, (state, action) => {
      state.project = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchProjectOptionsSuccess, (state, action) => {
      state.projectOptions = action.payload;
    })
    .addCase(fetchTaskOptionsSuccess, (state, action) => {
      state.taskOptions = action.payload;
    })
    .addCase(locationChange, (state, action) => {
      if (action.payload) {
        // @ts-ignore
        if (!action.payload.location.hash) {
          return DEFAULT_STATE;
        }
      }
      return state;
    }),
);

export default projectDetailsReducer;
