import React, { SVGProps } from 'react';
import './style.scss';

const AddIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" className="add-icon" {...props}>
      <g transform="translate(-1592 -115)">
        <rect className="a" width="10" height="2" transform="translate(1592 119)" />
        <rect className="a" width="10" height="2" transform="translate(1598 115) rotate(90)" />
      </g>
    </svg>
  );
};

export default AddIcon;
