import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  changeProjectsPagination,
  fetchAllActivities,
  fetchEntities,
  fetchProjectOptions,
  fetchProjects,
  setProjectRealizationFilter,
  changeCity,
} from '../../../redux/actions';
import { projectList } from '../../../redux/selectors/project/projectList';
import { Project } from '../../../types';
import { Link } from 'react-router-dom';
import ArchiveProjectFilter from './Filters/ArchiveProjectFilter';
import { handleTableOrdering } from '../../../utils/sorting';
import { setProjectListOrdering, setProjectListSearch } from '../../../redux/actions/index';
import ProjectListTable from './ProjectListTable/ProjectListTable';
import { SorterResult } from 'antd/lib/table/interface';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map, filter } from 'rxjs/operators';
import SearchInput from '../../Common/Form/SearchInput/SerachInput';
import './style.scss';
import RealizationStatusFilter from '../../Common/Filters/RealizationStatusFilter';

type Props = ReturnType<typeof projectList> & typeof mapDispatchToProps;

const ProjectList: React.FunctionComponent<Props> = ({
  fetchAllActivities,
  fetchEntities,
  fetchProjectOptions,
  fetchProjects,
  activities,
  entities,
  projects,
  pagination,
  filters,
  ordering,
  search,
  changeProjectsPagination,
  setProjectListOrdering,
  setProjectListSearch,
  setProjectRealizationFilter,
}) => {
  const [searchSubject, setSearchSubject] = useState<BehaviorSubject<string> | null>(null);

  useEffect(() => {
    fetchAllActivities();
    fetchEntities();
    fetchProjectOptions();
    fetchProjects({
      page: pagination.page,
      pageSize: pagination.pageSize,
      filters,
      ordering,
      search,
    });
    // Fetch nesesery data only on first component init.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchSubject === null) {
      const sub = new BehaviorSubject('');
      setSearchSubject(sub);
    } else {
      searchSubject
        .pipe(
          debounceTime(500),
          map((s) => s.trim()),
          filter((s) => s.length >= 3 || s.length === 0),
        )
        .subscribe((searchTerm) => setProjectListSearch(searchTerm));
    }
    return () => searchSubject?.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSubject]);

  useEffect(() => {
    fetchProjects({
      page: pagination.page,
      pageSize: pagination.pageSize,
      filters,
      ordering,
      search,
    });
  }, [fetchProjects, pagination.page, pagination.pageSize, filters, ordering, search]);

  const onPageChange = (page: number, pageSize?: number) => {
    changeProjectsPagination({
      page,
      pageSize: pageSize || pagination.pageSize,
    });
  };

  const onPageSizeChange = (currentSize: number, newSize: number) => {
    changeProjectsPagination({ page: 1, pageSize: newSize });
  };

  const onSorterChange = (sorter: SorterResult<Project> | SorterResult<Project>[]) => {
    handleTableOrdering(sorter, setProjectListOrdering);
  };

  return (
    <section id="projects">
      <div className="controls">
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="link-prl">
                <Link key="1" to="/project/new/prl">
                  PRL
                </Link>
              </Menu.Item>
              <Menu.Item key="link-pri">
                <Link key="2" to="/project/new/pri">
                  PRI
                </Link>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button type="primary">
            <PlusOutlined /> Dodaj Przedsięwzięcie
          </Button>
        </Dropdown>
        <SearchInput
          placeholder="Znajdź przedsięwzięcie"
          onChange={(event) => searchSubject?.next((event.target as HTMLInputElement).value)}
        />
        <ArchiveProjectFilter />
        <RealizationStatusFilter onChange={setProjectRealizationFilter} defaultValue={filters.realizationStatus} />
      </div>
      <span className="divider"></span>
      <ProjectListTable
        pagination={pagination}
        onPageChange={onPageChange}
        onSorterChange={onSorterChange}
        onPageSizeChange={onPageSizeChange}
        projects={projects}
        entities={entities}
        activities={activities}
      />
    </section>
  );
};

const mapDispatchToProps = {
  fetchAllActivities,
  fetchEntities,
  fetchProjects,
  changeProjectsPagination,
  setProjectListOrdering,
  fetchProjectOptions,
  setProjectListSearch,
  changeCity,
  setProjectRealizationFilter,
};
export default connect(projectList, mapDispatchToProps)(ProjectList);
