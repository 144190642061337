import React, { SVGProps } from 'react';
import './style.scss';

const FinishedStatusIcon: React.FC<SVGProps<SVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.155"
      height="14.184"
      viewBox="0 0 14.155 14.184"
      className="finished-status-icon"
    >
      <g transform="translate(0 0.05)">
        <g transform="translate(0 -0.05)">
          <path
            className="a"
            d="M3.587,22.58l.261-.073a.472.472,0,0,0,.327-.581L1.192,11.251a.59.59,0,0,0-.727-.409l-.034.009a.59.59,0,0,0-.409.727L3.005,22.252A.472.472,0,0,0,3.587,22.58Z"
            transform="translate(0 -8.413)"
          />
          <path
            className="a"
            d="M18.974,7.661a11.3,11.3,0,0,1-2.6-1.788.232.232,0,0,1-.066-.237,33.449,33.449,0,0,0,.857-4.442c.029-.249-.105-.323-.247-.151C14.141,4.368,9.332.434,6.47,3.456a.975.975,0,0,1,.081.2l1.934,6.929c2.867-2.986,7.659.924,10.433-2.394C19.06,8.023,19.087,7.71,18.974,7.661Z"
            transform="translate(-4.889 -0.95)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FinishedStatusIcon;
