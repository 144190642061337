import { Activity } from '../../../../types';

export const tabFieldsMap: { [key: string]: Array<keyof Activity> } = {
  basic: ['title', 'activity_leader', 'strategic_goals', 'main_impact', 'additional_impacts'],
  details: [
    'description',
    'products',
    'results',
    'direct_beneficiaries',
    'eventual_beneficiaries',
    'accessibility_standards',
    'community_engagement',
    'promotion',
    'sustainability',
  ],
};
