import create from 'zustand';
import {
  IndicatorConfigurationType,
  IndicatorMeasurementsListItem,
  IndicatorsMonitoringIndicator,
} from '../../../../types';
import produce from 'immer';

interface IndicatorConfigModalProps {
  type: IndicatorConfigurationType;
  strategic_goal?: number;
  result?: number;
  product?: number;
  edit?: {
    indicatorConfig: IndicatorsMonitoringIndicator;
  };
}

interface ProductModalContext {
  id: number;
  name: string;
}

interface ResultModalContext {
  id: number;
  name: string;
}

export interface IndicatorsStore {
  modalsVisibility: {
    indicator: boolean;
    indicatorConfig: boolean;
    indicatorMeasurement: boolean;
    editResult: boolean;
    editProduct: boolean;
  };
  indicatorConfig: IndicatorConfigModalProps;
  indicatorMeasurementModalContext?: IndicatorMeasurementsListItem;
  productModalContext?: ProductModalContext;
  resultModalContext?: ResultModalContext;
  goalId: number;
  resultId: number;
  lastlyAddedIndicator?: number;
  setProductVisibility: (newValue: boolean, resultId?: number, productContext?: ProductModalContext) => void;
  setIndicatorVisibility: (newValue: boolean) => void;
  setIndicatorConfigVisibility: (newValue: boolean, config?: IndicatorConfigModalProps) => void;
  setIndicatorMeasurementVisibility: (newValue: boolean, indicatorConfig?: IndicatorMeasurementsListItem) => void;
  setResultVisibility: (newValue: boolean, goalId?: number, resultContext?: ResultModalContext) => void;
  setLastlyAddedIndicator: (newVal: number) => void;
}

export const useIndicatorsMonitoringStore = create<IndicatorsStore>((set) => ({
  modalsVisibility: {
    indicator: false,
    indicatorConfig: false,
    indicatorMeasurement: false,
    editResult: false,
    editProduct: false,
  },
  indicatorConfig: {
    type: IndicatorConfigurationType.STRATEGIC_GOAL,
  },
  goalId: 0,
  resultId: 0,
  lastlyAddedIndicator: undefined,
  setLastlyAddedIndicator: (newValue) => set(() => ({ lastlyAddedIndicator: newValue })),
  setProductVisibility: (visibility, resultId, productContext) =>
    set(
      produce((state: IndicatorsStore) => {
        state.modalsVisibility.editProduct = visibility;
        state.resultId = resultId || 0;
        state.productModalContext = productContext;
      }),
    ),
  setResultVisibility: (visibility, goalId, resultContext) =>
    set(
      produce((state: IndicatorsStore) => {
        state.modalsVisibility.editResult = visibility;
        state.goalId = goalId || 0;
        state.resultModalContext = resultContext;
      }),
    ),
  setIndicatorConfigVisibility: (visibility, config) =>
    set(
      produce((state: IndicatorsStore) => {
        state.modalsVisibility.indicatorConfig = visibility;
        state.indicatorConfig = config || { type: IndicatorConfigurationType.STRATEGIC_GOAL };
      }),
    ),
  setIndicatorMeasurementVisibility: (visibility, indicatorConfig) =>
    set(
      produce((state: IndicatorsStore) => {
        state.modalsVisibility.indicatorMeasurement = visibility;
        if (indicatorConfig) {
          state.indicatorMeasurementModalContext = indicatorConfig;
        }
      }),
    ),
  setIndicatorVisibility: (v) =>
    set((state) => ({
      modalsVisibility: { ...state.modalsVisibility, indicator: v },
    })),
}));
