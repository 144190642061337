import React, { SVGProps } from 'react';

const InfoIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className="info-icon">
      <path
        className="a"
        d="M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0ZM9,16.364A7.364,7.364,0,1,1,16.364,9,7.372,7.372,0,0,1,9,16.364Z"
      />
      <path
        className="a"
        d="M146.093,70a1.091,1.091,0,1,0,1.091,1.091A1.092,1.092,0,0,0,146.093,70Z"
        transform="translate(-137.093 -66.182)"
      />
      <path
        className="a"
        d="M150.818,140a.818.818,0,0,0-.818.818v4.909a.818.818,0,1,0,1.636,0v-4.909A.818.818,0,0,0,150.818,140Z"
        transform="translate(-141.818 -132.364)"
      />
    </svg>
  );
};

export default InfoIcon;
