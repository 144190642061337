import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
  Action,
  deleteTask,
  deleteTaskFailed,
  deleteTaskSuccess,
  fetchAllProjects,
  fetchAllProjectsFailed,
  fetchAllProjectsSuccess,
  fetchEntityOptions,
  fetchEntityOptionsFailed,
  fetchEntityOptionsSuccess,
  fetchProject,
  fetchProjectFailed,
  fetchProjectOptions,
  fetchProjectOptionsFailed,
  fetchProjectOptionsSuccess,
  fetchProjects,
  fetchProjectsFailed,
  fetchProjectsSuccess,
  fetchProjectSuccess,
  fetchProjectSummary,
  fetchProjectSummaryFailed,
  fetchProjectSummarySuccess,
  fetchProjectTasks,
  fetchProjectTasksFailed,
  fetchProjectTasksSuccess,
  fetchRelatedProjects,
  fetchRelatedProjectsFailed,
  fetchRelatedProjectsSuccess,
  fetchTaskOptions,
  fetchTaskOptionsFailed,
  fetchTaskOptionsSuccess,
  saveEntity,
  saveEntityFailed,
  saveEntitySuccess,
  saveFundingSource,
  saveFundingSourceFailed,
  saveFundingSourceSuccess,
  saveImpact,
  saveImpactFailed,
  saveImpactSuccess,
  saveProject,
  saveProjectFailed,
  saveProjectSuccess,
  saveReporterType,
  saveReporterTypeFailed,
  saveReporterTypeSuccess,
  saveTask,
  saveTaskFailed,
  saveTaskSuccess,
  searchProjects,
  searchProjectsFailed,
  searchProjectsSuccess,
} from '../actions';
import { API_URL } from '../../utils/config';
import { Entity, FundingSource, Impact, Project, ReporterType, Task } from '../../types';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/notifications';
import {
  createDeleteObjectSaga,
  createFetchObjectSaga,
  createFetchObjectSummarySaga,
  createFetchOptionsSaga,
  createFetchPaginatedListSaga,
  createPostObjectSaga,
  createSearchListSaga,
} from './utils';

const fetchProjectsSaga = createFetchPaginatedListSaga(
  `${API_URL}/project/`,
  fetchProjectsSuccess,
  fetchProjectsFailed,
);

const fetchProjectSaga = createFetchObjectSaga(`${API_URL}/project`, fetchProjectSuccess, fetchProjectFailed);

function* fetchAllProjectsSaga() {
  try {
    const response = yield axios.get(`${API_URL}/project/`);
    yield put(fetchAllProjectsSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(fetchAllProjectsFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

const fetchProjectSummarySaga = createFetchObjectSummarySaga(
  `${API_URL}/project`,
  fetchProjectSummarySuccess,
  fetchProjectSummaryFailed,
);

const searchProjectsSaga = createSearchListSaga(`${API_URL}/project/`, searchProjectsSuccess, searchProjectsFailed);

const fetchProjectOptionsSaga = createFetchOptionsSaga(
  `${API_URL}/project/`,
  fetchProjectOptionsSuccess,
  fetchProjectOptionsFailed,
);

function* saveProjectSaga({ payload }: Action<Project>) {
  try {
    // if id is present then we're updating an existing project (PUT)
    // otherwise we're creating a new one (POST).
    let response;
    if (payload.id) {
      response = yield axios.put(`${API_URL}/project/${payload.id}/`, payload);
    } else {
      response = yield axios.post(`${API_URL}/project/`, payload);
    }
    yield put(saveProjectSuccess(response.data));
    displaySuccessNotification('Zapis projektu zakończony pomyślnie.');
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.status === 400) {
        yield put(saveProjectFailed(e.response.data));
      }
      displayErrorNotification('Błąd zapisu projektu.');
    } else {
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  }
}

const fetchEntityOptionsSaga = createFetchOptionsSaga(
  `${API_URL}/entity/`,
  fetchEntityOptionsSuccess,
  fetchEntityOptionsFailed,
);

const saveReporterTypeSaga = createPostObjectSaga<ReporterType>(
  `${API_URL}/reporter_type/`,
  saveReporterTypeSuccess,
  saveReporterTypeFailed,
  'Zapis typu instytucji zakończony pomyślnie.',
  'Błąd zapisu typu instytucji.',
);

const saveEntitySaga = createPostObjectSaga<Entity>(
  `${API_URL}/entity/`,
  saveEntitySuccess,
  saveEntityFailed,
  'Zapis podmiotu zakończony pomyślnie.',
  'Błąd zapisu podmiotu.',
);

const saveImpactSaga = createPostObjectSaga<Impact>(
  `${API_URL}/impact/`,
  saveImpactSuccess,
  saveImpactFailed,
  'Zapis wpływu zakończony pomyślnie.',
  'Błąd zapisu wpływu.',
);

const saveFundingSourceSaga = createPostObjectSaga<FundingSource>(
  `${API_URL}/funding_source/`,
  saveFundingSourceSuccess,
  saveFundingSourceFailed,
  'Zapis źródła finansowania zakończony pomyślnie.',
  'Błąd zapisu źródła finansowania.',
);

function* saveTaskSaga({ payload }: Action<Task>) {
  try {
    // if id is present then we're updating an existing project (PUT)
    // otherwise we're creating a new one (POST).
    let response;
    if (payload.id) {
      response = yield axios.put(`${API_URL}/task/${payload.id}/`, payload);
    } else {
      response = yield axios.post(`${API_URL}/task/`, payload);
    }
    yield put(saveTaskSuccess(response.data));
    displaySuccessNotification('Zapis zadania zakończony pomyślnie.');
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.status === 400) {
        yield put(saveTaskFailed(e.response.data));
      } else {
        displayErrorNotification('Błąd zapisu zadania.');
      }
    } else {
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  }
}

const fetchTaskOptionsSaga = createFetchOptionsSaga(
  `${API_URL}/task/`,
  fetchTaskOptionsSuccess,
  fetchTaskOptionsFailed,
);

const deleteTaskSaga = createDeleteObjectSaga(`${API_URL}/task`, deleteTaskSuccess, deleteTaskFailed);

function* fetchRelatedProjectsSaga({ payload }: Action<number>) {
  try {
    const response = yield axios.get(`${API_URL}/project/${payload}/related/`);
    yield put(fetchRelatedProjectsSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(fetchRelatedProjectsFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

function* fetchProjectTasksSaga({ payload }: Action<number>) {
  try {
    const response = yield axios.get(`${API_URL}/project/${payload}/task/`);
    yield put(fetchProjectTasksSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(fetchProjectTasksFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

export default function* projectSaga() {
  yield takeEvery(fetchProjects, fetchProjectsSaga);
  yield takeEvery(fetchAllProjects, fetchAllProjectsSaga);
  yield takeEvery(searchProjects, searchProjectsSaga);
  yield takeEvery(fetchProject, fetchProjectSaga);
  yield takeEvery(fetchProjectSummary, fetchProjectSummarySaga);
  yield takeEvery(fetchRelatedProjects, fetchRelatedProjectsSaga);
  yield takeEvery(saveProject, saveProjectSaga);
  yield takeEvery(fetchProjectOptions, fetchProjectOptionsSaga);
  yield takeEvery(fetchEntityOptions, fetchEntityOptionsSaga);
  yield takeEvery(saveReporterType, saveReporterTypeSaga);
  yield takeEvery(saveEntity, saveEntitySaga);
  yield takeEvery(saveImpact, saveImpactSaga);
  yield takeEvery(fetchTaskOptions, fetchTaskOptionsSaga);
  yield takeEvery(deleteTask, deleteTaskSaga);
  yield takeEvery(saveTask, saveTaskSaga);
  yield takeEvery(fetchProjectTasks, fetchProjectTasksSaga);
  yield takeEvery(saveFundingSource, saveFundingSourceSaga);
}
