import _ from 'lodash';

export const validateLength = (maxLength: number) => {
  const validator = (value: string | null | undefined) => {
    if (_.isNil(value)) return undefined;

    if (value.length > maxLength) {
      return 'Zbyt długi opis';
    }
    return undefined;
  };

  return validator;
};
