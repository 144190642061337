import { createReducer } from '@reduxjs/toolkit';
import { Plan, ValidationErrors } from '../../../types';
import { fetchPlanSuccess, initNewPlan, locationChange, savePlanFailed, savePlanSuccess } from '../../actions';

const DEFAULT_STATE = {
  plan: {} as Plan,
  planErrors: {} as ValidationErrors<Plan>,
};

const planFormReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(initNewPlan, (state, action) => {
      state.plan = {} as Plan;
    })
    .addCase(savePlanSuccess, (state, action) => {
      state.plan = action.payload;
      state.planErrors = {} as ValidationErrors<Plan>;
    })
    .addCase(fetchPlanSuccess, (state, action) => {
      state.plan = action.payload;
      state.planErrors = {} as ValidationErrors<Plan>;
    })
    .addCase(savePlanFailed, (state, action) => {
      state.planErrors = action.payload;
    })
    .addCase(locationChange, (state, action) => {
      state.planErrors = {} as ValidationErrors<Plan>;
      // @ts-ignore
      if (action.payload.location.pathname === '/plan/new') {
        state.plan = {} as Plan;
      }
    }),
);

export default planFormReducer;
