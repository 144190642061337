import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';

const { confirm } = Modal;

type Props = {
  resourceId: number;
  isArchived: boolean;
  archive: Function;
  unarchive: Function;
  confirmModalTitle: string;
  confirmModalContent: string;
};

const ArchiveButton = ({
  resourceId,
  isArchived,
  archive,
  unarchive,
  confirmModalTitle,
  confirmModalContent,
}: Props) => {
  const [processing, setProcessing] = useState(false);

  const handleConfirm = () => {
    confirm({
      centered: true,
      title: confirmModalTitle,
      content: confirmModalContent,
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: onClick,
      onCancel() {},
    });
  };

  const onClick = async () => {
    try {
      setProcessing(true);
      isArchived ? await unarchive(resourceId) : await archive(resourceId);
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
    }
  };

  return (
    <Button size="large" disabled={!resourceId || processing} onClick={handleConfirm}>
      {processing ? <Spin /> : isArchived ? 'Przywróć z archiwum' : 'Archiwizuj'}
    </Button>
  );
};

export default ArchiveButton;
