import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Activity, FinancialEffect, Risk, ValidationErrors } from '../../../types';
import { entities, impacts, projects, strategicGoals } from '../dictionaries';
import { TASK_FUNDING_SOURCE_VALUES_YEAR_START } from '../../../utils/config';
import { currency } from '../../../utils/formatters';

export const activityFormBranch = (state: RootStateType) => state.activityForm;
export const activity = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.activity || new Activity(),
);
export const activityErrors = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.activityErrors || ({} as ValidationErrors<Activity>),
);
export const riskInfluences = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.riskInfluences || ([] as any),
);
export const riskProbabilities = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.riskProbabilities || ([] as any),
);
export const riskEntries = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.riskEntries || ([] as any),
);
export const editRiskModalOpened = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.editRiskModalOpened || (false as boolean),
);
export const editFinancialEffectModalOpened = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.editFinancialEffectModalOpened || (false as boolean),
);
export const editedRisk = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.editedRisk || ({} as any),
);
export const editedFinancialEffect = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.editedFinancialEffect || ({} as any),
);
export const planYears = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.planYears || ([] as Array<number>),
);
export const activityFinancialEffectsOptions = createSelector(
  activityFormBranch,
  (activityFormBranch) => activityFormBranch.activityFinancialEffectsOptions || ([] as Array<any>),
);

export const risks = createSelector(activity, (activity) => activity.risks || ([] as Array<Risk>));

export const riskList = createSelector(risks, (risks) =>
  risks.map((risk: Risk) => ({
    id: risk.id,
    entry: risk.entry,
    category: risk.entry,
    influence: risk.influence,
    probability: risk.probability,
  })),
);

export const activityProjectsProducts = createSelector(activity, projects, (activity, projects) =>
  activity.projects.map((projectId) => {
    const project = _.find(projects, { id: projectId });
    return (
      project && {
        id: project.id || -1,
        name: project.title,
        text: project.direct_effect,
        direct_beneficiaries: project.direct_beneficiaries,
        eventual_beneficiaries: project.eventual_beneficiaries,
      }
    );
  }),
);

export const activityProjectsResults = createSelector(activity, projects, (activity, projects) =>
  activity.projects.map((projectId) => {
    const project = _.find(projects, { id: projectId });
    return project && { id: project.id || -1, name: project.title, text: project.long_term_effect };
  }),
);

export const activityProjectsAccessibilityStandards = createSelector(activity, projects, (activity, projects) =>
  activity.projects.map((projectId) => {
    const project = _.find(projects, { id: projectId });
    return project && { id: project.id || -1, name: project.title, text: project.accessibility_standards };
  }),
);

export const financialEffects = createSelector(
  activity,
  (activity) => activity.financial_effects || ([] as Array<FinancialEffect>),
);

export const financialEffectsDataSource = createSelector(
  financialEffects,
  planYears,
  (financialEffects: any, planYears) =>
    planYears.length > 0
      ? financialEffects.map((effect: FinancialEffect) => {
          const entry: any = {
            financial_effect: effect.financial_effect,
          };
          planYears.map(
            (year: number) => (entry[year.toString()] = effect.values[year - TASK_FUNDING_SOURCE_VALUES_YEAR_START]),
          );
          return entry;
        })
      : [],
);

export const financialEffectsColumns = createSelector(
  activityFinancialEffectsOptions,
  planYears,
  (activityFinancialEffectsOptions, planYears) => {
    const getConsequenceDisplayName = (value: any, options: Array<any>): string => {
      const option = _.find(options, ['value', value]);
      if (option && option.display_name) return option.display_name;
      return '';
    };
    const columns: any = [
      {
        title: 'Skutek finansowy',
        dataIndex: 'financial_effect',
        key: 'financial_effect',
        align: 'left' as 'left',
        width: 300,
        render: (value: string) => {
          return getConsequenceDisplayName(value, activityFinancialEffectsOptions);
        },
      },
    ];
    if (planYears.length > 0)
      planYears.map((year: number) =>
        columns.push({
          title: year.toString(),
          dataIndex: year.toString(),
          key: year.toString(),
          render: currency,
          align: 'left' as 'left',
        }),
      );
    return columns;
  },
);

export const activityForm = createSelector(
  [
    activity,
    activityErrors,
    entities,
    impacts,
    strategicGoals,
    projects,
    activityProjectsProducts,
    activityProjectsResults,
    activityProjectsAccessibilityStandards,
    riskInfluences,
    riskProbabilities,
    risks,
    riskList,
    riskEntries,
    editRiskModalOpened,
    editFinancialEffectModalOpened,
    editedRisk,
    editedFinancialEffect,
    financialEffects,
    planYears,
    activityFinancialEffectsOptions,
    financialEffectsDataSource,
    financialEffectsColumns,
  ],
  // @ts-ignore
  (
    activity,
    activityErrors,
    entities,
    impacts,
    strategicGoals,
    projects,
    activityProjectsProducts,
    activityProjectsResults,
    activityProjectsAccessibilityStandards,
    riskInfluences,
    riskProbabilities,
    risks,
    riskList,
    riskEntries,
    editRiskModalOpened,
    editFinancialEffectModalOpened,
    editedRisk,
    editedFinancialEffect,
    financialEffects,
    planYears,
    activityFinancialEffectsOptions,
    financialEffectsDataSource,
    financialEffectsColumns,
  ) => ({
    activity,
    activityErrors,
    entities,
    impacts,
    strategicGoals,
    projects,
    activityProjectsProducts,
    activityProjectsResults,
    activityProjectsAccessibilityStandards,
    riskInfluences,
    riskProbabilities,
    risks,
    riskList,
    riskEntries,
    editRiskModalOpened,
    editFinancialEffectModalOpened,
    editedRisk,
    editedFinancialEffect,
    financialEffects,
    planYears,
    activityFinancialEffectsOptions,
    financialEffectsDataSource,
    financialEffectsColumns,
  }),
);
