import React from 'react';
import { Card, Collapse } from 'antd';
import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import { connect } from 'react-redux';

const { Panel } = Collapse;

type Props = ReturnType<typeof projectDetails>;

function EffectsSection(props: Props) {
  const { project } = props;

  const collapsePanels = [
    { header: 'Produkty przedsięwzięcia', content: project.direct_effect },
    { header: 'Rezultaty przedsięwzięcia', content: project.long_term_effect },
    { header: 'Trwałość przedsięwzięcia', content: project.sustainability },
    { header: 'Beneficjenci bezpośredni', content: project.direct_beneficiaries },
    { header: 'Beneficjenci końcowi', content: project.eventual_beneficiaries },
  ];

  return (
    <Card id="effects" className="top-level-card" title="Efekty realizacji">
      {collapsePanels.map((panel, index) => (
        <Collapse key={index} className="collapse-info" defaultActiveKey={index === 0 ? ['1'] : []} bordered={false}>
          <Panel className="collapse-info-panel" key="1" header={panel.header}>
            <p>{panel.content}</p>
          </Panel>
        </Collapse>
      ))}
    </Card>
  );
}

export default connect(projectDetails)(EffectsSection);
