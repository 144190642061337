import React from 'react';
import { useMemo } from 'react';
import { RealizationStatusEnum } from '../../../enums';
import './style.scss';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  status?: RealizationStatusEnum;
}

const getStatusClassName = (status: RealizationStatusEnum): string => {
  switch (status) {
    case RealizationStatusEnum.UNKNOWN:
      return '';
    case RealizationStatusEnum.IN_PROGRESS:
      return 'success';
    case RealizationStatusEnum.FINISHED:
      return 'primary';
    case RealizationStatusEnum.DELAYED:
      return 'warning';
    default:
      return '';
  }
};

const StatusCircleIcon: React.FC<Props> = ({ status, className }) => {
  const classes = useMemo(() => {
    const res = ['status-circle-icon'];
    if (className && className.length) {
      res.push(...className.split(' '));
    }
    if (status) {
      res.push(getStatusClassName(status));
    }
    return res.join(' ');
  }, [status, className]);
  return <span className={classes}></span>;
};

export default StatusCircleIcon;
