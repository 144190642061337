import { Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import ArrowGray from '../../../../../assets/icons/arrows/ArrowGray/ArrowGray';
import { RealizationStatusEnum } from '../../../../../enums';
import RealizationStatus from '../../../../Common/RealizationStatus/RealizationStatus';
import './style.scss';
import SubCollapse from './SubCollapse/SubCollapse';
import UnstyledLink from '../../../../Common/UnstyledLink';

interface CompletionProps {
  id: number;
  name: string;
  percent_done?: number;
  percent_executed_expenses?: number;
  beginning?: string;
  end?: string;
  index?: number;
  subindex?: number;
  realization_status?: RealizationStatusEnum;
}

interface Milestone {
  name: string;
  executor: string;
  planned_end: string;
  actual_end?: string;
}

interface ProjectProps extends CompletionProps {
  milestones?: Milestone[];
}

const badgeAction = (index: number) => <label className="badge action">Dz. {index}</label>;

const badgeGoal = () => <label className="badge goal">Cel</label>;

const badgeProject = (index: number = 0, subIndex: number = 0) => (
  <label className="badge project">
    Prz. {index}.{subIndex}
  </label>
);

const getCompletionSection = (percent_done?: number, percent_executed_expenses?: number) => (
  <section className="completion-info">
    <Tooltip trigger="hover" title="Wskaźnik realizacji rzeczowej" overlayClassName="tooltip-standard-dark">
      <div className="labeled-info">
        <label>Wykonanie rzeczowe</label>
        <p className="realization">{percent_done ? percent_done.toFixed(0) : 0}%</p>
      </div>
    </Tooltip>
    <Tooltip trigger="hover" title="Wskaźnik wykonania planu finansowego" overlayClassName="tooltip-standard-dark">
      <div className="labeled-info">
        <label>Wykonanie finansowe</label>
        <p className="finance-realization">{percent_executed_expenses ? percent_executed_expenses.toFixed(0) : 0}%</p>
      </div>
    </Tooltip>
  </section>
);

const getSummarySection = (
  data: {
    beginning?: string;
    end?: string;
    percent_done?: number;
    percent_executed_expenses?: number;
    status?: RealizationStatusEnum;
  },
  openCollapse?: (v: boolean) => void,
  isCollapsible?: boolean,
  isOpen?: boolean,
) => {
  const { beginning, end, percent_done, percent_executed_expenses, status } = data;
  return (
    <section className="summary">
      {isCollapsible && openCollapse ? (
        <button className={isOpen ? 'control open' : 'control'} onClick={() => openCollapse(!isOpen)}>
          <ArrowGray />
        </button>
      ) : null}
      <div>
        <div className="labeled-info">
          <label>Planowany początek:</label>
          <p>{beginning}</p>
        </div>
        <div className="labeled-info">
          <label>Planowany koniec:</label>
          <p>{end}</p>
        </div>
        {status ? (
          <div className="labeled-info">
            <label>Status:</label>
            <RealizationStatus status={status} />
          </div>
        ) : null}
      </div>
      {getCompletionSection(percent_done, percent_executed_expenses)}
    </section>
  );
};

const Goal: React.FC<CompletionProps> = ({ name, beginning, end, percent_done, percent_executed_expenses }) => {
  return (
    <li className="completion-item">
      <header>
        {badgeGoal()}
        <p className="title">{name}</p>
      </header>
      <div className="content">
        {getSummarySection(
          {
            beginning,
            end,
            percent_done,
            percent_executed_expenses,
          },
          undefined,
          false,
          false,
        )}
      </div>
    </li>
  );
};

const Activity: React.FC<CompletionProps> = ({
  id,
  name,
  beginning,
  end,
  percent_done,
  percent_executed_expenses,
  index,
  realization_status,
}) => {
  return (
    <li className="completion-item">
      <header>
        {index ? badgeAction(index) : null}
        <p className="title">
          <UnstyledLink to={`/activity/${id}`}>{name}</UnstyledLink>
        </p>
      </header>
      <div className="content">
        {getSummarySection(
          {
            beginning,
            end,
            percent_done,
            percent_executed_expenses,
            status: realization_status,
          },
          undefined,
          false,
          false,
        )}
      </div>
    </li>
  );
};

const Project: React.FC<ProjectProps> = ({
  id,
  name,
  beginning,
  end,
  percent_done,
  percent_executed_expenses,
  index,
  subindex,
  milestones,
  realization_status,
}) => {
  const [isContentOpen, setContentOpen] = useState(false);
  const expandable = useMemo(() => milestones && milestones.length, [milestones]);

  return (
    <li className="completion-item">
      <header>
        {badgeProject(index, subindex)}
        <p className="title">
          <UnstyledLink to={`/project/${id}#milestones`}>{name}</UnstyledLink>
        </p>
      </header>
      <div className={isContentOpen ? 'content open' : 'content'}>
        {expandable
          ? getSummarySection(
              {
                beginning,
                end,
                percent_done,
                percent_executed_expenses,
                status: realization_status,
              },
              setContentOpen,
              true,
              isContentOpen,
            )
          : getSummarySection(
              {
                beginning,
                end,
                percent_done,
                percent_executed_expenses,
                status: realization_status,
              },
              undefined,
              false,
              false,
            )}
        {expandable ? (
          <Collapse isOpened={isContentOpen} theme={{ collapse: 'collapse', content: 'collapse-content' }}>
            {milestones?.map((milestone, milestoneIndex) => (
              <SubCollapse title={milestone.name} key={milestoneIndex}>
                <div className="labeled-info">
                  <label>Planowana data zakończenia:</label>
                  <p>{milestone.planned_end}</p>
                </div>
                {milestone.actual_end ? (
                  <div className="labeled-info">
                    <label>Rzeczywista data zakończenia:</label>
                    <p>{milestone.actual_end}</p>
                  </div>
                ) : null}
                <div className="labeled-info">
                  <label>Lider / Realizator:</label>
                  <p>{milestone.executor}</p>
                </div>
              </SubCollapse>
            ))}
          </Collapse>
        ) : null}
      </div>
    </li>
  );
};

const Milestone: React.FC<Milestone> = ({ name }) => {
  return <SubCollapse title={name}></SubCollapse>;
};

const CompletionItem = {
  Goal,
  Activity,
  Project,
};

export default CompletionItem;
