import { createReducer } from '@reduxjs/toolkit';
import { OpenProjectSummary } from '../../../types';
import { fetchOpenProject, fetchOpenProjectSummarySuccess, locationChange } from '../../actions';

const DEFAULT_STATE = {
  project: {} as OpenProjectSummary,
  projectOptions: {} as any,
  taskOptions: {} as any,
  isLoading: true,
};

const openProjectDetailsReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(fetchOpenProject, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchOpenProjectSummarySuccess, (state, action) => {
      state.project = action.payload;
      state.isLoading = false;
    })
    // .addCase(fetchProjectOptionsSuccess, (state, action) => {
    //   state.projectOptions = action.payload;
    // })
    .addCase(locationChange, (state, action) => {
      if (action.payload) {
        // @ts-ignore
        if (!action.payload.location.hash) {
          return DEFAULT_STATE;
        }
      }
      return state;
    }),
);

export default openProjectDetailsReducer;
