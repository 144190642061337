import React from 'react';
import { Form, Input, Select } from 'formik-antd';
import { Card, Col, Row } from 'antd';
import { activityForm } from '../../../../redux/selectors/activity/activityForm';
import { connect } from 'react-redux';
import { antFilterFunc } from '../../../../utils/forms';
import { Entity, Impact, StrategicGoal } from '../../../../types';
import AddObjectDropdown from '../../../Common/AddObjectModals/AddObjectDropdown';
import AddEntityForm from '../../../Common/ModalForms/AddEntityForm';
import { Field, useFormikContext } from 'formik';
import YesNoToggle from '../../../Common/YesNoToggle';
import { displayInfoNotification } from '../../../../utils/notifications';

type Props = ReturnType<typeof activityForm>;

const BasicInfoForm: React.FunctionComponent<Props> = (props) => {
  const { activityErrors, entities, impacts, strategicGoals } = props;

  const {
    values,
    setFieldValue,
  }: {
    values: any;
    setFieldValue: any;
  } = useFormikContext();

  const mainImpacts = values.additional_impacts
    ? impacts.filter((impact: Impact) => !values.additional_impacts.includes(impact.id))
    : impacts;
  const additionalImpacts = values.main_impact
    ? impacts.filter((impact: Impact) => impact.id !== values.main_impact)
    : impacts;
  const availableStrategicGoals = strategicGoals.filter((goal: StrategicGoal) => goal.kind === values.kind);

  const PRICheckBoxes = [
    {
      name: 'local_administration_development',
      description: 'Budowanie zdolności instytucjonalnych i rozwój kompetencji lokalnej administracji publicznej',
    },
    {
      name: 'raising_standards',
      description: 'Usprawnienie i podniesienie standardów działania samorządów lokalnych',
    },
    {
      name: 'building_citizens_trust',
      description: 'Budowanie zaufania obywateli do instytucji publicznych na poziomie samorządu lokalnego',
    },
  ];

  return (
    <Card className="top-level-card" title="Informacje ogólne">
      <Form.Item
        label="Tytuł"
        name="title"
        validateStatus={activityErrors.title ? 'error' : ''}
        help={activityErrors.title ? activityErrors.title.join(', ') : ''}
      >
        <Input name="title" />
      </Form.Item>

      <Form.Item
        label="Rodzaj"
        name="kind"
        validateStatus={activityErrors.kind ? 'error' : ''}
        help={activityErrors.kind ? activityErrors.kind.join(', ') : ''}
      >
        <Select
          name="kind"
          onChange={() => {
            const strategicGoalCount = values.strategic_goals.length;
            setFieldValue('strategic_goals', []);
            if (strategicGoalCount) {
              displayInfoNotification(
                'Cele strategiczne odpowiadały wcześniejszemu rodzajowi programu, więc należy je uzupełnić na nowo.',
                'Uzupełnij cele strategiczne',
              );
            }
          }}
        >
          <Select.Option value="PRL">PRL</Select.Option>
          <Select.Option value="PRI">PRI</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Lider działania"
        name="activity_leader"
        validateStatus={activityErrors.activity_leader ? 'error' : ''}
        help={activityErrors.activity_leader ? activityErrors.activity_leader.join(', ') : ''}
      >
        <Select
          name="activity_leader"
          dropdownRender={(menu) => <AddObjectDropdown menu={menu} title={'Dodaj podmiot'} form={<AddEntityForm />} />}
          showSearch
          optionFilterProp="children"
          filterOption={antFilterFunc}
        >
          {entities.map((entity: Entity) => (
            <Select.Option value={entity.id || -1} key={entity.id || -1}>
              {entity.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Row>
        <Col span={24}>
          <Form.Item
            label="Cele strategiczne, w które wpisuje się działanie"
            name="strategic_goals"
            validateStatus={activityErrors.strategic_goals ? 'error' : ''}
            help={activityErrors.strategic_goals ? activityErrors.strategic_goals.join(', ') : ''}
          >
            <Select
              name="strategic_goals"
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={antFilterFunc}
            >
              {availableStrategicGoals.map((goal: StrategicGoal) => (
                <Select.Option value={goal.id || -1} key={goal.id || -1}>
                  {goal.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {values.kind === 'PRL' && (
        <>
          <Row>
            <Col span={11}>
              <Form.Item
                label="Kluczowy wpływ działania"
                name="main_impact"
                validateStatus={activityErrors.main_impact ? 'error' : ''}
                help={activityErrors.main_impact ? activityErrors.main_impact.join(', ') : ''}
              >
                <Select name="main_impact" showSearch optionFilterProp="children" filterOption={antFilterFunc}>
                  {mainImpacts.map((impact: Impact) => (
                    <Select.Option value={impact.id || -1} key={impact.id || -1}>
                      {impact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item label="Uzupełniające wpływy działania" name="additional_impacts">
                <Select
                  mode="multiple"
                  name="additional_impacts"
                  showSearch
                  optionFilterProp="children"
                  filterOption={antFilterFunc}
                >
                  {additionalImpacts.map((impact: Impact) => (
                    <Select.Option value={impact.id || -1} key={impact.id || -1}>
                      {impact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Form.Item name="re_granting" valuePropName="checked">
          <Field name="re_granting" component={YesNoToggle}>
            Czy działanie obejmuje re-granting?
          </Field>
        </Form.Item>
      </Row>
      {values.kind === 'PRI' && (
        <Card size="small" title="Wpływ działania" headStyle={{ textAlign: 'left' }}>
          {PRICheckBoxes.map((checkbox) => (
            <Row key={checkbox.name}>
              <Form.Item name={checkbox.name}>
                <Field name={checkbox.name} component={YesNoToggle}>
                  {checkbox.description}
                </Field>
              </Form.Item>
            </Row>
          ))}
        </Card>
      )}
    </Card>
  );
};

export default connect(activityForm)(BasicInfoForm);
