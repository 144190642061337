import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProjectList from './components/Project/ProjectList';
import ProjectForm from './components/Project/ProjectForm';
import { auth } from './redux/selectors/auth';
import Login from './components/Login';
import { connect } from 'react-redux';
import ProjectDetails from './components/Project/ProjectDetails';
import ActivityForm from './components/Activity/ActivityForm';
import ActivityList from './components/Activity/ActivityList';
import ActivityDetails from './components/Activity/ActivityDetails';
import PlanList from './components/Plan/PlanList';
import PlanForm from './components/Plan/PlanForm';
import PlanDetails from './components/Plan/PlanDetails';
import CityGoals from './components/City';
import OpenProjectList from './components/OpenProject/OpenProjectList/index';
import OpenProjectForm from './components/OpenProject/OpenProjectForm';
import OpenProjectDetails from './components/OpenProject/OpenProjectDetails';

import IndicatorList from './components/Indicator/IndicatorList';
import MeasureUnitList from './components/Indicator/MeasureUnitList';
import VerificationSourceList from './components/Indicator/VerificationSourceList';
import MFiPR from './components/MFiPR';

type Props = ReturnType<typeof auth>;

const Routes: React.FunctionComponent<Props> = ({ token, user }) => (
  <Switch>
    <Route exact path="/login" component={Login} />
    {!token && <Redirect to="/login" />}
    <Route exact path="/">
      <Redirect to="/project" />
    </Route>
    <Route exact path="/mfipr" component={MFiPR} />
    {user.is_ministry && <Redirect to="/mfipr" />}
    <Route exact path="/project" component={ProjectList} />
    <Route exact path="/project/:id" component={ProjectDetails} />
    <Route exact path="/project/:id/edit" component={ProjectForm} />
    <Route exact path="/project/:id/edit/:tab" component={ProjectForm} />
    <Route exact path="/project/new/:kind" component={ProjectForm} />
    <Route exact path="/project/new/:kind/:tab" component={ProjectForm} />
    <Route exact path="/activity" component={ActivityList} />
    <Route exact path="/activity/new/:kind" component={ActivityForm} />
    <Route exact path="/activity/new/:kind/:tab" component={ActivityForm} />
    <Route exact path="/activity/:id" component={ActivityDetails} />
    <Route exact path="/activity/:id/edit" component={ActivityForm} />
    <Route exact path="/activity/:id/edit/:tab" component={ActivityForm} />
    <Route exact path="/plan" component={PlanList} />
    <Route exact path="/plan/new" component={PlanForm} />
    <Route exact path="/plan/:id" component={PlanDetails} />
    <Route exact path="/plan/:id/edit" component={PlanForm} />
    <Route exact path="/plan/:id/edit/:tab" component={PlanForm} />
    <Route exact path="/city/goals" component={CityGoals} />
    <Route exact path="/open_project" component={OpenProjectList} />
    <Route exact path="/open_project/new" component={OpenProjectForm} />
    <Route exact path="/open_project/new/:tab" component={OpenProjectForm} />
    <Route exact path="/open_project/:id" component={OpenProjectDetails} />
    <Route exact path="/open_project/:id/edit" component={OpenProjectForm} />
    <Route exact path="/open_project/:id/edit/:tab" component={OpenProjectForm} />
    <Route exact path="/indicators" component={IndicatorList} />
    <Route exact path="/measure_units" component={MeasureUnitList} />
    <Route exact path="/verification_sources" component={VerificationSourceList} />
  </Switch>
);

export default connect(auth)(Routes);
