import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, Input } from 'antd';
import { login } from '../../redux/actions';
import { auth } from '../../redux/selectors/auth';

type Props = ReturnType<typeof auth> & typeof mapDispatchToProps;

const Login: React.FunctionComponent<Props> = ({ didLoginFail, login }) => {
  // FIXME: strict typing for onSubmit argument
  const onSubmit = (values: any) => {
    const { username, password } = values;
    login({ username, password });
  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-container">
      <Form
        className="login-form"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
      >
        {didLoginFail && <Alert message="Nieprawidłowe dane logowania lub brak przypisanego miasta." type="error" />}

        <Form.Item label="Login" name="username" rules={[{ required: true, message: 'Wpisz login' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Hasło" name="password" rules={[{ required: true, message: 'Wpisz hasło' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
          <Button type="primary" htmlType="submit">
            Zaloguj
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapDispatchToProps = { login };
export default connect(auth, mapDispatchToProps)(Login);
