import { Project } from '../../../../types';

export const tabFieldsMap: { [key: string]: Array<keyof Project> } = {
  basic: ['title', 'number', 'type', 'venue', 'reporter_type', 'executor', 'beginning', 'end', 'depends_on'],
  description: ['background', 'scope_description', 'accessibility_standards'],
  policies: [
    'gender_equality',
    'sustainable_development',
    'non_discrimination',
    'local_administration_development',
    'raising_standards',
    'building_citizens_trust',
  ],
  effects: ['direct_effect', 'long_term_effect', 'sustainability', 'direct_beneficiaries', 'eventual_beneficiaries'],
  impact: ['main_impact', 'additional_impact', 'strategic_goal_impacts'],
  related: ['related_entities'],
  schedule: ['tasks'],
  public_aid: ['requires_public_aid', 'public_aid_test'],
};
