import React, { SVGProps } from 'react';
import './style.scss';

const CloseIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.828"
      height="10.828"
      viewBox="0 0 10.828 10.828"
      className="close-icon"
      {...props}
    >
      <g transform="translate(1.414 1.414)">
        <line className="a" x2="8" y2="8" />
        <line className="a" x1="8" y2="8" />
      </g>
    </svg>
  );
};

export default CloseIcon;
