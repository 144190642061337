import { Tag } from 'antd';
import React from 'react';

export const checkBoxIconMap = (value: boolean | null) => {
  let className = 'toggle-value';
  let label = '?';
  if (value === true) {
    className += ' yes';
    label = 'TAK';
  } else if (value === false) {
    className += ' no';
    label = 'NIE';
  }
  return <Tag className={className}>{label}</Tag>;
};

export const publicAidIconMap = (value: string | null) => {
  let className = 'toggle-value';
  let label = '?';
  if (value === 'YES') {
    className += ' yes';
    label = 'TAK';
  } else if (value === 'NO') {
    className += ' no';
    label = 'NIE';
  } else if (value === 'PARTIALLY') {
    className += ' partially';
    label = 'CZĘŚCIOWO';
  }
  return <Tag className={className}>{label}</Tag>;
};
