import { createReducer } from '@reduxjs/toolkit';
import {
  changeActivitiesPagination,
  fetchActivitiesSuccess,
  setActivityListOrdering,
  setActivityRealizationFilter,
} from '../../actions';
import { Activity } from '../../../types';
import { ArchiveFilter, RealizationStatusEnum } from '../../../enums';
import { setActivityListSearch, setActivitySearchArchiveFilter } from '../../actions/index';

const DEFAULT_STATE = {
  activities: [] as Array<Activity>,
  activitiesCount: 0,
  page: 1,
  pageSize: 10,
  filters: {
    archive: ArchiveFilter.Hide,
    realizationStatus: [] as RealizationStatusEnum[],
  },
  ordering: '',
  search: '',
};

const activityListReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(changeActivitiesPagination, (state, action) => {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    })
    .addCase(fetchActivitiesSuccess, (state, action) => {
      state.activities = action.payload.results;
      state.activitiesCount = action.payload.count;
    })
    .addCase(setActivitySearchArchiveFilter, (state, action) => {
      state.filters.archive = action.payload;
    })
    .addCase(setActivityListOrdering, (state, action) => {
      state.ordering = action.payload;
    })
    .addCase(setActivityListSearch, (state, action) => {
      state.search = action.payload;
    })
    .addCase(setActivityRealizationFilter, (state, action) => {
      state.filters.realizationStatus = action.payload;
    }),
);

export default activityListReducer;
