import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row, Tabs } from 'antd';
import { connect } from 'react-redux';
import {
  fetchAllProjects,
  fetchEntities,
  fetchEntityOptions,
  fetchFundingSources,
  fetchImpacts,
  fetchProject,
  fetchProjectOptions,
  fetchProjectTasks,
  fetchReporterTypes,
  fetchStrategicGoals,
  fetchTaskOptions,
  initNewProject,
  saveProject,
} from '../../../redux/actions';
import { projectForm } from '../../../redux/selectors/project/projectForm';
import BasicInfoForm from './Tabs/BasicInfoForm';
import PoliciesForm from './Tabs/PoliciesForm';
import DescriptionForm from './Tabs/DescriptionForm';
import EffectsForm from './Tabs/EffectsForm';
import RelatedEntitiesForm from './Tabs/RelatedEntitiesForm';
import TasksForm from './Tabs/ScheduleForm';
import FormTabPane from '../../Common/FormTabPane';
import ImpactForm from './Tabs/ImpactForm';
import EditTaskModal from './EditTaskModal';
import { tabFieldsMap } from './Tabs/tabFieldsMap';
import AddObjectModal from '../../Common/AddObjectModals/AddObjectModal';
import ModalContext from '../../Common/AddObjectModals/ModalContext';
import EditTaskFundingSourcesModal from './EditTaskFundingSourcesModal';
import UnstyledLink from '../../Common/UnstyledLink';
import back from '../../../assets/back.svg';
import PublicAidForm from './Tabs/PublicAidForm';

type Props = ReturnType<typeof projectForm> & typeof mapDispatchToProps;

type Params = {
  id: string;
  kind: string;
  tab: string;
};

/**
 * This form handles both new project creation and updates of
 * existing projects. The two cases can be distinguished by url params:
 * match.params.id present - updating existing project with given id
 * match.params.type present - creating new project of given type (PRL/PRI)
 */
const ProjectForm: React.FunctionComponent<Props> = (props) => {
  const {
    project,
    fetchProjectOptions,
    fetchEntities,
    fetchReporterTypes,
    fetchImpacts,
    initNewProject,
    projectErrors,
    fetchProject,
    fetchEntityOptions,
    saveProject,
    fetchStrategicGoals,
    fetchTaskOptions,
    fetchProjectTasks,
    fetchFundingSources,
    fetchAllProjects,
    tasks,
  } = props;

  const { id, kind, tab } = useParams<Params>();

  useEffect(() => {
    // options object contains choices for choice fields
    // and verbose names for all fields
    fetchProjectOptions();
    fetchEntities();
    fetchReporterTypes();
    fetchImpacts();
    fetchEntityOptions();
    fetchStrategicGoals();
    fetchTaskOptions();
    fetchFundingSources();
    fetchAllProjects();
    if (id) {
      // editing existing project
      fetchProject(parseInt(id));
      fetchProjectTasks(parseInt(id));
    } else if (kind) {
      // creating new project
      initNewProject(kind);
    } else {
      // this should never happen with correct routing setup
      console.error('ProjectFrom: neither project id nor project type set.');
    }
  }, [
    fetchProjectOptions,
    fetchEntities,
    fetchReporterTypes,
    fetchImpacts,
    initNewProject,
    fetchProject,
    fetchEntityOptions,
    fetchStrategicGoals,
    fetchTaskOptions,
    fetchProjectTasks,
    fetchFundingSources,
    fetchAllProjects,
    id,
    kind,
  ]);

  useEffect(() => {
    id && fetchProject(parseInt(id));
  }, [fetchProject, id, tasks]);

  const [isAddObjectModalVisible, setAddObjectModalVisible] = useState(false);
  const [addObjectModalTitle, setAddObjectModalTitle] = useState('');
  const [addObjectModalContent, setAddObjectModalContent] = useState<ReactElement | undefined | null>();

  const showAddObjectModal = (title: string, form: ReactElement) => {
    setAddObjectModalTitle(title);
    setAddObjectModalContent(form);
    setAddObjectModalVisible(true);
  };

  const closeAddObjectModal = () => {
    setAddObjectModalVisible(false);
  };

  const hasErrors = (tabKey: string) => _.intersection(tabFieldsMap[tabKey], _.keys(projectErrors)).length > 0;

  const onSubmit = (values: any) => {
    // dates that come from ant date picker component are moment.js dates
    // we need to format them to conform to api requirements
    const date_format = 'YYYY-MM-DD';
    values.beginning = values.beginning ? moment(values.beginning).format(date_format) : null;
    values.end = values.end ? moment(values.end).format(date_format) : null;

    let toSave = {
      ...project,
      ...values,
    };

    saveProject(toSave);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Form validation failed:', errorInfo);
  };

  const initialValues = {
    ...project,
    date_range: [project.beginning ? moment(project.beginning) : null, project.end ? moment(project.end) : null],
  };

  // FIXME: strict typing
  // show tab validation status: https://github.com/ant-design/ant-design/issues/17146
  const renderTabBar = (tabBarProps: any, DefaultTabBar: any) => (
    <DefaultTabBar {...tabBarProps}>
      {(node: any) => (
        <React.Fragment key={node.key}>
          {hasErrors(node.key)
            ? React.cloneElement(node, {
                className: `${node.props.className} tab-with-errors`,
              })
            : node}
        </React.Fragment>
      )}
    </DefaultTabBar>
  );

  const url = id ? `/project/${id}/edit` : `/project/new/${kind}`;

  const history = useHistory();
  const goBack = () => {
    history.push(id ? `/project/${id}` : '/project');
  };

  return (
    <>
      <Row className="title-bar form">
        <Col className="title" span={24}>
          <Button className="back-button" size="large" onClick={goBack}>
            <img src={back} alt="edit icon" />
            Powrót
          </Button>
          <div>{project.title}</div>
        </Col>
      </Row>
      <ModalContext.Provider
        value={{
          showModal: showAddObjectModal,
          closeModal: closeAddObjectModal,
        }}
      >
        <Tabs
          className="form-tabs"
          defaultActiveKey="basic"
          activeKey={tab || 'basic'}
          renderTabBar={renderTabBar}
          destroyInactiveTabPane={true}
          tabPosition="left"
          tabBarStyle={{ border: 'none' }}
        >
          <FormTabPane
            tab={<UnstyledLink to={`${url}/basic`}>Dane identyfikacyjne</UnstyledLink>}
            key="basic"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <BasicInfoForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/description`} disabled={!project.id}>
                Opis
              </UnstyledLink>
            }
            key="description"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <DescriptionForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/effects`} disabled={!project.id}>
                Efekty realizacji
              </UnstyledLink>
            }
            key="effects"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <EffectsForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/impact`} disabled={!project.id}>
                Wpływ
              </UnstyledLink>
            }
            key="impact"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <ImpactForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/related`} disabled={!project.id}>
                Podmioty zaangażowane
              </UnstyledLink>
            }
            key="related"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <RelatedEntitiesForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/schedule`} disabled={!project.id}>
                Harmonogram i finansowanie
              </UnstyledLink>
            }
            key="schedule"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
            showSubmitSection={false}
          >
            <TasksForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/policies`} disabled={!project.id}>
                Zgodność z politykami
              </UnstyledLink>
            }
            key="policies"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <PoliciesForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/public_aid`} disabled={!project.id}>
                Pomoc publiczna
              </UnstyledLink>
            }
            key="public_aid"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <PublicAidForm />
          </FormTabPane>
        </Tabs>

        <AddObjectModal
          title={addObjectModalTitle}
          content={addObjectModalContent}
          isVisible={isAddObjectModalVisible}
          closeModal={closeAddObjectModal}
        />
        <EditTaskModal />
        <EditTaskFundingSourcesModal />
      </ModalContext.Provider>
    </>
  );
};

const mapDispatchToProps = {
  initNewProject,
  fetchProjectOptions,
  fetchEntities,
  fetchReporterTypes,
  fetchImpacts,
  fetchProject,
  saveProject,
  fetchEntityOptions,
  fetchStrategicGoals,
  fetchTaskOptions,
  fetchProjectTasks,
  fetchFundingSources,
  fetchAllProjects,
};
export default connect(projectForm, mapDispatchToProps)(ProjectForm);
