import { Form, FormInstance, Input } from 'antd';
import React from 'react';
import { ProductModalFormValues } from './index';

interface Props {
  form: FormInstance<ProductModalFormValues>;
  onFinish: (values: ProductModalFormValues) => void;
}

const ProductModalForm: React.FC<Props> = ({ form, onFinish }) => {
  return (
    <Form form={form} onFinish={onFinish} className="modal-form">
      <div className="form-row">
        <Form.Item name="name" rules={[{ required: true, message: 'Nazwa wymagana' }]} label="Nazwa" className="full">
          <Input placeholder="Podaj nazwę" />
        </Form.Item>
      </div>
    </Form>
  );
};

export default ProductModalForm;
