import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import './style.scss';
import { PlanSummary } from '../../../../types';
import exportIcon from '../../../../assets/export.svg';
import { getPlanIndicatorsProgressExcel, getPlanMonitoringExcel } from '../../api';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import FileSaver from 'file-saver';
import { AxiosResponse } from 'axios';
import IndicatorMonitoringModal from './IndicatorMonitoringModal';

interface Props {
  plan: PlanSummary;
}

interface Params {
  id: string;
}

export const save_excel = (response: AxiosResponse<any>) => {
  const blob_config = { type: response.headers['content-type'] };
  const blob = new Blob([response.data], blob_config);
  const filename = response.headers['content-disposition'].split(';')[1].replace(' ', '').split('=')[1];
  FileSaver.saveAs(blob, filename);
};

const PlanExcelExport: React.FC<Props> = () => {
  const [visible, setVisibility] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { id: planId } = useParams<Params>();

  const downloadMonitoringExcel = useMutation(getPlanMonitoringExcel, {
    onSuccess: (response) => save_excel(response),
  });

  const downloadIndicatorsProgressExcel = useMutation(getPlanIndicatorsProgressExcel, {
    onSuccess: (response) => save_excel(response),
  });

  const content = (
    <React.Fragment>
      <Button type="primary" onClick={() => downloadMonitoringExcel.mutate(planId)}>
        Monitoring rzeczowy
      </Button>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Monitoring wskaźników
      </Button>
      <Button type="primary" onClick={() => downloadIndicatorsProgressExcel.mutate(planId)}>
        Wskaźniki a przedsięwzięcia
      </Button>
      {/* <Button type="primary">Uzupełnij dane</Button>
      <Button type="primary">Uzupełnij dane</Button> */}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Popover
        title="Eksport Excel"
        content={content}
        placement="bottom"
        trigger="click"
        visible={visible}
        onVisibleChange={setVisibility}
        overlayClassName="excel-export-popover"
      >
        <Button size="large">
          <img src={exportIcon} alt="export icon" /> Eksportuj raporty
        </Button>
      </Popover>
      <IndicatorMonitoringModal planId={planId} visible={modalVisible} onClose={() => setModalVisible(false)} />
    </React.Fragment>
  );
};

export default PlanExcelExport;
