import { store } from 'react-notifications-component';

export const displaySuccessNotification = (message: string, title: string | null = null) => {
  store.addNotification({
    title: title || undefined,
    message,
    type: 'success',
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 10000,
    },
  });
};

export const displayInfoNotification = (message: string, title: string | null = null) => {
  store.addNotification({
    title: title || undefined,
    message,
    type: 'info',
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 10000,
    },
  });
};

export const displayErrorNotification = (message: string, title: string | null = null) => {
  store.addNotification({
    title: title || 'Błąd',
    message,
    type: 'danger',
    insert: 'bottom',
    container: 'bottom-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 10000,
    },
  });
};
