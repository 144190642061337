import React from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';

import L from 'leaflet';

import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import blueIcon from 'leaflet/dist/images/marker-icon-2x.png';
import greenIcon from '../../../assets/marker-icon-2x-green.png';
import blackIcon from '../../../assets/marker-icon-2x-black.png';
import redIcon from '../../../assets/marker-icon-2x-red.png';

import GeolocationSearchBar from './GeolocationSearchBar';
import { useEffect } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { OpenProjectMapped } from '../../../types';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';

const createMarkerIcon = (icon: string) => {
  return L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
};

const getMarkerIcon = (progressStatus: 'IN_PREPARATION' | 'IN_PROGRESS' | 'REALIZED' | null) => {
  switch (progressStatus) {
    case 'IN_PREPARATION':
      return createMarkerIcon(redIcon);
    case 'IN_PROGRESS':
      return createMarkerIcon(blueIcon);
    case 'REALIZED':
      return createMarkerIcon(greenIcon);
    default:
      return createMarkerIcon(blackIcon);
  }
};

type Props = {
  data?: Array<OpenProjectMapped>;
  onMarkerClick?: Function;
  onMapClick?: Function;
  editMode?: boolean;
  initialLocation?: string;
  showSearch?: boolean;
  isLoading?: boolean;
  isError?: boolean;
};

type LocationMarkersProps = {
  data: Array<OpenProjectMapped>;
  onMarkerClick?: Function;
  onMapClick?: Function;
  initialLocation?: string;
  editMode?: boolean;
};

const LocationMarkers = ({
  data,
  initialLocation,
  onMarkerClick,
  onMapClick,
  editMode = false,
}: LocationMarkersProps) => {
  const map = useMap();

  useEffect(() => {
    const searchForLocation = async (value: string) => {
      const provider = new OpenStreetMapProvider();
      return await provider.search({ query: value });
    };

    const zoomToLocation = async (value: string) => {
      const results = await searchForLocation(value);
      if (results.length) {
        const result = results[0];
        map.fitBounds(new L.LatLngBounds(result.bounds as any), { animate: false });
      }
    };

    new SimpleMapScreenshoter({ screenName: 'plan_rozwoju_strategia_na_mapie' }).addTo(map);

    if (data.length) {
      // Zoom in so that all markers all visible
      const bounds = L.latLngBounds(data.map((item) => [item.geolocation1_latitude, item.geolocation1_longitude]));
      map.fitBounds(bounds);
    } else {
      initialLocation && zoomToLocation(initialLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMapEvents({
    click(e) {
      if (editMode && onMapClick) {
        onMapClick(e.latlng);
      }
    },
  });

  return (
    <>
      {data.map((item) => (
        <Marker
          key={item.id}
          position={{ lat: item.geolocation1_latitude, lng: item.geolocation1_longitude }}
          icon={getMarkerIcon(item.progress_status)}
          eventHandlers={{
            click: () => onMarkerClick && onMarkerClick(item),
          }}
        />
      ))}
    </>
  );
};

const MapNotificationBox = ({ text }: { text: string }) => {
  return <div style={{ height: 70, width: '100%', textAlign: 'center' }}>{text}</div>;
};

const GeolocationMap = ({
  data,
  initialLocation,
  onMarkerClick,
  onMapClick,
  editMode = false,
  showSearch = false,
  isLoading = false,
  isError = false,
}: Props) => {
  return (
    <MapContainer
      style={{ height: 450, width: '100%' }}
      // Zoom to Poland by default
      center={[51.9189, 19.1344]}
      zoom={6}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {isLoading ? (
        <MapNotificationBox text="Trwa pobieranie danych..." />
      ) : isError ? (
        <MapNotificationBox text="Wystąpił błąd podczas pobierania danych." />
      ) : !data ? null : (
        <LocationMarkers
          data={data}
          initialLocation={initialLocation}
          editMode={editMode}
          onMarkerClick={onMarkerClick}
          onMapClick={onMapClick}
        />
      )}
      {showSearch && <GeolocationSearchBar editMode={editMode} setCoordinatesCallback={onMapClick} />}
    </MapContainer>
  );
};

export default GeolocationMap;
