import { createReducer } from '@reduxjs/toolkit';
import {
  changeOpenProjectsPagination,
  fetchOpenProjectsSuccess,
  setOpenProjectListOrdering,
  setOpenProjectListSearch,
  setOpenProjectSearchExecutorsFilter,
  setOpenProjectSearchProgressStatusFilter,
  setOpenProjectSearchTypeFilter,
} from '../../actions';
import { OpenProject } from '../../../types';
import { ArchiveFilter, ProgressStatusFilter, TypeFilter } from '../../../enums';

const DEFAULT_STATE = {
  projects: [] as Array<OpenProject>,
  projectsCount: 0,
  page: 1,
  pageSize: 10,
  filters: {
    archive: ArchiveFilter.Hide,
    type: TypeFilter.None,
    progress_status: ProgressStatusFilter.None,
    executors: [] as Array<number>,
  },
  ordering: '',
  search: '',
};

const openProjectListReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(changeOpenProjectsPagination, (state, action) => ({
      ...state,
      page: action.payload.page,
      pageSize: action.payload.pageSize,
    }))
    .addCase(fetchOpenProjectsSuccess, (state, action) => ({
      ...state,
      projects: action.payload.results,
      projectsCount: action.payload.count,
    }))
    .addCase(setOpenProjectSearchTypeFilter, (state, action) => {
      state.filters.type = action.payload;
    })
    .addCase(setOpenProjectSearchProgressStatusFilter, (state, action) => {
      state.filters.progress_status = action.payload;
    })
    .addCase(setOpenProjectSearchExecutorsFilter, (state, action) => {
      state.filters.executors = action.payload;
    })
    .addCase(setOpenProjectListOrdering, (state, action) => {
      state.ordering = action.payload;
    })
    .addCase(setOpenProjectListSearch, (state, action) => {
      state.search = action.payload;
    }),
);

export default openProjectListReducer;
