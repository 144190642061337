import React, { useState } from 'react';
import { Modal } from 'antd';

function Changelog({ changelogData }: { changelogData: Array<any> }) {
  const [isChangelogModalVisible, setChangelogModalVisible] = useState(false);

  const showChangelogModal = () => {
    setChangelogModalVisible(true);
  };

  const closeChangelogModal = () => {
    setChangelogModalVisible(false);
  };

  return changelogData[0] ? (
    isChangelogModalVisible ? (
      <>
        <span>{'v' + changelogData[0].tag}</span>
        <Modal title="CHANGELOG" visible={isChangelogModalVisible} onCancel={closeChangelogModal} footer={null}>
          {changelogData.map((version: any) => (
            <div>
              <h3>
                <b>{version.tag}</b> ({version.date})
              </h3>
              <ul>
                {version.changes.map((change: any) => (
                  <li>
                    {change.type}
                    <ul>
                      {change.content.map((feature: any) => {
                        if ('commit' in feature.body) {
                          return (
                            <li>
                              <b>{feature.title !== 'Unnamed' ? feature.title + ': ' : null}</b>
                              {feature.body.description}
                            </li>
                          );
                        }
                        return '';
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Modal>
      </>
    ) : (
      <span onClick={() => showChangelogModal()}>{'v' + changelogData[0].tag}</span>
    )
  ) : null;
}

export default Changelog;
