import React, { SVGProps } from 'react';
import './style.scss';

const SearchIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.829"
      height="16.828"
      viewBox="0 0 16.829 16.828"
      className="search-icon"
      {...props}
    >
      <g className="a">
        <circle className="c" cx="7.5" cy="7.5" r="7.5" />
        <circle className="d" cx="7.5" cy="7.5" r="6.5" />
      </g>
      <rect className="b" width="6" height="2" rx="1" transform="translate(12.586 11.172) rotate(45)" />
    </svg>
  );
};

export default SearchIcon;
