import React from 'react';
import { connect } from 'react-redux';
import { Card, Table } from 'antd';
import { planDetails } from '../../../redux/selectors/plan/planDetails';

type Props = ReturnType<typeof planDetails>;

function FundingSection(props: Props) {
  const { fundingTable } = props;

  return (
    <Card id="funding" className="top-level-card" title="Finansowanie">
      <Table
        className="funding-table"
        columns={fundingTable.columns}
        dataSource={fundingTable.rows}
        size="small"
        pagination={false}
        rowClassName={(record: any) => {
          const isPrimaryCostRow =
            record.key === 'primaryNonInvestmentSummary' || record.key === 'primaryInvestmentSummary';

          const exceededClass = isPrimaryCostRow
            ? record.exceeded
              ? 'primary-cost-exceeded'
              : 'primary-cost-not-exceeded'
            : '';
          const activityTitleClass = record.key.startsWith('activityTitle') ? 'activity-title-row' : '';

          return activityTitleClass + exceededClass;
        }}
      />
    </Card>
  );
}

export default connect(planDetails)(FundingSection);
