import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from './config';
import { getAuthToken } from './storage';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const authorizationInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getAuthToken();
  if (token) {
    config.headers.authorization = `Token ${getAuthToken()}`;
  }

  return config;
};

const client = axios.create({
  baseURL: API_URL,
});

client.interceptors.request.use(authorizationInterceptor);

export { client };
