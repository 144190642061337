import React from 'react';
import { connect } from 'react-redux';
import { archivePlan, unarchivePlan } from '../api';
import { fetchPlanSummarySuccess } from '../../../redux/actions/index';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/notifications';
import { PlanSummary } from '../../../types';
import ArchiveButton from '../../Common/ArchiveButton';

type Props = {
  plan: PlanSummary;
} & typeof mapDispatchToProps;

const ArchivePlanButton = ({ plan, fetchPlanSummarySuccess }: Props) => {
  const archive = async () => {
    try {
      const response = await archivePlan(plan.id!);
      fetchPlanSummarySuccess(response.data);
      displaySuccessNotification('Archiwizacja planu zakończona pomyślnie.');
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.message) {
          return displayErrorNotification(e.response.data.message);
        }
        displayErrorNotification('Błąd archiwizacji planu.');
      } else {
        displayErrorNotification('Błąd komunikacji z serwerem.');
      }
      throw e;
    }
  };

  const unarchive = async () => {
    try {
      const response = await unarchivePlan(plan.id!);
      fetchPlanSummarySuccess(response.data);
      displaySuccessNotification('Przywrócenie planu z archiwum zakończone pomyślnie.');
    } catch (e) {
      if (e.response) {
        displayErrorNotification('Błąd przywrócenia planu z archiwum.');
      } else {
        displayErrorNotification('Błąd komunikacji z serwerem.');
      }
      throw e;
    }
  };

  const confirmModalTitle = plan.archived
    ? 'Czy na pewno chcesz przywrócić plan z archiwum?'
    : 'Czy na pewno chcesz zarchiwizować plan?';

  const confirmModalContent = '';

  return (
    <ArchiveButton
      resourceId={plan.id!}
      isArchived={plan.archived}
      archive={archive}
      unarchive={unarchive}
      confirmModalTitle={confirmModalTitle}
      confirmModalContent={confirmModalContent}
    />
  );
};

const mapDispatchToProps = {
  fetchPlanSummarySuccess,
};

export default connect(null, mapDispatchToProps)(ArchivePlanButton);
