import React, { ReactElement, useContext } from 'react';
import { Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalContext from './ModalContext';

type Props = {
  menu: ReactElement;
  title: string;
  form: ReactElement;
};

function AddObjectDropdown(props: Props) {
  const { menu, title, form } = props;
  const { showModal } = useContext(ModalContext);

  return (
    <div>
      {menu}
      <Divider />
      <div>
        <Button onClick={() => showModal && showModal(title, form)}>
          <PlusOutlined /> Dodaj
        </Button>
      </div>
    </div>
  );
}

export default AddObjectDropdown;
