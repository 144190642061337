import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Drawer, Row } from 'antd';
import { Form, Input, Select } from 'formik-antd';
import { activityForm } from '../../../../redux/selectors/activity/activityForm';
import { validateLength } from '../../../../utils/validation';
import { Project } from '../../../../types';
import { antFilterFunc } from '../../../../utils/forms';
import { Link } from 'react-router-dom';

type Props = ReturnType<typeof activityForm>;

const ScopeDetailsForm: React.FunctionComponent<Props> = (props) => {
  const {
    activity,
    projects,
    activityProjectsResults,
    activityProjectsProducts,
    activityProjectsAccessibilityStandards,
  } = props;

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(
    [] as Array<
      | { id: number; name: string; text: string; direct_beneficiaries: string; eventual_beneficiaries: string }
      | undefined
    >,
  );
  const [drawerContentType, setDrawerContentType] = useState('' as string);

  const availableProjectChoices = projects.filter(
    (project: Project) => (!project.activity || project.activity === activity.id) && project.kind === activity.kind,
  );

  return (
    <>
      <Card className="top-level-card" title="Szczegóły" id="details">
        <Row>
          <Col span={24}>
            <Form.Item label="Przedsięwzięcia w ramach działania" name="projects">
              <Select
                name="projects"
                mode="multiple"
                placeholder="Wyszukaj przedsięwzięcie"
                showSearch
                notFoundContent={null}
                optionFilterProp="children"
                filterOption={antFilterFunc}
              >
                {availableProjectChoices.map((project: Project) => (
                  <Select.Option value={project.id || -1} key={project.id || -1}>
                    {project.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <div>
                  Krótki opis:{' '}
                  <div className="explanation">
                    Dlaczego chcemy wykonać działanie - deficyt/potencjał, jakie są cele, na czym działanie polega (max
                    3000 znaków)
                  </div>
                </div>
              }
              name="description"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="description" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <div>Planowane produkty:</div>
                  <div className="explanation">
                    Nazwa produktu i wartość wskaźnika liczbowego; produkt to wymierny, bezpośredni efekt działania,
                    mierzony w sztukach, metrach, etc. (max 3000 znaków)
                  </div>
                  <Button
                    className="drawer-toggle"
                    type="link"
                    onClick={() => {
                      setDrawerContentType('projects');
                      setDrawerContent(activityProjectsProducts);
                      setDrawerOpen(!isDrawerOpen);
                    }}
                  >
                    Produkty przedsięwzięć
                  </Button>
                </div>
              }
              name="products"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="products" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <div>
                    Planowane rezultaty:{' '}
                    <div className="explanation">Po co wykonamy działanie, co ono ma dać (max 3000 znaków)</div>
                  </div>
                  <Button
                    className="drawer-toggle"
                    type="link"
                    onClick={() => {
                      setDrawerContentType('projects');
                      setDrawerContent(activityProjectsResults);
                      setDrawerOpen(!isDrawerOpen);
                    }}
                  >
                    Rezultaty przedsięwzięć
                  </Button>
                </div>
              }
              name="results"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="results" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <div>
                    Beneficjenci bezpośredni:{' '}
                    <div className="explanation">
                      Związany z samą realizacją przedsięwzięcia i jego produktami (max 3000 znaków)
                    </div>
                  </div>
                  <Button
                    className="drawer-toggle"
                    type="link"
                    onClick={() => {
                      setDrawerContentType('direct-beneficiaries');
                      setDrawerContent(activityProjectsProducts);
                      setDrawerOpen(!isDrawerOpen);
                    }}
                  >
                    Beneficjencji bezpośredni przedsięwzięć
                  </Button>
                </div>
              }
              name="direct_beneficiaries"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="direct_beneficiaries" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <div>
                    Beneficjenci końcowi:{' '}
                    <div className="explanation">Związany z oczekiwanymi rezultatami (max 3000 znaków)</div>
                  </div>
                  <Button
                    className="drawer-toggle"
                    type="link"
                    onClick={() => {
                      setDrawerContentType('eventual-beneficiaries');
                      setDrawerContent(activityProjectsProducts);
                      setDrawerOpen(!isDrawerOpen);
                    }}
                  >
                    Beneficjencji końcowi przedsięwzięć
                  </Button>
                </div>
              }
              name="eventual_beneficiaries"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="eventual_beneficiaries" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <div>
                    Standardy dostępności:{' '}
                    <div className="explanation">
                      Wymiary i formy, patrz: p. 8.3. regulaminu naboru (max 3000 znaków)
                    </div>
                  </div>
                  <Button
                    className="drawer-toggle"
                    type="link"
                    onClick={() => {
                      setDrawerContentType('projects');
                      setDrawerContent(activityProjectsAccessibilityStandards);
                      setDrawerOpen(!isDrawerOpen);
                    }}
                  >
                    Standardy dostępności przedsięwzięć
                  </Button>
                </div>
              }
              name="accessibility_standards"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="accessibility_standards" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  Włączenie społeczności lokalnej:{' '}
                  <div className="explanation">
                    Sposoby i skala włączenia społeczności lokalnej w przygotowanie i wdrożenie działania (max 3000
                    znaków)
                  </div>
                </div>
              }
              name="community_engagement"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="community_engagement" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  Informacja i promocja: <div className="explanation">(max 3000 znaków)</div>
                </div>
              }
              name="promotion"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="promotion" autoSize={{ minRows: 10 }} />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  Trwałość działania:{' '}
                  <div className="explanation">
                    Opis utrzymania trwałości działania - jeżeli dotyczy (max 3000 znaków)
                  </div>
                </div>
              }
              name="sustainability"
              validate={validateLength(3000)}
            >
              <Input.TextArea name="sustainability" autoSize={{ minRows: 10 }} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Drawer width={600} placement="right" visible={isDrawerOpen} mask={false} onClose={() => setDrawerOpen(false)}>
        {drawerContentType === 'projects'
          ? drawerContent.map(
              (project) =>
                project && (
                  <Card
                    key={project.id}
                    title={<Link to={`/project/${project.id}`}>{project.name}</Link>}
                    bordered={false}
                  >
                    {project.text}
                  </Card>
                ),
            )
          : drawerContentType === 'direct-beneficiaries'
          ? drawerContent.map(
              (project) =>
                project && (
                  <Card
                    key={project.id}
                    title={<Link to={`/project/${project.id}`}>{project.name}</Link>}
                    bordered={false}
                  >
                    {project.direct_beneficiaries}
                  </Card>
                ),
            )
          : drawerContent.map(
              (project) =>
                project && (
                  <Card
                    key={project.id}
                    title={<Link to={`/project/${project.id}`}>{project.name}</Link>}
                    bordered={false}
                  >
                    {project.eventual_beneficiaries}
                  </Card>
                ),
            )}
      </Drawer>
    </>
  );
};

export default connect(activityForm)(ScopeDetailsForm);
