import React from 'react';
import { Card, Col, Descriptions, Row, Tag } from 'antd';

import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import { connect } from 'react-redux';
import { checkBoxIconMap } from '../../Common/utils';

type Props = ReturnType<typeof projectDetails>;

function ImpactSection(props: Props) {
  const { mainImpact, additionalImpacts, project, strategicGoalImpacts } = props;

  const PRICheckBoxes = [
    {
      key: 5,
      value: project.local_administration_development,
      description: 'Budowanie zdolności instytucjonalnych i rozwój kompetencji lokalnej administracji publicznej',
    },
    {
      key: 6,
      value: project.raising_standards,
      description: 'Usprawnienie i podniesienie standardów działania samorządów lokalnych',
    },
    {
      key: 7,
      value: project.building_citizens_trust,
      description: 'Budowanie zaufania obywateli do instytucji publicznych na poziomie samorządu lokalnego',
    },
  ];

  return (
    <Card id="impact" className="top-level-card" title="Wpływ">
      {project.kind === 'PRL' && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card className="detail-card" size="small" title="Kluczowy wpływ przedsięwzięcia">
              {mainImpact && <Tag>{mainImpact.name}</Tag>}
            </Card>
          </Col>
          <Col span={12}>
            <Card className="detail-card" size="small" title="Uzupełniające wpływy przedsięwzięcia">
              {additionalImpacts.map((impact) => impact && <Tag key={impact.id || -1}>{impact.name}</Tag>)}
            </Card>
          </Col>
        </Row>
      )}
      {project.kind === 'PRI' &&
        PRICheckBoxes.map((checkbox) => (
          <Row gutter={[16, 16]} key={checkbox.key} className="checkbox-info">
            <div>{checkBoxIconMap(checkbox.value)}</div>
            <div>
              <span>{checkbox.description}</span>
            </div>
          </Row>
        ))}
      <Card className="second-level-card" size="small" title="Wpływ na cele strategiczne">
        {strategicGoalImpacts.map(
          ({ description, strategicGoal }, index) =>
            strategicGoal && (
              <Descriptions
                key={strategicGoal.id || -1}
                title={`Cel strategiczny: ${strategicGoal.name}`}
                layout="vertical"
              >
                <Descriptions.Item label="Opis wpływu na cel strategiczny">{description}</Descriptions.Item>
              </Descriptions>
            ),
        )}
      </Card>
    </Card>
  );
}

export default connect(projectDetails)(ImpactSection);
