import { Modal, Popover, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import ArrowGray from '../../../../../../assets/icons/arrows/ArrowGray/ArrowGray';
import OptionsIcon from '../../../../../../assets/icons/OptionsIcon/OptionsIcon';
import { IndicatorsMonitoringIndicator } from '../../../../../../types';
import dayjs from 'dayjs';
import './style.scss';
import { deleteIndicatorConfiguration, deleteLatestIndicatorMeasurement } from '../../../../api';
import { useQueryClient } from 'react-query';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../utils/notifications';
import { formatNumber, parseBinaryIndicatorValue } from '../../../../../../utils/formatters';
import _ from 'lodash';

const { confirm } = Modal;

interface Props {
  indicatorConfig: IndicatorsMonitoringIndicator;
  planId: string;
  onEdit: () => void;
  onMeasure: () => void;
  onDelete?: () => void;
}

const IndicatorListItem: React.FC<Props> = ({ indicatorConfig, planId, onEdit, onMeasure, onDelete }) => {
  const [actionsIsOpen, setActionsOpen] = useState(false);
  const [isCollapseOpen, setCollapseOpen] = useState(false);

  const queryClient = useQueryClient();

  const deleteIndicatorConfig = () => {
    deleteIndicatorConfiguration(planId, indicatorConfig.id)
      .then(() => {
        displaySuccessNotification('Usunięto konfigurację wskaźnika');
        queryClient.invalidateQueries('indicatorsMonitoringList');
        queryClient.invalidateQueries('indicatorsMonitoringMeasurementsList');
        onDelete && onDelete();
      })
      .catch((err) => {
        console.error(err);
        displayErrorNotification('Wystąpił błąd przy usuwaniu konfiguracji wskaźnika');
      });
  };

  const deleteLatestMeasurement = () => {
    deleteLatestIndicatorMeasurement(planId, indicatorConfig.id)
      .then(() => {
        displaySuccessNotification('Usunięto ostatni pomiar');
        queryClient.invalidateQueries('indicatorsMonitoringList');
        queryClient.invalidateQueries('indicatorsMonitoringMeasurementsList');
        onDelete && onDelete();
      })
      .catch((err) => {
        console.error(err);
        displayErrorNotification('Wystąpił błąd przy usuwaniu ostatniego pomiaru');
      });
  };

  const handleDelete = () => {
    confirm({
      centered: true,
      title: 'Usuń wskaźnik',
      content: `Czy na pewno chcesz usunąć wskaźnik ${indicatorConfig.source_indicator.name}?`,
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: deleteIndicatorConfig,
      onCancel() {},
    });
  };

  const handleDeleteLatestMeasure = () => {
    confirm({
      centered: true,
      title: 'Usuń ostatni pomiar',
      content: `Czy na pewno chcesz usunąć ostatni pomiar dla wskaźnika ${indicatorConfig.source_indicator.name}?`,
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: deleteLatestMeasurement,
      onCancel() {},
    });
  };

  const formatToPrecision = (value: number) => {
    return value.toLocaleString('fr', {
      minimumFractionDigits: indicatorConfig.precision,
      maximumFractionDigits: indicatorConfig.precision,
    });
  };

  const isBinary = useMemo(() => indicatorConfig.source_indicator.is_binary, [indicatorConfig]);
  const formatValue = (value: number | undefined) => {
    if (_.isNil(value)) {
      return null;
    }
    return isBinary ? parseBinaryIndicatorValue(value) : formatToPrecision(value);
  };

  return (
    <div className="indicator">
      <header className={isCollapseOpen ? 'open' : ''}>
        <button
          className={isCollapseOpen ? 'collapse open' : 'collapse'}
          onClick={() => setCollapseOpen((state) => !state)}
        >
          <ArrowGray />
        </button>
        <p className="title">{indicatorConfig.source_indicator.name}</p>
        <div className="info">
          <Tooltip placement="top" title="Wartość bazowa" overlayClassName="tooltip-standard-dark">
            <span className="base">{formatValue(indicatorConfig.base_value)}</span>
          </Tooltip>
          <Tooltip placement="top" title="Wartość docelowa" overlayClassName="tooltip-standard-dark">
            <span className="target">{formatValue(indicatorConfig.target_value)}</span>
          </Tooltip>
          <Tooltip placement="top" title="Wartość bieżąca" overlayClassName="tooltip-standard-dark">
            <span className="current">{formatValue(indicatorConfig?.latest_measurement?.measured_value)}</span>
          </Tooltip>
        </div>
        <Popover
          placement="right"
          overlayClassName="popover-standard"
          visible={actionsIsOpen}
          trigger="click"
          onVisibleChange={(value) => setActionsOpen(value)}
          content={() => (
            <React.Fragment>
              <button onClick={() => onEdit()}>Edytuj</button>
              <span className="spacer" />
              <button onClick={() => onMeasure()}>Dokonaj pomiaru</button>
              <span className="spacer" />
              {_.isFinite(indicatorConfig.latest_measurement?.measured_value) && (
                <>
                  <button onClick={handleDeleteLatestMeasure}>Usuń ostatni pomiar</button>
                  <span className="spacer" />
                </>
              )}
              <button onClick={handleDelete}>Usuń wskaźnik</button>
            </React.Fragment>
          )}
        >
          <div className={actionsIsOpen ? 'action open' : 'action'}>
            <OptionsIcon />
          </div>
        </Popover>
      </header>
      <Collapse isOpened={isCollapseOpen} theme={{ collapse: 'collapse', content: 'collapse-content' }}>
        <div className="details">
          <div className="labeled-info">
            <label>Jednostka miary:</label>
            <p>{indicatorConfig.source_indicator.measure_unit}</p>
          </div>
          <div className="labeled-info">
            <label>Rok bazowy</label>
            <p>{indicatorConfig.base_year}</p>
          </div>
          <div className="labeled-info">
            <label>Planowany rok osiągnięcia wskaźnika:</label>
            <p>{indicatorConfig.target_year}</p>
          </div>
          <div className="labeled-info">
            <label>Częstotliwość raportowania:</label>
            <p>{indicatorConfig.report_frequency}</p>
          </div>
          <div className="labeled-info">
            <label>Wartość bazowa</label>
            <p>{formatValue(indicatorConfig.base_value)}</p>
          </div>
          <div className="labeled-info">
            <label>% postępu</label>
            <p>
              {_.isFinite(indicatorConfig.progress_percentage)
                ? `${formatNumber(indicatorConfig.progress_percentage)}%`
                : 'Brak'}
            </p>
          </div>
          <div className="labeled-info">
            <label>Źródło weryfikacji:</label>
            <p>{indicatorConfig.source_indicator.verification_source ?? 'Brak'}</p>
          </div>
          <div className="labeled-info">
            <label>Zmiana nominalna (do bazowej):</label>
            <p>
              {_.isFinite(indicatorConfig.base_nominal_change)
                ? formatNumber(indicatorConfig.base_nominal_change)
                : 'Brak'}
            </p>
          </div>
          <div className="labeled-info">
            <label>Zmiana nominalna (do docelowej):</label>
            <p>
              {_.isFinite(indicatorConfig.target_nominal_change)
                ? formatNumber(indicatorConfig.target_nominal_change)
                : 'Brak'}
            </p>
          </div>
          <div className="labeled-info">
            <label>Data przeprowadzenia pomiaru:</label>
            <p>
              {indicatorConfig.latest_measurement?.measurement_date
                ? dayjs(indicatorConfig.latest_measurement?.measurement_date).format('DD.MM.YYYY')
                : 'Brak'}
            </p>
          </div>
          <div className="labeled-info">
            <label>Działanie:</label>
            <p>{indicatorConfig.activity?.title}</p>
          </div>
          <div className="labeled-info">
            <label>Przedsięwzięcie:</label>
            <p>{indicatorConfig.project?.title}</p>
          </div>
          <div className="labeled-info additional">
            <label>Uwagi:</label>
            <p>{indicatorConfig.latest_measurement?.note ?? 'Brak'}</p>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default IndicatorListItem;
