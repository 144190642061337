import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import AddEntityForm from '../../Common/ModalForms/AddEntityForm';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { projectForm } from '../../../redux/selectors/project/projectForm';
import { closeEditTaskModal, saveTask } from '../../../redux/actions';
import { Form, Input, Select } from 'formik-antd';
import { Formik } from 'formik';
import AddObjectDropdown from '../../Common/AddObjectModals/AddObjectDropdown';
import { antFilterFunc } from '../../../utils/forms';
import { Entity } from '../../../types';
import QuarterRangePicker from '../../Common/QuarterRangePicker';
import CurrencyInput from '../../Common/CurrencyInput';

type Props = ReturnType<typeof projectForm> & typeof mapDispatchToProps;

const EditTaskModal: React.FunctionComponent<Props> = (props) => {
  const {
    project,
    editTaskModalOpened,
    closeEditTaskModal,
    saveTask,
    editedTask,
    taskErrors,
    taskFieldDescriptions,
    entities,
  } = props;

  const onSubmit = (values: any, actions: any) => {
    const { beginning, end, cost, ...rest } = values;
    saveTask({
      project: project.id,
      ...rest,
      beginning: beginning ? moment(beginning).format('YYYY-MM-DD') : null,
      end: end ? moment(end).format('YYYY-MM-DD') : null,
      cost: Number(cost),
    });
  };

  const requiredFieldMsg = 'To pole jest wymagane.';

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(requiredFieldMsg),
    stage: Yup.string().required(requiredFieldMsg),
    cost: Yup.string().required(requiredFieldMsg).typeError('Wartość musi być liczbą.'),
    executor: Yup.number().required(requiredFieldMsg).typeError(requiredFieldMsg),
    beginning: Yup.date().required(requiredFieldMsg).typeError(requiredFieldMsg),
    end: Yup.date().required(requiredFieldMsg).typeError(requiredFieldMsg),
    expense_type: Yup.string().required(requiredFieldMsg),
  });
  return (
    <Modal
      className="edit-task-modal"
      title="Zadanie"
      centered
      visible={editTaskModalOpened}
      onCancel={closeEditTaskModal}
      destroyOnClose
      footer={
        <>
          <Button form="taskForm" onClick={closeEditTaskModal}>
            Anuluj
          </Button>
          <Button type="primary" form="taskForm" htmlType="submit">
            Zapisz
          </Button>
        </>
      }
    >
      <Formik
        initialValues={{
          ...editedTask,
          beginning: editedTask.beginning ? moment(editedTask.beginning) : null,
          end: editedTask.end ? moment(editedTask.end) : null,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <Form id="taskForm" layout="vertical">
          <Form.Item label="Nazwa" name="name">
            <Input name="name" />
          </Form.Item>
          <Form.Item label="Realizator" name="executor">
            <Select
              name="executor"
              dropdownRender={(menu) => (
                <AddObjectDropdown menu={menu} title={'Dodaj realizatora'} form={<AddEntityForm />} />
              )}
              showSearch
              optionFilterProp="children"
              filterOption={antFilterFunc}
            >
              {entities.map((entity: Entity) => (
                <Select.Option value={entity.id || -1} key={entity.id || -1}>
                  {entity.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Faza" name="stage">
            <Select name="stage">
              {taskFieldDescriptions.stage.choices.map((choice: any) => (
                <Select.Option value={choice.value} key={choice.value}>
                  {choice.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <QuarterRangePicker errors={taskErrors} />
          <Form.Item className="cost-input" label="Koszt" name="cost">
            <CurrencyInput name="cost" />
          </Form.Item>
          <Form.Item label="Rodzaj wydatku" name="expense_type">
            <Select name="expense_type">
              <Select.Option value="CURRENT" key="CURRENT">
                Wydatek bieżący
              </Select.Option>
              <Select.Option value="PROPERTY" key="PROPERTY">
                Wydatek majątkowy
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  closeEditTaskModal,
  saveTask,
};

export default connect(projectForm, mapDispatchToProps)(EditTaskModal);
