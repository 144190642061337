import { currency } from '../../utils/formatters';
import { Popover } from 'antd';
import React from 'react';

export const renderTaskCostValidated = (value: string, row: any) =>
  row.is_fully_funded ? (
    <span>{currency(value)}</span>
  ) : (
    <Popover title="Błąd finansowania" content="Finansowanie nie jest równe kosztom zadania">
      <span className="task-funding-error">{currency(value)}</span>
    </Popover>
  );
