import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import activitySaga from './activitySaga';
import projectSaga from './projectSaga';
import simpleListsSaga from './simpleListsSaga';
import planSaga from './planSaga';
import openProjectSaga from './openProjectSaga';
import citySaga from './citySaga';

export default function* rootSaga() {
  yield all([authSaga(), activitySaga(), planSaga(), projectSaga(), simpleListsSaga(), openProjectSaga(), citySaga()]);
}
