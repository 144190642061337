import axios from 'axios';
import { put } from 'redux-saga/effects';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/notifications';
import { Action, ListFetchPayload } from '../actions';
import { ValidationErrors } from '../../types';
import { uiToApi } from '../../utils/pagination';
import { translateFiltersToParams } from '../../utils/filters';
import * as qs from 'query-string';

/**
 * Helper functions to generate CRUD sagas.
 */

export function createFetchListSaga(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<any>,
  failAction: ActionCreatorWithoutPayload<any>,
) {
  return function* fetchListSaga() {
    try {
      const response = yield axios.get(apiEndpoint);
      yield put(successAction(response.data));
    } catch (e) {
      console.log(e);
      yield put(failAction());
    }
  };
}

export function createSearchListSaga(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<any>,
  failAction: ActionCreatorWithoutPayload<any>,
) {
  return function* fetchSearchSaga({ payload }: Action<string>) {
    if (payload) {
      try {
        const response = yield axios.get(apiEndpoint, { params: { search: payload } });
        yield put(successAction(response.data));
      } catch (e) {
        console.log(e);
        yield put(failAction());
      }
    }
  };
}

export function createFetchPaginatedListSaga<ObjectType>(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<any>,
  failAction: ActionCreatorWithoutPayload<any>,
) {
  return function* fetchPaginatedListSaga({ payload }: Action<ListFetchPayload>) {
    const pagination = uiToApi(payload.page, payload.pageSize);
    const filters = translateFiltersToParams(payload.filters);
    const realization_filter = payload.filters.realizationStatus
      ? { realization_status: payload.filters.realizationStatus }
      : {};
    const ordering = payload.ordering || null;
    const search = payload.search || null;
    const params = { ...pagination, ...filters, ordering, search, ...realization_filter };

    try {
      const response = yield axios.get(apiEndpoint, { params, paramsSerializer: (params) => qs.stringify(params) });
      yield put(successAction(response.data));
    } catch (e) {
      console.log(e);
      yield put(failAction());
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  };
}

export function createFetchOptionsSaga(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<any>,
  failAction: ActionCreatorWithoutPayload<any>,
) {
  return function* fetchOptionsSaga() {
    try {
      const response = yield axios.options(apiEndpoint);
      yield put(successAction(response.data));
    } catch (e) {
      console.log(e);
      yield put(failAction());
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  };
}

export function createFetchObjectSaga<ObjectType>(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<ObjectType>,
  failAction: ActionCreatorWithoutPayload<any>,
) {
  return function* fetchObjectSaga({ payload }: Action<number>) {
    try {
      const response = yield axios.get(`${apiEndpoint}/${payload}/`);
      yield put(successAction(response.data));
    } catch (e) {
      console.log(e);
      yield put(failAction());
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  };
}

export function createDeleteObjectSaga(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<number>,
  failAction: ActionCreatorWithoutPayload<any>,
) {
  return function* deleteObjectSaga({ payload }: Action<number>) {
    try {
      yield axios.delete(`${apiEndpoint}/${payload}/`);
      yield put(successAction(payload));
    } catch (e) {
      console.log(e);
      yield put(failAction());
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  };
}

export function createFetchObjectSummarySaga<ObjectType>(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<ObjectType>,
  failAction: ActionCreatorWithoutPayload<any>,
) {
  return function* fetchObjectSaga({ payload }: Action<number>) {
    try {
      const response = yield axios.get(`${apiEndpoint}/${payload}/summary/`);
      yield put(successAction(response.data));
    } catch (e) {
      console.log(e);
      yield put(failAction());
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  };
}

export function createPostObjectSaga<ObjectType>(
  apiEndpoint: string,
  successAction: ActionCreatorWithPayload<ObjectType>,
  failAction: ActionCreatorWithPayload<ValidationErrors<ObjectType>>,
  successMessage: string = 'Zapis obiektu zakończony pomyślnie.',
  failMessage: string = 'Zapis obiektu nie powiódł się.',
) {
  return function* postObjectSaga({ payload }: Action<ObjectType>) {
    try {
      const response = yield axios.post(apiEndpoint, payload);
      yield put(successAction(response.data));
      displaySuccessNotification(successMessage);
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 400) {
          yield put(failAction(e.response.data));
        } else {
          displayErrorNotification(failMessage);
        }
      } else {
        displayErrorNotification('Błąd komunikacji z serwerem.');
      }
    }
  };
}
