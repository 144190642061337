import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { updateProjectRealization } from '../../../api';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Store } from 'antd/lib/form/interface';
import { useMutation } from 'react-query';
import CloseIcon from '../../../../../assets/icons/CloseIcon/CloseIcon';
import OkIcon from '../../../../../assets/icons/OkIcon/OkIcon';
import { useSelector } from 'react-redux';
import { projectRealizationSelector } from '../../../../../redux/selectors/project/projectDetails';

type Params = {
  id: string;
};

interface Props {
  visible: boolean;
  onCancel: () => void;
  onCreate: () => void;
}

interface Inputs {
  executed_expenses: number;
  committed_expenses: number;
}

const RealizationModal: React.FC<Props> = ({ visible, onCancel, onCreate }) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const projectRealization = useSelector(projectRealizationSelector);
  const { id: projectId } = useParams<Params>();
  const [initialValues, setInitialValues] = useState<Inputs>({
    committed_expenses: 0.0,
    executed_expenses: 0.0,
  });
  const { mutate } = useMutation(updateProjectRealization, {
    onSuccess: () => onCreate(),
  });

  useEffect(() => {
    setInitialValues({
      committed_expenses: Number(projectRealization.committed_expenses),
      executed_expenses: Number(projectRealization.executed_expenses),
    });
  }, [projectRealization]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const onSubmit = (store: Store) => {
    const data = store as Inputs;
    setConfirmLoading(true);
    mutate({
      executed_expenses: data.executed_expenses > 0 ? data.executed_expenses.toString() : '0.00',
      committed_expenses: data.committed_expenses > 0 ? data.committed_expenses.toString() : '0.00',
      id: projectId,
    });
    setConfirmLoading(false);
  };

  const onOk = () => {
    form.submit();
  };

  return (
    <Modal
      wrapClassName="standard-modal project-realization"
      className="finance-realization-modal"
      title={'Realizacja'}
      afterClose={() => form.resetFields()}
      visible={visible}
      onOk={() => onOk()}
      confirmLoading={confirmLoading}
      onCancel={() => onCancel()}
      footer={[
        <Button
          className="submit-button"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={confirmLoading}
          onClick={() => onOk()}
        >
          <OkIcon />
          Zapisz zmiany
        </Button>,
        <Button className="cancel-button" key="back" onClick={() => onCancel()}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="project-realization" initialValues={initialValues} onFinish={onSubmit}>
        <div className="input-row">
          <Form.Item name="committed_expenses" label="Zaangażowanie wydatków:">
            <Input type="number" placeholder="0.00" />
          </Form.Item>
          <span className="extra">zł</span>
        </div>
        <div className="input-row">
          <Form.Item name="executed_expenses" label="Wykonanie wydatków:">
            <Input type="number" placeholder="0.00" />
          </Form.Item>
          <span className="extra">zł</span>
        </div>
      </Form>
    </Modal>
  );
};
export default RealizationModal;
