import React, { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../utils/config';
import FileSaver from 'file-saver';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Layout, Row } from 'antd';

import { fetchProjectOptions, fetchProjectSummary, fetchTaskOptions } from '../../../redux/actions';
import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import BasicInfoSection from './BasicInfoSection';
import DescriptionSection from './DescriptionSection';
import PoliciesSection from './PoliciesSection';
import EffectsSection from './EffectsSection';
import ImpactSection from './ImpactSection';
import RelatedEntitiesSection from './RelatedEntitiesSection';
import ScheduleSection from './ScheduleSection/ScheduleSection';
import editIcon from '../../../assets/edit.svg';
import exportIcon from '../../../assets/export.svg';
import Scrollspy from 'react-scrollspy';
import ScrollToTop from '../../Common/ScrollToTop';
import PublicAidSection from './PublicAidSection';
import ArchiveProjectButton from './ArchiveProjectButton';
import ProjectMilestones from './ProjectMilestones/ProjectMilestones';

const { Content, Sider } = Layout;

type Props = ReturnType<typeof projectDetails> & typeof mapDispatchToProps;

interface Params {
  id: string;
}

function ProjectDetails(props: Props) {
  const { project, fetchProjectSummary, fetchProjectOptions, fetchTaskOptions } = props;

  const { id } = useParams<Params>();
  useEffect(() => {
    fetchProjectOptions();
    fetchTaskOptions();
    id && fetchProjectSummary(parseInt(id));
  }, [fetchProjectSummary, fetchProjectOptions, fetchTaskOptions, id]);

  const downloadFile = () => {
    axios.get(`${API_URL}/project/${id}/export/`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(new Blob([response.data], { type: response.headers['content-type'] }), `${project.title}.docx`);
    });
  };

  return (
    <>
      <Row className="title-bar">
        <Col className="title" span={12}>
          <div>{project.title}</div>
        </Col>
        <Col className="buttons" span={6}>
          <Link to={`/project/${id}/edit`}>
            <Button type="primary" size="large">
              <img src={editIcon} alt="edit icon" />
              Edytuj
            </Button>
          </Link>
          <Button size="large" onClick={downloadFile}>
            <img src={exportIcon} alt="export icon" /> Eksportuj
          </Button>
          <ArchiveProjectButton project={project} />
        </Col>
      </Row>
      <Layout className="inner-layout">
        <Sider className="navigation-sider">
          <Scrollspy
            className="side-menu"
            items={[
              'basic-info',
              'description',
              'effects',
              'impact',
              'related-entities',
              'schedule',
              'policies',
              'public-aid',
              'milestones',
            ]}
            currentClassName="active-section"
          >
            <div>
              <a href="#basic-info">Dane identyfikacyjne</a>
            </div>
            <div>
              <a href="#description">Opis</a>
            </div>
            <div>
              <a href="#effects">Efekty realizacji</a>
            </div>
            <div>
              <a href="#impact">Wpływ</a>
            </div>
            <div>
              <a href="#related-entities">Podmioty zaangażowane</a>
            </div>
            <div>
              <a href="#schedule">Harmonogram i finansowanie</a>
            </div>
            <div>
              <a href="#policies">Zgodność z politykami</a>
            </div>
            <div>
              <a href="#public-aid">Pomoc publiczna</a>
            </div>
            <div>
              <a href="#milestones">Kamienie milowe</a>
            </div>
          </Scrollspy>
        </Sider>
        <Content>
          {project.archived && <p>Projekt został zarchiwizowany.</p>}
          <BasicInfoSection />
          <DescriptionSection />
          <EffectsSection />
          <ImpactSection />
          <RelatedEntitiesSection />
          <ScheduleSection />
          <PoliciesSection />
          <PublicAidSection />
          <ProjectMilestones />
        </Content>
      </Layout>
      <ScrollToTop />
    </>
  );
}

const mapDispatchToProps = {
  fetchProjectSummary,
  fetchProjectOptions,
  fetchTaskOptions,
};

export default connect(projectDetails, mapDispatchToProps)(ProjectDetails);
