import React from 'react';
import { Col, Tabs } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import FormSubmitSection from './FormSubmitSection';

type Props = {
  tab: string;
  key: string;
  initialValues: any;
  onSubmit: any;
  showSubmitSection: boolean;
  onFinishFailed: any;
  validationSchema: any;
};

/**
 * This component renders tab content with a form and handles its submissions.
 * It's meant as a generic wrapper for form input fields handling API communication logic.
 * @param tab: tab title
 * @param key: react key value
 * @param initialValues: object defining initial form values
 * @param onSubmit: submit callback function
 * @param onFinishFailed: form submit failed callback function
 * @param showSubmitSection: defines if submit section should be displayed.
 * @param children: form elements (inputs).
 * @param restProps: all the other props, need to pass this to Tabs.TabPane for ant to recognize it as a TabPane
 */
const FormTabPane: React.FunctionComponent<Props> = ({
  tab,
  key,
  initialValues,
  onSubmit,
  onFinishFailed,
  showSubmitSection,
  children,
  validationSchema,
  ...restProps
}) => {
  return (
    <Tabs.TabPane tab={tab} key={key} {...restProps}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        <Form layout="vertical" size="middle" name="basic" onFinishFailed={onFinishFailed}>
          <Col span={24}>{children}</Col>
          {showSubmitSection && <FormSubmitSection />}
        </Form>
      </Formik>
    </Tabs.TabPane>
  );
};

FormTabPane.defaultProps = { showSubmitSection: true };

// FIXME: strict typing for ownProps
const mapStateToProps = (state: RootStateType, ownProps: any) => ({
  ...ownProps,
});

export default connect(mapStateToProps)(FormTabPane);
