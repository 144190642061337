import React from 'react';
import { Form } from 'formik-antd';
import { Field } from 'formik';
import YesNoToggle from '../../../Common/YesNoToggle';

/**
 * Form fields present in PRI project only.
 */
const PriParameters: React.FunctionComponent = () => (
  <>
    <Form.Item name="local_administration_development" valuePropName="checked">
      <Field name="local_administration_development" component={YesNoToggle}>
        Budowanie zdolności instytucjonalnych i rozwój kompetencji lokalnej administracji publicznej
      </Field>
    </Form.Item>

    <Form.Item name="raising_standards" valuePropName="checked">
      <Field name="raising_standards" component={YesNoToggle}>
        Usprawnienie i podniesienie standardów działania samorządów lokalnych
      </Field>
    </Form.Item>
    <Form.Item name="building_citizens_trust" valuePropName="checked">
      <Field name="building_citizens_trust" component={YesNoToggle}>
        Budowanie zaufania obywateli do instytucji publicznych na poziomie samorządu lokalnego
      </Field>
    </Form.Item>
  </>
);

export default PriParameters;
