import { createReducer } from '@reduxjs/toolkit';
import { Entity, OpenProject, ValidationErrors } from '../../../types';
import {
  initNewOpenProject,
  saveOpenProjectSuccess,
  saveOpenProjectFailed,
  fetchOpenProjectSuccess,
} from '../../actions';

const DEFAULT_STATE = {
  openProject: {} as OpenProject,
  projectErrors: {} as ValidationErrors<OpenProject>,
  projectOptions: {} as any,
};

const openProjectFormReducer = createReducer(
  DEFAULT_STATE,
  (builder) =>
    builder
      .addCase(initNewOpenProject, (state, action) => {
        state.openProject = {
          status: 'DRAFT',
          executors: [] as Array<Entity>,
        } as OpenProject;
        state.projectErrors = {} as ValidationErrors<OpenProject>;
      })
      .addCase(saveOpenProjectSuccess, (state, action) => {
        state.openProject = action.payload;
        state.projectErrors = {} as ValidationErrors<OpenProject>;
      })
      .addCase(fetchOpenProjectSuccess, (state, action) => {
        state.openProject = action.payload;
        state.projectErrors = {} as ValidationErrors<OpenProject>;
      })
      .addCase(saveOpenProjectFailed, (state, action) => {
        state.projectErrors = action.payload;
      }),
  // .addCase(fetchProjectOptionsSuccess, (state, action) => {
  //     state.projectOptions = action.payload;
  //   }
  // )
);

export default openProjectFormReducer;
