import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
  Action,
  changeCity,
  ChangeCityPayload,
  fetchActivities,
  fetchAllActivities,
  fetchAllProjects,
  fetchCurrentUser,
  fetchEntities,
  fetchOpenProjects,
  fetchPlans,
  fetchProjectOptions,
  fetchProjects,
} from '../actions';
import { API_URL } from '../../utils/config';
import { Filters } from '../../types';

function* changeCitySaga({ payload }: Action<ChangeCityPayload>) {
  try {
    const paginationPayload = { page: 1, pageSize: 10, filters: {} as Filters, ordering: '', search: '' };
    yield axios.patch(`${API_URL}/user/`, payload.city);
    yield put(fetchCurrentUser());
    yield put(fetchAllActivities());
    yield put(fetchAllProjects());
    yield put(fetchEntities());
    yield put(fetchProjectOptions());
    yield put(fetchActivities(paginationPayload));
    yield put(fetchProjects(paginationPayload));
    yield put(fetchPlans(paginationPayload));
    yield put(fetchOpenProjects(paginationPayload));
  } catch (e) {
    console.log('changeCity error:', e);
  }
}

export default function* citySaga() {
  yield takeEvery(changeCity, changeCitySaga);
}
