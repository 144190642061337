import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row, Tabs } from 'antd';
import { connect } from 'react-redux';
import {
  fetchAllActivities,
  fetchAllProjects,
  fetchEntities,
  fetchEntityOptions,
  fetchOpenProject,
  fetchProjectOptions,
  fetchReporterTypes,
  initNewOpenProject,
  patchOpenProject,
  saveOpenProject,
} from '../../../redux/actions';
import BasicInfoForm from './Tabs/BasicInfoForm';
import FormTabPane from '../../Common/FormTabPane';
import { tabFieldsMap } from './Tabs/tabFieldsMap';
import AddObjectModal from '../../Common/AddObjectModals/AddObjectModal';
import ModalContext from '../../Common/AddObjectModals/ModalContext';
import UnstyledLink from '../../Common/UnstyledLink';
import back from '../../../assets/back.svg';
import { openProjectForm } from '../../../redux/selectors/openProject/openProjectForm';
import * as Yup from 'yup';
import ExecutionForm from './Tabs/ExecutionForm';
import Projects from './Tabs/Projects';
import CommentForm from './Tabs/CommentForm';
import GeolocationForm from './Tabs/GeolocationForm';
import { OpenProject } from '../../../types';

type Props = ReturnType<typeof openProjectForm> & typeof mapDispatchToProps;

/**
 * This form handles both new project creation and updates of
 * existing projects. The two cases can be distinguished by url params:
 * match.params.id present - updating existing project with given id
 * match.params.type present - creating new project of given type (PRL/PRI)
 */
const OpenProjectForm: React.FunctionComponent<Props> = (props) => {
  const {
    project,
    fetchProjectOptions,
    fetchEntities,
    fetchReporterTypes,
    initNewOpenProject,
    projectErrors,
    fetchEntityOptions,
    fetchAllProjects,
    fetchAllActivities,
    saveOpenProject,
    fetchOpenProject,
    patchOpenProject,
  } = props;

  const { id, tab } = useParams();

  useEffect(() => {
    // options object contains choices for choice fields
    // and verbose names for all fields
    fetchProjectOptions();
    fetchAllActivities();
    fetchEntities();
    fetchEntityOptions();
    fetchAllProjects();

    if (id) {
      fetchOpenProject(parseInt(id));
    } else {
      initNewOpenProject();
    }
  }, [
    fetchOpenProject,
    fetchProjectOptions,
    fetchEntities,
    fetchReporterTypes,
    initNewOpenProject,
    fetchEntityOptions,
    fetchAllProjects,
    fetchAllActivities,
    id,
  ]);

  const [isAddObjectModalVisible, setAddObjectModalVisible] = useState(false);
  const [addObjectModalTitle, setAddObjectModalTitle] = useState('');
  const [addObjectModalContent, setAddObjectModalContent] = useState<ReactElement | undefined | null>();

  const showAddObjectModal = (title: string, form: ReactElement) => {
    setAddObjectModalTitle(title);
    setAddObjectModalContent(form);
    setAddObjectModalVisible(true);
  };

  const closeAddObjectModal = () => {
    setAddObjectModalVisible(false);
  };

  const hasErrors = (tabKey: string) => _.intersection(tabFieldsMap[tabKey], _.keys(projectErrors)).length > 0;

  const onSubmit = (values: any) => {
    saveOpenProject({
      ...project,
      ...values,
    });
  };

  const onSubmitFile = (values: any) => {
    const {
      location,
      geolocation1_latitude,
      geolocation1_longitude,
      geolocation2_administrative_address,
      geolocation3_plot_id,
      geolocation4_attachment,
    } = values;

    patchOpenProject({
      id: project.id,
      status: project.status,
      location,
      geolocation1_latitude: geolocation1_latitude === '' ? null : geolocation1_latitude,
      geolocation1_longitude: geolocation1_longitude === '' ? null : geolocation1_longitude,
      geolocation2_administrative_address,
      geolocation3_plot_id,
      geolocation4_attachment,
    } as OpenProject);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Form validation failed:', errorInfo);
  };

  const initialValues = {
    ...project,
    geolocation4_attachment: undefined,
  };

  // FIXME: strict typing
  // show tab validation status: https://github.com/ant-design/ant-design/issues/17146
  const renderTabBar = (tabBarProps: any, DefaultTabBar: any) => (
    <DefaultTabBar {...tabBarProps}>
      {(node: any) => (
        <React.Fragment key={node.key}>
          {hasErrors(node.key)
            ? React.cloneElement(node, {
                className: `${node.props.className} tab-with-errors`,
              })
            : node}
        </React.Fragment>
      )}
    </DefaultTabBar>
  );

  const url = id ? `/open_project/${id}/edit` : `/open_project/new`;

  const history = useHistory();
  const goBack = () => {
    history.push(id ? `/open_project/${id}` : '/open_project');
  };

  const validationSchemaBasic = Yup.object().shape({
    title: Yup.string().required('To pole jest wymagane.'),
  });

  return (
    <>
      <Row className="title-bar form">
        <Col className="title" span={24}>
          <Button className="back-button" size="large" onClick={goBack}>
            <img src={back} alt="edit icon" />
            Powrót
          </Button>
          <div>{project.title}</div>
        </Col>
      </Row>
      <ModalContext.Provider
        value={{
          showModal: showAddObjectModal,
          closeModal: closeAddObjectModal,
        }}
      >
        <Tabs
          className="form-tabs"
          defaultActiveKey="basic"
          activeKey={tab || 'basic'}
          renderTabBar={renderTabBar}
          destroyInactiveTabPane={true}
          tabPosition="left"
          tabBarStyle={{ border: 'none' }}
        >
          <FormTabPane
            tab={<UnstyledLink to={`${url}/basic`}>Dane identyfikacyjne</UnstyledLink>}
            key="basic"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
            validationSchema={validationSchemaBasic}
          >
            <BasicInfoForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/execution`} disabled={!project.id}>
                Realizacja
              </UnstyledLink>
            }
            key="execution"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <ExecutionForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/projects`} disabled={!project.id}>
                Przedsięwzięcia
              </UnstyledLink>
            }
            key="projects"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <Projects />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/geolocation`} disabled={!project.id}>
                Lokalizacja
              </UnstyledLink>
            }
            key="geolocation"
            initialValues={initialValues}
            onSubmit={onSubmitFile}
            onFinishFailed={onFinishFailed}
          >
            <GeolocationForm />
          </FormTabPane>

          <FormTabPane
            tab={
              <UnstyledLink to={`${url}/comments`} disabled={!project.id}>
                Uwagi
              </UnstyledLink>
            }
            key="comments"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onFinishFailed={onFinishFailed}
          >
            <CommentForm />
          </FormTabPane>
        </Tabs>

        <AddObjectModal
          title={addObjectModalTitle}
          content={addObjectModalContent}
          isVisible={isAddObjectModalVisible}
          closeModal={closeAddObjectModal}
        />
      </ModalContext.Provider>
    </>
  );
};

const mapDispatchToProps = {
  initNewOpenProject,
  fetchOpenProject,
  fetchProjectOptions,
  fetchEntities,
  fetchEntityOptions,
  fetchReporterTypes,
  fetchAllProjects,
  fetchAllActivities,
  saveOpenProject,
  patchOpenProject,
};
export default connect(openProjectForm, mapDispatchToProps)(OpenProjectForm);
