import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Popover, Row, Tag } from 'antd';

import { activityDetails } from '../../../redux/selectors/activity/activityDetails';
import { Link } from 'react-router-dom';
import { checkBoxIconMap } from '../../Common/utils';
import { getQuarter } from '../../../utils/dates';

type Props = ReturnType<typeof activityDetails>;

function GeneralInfoSection(props: Props) {
  const {
    activity,
    activityLeader,
    mainImpact,
    additionalImpacts,
    strategicGoals,
    projectsGenderEquality,
    projectsNonDiscrimination,
    projectsSustainableDevelopment,
    projectsPublicAid,
  } = props;

  const CheckBoxes = [
    {
      key: 1,
      value: activity.gender_equality,
      description: 'Równość mężczyzn i kobiet',
      popoverContent: projectsGenderEquality,
    },
    {
      key: 2,
      value: activity.non_discrimination,
      description:
        'Niedyskryminację ze względu na płeć, rasę, pochodzenie etniczne, narodowość, religię, wyznanie, światopogląd, niepełnosprawność, wiek lub orientację seksualną',
      popoverContent: projectsNonDiscrimination,
    },
    {
      key: 3,
      value: activity.sustainable_development,
      description: 'Zrównoważony rozwój, w szczególności pozytywny wpływ na realizację zasady 4R',
      popoverContent: projectsSustainableDevelopment,
    },
    {
      key: 4,
      value: activity.requires_public_aid,
      description:
        'Zawiera przedsięwzięcie(a), w których przewiduje się udzielenie pomocy publicznej lub pomocy de minimis',
      popoverContent: projectsPublicAid,
    },
  ];

  const renderPopoverContent = (content: Array<{ id: number; name: string; value: boolean | null }>) =>
    content &&
    content.map((project) => (
      <div className="checkbox-info" key={project.id}>
        {checkBoxIconMap(project.value)}
        <Link to={`/project/${project.id}`}>{project.name}</Link>
      </div>
    ));

  return (
    <Card id="general-info" className="top-level-card" title="Informacje ogólne">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Tytuł:">
            <p>{activity.title}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Rodzaj:">
            <p>{activity.kind}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Lider działania:">
            <p>{activityLeader && activityLeader.name}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Początek:">
            <p>{activity.beginning && getQuarter(activity.beginning)}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Koniec:">
            <p>{activity.end && getQuarter(activity.end)}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Cele strategiczne, w które wpisuje się działanie:">
            {strategicGoals.map((goal: any) => goal && <Tag key={goal.id || -1}>{goal.name}</Tag>)}
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Działanie obejmuje re-granting:">
            <div className="re-granting">{checkBoxIconMap(activity.re_granting)}</div>
          </Card>
        </Col>
      </Row>

      <Card className="second-level-card" size="small" title="Wpływ">
        {activity.kind === 'PRL' && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card className="detail-card" size="small" title="Kluczowy wpływ działania:">
                {mainImpact && <Tag>{mainImpact.name}</Tag>}
              </Card>
            </Col>
            <Col span={12}>
              <Card className="detail-card" size="small" title="Uzupełniające wpływy działania:">
                {additionalImpacts.map((impact: any) => impact && <Tag key={impact.id || -1}>{impact.name}</Tag>)}
              </Card>
            </Col>
          </Row>
        )}

        {activity.kind === 'PRI' && (
          <>
            <Row className="checkbox-info">
              <div>{checkBoxIconMap(activity.local_administration_development)}</div>
              <div>
                <span>
                  Budowanie zdolności instytucjonalnych i rozwój kompetencji lokalnej administracji publicznej
                </span>
              </div>
            </Row>
            <Row className="checkbox-info">
              <div>{checkBoxIconMap(activity.raising_standards)}</div>
              <div>
                <span>Usprawnienie i podniesienie standardów działania samorządów lokalnych</span>
              </div>
            </Row>
            <Row className="checkbox-info">
              <div>{checkBoxIconMap(activity.building_citizens_trust)}</div>
              <div>
                <span>Budowanie zaufania obywateli do instytucji publicznych na poziomie samorządu lokalnego</span>
              </div>
            </Row>
          </>
        )}
      </Card>

      <Card className="second-level-card" size="small" title="Zgodność z politykami/pomoc publiczna:">
        {CheckBoxes.map((checkbox) => (
          <Row key={checkbox.key} className="checkbox-info">
            <div>
              <Popover placement="left" content={renderPopoverContent(checkbox.popoverContent)}>
                {checkBoxIconMap(checkbox.value)}
              </Popover>
            </div>
            <div>
              <span>{checkbox.description}</span>
            </div>
          </Row>
        ))}
      </Card>
    </Card>
  );
}

export default connect(activityDetails)(GeneralInfoSection);
