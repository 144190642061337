import React from 'react';
import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { ActivitySummary, PlanSummary } from '../../../types';
import { getQuarter } from '../../../utils/dates';
import { currency } from '../../../utils/formatters';
import _ from 'lodash';

export const planDetailsBranch = (state: RootStateType) => state.planDetails;

export const plan = createSelector(planDetailsBranch, (branch) => branch.plan || ({} as PlanSummary));
export const primary_activities = createSelector(
  plan,
  (plan) => plan.primary_activities || ([] as Array<ActivitySummary>),
);
export const complementary_activities = createSelector(
  plan,
  (plan) => plan.complementary_activities || ([] as Array<ActivitySummary>),
);

export const fundingTable = createSelector(
  primary_activities,
  complementary_activities,
  (primary_activities, complementary_activities) => {
    const renderContent = (value: string, row: any) => {
      if (row.titleSpan) {
        return {
          children: value,
          props: {
            colSpan: 0,
          },
        };
      }
      return {
        children: value,
        props: {},
      };
    };

    const columns = [
      {
        title: 'Nazwa przedsięwzięcia w ramach działania',
        dataIndex: 'name',
        render: (value: string, row: any) => {
          return {
            children: value,
            props: {
              colSpan: row.titleSpan,
            },
          };
        },
      },
      {
        title: 'Opis oraz zakres przedmiotowy przedsięwzięcia w ramach działania',
        dataIndex: 'scope_description',
        render: renderContent,
      },
      {
        title: 'Podmiot odpowiedzialny za przedsięwzięcie',
        dataIndex: 'executor',
        render: renderContent,
      },
      {
        title: 'Zakres czasowy (kwartał i rok)',
        dataIndex: 'time_range',
        align: 'center' as 'center',
        render: renderContent,
      },
      {
        title: 'Szacunkowy koszt (w zł)',
        dataIndex: 'cost',
        align: 'right' as 'right',
        render: (value: string, row: any) => {
          return {
            children: currency(value),
            props: {
              colSpan: row.titleSpan ? 6 - row.titleSpan : 1,
            },
          };
        },
      },
      {
        title: 'Planowane źródła finansowania',
        dataIndex: 'funding_sources',
        render: (sources: Array<{ name: string; value: number }>) =>
          sources &&
          sources.map((source) => (
            <div key={source.name}>
              {currency(source.value)} - {source.name}
            </div>
          )),
      },
    ];

    const primaryCost = _.sumBy(primary_activities, 'cost');
    const complementaryCost = _.sumBy(complementary_activities, 'cost');

    const primaryInvestment = _.sumBy(primary_activities, 'investment_cost');
    const primaryNonInvestment = _.sumBy(primary_activities, 'non_investment_cost');

    const primaryInvestmentPercentage = Math.round((primaryInvestment / primaryCost) * 100);
    const primaryNonInvestmentPercentage = Math.round((primaryNonInvestment / primaryCost) * 100);

    const primaryInvestmentPercentageText = primaryInvestmentPercentage ? `(${primaryInvestmentPercentage}%)` : '';
    const primaryNonInvestmentPercentageText = primaryNonInvestmentPercentage
      ? `(${primaryNonInvestmentPercentage}%)`
      : ``;

    const rows = [
      ..._.flatten(
        primary_activities.map((activity, activityIndex) => {
          const projects = activity.projects.map((project) => ({
            key: `${activity.id}-${project.id}`,
            name: project.title,
            scope_description: project.scope_description,
            executor: project.executor.name,
            time_range: `${getQuarter(project.beginning)} - ${getQuarter(project.end)}`,
            cost: project.cost,
            funding_sources: project.project_funding_sources,
          }));
          return [
            {
              key: `activityTitle-${activity.id}`,
              name: `DZIAŁANIE PODSTAWOWE ${activityIndex + 1}. ${activity.title} ${
                activity.cost ? `(${currency(activity.cost)})` : ''
              }`,
              titleSpan: 6,
            },
            ...projects,
          ];
        }),
      ),
      ..._.flatten(
        complementary_activities.map((activity, activityIndex) => {
          const projects = activity.projects.map((project) => ({
            key: `${activity.id}-${project.id}`,
            name: project.title,
            scope_description: project.scope_description,
            executor: project.executor.name,
            time_range: `${getQuarter(project.beginning)} - ${getQuarter(project.end)}`,
            cost: project.cost,
            funding_sources: project.project_funding_sources,
          }));
          return [
            {
              key: `activityTitle-${activity.id}`,
              name: `DZIAŁANIE UZUPEŁNIAJĄCE ${activityIndex + 1}. ${activity.title} ${
                activity.cost ? `(${currency(activity.cost)})` : ''
              }`,
              titleSpan: 6,
            },
            ...projects,
          ];
        }),
      ),
      {
        key: 'primarySummary',
        name: 'Podsumowanie ram finansowych DZIAŁAŃ PODSTAWOWYCH, z tego:',
        cost: primaryCost,
        titleSpan: 4,
      },
      {
        key: 'primaryInvestmentSummary',
        name: `Przedsięwzięcia o charakterze inwestycyjnym ${primaryInvestmentPercentageText}`,
        cost: primaryInvestment,
        exceeded: primaryInvestmentPercentage > 60,
        titleSpan: 4,
      },
      {
        key: 'primaryNonInvestmentSummary',
        name: `Przedsięwzięcia o charakterze nieinwestycyjnym ${primaryNonInvestmentPercentageText}`,
        cost: primaryNonInvestment,
        exceeded: primaryNonInvestmentPercentage < 40,
        titleSpan: 4,
      },
      {
        key: 'complementarySummary',
        name: 'Podsumowanie ram finansowych DZIAŁAŃ UZUPEŁNIAJĄCYCH',
        cost: complementaryCost,
        titleSpan: 4,
      },
      {
        key: 'totalSummary',
        name: 'Ramy finansowe PLANU ROZWOJU LOKALNEGO',
        cost: primaryCost + complementaryCost,
        titleSpan: 4,
      },
    ];
    return { columns, rows };
  },
);

export const planDetails = createSelector(
  [plan, primary_activities, complementary_activities, fundingTable],
  (plan, primary_activities, complementary_activities, fundingTable) => ({
    plan,
    primary_activities,
    complementary_activities,
    fundingTable,
  }),
);
