import React from 'react';
import { Card, Col, Row } from 'antd';
import { connect } from 'react-redux';
import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import { getQuarter } from '../../../utils/dates';

type Props = ReturnType<typeof projectDetails>;

function BasicInfoSection(props: Props) {
  const { project, executor, reporterType, projectType } = props;

  return (
    <Card id="basic-info" className="top-level-card" title="Dane identyfikacyjne">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Tytuł">
            <p>{project.title}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Numer">
            <p>{`${project.kind}-${project.number + 1}`}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Planowany początek:">
            <p>{getQuarter(project.beginning)}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Planowany koniec:">
            <p>{getQuarter(project.end)}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Rzeczywisty początek:">
            <p>{getQuarter(project.real_beginning)}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Rzeczywisty koniec:">
            <p>{getQuarter(project.real_end)}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Typ">
            <p>{projectType.display_name}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Miejsce realizacji">
            <p>{project.venue}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Typ instytucji zgłaszającej przedsięwzięcie">
            <p>{reporterType.name}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Realizator">
            <p>{executor.name}</p>
          </Card>
        </Col>
      </Row>
    </Card>
  );
}

export default connect(projectDetails)(BasicInfoSection);
