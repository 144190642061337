import { takeEvery } from 'redux-saga/effects';
import {
  fetchEntities,
  fetchEntitiesFailed,
  fetchEntitiesSuccess,
  fetchFundingSources,
  fetchFundingSourcesFailed,
  fetchFundingSourcesSuccess,
  fetchImpacts,
  fetchImpactsFailed,
  fetchImpactsSuccess,
  fetchReporterTypes,
  fetchReporterTypesFailed,
  fetchReporterTypesSuccess,
  fetchStrategicGoals,
  fetchStrategicGoalsFailed,
  fetchStrategicGoalsSuccess,
} from '../actions';
import { API_URL } from '../../utils/config';
import { createFetchListSaga } from './utils';

export default function* entitySaga() {
  yield takeEvery(fetchEntities, createFetchListSaga(`${API_URL}/entity/`, fetchEntitiesSuccess, fetchEntitiesFailed));
  yield takeEvery(
    fetchReporterTypes,
    createFetchListSaga(`${API_URL}/reporter_type/`, fetchReporterTypesSuccess, fetchReporterTypesFailed),
  );
  yield takeEvery(fetchImpacts, createFetchListSaga(`${API_URL}/impact/`, fetchImpactsSuccess, fetchImpactsFailed));
  yield takeEvery(
    fetchStrategicGoals,
    createFetchListSaga(`${API_URL}/strategic_goal/`, fetchStrategicGoalsSuccess, fetchStrategicGoalsFailed),
  );
  yield takeEvery(
    fetchFundingSources,
    createFetchListSaga(`${API_URL}/funding_source/`, fetchFundingSourcesSuccess, fetchFundingSourcesFailed),
  );
}
