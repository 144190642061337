import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AddListElementIcon from '../../../../../assets/icons/AddListElementIcon/AddListElementIcon';
import {
  IndicatorConfigurationType,
  IndicatorMeasurementsListItem,
  IndicatorsMonitoringIndicator,
  IndicatorsMonitoringList,
} from '../../../../../types';
import { IndicatorsStore, useIndicatorsMonitoringStore } from '../indicatorsStore';
import IndicatorListItem from './IndicatorListItem/IndicatorListItem';
import './style.scss';
import Result from './Result';

interface Props {
  index: number;
  data: IndicatorsMonitoringList;
}

interface Params {
  id: string;
}

export const indicatorConfigVisibilitySelector = (state: IndicatorsStore) => state.setIndicatorConfigVisibility;

const addResultVisibilitySelector = (state: IndicatorsStore) => state.setResultVisibility;

export const indicatorMeasurementVisibilitySelector = (state: IndicatorsStore) =>
  state.setIndicatorMeasurementVisibility;

export const parseIndicatorMeasurementContext = (
  config: IndicatorsMonitoringIndicator,
  type: IndicatorConfigurationType,
  typeName: string,
): IndicatorMeasurementsListItem => {
  let typeLabel = '';
  switch (type) {
    case IndicatorConfigurationType.PRODUCT:
      typeLabel = 'Produkt';
      break;
    case IndicatorConfigurationType.RESULT:
      typeLabel = 'Rezultat';
      break;
    case IndicatorConfigurationType.STRATEGIC_GOAL:
      typeLabel = 'Cel';
      break;
  }
  return {
    id: config.id,
    source_indicator: config.source_indicator,
    precision: config.precision,
    type_label: typeLabel,
    type_name: typeName,
  };
};

const IndicatorsList: React.FC<Props> = ({ index: goalIndex, data }) => {
  const { id: planId } = useParams<Params>();
  const goalLabel = useMemo(() => (data.is_primary ? 'Cel' : `C ${goalIndex}`), [goalIndex, data.is_primary]);
  const setIndicatorConfigVisibility = useIndicatorsMonitoringStore(indicatorConfigVisibilitySelector);
  const setResultVisibility = useIndicatorsMonitoringStore(addResultVisibilitySelector);

  const setIndicatorMeasurementVisibility = useIndicatorsMonitoringStore(indicatorMeasurementVisibilitySelector);

  return (
    <div className="indicators-list">
      <div className="goal">
        <header>
          <Tooltip
            placement="top"
            title={data.is_primary ? 'Cel nadrzędny' : `Cel ${goalIndex}`}
            trigger="hover"
            overlayClassName="tooltip-standard-dark"
          >
            <label className="status goal">{goalLabel}</label>
          </Tooltip>
          <p className="title">{data.name}</p>
          <section className="controls">
            <button
              className="standard-button primary"
              onClick={() =>
                setIndicatorConfigVisibility(true, {
                  type: IndicatorConfigurationType.STRATEGIC_GOAL,
                  strategic_goal: data.id,
                })
              }
            >
              <AddListElementIcon />
              <p>Dodaj wskaźnik</p>
            </button>
            <button className="standard-button secondary" onClick={() => setResultVisibility(true, data.id)}>
              <AddListElementIcon />
              <p>Dodaj rezultat</p>
            </button>
          </section>
        </header>
        {data.indicators.length ? (
          <section className="content">
            {data.indicators.map((indicatorConfig) => (
              <IndicatorListItem
                key={indicatorConfig.id}
                indicatorConfig={indicatorConfig}
                planId={planId}
                onEdit={() =>
                  setIndicatorConfigVisibility(true, {
                    type: IndicatorConfigurationType.STRATEGIC_GOAL,
                    strategic_goal: data.id,
                    edit: {
                      indicatorConfig: indicatorConfig,
                    },
                  })
                }
                onMeasure={() => {
                  const context = parseIndicatorMeasurementContext(
                    indicatorConfig,
                    IndicatorConfigurationType.STRATEGIC_GOAL,
                    data.name,
                  );
                  setIndicatorMeasurementVisibility(true, context);
                }}
              />
            ))}
          </section>
        ) : null}
      </div>
      {data.results.length
        ? data.results.map((result, resultIndex) => (
            <Result key={result.id} result={result} resultIndex={resultIndex} planId={planId} />
          ))
        : null}
    </div>
  );
};

export default IndicatorsList;
