/**
 * Creates a "lookup-table" from a list of objects with given property as lookup key.
 * E.g.:
 * lookupTable([{name: n1, value: v1}, {name: n2, value: v2}], "name") ->
 *   {n1: {name: n1, value: v1}, n2: {name: n2, value: v2}}
 *
 * If specified, valueProperty attribute allows additionally to extract value from object, e.g.
 * lookupTable([{name: n1, value: v1}, {name: n2, value: v2}], "name", "value") ->
 *   {n1: v1, n2: v2}
 */
export const lookupTable = (objects: Array<any>, keyProperty: string, valueProperty: string | null = null) => {
  const result = {} as any;
  objects.forEach((object: any) => {
    result[object[keyProperty]] = valueProperty ? object[valueProperty] : object;
  });

  return result;
};
