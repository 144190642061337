import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Button, Col, Empty, Input as AntInput, Modal, Row, Tooltip } from 'antd';
import _ from 'lodash';
import { projectForm } from '../../../redux/selectors/project/projectForm';
import { closeEditTaskFundingSourcesModal, saveTask } from '../../../redux/actions';
import { Form, Select } from 'formik-antd';
import { Field, FieldArray, Formik } from 'formik';
import { FundingSource, TaskFundingSource } from '../../../types';
import { getValidQuartersInYear, getYearRange } from '../../../utils/dates';
import YesNoToggle from '../../Common/YesNoToggle';
import CurrencyInput from '../../Common/CurrencyInput';

type Props = ReturnType<typeof projectForm> & typeof mapDispatchToProps;

const EditTaskFundingSourcesModal: React.FunctionComponent<Props> = (projectForm, open) => {
  const {
    project,
    editTaskFundingSourcesModalOpened,
    closeEditTaskFundingSourcesModal,
    saveTask,
    editedTask,
    fundingSources,
  } = projectForm;

  const [currentYear, setCurrentYear] = useState(moment().year());

  useEffect(() => setCurrentYear(moment().year()), [editTaskFundingSourcesModalOpened]);

  const onSubmit = (values: any, actions: any) => {
    const { date_range, ...valuesWithoutDateRange } = values;
    saveTask({
      project: project.id,
      ...valuesWithoutDateRange,
      beginning: date_range && date_range[0] ? date_range[0].format('YYYY-MM-DD') : null,
      end: date_range && date_range[1] ? date_range[1].format('YYYY-MM-DD') : null,
    });
  };

  const requiredFieldMsg = 'To pole jest wymagane.';
  const jstTooltipMsg =
    'Przez pozycje „uwzględniane w budżecie JST” należy rozumieć wielkości, które są bezpośrednio powiązane z budżetem samorządu i będą wykazywane oraz analizowane w modelu „Symulacje WPF".';
  const validationSchema = Yup.object().shape({
    task_funding_sources: Yup.array().of(
      Yup.object().shape({
        funding_source: Yup.number().required(requiredFieldMsg).typeError(requiredFieldMsg),
        values: Yup.array().of(Yup.number().required(requiredFieldMsg).typeError('Wartość musi być liczbą.')),
        jst: Yup.boolean().required(requiredFieldMsg),
      }),
    ),
  });
  return (
    <Modal
      title="Źródła finansowania"
      className="funding-sources-modal"
      centered
      closable={false}
      visible={editTaskFundingSourcesModalOpened}
      onCancel={closeEditTaskFundingSourcesModal}
      width="75%"
      destroyOnClose
      footer={
        <>
          <Button className="submit-button" type="primary" form="taskFundingSourcesForm" htmlType="submit">
            <CheckOutlined /> Zapisz zmiany
          </Button>
          <Button className="cancel-button" form="taskForm" onClick={closeEditTaskFundingSourcesModal}>
            <CloseOutlined /> Odrzuć zmiany
          </Button>
        </>
      }
    >
      <Formik
        initialValues={{
          ...editedTask,
          date_range: [
            editedTask.beginning ? moment(editedTask.beginning) : null,
            editedTask.end ? moment(editedTask.end) : null,
          ],
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form id="taskFundingSourcesForm" layout="vertical">
            <FieldArray name="task_funding_sources">
              {(arrayHelpers) => {
                const usedSources = values.task_funding_sources.map((s: TaskFundingSource) => s.funding_source);
                return (
                  <>
                    {values.task_funding_sources.length === 0 && <Empty />}
                    {values.task_funding_sources.length !== 0 && (
                      <Row className="year-picker" key="year-picker">
                        <Col span={1} offset={9}>
                          <span className="label">Rok: </span>
                        </Col>
                        <Col span={14}>
                          {getYearRange(values.beginning, values.end).map((year) => (
                            <Button
                              key={year}
                              size="small"
                              className={year === currentYear ? 'active' : ''}
                              onClick={() => setCurrentYear(year)}
                            >
                              {year}
                            </Button>
                          ))}
                        </Col>
                      </Row>
                    )}
                    {values.task_funding_sources.map((fundingSource: TaskFundingSource, index: number) => (
                      <Row key={index} className="source-row">
                        <Col span={5}>
                          <Form.Item
                            className="source-select"
                            name={`task_funding_sources[${index}].funding_source`}
                            label={index === 0 ? 'Nazwa źródła finansowania:' : undefined}
                          >
                            <Select name={`task_funding_sources[${index}].funding_source`} fast={true}>
                              {fundingSources.map((source: FundingSource) => {
                                return (
                                  <Select.Option
                                    disabled={_.includes(usedSources, source.id)}
                                    value={source.id || -1}
                                    key={source.id || -1}
                                  >
                                    <Tooltip title={source.description ? source.description : source.name}>
                                      <span>{source.name}</span>
                                    </Tooltip>
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10} offset={1}>
                          <AntInput.Group compact>
                            <FieldArray name={`task_funding_sources[${index}].values`}>
                              {() => (
                                <>
                                  {getValidQuartersInYear(currentYear, values.beginning, values.end).map(
                                    (quarter: any) => (
                                      <Form.Item
                                        className="value-input"
                                        key={quarter.offset}
                                        name={`task_funding_sources[${index}].values[${quarter.offset}]`}
                                        label={index === 0 ? quarter.label : undefined}
                                      >
                                        <CurrencyInput
                                          name={`task_funding_sources[${index}].values[${quarter.offset}]`}
                                          fast={true}
                                        />
                                      </Form.Item>
                                    ),
                                  )}
                                </>
                              )}
                            </FieldArray>
                          </AntInput.Group>
                        </Col>
                        <Col span={5}>
                          <Form.Item name={`task_funding_sources[${index}].jst`} label="Uwzględniane w budżecie JST">
                            <Tooltip title={jstTooltipMsg}>
                              <span>
                                <Field name={`task_funding_sources[${index}].jst`} component={YesNoToggle} />
                              </span>
                            </Tooltip>
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button className="remove-button" onClick={() => arrayHelpers.remove(index)}>
                            <CloseOutlined /> Usuń
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Button
                        className="add-source-button"
                        onClick={() => {
                          arrayHelpers.push(new TaskFundingSource());
                        }}
                      >
                        <PlusOutlined /> Dodaj źródło finansowania
                      </Button>
                    </Row>
                  </>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  closeEditTaskFundingSourcesModal,
  saveTask,
};

export default connect(projectForm, mapDispatchToProps)(EditTaskFundingSourcesModal);
