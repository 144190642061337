import React from 'react';
import axios from 'axios';
import { Card, Divider, Table, Button } from 'antd';
import { connect } from 'react-redux';
import { activityDetails } from '../../../redux/selectors/activity/activityDetails';
import { RiskInfluence, RiskProbability } from '../../../types';
import { useParams } from 'react-router-dom';
import exportIcon from '../../../assets/export.svg';
import { API_URL } from '../../../utils/config';
import FileSaver from 'file-saver';
import _ from 'lodash';

type Props = ReturnType<typeof activityDetails>;

function RiskAnalysisSection(props: Props) {
  const { id } = useParams();
  const { riskInfluences, riskProbabilities, riskEntries, riskList, activity } = props;

  const downloadRiskAnalysis = () => {
    axios.get(`${API_URL}/activity/${id}/export_risk_analysis/`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], { type: response.headers['content-type'] }),
        `${activity.title}_analysis.docx`,
      );
    });
  };

  const getEntryDisplayName = (value: number): string => {
    const entry = _.find(riskEntries, ['id', value]);
    return entry ? entry.title : '';
  };

  const getInfluenceDisplayName = (value: number): string => {
    const influence = _.find(riskInfluences, ['id', value]);
    return influence ? influence.title : '';
  };

  const getProbabilityDisplayName = (value: number): string => {
    const probability = _.find(riskProbabilities, ['id', value]);
    return probability ? probability.title : '';
  };

  const getCategoryDisplayName = (value: number): string => {
    const entry = _.find(riskEntries, ['id', value]);
    if (entry && entry.category && entry.category.title) {
      return entry.category.title;
    }
    return '';
  };

  const helpColumns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      align: 'left' as 'left',
    },
    {
      title: 'Nazwa',
      dataIndex: 'title',
      key: 'title',
      align: 'left' as 'left',
    },
    {
      title: 'Opis',
      dataIndex: 'description',
      key: 'description',
      align: 'left' as 'left',
    },
  ];

  const riskColumns = [
    {
      title: 'Ryzyko',
      dataIndex: 'entry',
      key: 'entry',
      align: 'left' as 'left',
      render: (value: any) => {
        return getEntryDisplayName(value);
      },
    },
    {
      title: 'Kategoria ryzyka',
      dataIndex: 'category',
      key: 'category',
      align: 'left' as 'left',
      render: (value: any) => {
        return getCategoryDisplayName(value);
      },
    },
    {
      title: 'Wpływ na projekt',
      dataIndex: 'influence',
      key: 'influence',
      align: 'left' as 'left',
      render: (value: any) => {
        return getInfluenceDisplayName(value);
      },
    },
    {
      title: 'Prawdopodobieństwo',
      dataIndex: 'probability',
      key: 'probability',
      align: 'left' as 'left',
      render: (value: any) => {
        return getProbabilityDisplayName(value);
      },
    },
  ];

  const influenceDataSource = riskInfluences.map((influence: RiskInfluence) => ({
    key: influence.id,
    symbol: influence.symbol,
    title: influence.title,
    description: influence.description,
  }));

  const probabilityDataSource = riskProbabilities.map((probability: RiskProbability) => ({
    key: probability.id,
    symbol: probability.symbol,
    title: probability.title,
    description: probability.description,
  }));

  return (
    <Card
      id="risk-analysis"
      className="top-level-card"
      title="Analiza ryzyka"
      extra={
        <Button onClick={downloadRiskAnalysis}>
          <img src={exportIcon} alt="export icon" /> Eksportuj ryzyko
        </Button>
      }
    >
      <Divider>Ryzyka</Divider>
      <Table columns={riskColumns} dataSource={riskList} size="small" pagination={false} />
      <Divider>Wpływ na projekt</Divider>
      <Table columns={helpColumns} dataSource={influenceDataSource} size="small" pagination={false} />
      <Divider>Prawdopodobieństwo ryzyka</Divider>
      <Table columns={helpColumns} dataSource={probabilityDataSource} size="small" pagination={false} />
    </Card>
  );
}

export default connect(activityDetails)(RiskAnalysisSection);
