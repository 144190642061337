import { Button, Form, message, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import AddIcon from '../../../../../../assets/icons/AddIcon/AddIcon';
import CloseIcon from '../../../../../../assets/icons/CloseIcon/CloseIcon';
import {
  AddIndicatorConfigRequest,
  EditIndicatorConfigRequest,
  Indicator,
  IndicatorConfigurationType,
  IndicatorReportFrequency,
  IndicatorsMonitoringIndicator,
} from '../../../../../../types';
import { addIndicator, editIndicator } from '../../../../api';
import IndicatorConfigForm from './IndicatorConfigForm';
import { omit, pickBy } from 'lodash-es';
import SuccessIcon from '../../../../../../assets/icons/SuccessIcon/SuccessIcon';
import IndicatorSelectSection from './IndicatorSelectSection';

interface Props {
  visible: boolean;
  setVisible: (v: boolean) => void;
  config: {
    type: IndicatorConfigurationType;
    strategic_goal?: number;
    result?: number;
    product?: number;
    edit?: {
      indicatorConfig: IndicatorsMonitoringIndicator;
    };
  };
}

interface Params {
  id: string;
}

const showAddIndicatorConfigMessage = () => {
  message.success({
    className: 'standard-toast',
    content: [<p className="title">Dodano konfigurację wskaźnika.</p>],
    duration: 3,
    icon: <SuccessIcon />,
  });
};

const showEditIndicatorConfigMessage = () => {
  message.success({
    className: 'standard-toast',
    content: [<p className="title">Zapisano zmiany wskaźnika.</p>],
    duration: 3,
    icon: <SuccessIcon />,
  });
};

export interface AddIndicatorConfigFormValues {
  report_frequency: IndicatorReportFrequency;
  base_year: number;
  target_year: number;
  precision: number;
  base_value: number;
  target_value: number;
  activity: number | null;
  project: number | null;
  measurement_start: string;
}

const AddIndicatorConfigModal: React.FC<Props> = ({ visible, setVisible, config }) => {
  const queryClient = useQueryClient();
  const editingIndicatorConfig = useMemo(() => {
    if (config && config.edit && config.edit.indicatorConfig) {
      return config.edit.indicatorConfig;
    }
    return undefined;
  }, [config]);

  const addIndicatorMutation = useMutation(addIndicator, {
    mutationKey: 'addIndicatorConfig',
    onSuccess: () => {
      queryClient.invalidateQueries('indicatorsMonitoringList');
      setVisible(false);
      showAddIndicatorConfigMessage();
    },
  });

  const editIndicatorMutation = useMutation(editIndicator, {
    mutationKey: 'editIndicatorConfig',
    onSuccess: () => {
      queryClient.invalidateQueries('indicatorsMonitoringList');
      setVisible(false);
      showEditIndicatorConfigMessage();
    },
  });

  const [selectedIndicator, setSelectedIndicator] = useState<Indicator | undefined>(undefined);

  const { id: planIdRaw } = useParams<Params>();

  const clearSelection = () => {
    setSelectedIndicator(undefined);
  };

  const [form] = Form.useForm<AddIndicatorConfigFormValues>();

  const onFinish = (values: AddIndicatorConfigFormValues) => {
    if (selectedIndicator && selectedIndicator.id && typeof editingIndicatorConfig === 'undefined') {
      const planId = Number(planIdRaw);
      const requestData: AddIndicatorConfigRequest = {
        ...values,
        ...omit(config, ['edit']),
        plan: planId,
        indicator: selectedIndicator.id,
        base_value: Number(values.base_value),
        target_value: Number(values.target_value),
        base_year: Number(values.base_year),
        target_year: Number(values.target_year),
        precision: Number(values.precision),
      };
      const clearedData = pickBy(requestData, (val) => typeof val !== 'undefined');
      addIndicatorMutation.mutate(clearedData as AddIndicatorConfigRequest);
    } else {
      if (editingIndicatorConfig) {
        const planId = Number(planIdRaw);
        const requestData: EditIndicatorConfigRequest = {
          ...values,
          ...omit(config, ['edit']),
          plan: planId,
          base_value: Number(values.base_value),
          target_value: Number(values.target_value),
          base_year: Number(values.base_year),
          target_year: Number(values.target_year),
          id: editingIndicatorConfig.id,
          indicator: editingIndicatorConfig.source_indicator.id,
          precision: Number(values.precision),
        };
        const clearedData = pickBy(requestData, (val) => typeof val !== 'undefined');
        editIndicatorMutation.mutate({
          ...clearedData,
        } as EditIndicatorConfigRequest);
      }
    }
  };

  const afterClose = () => {
    form.resetFields();
    clearSelection();
  };

  return (
    <Modal
      visible={visible}
      title="Dodaj wskaźnik"
      wrapClassName="standard-modal add-indicator-config"
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      destroyOnClose={true}
      afterClose={afterClose}
      footer={[
        <Button
          className="submit-button"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={addIndicatorMutation.isLoading}
        >
          <AddIcon />
          {editingIndicatorConfig ? 'Zapisz wskaźnik' : 'Dodaj wskaźnik'}
        </Button>,
        <Button className="cancel-button" key="back" onClick={() => setVisible(false)}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <IndicatorSelectSection
        clearSelection={clearSelection}
        selectIndicator={setSelectedIndicator}
        selectedIndicator={selectedIndicator}
        indicatorConfig={editingIndicatorConfig}
      />
      <span className="divider" />
      <IndicatorConfigForm
        selectedIndicator={selectedIndicator}
        form={form}
        onFinish={onFinish}
        indicatorConfig={editingIndicatorConfig}
      />
    </Modal>
  );
};
export default AddIndicatorConfigModal;
