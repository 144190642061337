import { Form, FormInstance, Input } from 'antd';
import React from 'react';
import { AddResultFormInputs } from './index';

interface Props {
  form: FormInstance<AddResultFormInputs>;
  onFinish: (values: AddResultFormInputs) => void;
}

const ResultModalForm: React.FC<Props> = ({ form, onFinish }) => {
  return (
    <Form form={form} onFinish={onFinish} className="modal-form">
      <div className="form-row">
        <Form.Item name="name" rules={[{ required: true, message: 'Nazwa wymagana' }]} label="Nazwa" className="full">
          <Input placeholder="Podaj nazwę" />
        </Form.Item>
      </div>
    </Form>
  );
};

export default ResultModalForm;
