import React, { useState } from 'react';
import { Button, Popover, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import exportIcon from '../../../assets/export.svg';
import { API_URL } from '../../../utils/config';
import FileSaver from 'file-saver';
import { displayErrorNotification } from '../../../utils/notifications';

function WPFExport({
  id,
  title,
  type,
  data,
}: {
  id: string | undefined;
  title: string;
  type: string;
  data: Array<any> | undefined;
}) {
  const [visible, setVisibility] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [uploading, setUplading] = useState(false);

  const handleUpload = () => {
    setUplading(true);
    const formData = new FormData();
    formData.append('template', file);

    if (data) {
      formData.append('plans', JSON.stringify(data));
    }
    axios
      .post(`${API_URL}/${type}${id ? '/' + id : ''}/fill_wpf_data${data ? 's' : ''}/`, formData, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) =>
        FileSaver.saveAs(new Blob([response.data], { type: response.headers['content-type'] }), `${title}.xlsx`),
      )
      .catch(async (e) => {
        console.log(e);
        const text = await new Response(e.response.data).text();
        displayErrorNotification(text.replace(/"/g, ''));
      })
      .then(() => {
        setUplading(false);
      });
  };

  const content = (
    <div className="wpf-export">
      <Upload
        fileList={file ? [file] : []}
        onRemove={() => setFile(null)}
        beforeUpload={(file) => {
          setFile(file);
          return false;
        }}
      >
        <Button>
          <UploadOutlined /> Wybierz plik
        </Button>
      </Upload>
      <Button type="primary" onClick={handleUpload} disabled={!file} loading={uploading} style={{ marginTop: 16 }}>
        Uzupełnij dane
      </Button>
    </div>
  );

  return (
    <Popover
      title="Eksport danych WPF"
      content={content}
      placement="bottom"
      trigger="click"
      visible={visible}
      onVisibleChange={setVisibility}
    >
      <Button size="large" disabled={!id && !data}>
        <img src={exportIcon} alt="export icon" /> Eksportuj WPF
      </Button>
    </Popover>
  );
}

export default WPFExport;
