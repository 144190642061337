import React from 'react';
import { connect } from 'react-redux';
import { setActivitySearchArchiveFilter } from '../../../../redux/actions/index';
import { archiveFilter } from '../../../../redux/selectors/activity/activityList';
import ArchiveFilterDropdown from '../../../Common/Filters/ArchiveFilterDropdown';

type Props = ReturnType<typeof archiveFilter> & typeof mapDispatchToProps;

const ArchiveActivityFilter = ({ setActivitySearchArchiveFilter, archiveFilter }: Props) => {
  return (
    <ArchiveFilterDropdown archiveFilter={archiveFilter} setSearchArchiveFilter={setActivitySearchArchiveFilter} />
  );
};

const mapDispatchToProps = {
  setActivitySearchArchiveFilter,
};

export default connect(archiveFilter, mapDispatchToProps)(ArchiveActivityFilter);
