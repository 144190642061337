import React, { useState } from 'react';
import { Button, Card, Popconfirm, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { scheduleFormSelector } from '../../../../redux/selectors/project/projectForm';
import { connect, useSelector } from 'react-redux';
import { Task } from '../../../../types';
import { deleteTask, editTask, editTaskFundingSources, fetchProject } from '../../../../redux/actions';
import { getQuarter } from '../../../../utils/dates';
import { renderTaskCostValidated } from '../../utils';
import RealizationModal from '../../ProjectDetails/ScheduleSection/RealizationModal/RealizationModal';
import ScheduleRealizationTable from '../../ProjectDetails/ScheduleSection/ScheduleRealizationTable/ScheduleRealizationTable';
import PencilIcon from '../../../../assets/icons/PencilIcon/Pencilicon';
import CloseIcon from '../../../../assets/icons/CloseIcon/CloseIcon';

type Props = typeof mapDispatchToProps;

const ScheduleForm: React.FunctionComponent<Props> = ({
  deleteTask,
  editTask,
  editTaskFundingSources,
  fetchProject,
}) => {
  const [realizationModalVisible, setRealizationModalVisible] = useState(false);
  const { project, tasks, taskFieldDescriptions } = useSelector(scheduleFormSelector);
  const getStageDisplayName = (value: string): string => {
    const matchingChoices = taskFieldDescriptions.stage.choices.filter((stage: any) => stage.value === value);
    return matchingChoices.length > 0 ? matchingChoices[0].display_name : '';
  };

  const addNewTaskHandler = () => {
    editTask(new Task());
  };

  const handleRealizationModalSuccess = () => {
    setRealizationModalVisible(false);
    fetchProject(project.id);
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Faza',
      dataIndex: 'stage',
      render: (text: any, record: any, index: any) => {
        return getStageDisplayName(text);
      },
    },
    {
      title: 'Koszt',
      dataIndex: 'cost',
      render: renderTaskCostValidated,
      align: 'right' as 'right',
      width: 130,
    },
    {
      title: 'Finansowanie',
      dataIndex: 'total_funding',
      render: renderTaskCostValidated,
      align: 'right' as 'right',
      width: 130,
    },
    {
      title: 'Początek',
      dataIndex: 'beginning',
      render: (text_date: string) => getQuarter(text_date),
    },
    {
      title: 'Koniec',
      dataIndex: 'end',
      render: (text_date: string) => getQuarter(text_date),
    },
    {
      title: '',
      dataIndex: '',
      key: 'editFundingSources',
      width: '2%',
      render: (_text: string, record: Task | null) => (
        <Button size="small" className="edit-funding-sources" onClick={() => record && editTaskFundingSources(record)}>
          Finansowanie <PencilIcon />
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '2%',
      render: (_text: string, record: Task | null) => (
        <button className="action-icon" onClick={() => record && editTask(record)}>
          <PencilIcon />
        </button>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      width: '2%',
      render: (_text: string, record: Task | null) => (
        <Popconfirm
          title="Czy na pewno usunąć zadanie?"
          okText="Usuń"
          cancelText="Anuluj"
          onConfirm={() => record && record.id && deleteTask(record.id)}
        >
          <button className="action-icon warning">
            <CloseIcon />
          </button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Card
      className="top-level-card project-schedule-card"
      title="Harmonogram i finansowanie"
      extra={
        <div className="extra">
          {/* <Button
            type="default"
            onClick={() => setRealizationModalVisible(true)}
            disabled={!project.id}
            className="realization"
          >
            Realizacja
          </Button> */}
          <Button type="primary" onClick={addNewTaskHandler} disabled={!project.id}>
            <PlusOutlined /> Dodaj zadanie
          </Button>
        </div>
      }
    >
      <div className="labeled-content">
        <label>Zadania:</label>
        <Table
          className="schedule-table"
          columns={columns}
          dataSource={tasks}
          size="small"
          pagination={false}
          rowKey={(task: Task) => `${task.name}-${task.id}`}
          scroll={{ x: true }}
        />
      </div>
      <div className="labeled-content">
        <label>Realizacja:</label>
        <ScheduleRealizationTable project={project} tasks={tasks} />
      </div>
      <RealizationModal
        visible={realizationModalVisible}
        onCancel={() => setRealizationModalVisible(false)}
        onCreate={() => handleRealizationModalSuccess()}
      />
    </Card>
  );
};

const mapDispatchToProps = {
  deleteTask,
  editTask,
  editTaskFundingSources,
  fetchProject,
};

export default connect(null, mapDispatchToProps)(ScheduleForm);
