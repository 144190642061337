import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { Form, Select } from 'formik-antd';
import { Project } from '../../../../types';
import { antFilterFunc } from '../../../../utils/forms';
import { openProjectForm } from '../../../../redux/selectors/openProject/openProjectForm';

type Props = ReturnType<typeof openProjectForm>;

const ProjectsForm: React.FunctionComponent<Props> = (props) => {
  const { projects, projectErrors, isProjectApproved } = props;

  return (
    <Card className="top-level-card" title="Przedsięwzięcia">
      <Row>
        <Col span={24}>
          <Form.Item
            label="Proszę wskazać przedsięwzięcia, które będą należały do projektu"
            name="projects"
            validateStatus={projectErrors.projects ? 'error' : ''}
            help={projectErrors.projects ? projectErrors.projects.join(', ') : ''}
          >
            <Select
              name="projects"
              mode="multiple"
              placeholder="Wyszukaj przedsięwzięcie"
              showSearch
              notFoundContent={null}
              optionFilterProp="children"
              filterOption={antFilterFunc}
              disabled={isProjectApproved}
            >
              {projects.map((project: Project) => (
                <Select.Option value={project.id || -1} key={project.id || -1}>
                  {project.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(openProjectForm)(ProjectsForm);
