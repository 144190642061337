import React, { SVGProps } from 'react';
import './style.scss';

const FilterIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className="filter-icon"
      {...props}
    >
      <g transform="translate(-0.004)">
        <g transform="translate(0.004)">
          <path
            className="a"
            d="M11.575,0H.433A.429.429,0,0,0,0,.429V2.571a.429.429,0,0,0,.142.319L4.29,6.62v4.952a.429.429,0,0,0,.62.384l2.571-1.286a.429.429,0,0,0,.237-.384V6.62l4.144-3.729A.429.429,0,0,0,12,2.571V.429A.429.429,0,0,0,11.575,0Z"
            transform="translate(-0.004)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FilterIcon;
