import React, { SVGProps } from 'react';
import './style.scss';

const AddListElementIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      className="add-list-element-icon"
    >
      <g transform="translate(-1592 -112)">
        <rect className="a" width="8" height="2" transform="translate(1599 120)" />
        <rect className="a" width="8" height="2" transform="translate(1592 116)" />
        <rect className="a" width="8" height="2" transform="translate(1592 112)" />
        <rect className="a" width="8" height="2" transform="translate(1604 117) rotate(90)" />
      </g>
    </svg>
  );
};

export default AddListElementIcon;
