import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BackTop, Button, Divider, Dropdown, Layout, Menu, Tooltip } from 'antd';
import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import ReactNotification from 'react-notifications-component';
import Changelog from './Changelog';
import 'antd/dist/antd.css';
import 'react-notifications-component/dist/theme.css';
import '../../css/main.css';
import { auth } from '../../redux/selectors/auth';
import { connect } from 'react-redux';
import { changeCity, logout } from '../../redux/actions';
import logo from '../../assets/logo.svg';
import marker from '../../assets/marker.svg';
import userIcon from '../../assets/user.svg';
import { AddIndicatorForm } from '../Indicator/IndicatorFormModal';
import { Indicator, MeasureUnit, VerificationSource } from '../../types';
import EditMeasureUnitModal from '../Indicator/MeasureUnitList/EditMeasureUnitModal';
import EditVerificationSourceModal from '../Indicator/VerificationSourceList/EditVerificationSourceModal';

const { Header, Content, Footer } = Layout;

type Props = ReturnType<typeof auth> & typeof mapDispatchToProps;

const AppLayout: React.FunctionComponent<Props> = ({
  token,
  userName,
  city,
  cities,
  changelog,
  logout,
  changeCity,
  children,
  user,
}) => {
  const { pathname } = useLocation();
  let [dropdownMenu, setDropdownMenu] = useState(false);
  let [citiesMenu, setCitiesMenu] = useState(false);
  let [userMenu, setUserMenu] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [unitModalVisible, setUnitModalVisible] = useState(false);
  const [sourceModalVisible, setSourceModalVisible] = useState(false);

  const menuKey = `/${pathname.split('/')[1]}`;

  const selectCity = (selectedItem: any) => {
    const selected = cities.find((city) => city.name === selectedItem.key);
    if (selected) {
      changeCity({ username: userName, city: selected });
    }
  };

  const citiesDropdown = (
    <Menu className="dropdown-menu dropdown-menu-right" onClick={selectCity}>
      {cities.map((c) => (
        <Menu.Item key={c.name}>
          <Button block>{<span className="city-link">{c.name || ''}</span>}</Button>
        </Menu.Item>
      ))}
    </Menu>
  );

  const dropdown = (
    <Menu className="dropdown-menu dropdown-menu-right">
      <Menu.Item key="dropdown-instruction">
        <Button>
          <a
            href={process.env.PUBLIC_URL + '/Generator Przedsięwzięć PRL i PRI_handbook_v2.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Instrukcja
          </a>
        </Button>
      </Menu.Item>
      <Menu.Item key="dropdown-log-out">
        <Button onClick={logout}>Wyloguj</Button>
      </Menu.Item>
    </Menu>
  );

  const onIndicatorCreate = (values: any) => {
    setModalVisible(false);
    setDropdownMenu(!dropdownMenu);
  };

  const makroDataDropdown = (
    <Menu className="dropdown-menu">
      <Menu.Item key="dropdown-indicator">
        <Button className="left-btn">
          <Link to="/indicators">Wskaźniki</Link>
        </Button>
        <Tooltip title="Dodaj nowy wskaźnik">
          <Button
            className="right-btn"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <PlusOutlined />
          </Button>
        </Tooltip>
        <AddIndicatorForm
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            setDropdownMenu(!dropdownMenu);
          }}
          onCreate={onIndicatorCreate}
          indicator={new Indicator()}
        />
      </Menu.Item>
      <Menu.Item key="dropdown-measure-unit">
        <Button className="left-btn">
          <Link to="/measure_units">Jednostki miary</Link>
        </Button>
        <Tooltip title="Dodaj jednostkę miary">
          <Button
            className="right-btn"
            onClick={() => {
              setUnitModalVisible(true);
            }}
          >
            <PlusOutlined />
          </Button>
        </Tooltip>
        <EditMeasureUnitModal
          visible={unitModalVisible}
          onClose={() => {
            setUnitModalVisible(false);
            setDropdownMenu(!dropdownMenu);
          }}
          unit={new MeasureUnit()}
        />
      </Menu.Item>
      <Menu.Item key="dropdown-verification-source">
        <Button className="left-btn">
          <Link to="/verification_sources">Źródła weryfikacji</Link>
        </Button>
        <Tooltip title="Dodaj źródło weryfikacji">
          <Button
            className="right-btn"
            onClick={() => {
              setSourceModalVisible(true);
            }}
          >
            <PlusOutlined />
          </Button>
        </Tooltip>
        <EditVerificationSourceModal
          visible={sourceModalVisible}
          onClose={() => {
            setSourceModalVisible(false);
            setDropdownMenu(!dropdownMenu);
          }}
          source={new VerificationSource()}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="layout">
      <ReactNotification />
      {pathname !== '/login' && (
        <Header>
          <div className="left-menu">
            <Link to="/">
              <img className="logo" src={logo} alt="PRL logo" />
            </Link>
            <Divider type="vertical" />
            {!user.is_ministry ? (
              <Menu mode="horizontal" selectedKeys={[menuKey]}>
                <Menu.Item key="/project">
                  <Link to="/project">Przedsięwzięcia</Link>
                </Menu.Item>
                <Menu.Item key="/activity">
                  <Link to="/activity">Działania</Link>
                </Menu.Item>
                <Menu.Item key="/plan">
                  <Link to="/plan">Plany</Link>
                </Menu.Item>
                <Menu.Item key="/open_project">
                  <Tooltip title="Ten moduł służy przygotowaniu informacji o projektach w celu ich zaprezentowania na mapie miasta.">
                    <Link to="/open_project">Projekty na mapie</Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item style={{ color: '#111111' }} key="dropdown-macro-data">
                  <Dropdown
                    overlay={makroDataDropdown}
                    placement="bottomCenter"
                    onVisibleChange={() => setDropdownMenu(!dropdownMenu)}
                  >
                    <div>
                      <span>Metryczki wskaźników </span>
                      {dropdownMenu ? <UpOutlined style={{ fontSize: '10px' }} /> : <DownOutlined />}
                    </div>
                  </Dropdown>
                </Menu.Item>
              </Menu>
            ) : null}
          </div>
          {token && (
            <div className="right-menu">
              <div className="user-info version">
                <Changelog changelogData={changelog} />
              </div>
              {!user.is_ministry ? (
                <Dropdown overlay={citiesDropdown} onVisibleChange={() => setCitiesMenu(!citiesMenu)}>
                  <div className="user-info city">
                    <div className="user-info dropdown-link" onClick={(e) => e.preventDefault()}>
                      <img src={marker} alt="location marker" />
                      <span>
                        {
                          <Link to="/city/goals" className="city-link">
                            {` ${city.name || ''} `}
                          </Link>
                        }
                      </span>
                      {citiesMenu ? <UpOutlined className="arrows" /> : <DownOutlined className="arrows" />}
                    </div>
                  </div>
                </Dropdown>
              ) : null}
              <Dropdown overlay={dropdown} onVisibleChange={() => setUserMenu(!userMenu)}>
                <div className="user-info dropdown-link" onClick={(e) => e.preventDefault()}>
                  <img src={userIcon} alt="user icon" />
                  <span>{` ${userName} `}</span>
                  {userMenu ? <UpOutlined className="arrows" /> : <DownOutlined className="arrows" />}
                </div>
              </Dropdown>
            </div>
          )}
        </Header>
      )}
      <Content>
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer>
        Kontakt: <a href="mailto:generator@zmp.poznan.pl">generator@zmp.poznan.pl</a>
      </Footer>
      <BackTop visibilityHeight={800} />
    </Layout>
  );
};
const mapDispatchToProps = { logout, changeCity };
export default connect(auth, mapDispatchToProps)(AppLayout);
