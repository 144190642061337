import { Button, DatePicker, Form, FormInstance, Input, Select, InputNumber, Radio } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Indicator, IndicatorReportFrequency, IndicatorsMonitoringIndicator } from '../../../../../../types';
import { getActivitiesMinimalList, getProjectsMinimalList } from '../../../../api';
import { AddIndicatorConfigFormValues } from './AddIndicatorConfigModal';
import moment from 'moment';
import { find } from 'lodash-es';

interface Props {
  selectedIndicator?: Indicator;
  form: FormInstance<AddIndicatorConfigFormValues>;
  onFinish: (values: AddIndicatorConfigFormValues) => void;
  indicatorConfig?: IndicatorsMonitoringIndicator;
}
interface SelectOption {
  label: string;
  value: number;
}

type Validator = (
  rule: RuleObject,
  value: StoreValue,
  callback: (error?: string) => void,
) => Promise<void | any> | void;

const indicatorFrequencyOptions = [
  { label: 'Rocznie', value: IndicatorReportFrequency.ANNUALLY },
  { label: 'Półrocznie', value: IndicatorReportFrequency.SEMI_ANNUALLY },
  { label: 'Kwartalnie', value: IndicatorReportFrequency.QUARTERLY },
  { label: 'Jednorazowo', value: IndicatorReportFrequency.ONE_TIME },
];

const IndicatorConfigForm: React.FC<Props> = ({ selectedIndicator, form, onFinish, indicatorConfig }) => {
  const editMode = useMemo(() => typeof indicatorConfig !== 'undefined', [indicatorConfig]);

  const isBinary = useMemo(() => {
    if (selectedIndicator) {
      return selectedIndicator.is_binary;
    }
    return indicatorConfig?.source_indicator.is_binary || false;
  }, [indicatorConfig, selectedIndicator]);

  const isFormDisabled = useMemo(() => {
    if (selectedIndicator || editMode) {
      return false;
    }
    return true;
  }, [editMode, selectedIndicator]);

  const [activitiesOptions, setActivitiesOptions] = useState<SelectOption[]>([]);
  const [projectsOptions, setProjectsOptions] = useState<SelectOption[]>([]);
  const [precision, setPrecision] = useState<number | undefined>(indicatorConfig?.precision || 2);

  const { isLoading: activitiesLoading } = useQuery('indicatorGetActivitiesMinimalList', getActivitiesMinimalList, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess: (response) => {
      if (response.data) {
        setActivitiesOptions(
          response.data.map((activity) => ({
            label: activity.title,
            value: activity.id,
          })),
        );
      }
    },
  });

  const { isLoading: projectsLoading } = useQuery('indicatorGetProjectsMinimalList', getProjectsMinimalList, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    onSuccess: (response) => {
      if (response.data) {
        setProjectsOptions(
          response.data.map((activity) => ({
            label: activity.title,
            value: activity.id,
          })),
        );
      }
    },
  });

  const targetYearValidator: Validator = async (_, value) => {
    const baseYear = Number(form.getFieldValue('base_year'));
    if (baseYear > value) {
      return Promise.reject('Wartość poniżej bazowej');
    }
    return Promise.resolve();
  };

  const baseYearValidator: Validator = async (_, value) => {
    const targetYear = Number(form.getFieldValue('target_year'));
    if (targetYear < value) {
      return Promise.reject('Wartość powyżej docelowej');
    }
    return Promise.resolve();
  };

  const handleActivityErase = () => {
    form.setFieldsValue({ activity: null });
  };
  const handleProjectErase = () => {
    form.setFieldsValue({ project: null });
  };

  const onPrecisionChange = (value: number) => {
    setPrecision(value);
  };

  useEffect(() => {
    form.resetFields();
  }, [editMode, indicatorConfig, form]);

  return (
    <section className={`config-form ${selectedIndicator ? '' : 'disabled'}`}>
      <label>Konfiguracja wskaźnika:</label>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={
          editMode && indicatorConfig
            ? {
                ...indicatorConfig,
                measurement_start: moment(indicatorConfig.measurement_start),
                activity: indicatorConfig.activity ? indicatorConfig.activity.id : undefined,
                project: indicatorConfig.project ? indicatorConfig.project.id : undefined,
                report_frequency: find(indicatorFrequencyOptions, (o) => o.label === indicatorConfig.report_frequency)
                  ?.value,
                precision: indicatorConfig.precision || 2,
              }
            : { precision: 2 }
        }
      >
        <div className="form-row">
          <Form.Item
            label="Częstotliwość:"
            className="half"
            rules={[{ required: true, message: 'Pole jest wymagane' }]}
            name="report_frequency"
          >
            <Select placeholder="Wybierz częstotliwość" options={indicatorFrequencyOptions} disabled={isFormDisabled} />
          </Form.Item>
          <Form.Item
            label="Ostateczny termin pierwszego pomiaru:"
            rules={[{ required: true, message: 'Pole jest wymagane' }]}
            name="measurement_start"
          >
            <DatePicker placeholder="Wybierz date" disabled={isFormDisabled} />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            label="Rok bazowy:"
            className="half"
            rules={[
              { required: true, message: 'Pole jest wymagane' },
              {
                validator: baseYearValidator,
                validateTrigger: ['onChange', 'onSubmit', 'onBlur'],
              },
            ]}
            name="base_year"
          >
            <Input placeholder="Podaj rok bazowy" disabled={isFormDisabled} type="number" />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            label="Planowany rok osiągnięcia:"
            className="half"
            rules={[
              { required: true, message: 'Pole jest wymagane' },
              {
                validator: targetYearValidator,
                validateTrigger: ['onChange', 'onSubmit', 'onBlur'],
              },
            ]}
            name="target_year"
          >
            <Input placeholder="Podaj rok osiągnięcia" disabled={isFormDisabled} type="number" />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            label="Wartość bazowa:"
            className="half"
            rules={[{ required: true, message: 'Pole jest wymagane' }]}
            name="base_value"
          >
            {isBinary ? (
              <Radio.Group>
                <Radio.Button value={1}>TAK</Radio.Button>
                <Radio.Button value={0}>NIE</Radio.Button>
              </Radio.Group>
            ) : (
              <InputNumber
                placeholder="Podaj wartość bazową"
                disabled={isFormDisabled}
                decimalSeparator=","
                precision={precision}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')}
              />
            )}
          </Form.Item>
          {isBinary ? null : (
            <Form.Item
              label="Liczba miejsc po przecinku:"
              className="small"
              rules={[{ required: true, message: 'Pole jest wymagane' }]}
              name="precision"
            >
              <InputNumber
                size="small"
                placeholder="Podaj liczbę miejsc po przecinku"
                disabled={isFormDisabled}
                onChange={onPrecisionChange}
                defaultValue={precision}
                min={0}
                max={4}
              />
            </Form.Item>
          )}
        </div>
        <div className="form-row">
          <Form.Item
            label="Wartość docelowa:"
            className="half"
            rules={[{ required: true, message: 'Pole jest wymagane' }]}
            name="target_value"
          >
            {isBinary ? (
              <Radio.Group>
                <Radio.Button value={1}>TAK</Radio.Button>
                <Radio.Button value={0}>NIE</Radio.Button>
              </Radio.Group>
            ) : (
              <InputNumber
                placeholder="Podaj wartość docelową"
                disabled={isFormDisabled}
                decimalSeparator=","
                precision={precision}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')}
              />
            )}
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            label="Przypisz do działania:"
            className="full"
            rules={[{ required: false, message: 'Pole jest wymagane' }]}
            name="activity"
          >
            <Select
              placeholder="Wybierz działanie"
              disabled={isFormDisabled}
              showSearch
              options={activitiesOptions}
              optionFilterProp={'label'}
              loading={activitiesLoading}
            />
          </Form.Item>
          <Form.Item>
            <Button className="cancel-button" key="back" onClick={() => handleActivityErase()}>
              Wyczyść
            </Button>
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            label="Przypisz do przedsięwzięcia:"
            className="full"
            rules={[{ required: false, message: 'Pole jest wymagane' }]}
            name="project"
          >
            <Select
              placeholder="Wybierz przedsięwzięcie"
              disabled={isFormDisabled}
              showSearch
              options={projectsOptions}
              optionFilterProp={'label'}
              loading={projectsLoading}
            />
          </Form.Item>
          <Form.Item>
            <Button className="cancel-button" key="back" onClick={() => handleProjectErase()}>
              Wyczyść
            </Button>
          </Form.Item>
        </div>
      </Form>
    </section>
  );
};

export default IndicatorConfigForm;
