import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { planDetails } from '../../../redux/selectors/plan/planDetails';
import { fetchPlanSummary } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Button, Col, Layout, Row } from 'antd';

import GeneralInfoSection from './GeneralInfoSection';
import axios from 'axios';
import { API_URL } from '../../../utils/config';
import FileSaver from 'file-saver';
import exportIcon from '../../../assets/export.svg';
import editIcon from '../../../assets/edit.svg';
import Scrollspy from 'react-scrollspy';
import FundingSection from './FundingSection';
import ScrollToTop from '../../Common/ScrollToTop';
import WPFExport from '../../Activity/ActivityDetails/WPFExport';
import ArchivePlanButton from './ArchivePlanButton';
import CompletionSection from './CompletionSection/CompletionSection';
import IndicatorsSection from './IndicatorsSection/IndicatorsSection';
import GanttGraphSection from './GanttGraphSection/GranttGraphSection';
import PlanExcelExport from './PlanExcelExport/PlanExcelExport';

const { Content, Sider } = Layout;

type Props = ReturnType<typeof planDetails> & typeof mapDispatchToProps;
interface Params {
  id: string;
}

function PlanDetails(props: Props) {
  const { fetchPlanSummary, plan } = props;

  const { id } = useParams<Params>();

  useEffect(() => {
    id && fetchPlanSummary(parseInt(id));
  }, [fetchPlanSummary, id]);

  const downloadFile = () => {
    axios.get(`${API_URL}/plan/${id}/export/`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(new Blob([response.data], { type: response.headers['content-type'] }), `${plan.title}.docx`);
    });
  };

  const downloadBudget = () => {
    axios.get(`${API_URL}/plan/${id}/export_budget/`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], { type: response.headers['content-type'] }),
        `${plan.title}_budget.xlsx`,
      );
    });
  };

  return (
    <>
      <Row className="title-bar">
        <Col className="title">
          <div>{plan.title}</div>
        </Col>
        <Col className="buttons">
          <PlanExcelExport plan={plan} />
          <Button size="large" onClick={downloadFile}>
            <img src={exportIcon} alt="export icon" /> Eksportuj plan
          </Button>
          <WPFExport id={id} title={plan.title} type="plan" data={undefined} />
          <Button size="large" onClick={downloadBudget}>
            <img src={exportIcon} alt="export-icon" /> Eksportuj budżet
          </Button>
          <Link to={`/plan/${id}/edit`}>
            <Button type="primary" size="large">
              <img src={editIcon} alt="edit icon" />
              Edytuj
            </Button>
          </Link>
          <ArchivePlanButton plan={plan} />
        </Col>
      </Row>
      <Layout className="inner-layout">
        <Sider className="navigation-sider">
          <Scrollspy
            className="side-menu"
            items={['general-info', 'funding', 'indicators-monitoring', 'completion-monitoring', 'gantt-graph']}
            currentClassName="active-section"
          >
            <div>
              <a href="#general-info">Informacje ogólne</a>
            </div>
            <div>
              <a href="#funding">Finansowanie</a>
            </div>
            <div>
              <a href="#indicators-monitoring">Monitoring wskaźników</a>
            </div>
            <div>
              <a href="#completion-monitoring">Monitoring rzeczowy</a>
            </div>
            <div>
              <a href="#gantt-graph">Wykres Gantta</a>
            </div>
          </Scrollspy>
        </Sider>
        <Content>
          {plan.archived && <p>Plan został zarchiwizowany.</p>}
          <GeneralInfoSection />
          <FundingSection />
          <IndicatorsSection />
          <CompletionSection />
          <GanttGraphSection />
        </Content>
      </Layout>
      <ScrollToTop />
    </>
  );
}

const mapDispatchToProps = {
  fetchPlanSummary,
};

export default connect(planDetails, mapDispatchToProps)(PlanDetails);
