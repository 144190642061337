import { Button, Form, Modal } from 'antd';
import React from 'react';
import AddIcon from '../../../../../../assets/icons/AddIcon/AddIcon';
import CloseIcon from '../../../../../../assets/icons/CloseIcon/CloseIcon';
import ResultModalForm from './ResultModalForm';
import { addResult, editResult } from '../../../../api';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useIndicatorsMonitoringStore } from '../../indicatorsStore';
import { displaySuccessNotification } from '../../../../../../utils/notifications';

interface Props {
  planId: number;
}

export interface AddResultFormInputs {
  name: string;
}

const EditResultModal: React.FC<Props> = ({ planId }) => {
  const visible = useIndicatorsMonitoringStore((state) => state.modalsVisibility.editResult);
  const setVisible = useIndicatorsMonitoringStore((state) => state.setResultVisibility);
  const [goalId, context] = useIndicatorsMonitoringStore((state) => [state.goalId, state.resultModalContext]);

  const [form] = Form.useForm<AddResultFormInputs>();
  context && form.setFieldsValue(context);

  const queryClient = useQueryClient();

  const addResultMutation = useMutation(addResult, {
    onSuccess: () => {
      queryClient.invalidateQueries('indicatorsMonitoringList');
      setVisible(false);
      displaySuccessNotification('Dodano rezultat');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const editResultMutation = useMutation(editResult, {
    onSuccess: () => {
      queryClient.invalidateQueries('indicatorsMonitoringList');
      setVisible(false);
      displaySuccessNotification('Zapisano rezultat');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const onFinish = (values: AddResultFormInputs) => {
    if (context) {
      editResultMutation.mutate({ ...values, result: context.id, plan: planId });
    } else {
      addResultMutation.mutate({ ...values, strategic_goal: goalId, plan: planId });
    }
  };

  const handleAfterClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title={context ? 'Edytuj rezultat' : 'Dodaj rezultat'}
      wrapClassName="standard-modal add-result"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      afterClose={handleAfterClose}
      destroyOnClose={true}
      footer={[
        <Button
          className="submit-button"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={addResultMutation.isLoading}
        >
          <AddIcon />
          {context ? 'Zapisz rezultat' : 'Dodaj rezultat'}
        </Button>,
        <Button className="cancel-button" key="back" onClick={() => setVisible(false)}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <ResultModalForm form={form} onFinish={onFinish} />
    </Modal>
  );
};

export default EditResultModal;
