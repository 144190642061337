import React, { useEffect } from 'react';
import { cityGoals } from '../../redux/selectors/city/cityGoals';
import GoalList from './Tabs/GoalList';
import { connect } from 'react-redux';
import { fetchStrategicGoals } from '../../redux/actions';
import { Layout } from 'antd';

type Props = ReturnType<typeof cityGoals> & typeof mapDispatchToProps;

const { Content } = Layout;

const CityGoals: React.FunctionComponent<Props> = (props) => {
  const { fetchStrategicGoals } = props;

  useEffect(() => {
    fetchStrategicGoals();
  }, [fetchStrategicGoals]);

  return (
    <Layout>
      <Content>
        <GoalList />
      </Content>
    </Layout>
  );
};

const mapDispatchToProps = {
  fetchStrategicGoals,
};
export default connect(cityGoals, mapDispatchToProps)(CityGoals);
