import React from 'react';
import AddIndicatorMeasurementIcon from '../../../../../assets/icons/AddIndicatorIcon/AddIndicatorIcon';
import { IndicatorMeasurementsListItem } from '../../../../../types';
import { IndicatorsStore, useIndicatorsMonitoringStore } from '../indicatorsStore';

const indicatorMeasurementVisibilitySelector = (state: IndicatorsStore) => state.setIndicatorMeasurementVisibility;

interface Props {
  indicatorConfig: IndicatorMeasurementsListItem;
}

const IndicatorMeasurementListItem: React.FC<Props> = ({ indicatorConfig }) => {
  const setIndicatorMeasurementVisibility = useIndicatorsMonitoringStore(indicatorMeasurementVisibilitySelector);

  return (
    <div className="group-item">
      <header>
        <p className="title">{indicatorConfig.source_indicator.name}</p>
        <button className="action" onClick={() => setIndicatorMeasurementVisibility(true, indicatorConfig)}>
          <AddIndicatorMeasurementIcon />
        </button>
      </header>
    </div>
  );
};

export default IndicatorMeasurementListItem;
