import React, { SVGProps } from 'react';
import './style.scss';

const AddIndicatorMeasurementIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="add-indicator-icon"
      {...props}
    >
      <g transform="translate(-1521 -111)">
        <rect className="a" width="18" height="18" transform="translate(1521 111)" />
        <g transform="translate(-2)">
          <rect className="b" width="6" height="2" rx="1" transform="translate(1536.414 115.5) rotate(45)" />
          <rect className="b" width="6" height="2" rx="1" transform="translate(1535 122.993) rotate(-45)" />
          <rect className="b" width="10" height="2" rx="1" transform="translate(1526 115) rotate(90)" />
          <rect className="b" width="10" height="2" rx="1" transform="translate(1540 121) rotate(180)" />
        </g>
      </g>
    </svg>
  );
};

export default AddIndicatorMeasurementIcon;
