import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Form } from 'formik-antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import YesNoToggle from '../../../Common/YesNoToggle';
import { Field } from 'formik';

type Props = ReturnType<typeof projectForm>;

const ParametersForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Card className="top-level-card" title="Zgodności z politykami">
      <Form.Item name="gender_equality" valuePropName="checked">
        <Field name="gender_equality" component={YesNoToggle}>
          Równość mężczyzn i kobiet
        </Field>
      </Form.Item>
      <Form.Item name="non_discrimination" valuePropName="checked">
        <Field name="non_discrimination" component={YesNoToggle}>
          Niedyskryminację ze względu na płeć, rasę, pochodzenie etniczne, narodowość, religię, wyznanie, światopogląd,
          niepełnosprawność, wiek lub orientację seksualną
        </Field>
      </Form.Item>
      <Form.Item name="sustainable_development" valuePropName="checked">
        <Field name="sustainable_development" component={YesNoToggle}>
          Zrównoważony rozwój, w szczególności pozytywny wpływ na realizację zasady 4R
        </Field>
      </Form.Item>
    </Card>
  );
};

export default connect(projectForm)(ParametersForm);
