import React, { useState } from 'react';
import { Button, Card, Empty, Popover, Spin, Tooltip } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteProjectMilestone, editProjectMilestonesOrdering, getProjectMilestones } from '../api';
import { useParams } from 'react-router-dom';
import { ProjectMilestoneCreateModal } from './ProjectMilestonesModals/ProjectMilestoneCreateFormModal/ProjectMilestoneCreateModal';
import './style.scss';
import OptionsIcon from '../../../../assets/icons/OptionsIcon/OptionsIcon';
import FinishedStatusIcon from '../../../../assets/icons/status/FinishedIcon/FinishedStatusIcon';
import InProgressStatusIcon from '../../../../assets/icons/status/InProgressIcon/InProgressStatusIcon';
import AddIcon from '../../../../assets/icons/AddIcon/AddIcon';
import { ProjectMilestone } from '../../../../types';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { cloneDeep, orderBy } from 'lodash-es';
import { useEffect } from 'react';
import NotStartedIcon from '../../../../assets/icons/status/NotStartedIcon/NotStartedIcon';
import { fetchProjectSummary } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';

interface Params {
  id?: string;
}

interface EditModalState {
  projectMilestone?: ProjectMilestone;
  visible: boolean;
}

const ProjectMilestones: React.FC = () => {
  const { id } = useParams<Params>();
  const [projectMilestones, setProjectMilestones] = useState<ProjectMilestone[]>([]);
  const [finalMilestoneEnabled, setFinalMilestoneEnabled] = useState(false);
  const [executionStarted, setExecutionStarted] = useState(false);
  const [milestoneFormModalState, setMilestoneFormModalState] = useState<EditModalState>({
    visible: false,
    projectMilestone: {} as ProjectMilestone,
  });
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const deleteProjectMilestoneMutation = useMutation(deleteProjectMilestone, {
    onSuccess: () => {
      id && dispatch(fetchProjectSummary(Number(id)));
      queryClient.invalidateQueries('getProjectMilestones');
    },
  });
  const updateProjectMilestonesOrdering = useMutation(editProjectMilestonesOrdering, {
    onSuccess: () => {
      queryClient.invalidateQueries('getProjectMilestones');
    },
  });
  const { isLoading, data } = useQuery(['getProjectMilestones', id], () => getProjectMilestones(Number(id)));

  const openEdit = (milestone: ProjectMilestone) => {
    setMilestoneFormModalState({
      visible: true,
      projectMilestone: milestone,
    });
  };

  const handleMilestoneFormClose = () => {
    setMilestoneFormModalState({
      ...milestoneFormModalState,
      visible: false,
    });
  };

  const reorder = (items: ProjectMilestone[], from: number, to: number): ProjectMilestone[] => {
    const cloned = cloneDeep(items);
    let res = cloned;
    if (from > to) {
      const moved = cloneDeep(cloned[from]);
      moved.order = to;
      res = res.filter((item) => item.order !== from);
      res = res.map((item) => {
        if (item.order >= to && item.order <= from) {
          item.order = item.order + 1;
        }
        return item;
      });
      res.splice(to, 0, moved);
    }
    if (from < to) {
      const moved = cloneDeep(cloned[from]);
      moved.order = to;
      res = res.filter((item) => item.order !== from);
      res = res.map((item) => {
        if (item.order >= from && item.order <= to) {
          item.order = item.order - 1;
        }
        return item;
      });
      res.splice(to, 0, moved);
    }
    return res;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reordered = reorder(
      orderBy(cloneDeep(projectMilestones), ['order']),
      result.source.index,
      result.destination.index,
    );
    setProjectMilestones(reordered);
    updateProjectMilestonesOrdering.mutate(reordered.map((m) => m.id));
  };

  useEffect(() => {
    if (data) {
      setProjectMilestones(data);
      const clone = cloneDeep(data.filter((d) => !d.final));
      let complete = true;

      // check if all milestones were completed
      for (let i = 0; i < clone.length; i++) {
        if (!clone[i].actual_end) {
          complete = false;
          break;
        }
      }
      if (complete) {
        setFinalMilestoneEnabled(true);
      } else {
        if (finalMilestoneEnabled) {
          setFinalMilestoneEnabled(false);
        }
      }

      // check if first milestone was completed
      if (clone.length && clone[0].actual_end) {
        setExecutionStarted(true);
      } else {
        setExecutionStarted(false);
      }
    }
  }, [data, finalMilestoneEnabled]);

  return (
    <Card
      id="milestones"
      className="top-level-card"
      title="Kamienie milowe"
      extra={
        <Button
          type="primary"
          onClick={() => {
            setMilestoneFormModalState({
              projectMilestone: undefined,
              visible: true,
            });
          }}
        >
          <AddIcon /> Dodaj kamień milowy
        </Button>
      }
    >
      {isLoading ? (
        <div className="section-loader">
          <Spin size="large" />
        </div>
      ) : projectMilestones.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <ul className="milestones-list" {...provided.droppableProps} ref={provided.innerRef}>
                {projectMilestones.map((projectMilestone, index) => (
                  // NOTE: drag & drop functionality is disabled until we decide if it's necessary
                  <Draggable
                    key={projectMilestone.order}
                    draggableId={projectMilestone.order.toString()}
                    index={index}
                    isDragDisabled
                  >
                    {(provided, snapshot) => (
                      <li
                        className={snapshot.isDragging ? 'milestone dragging' : 'milestone'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                      >
                        <header>
                          <Tooltip
                            overlayClassName="tooltip-standard-dark"
                            title={
                              executionStarted
                                ? projectMilestone.actual_end
                                  ? 'Ukończony'
                                  : 'W trakcie realizacji'
                                : 'Nie rozpoczęto'
                            }
                            trigger="hover"
                          >
                            <div className="milestone-label">
                              <div
                                className={
                                  executionStarted
                                    ? projectMilestone.actual_end
                                      ? 'icon-container finished'
                                      : 'icon-container in-progress'
                                    : 'icon-container not-started'
                                }
                              >
                                {executionStarted ? (
                                  projectMilestone.actual_end ? (
                                    <FinishedStatusIcon />
                                  ) : (
                                    <InProgressStatusIcon />
                                  )
                                ) : (
                                  <NotStartedIcon />
                                )}
                              </div>

                              <span className="order">{projectMilestone.order + 1}</span>
                            </div>
                          </Tooltip>
                          <p className={projectMilestone.actual_end ? 'title' : 'title in-progress'}>
                            {projectMilestone.name}
                          </p>
                        </header>
                        <div className="content">
                          <div className="labeled-info">
                            <label>{`Planowana data ${index === 0 ? 'rozpoczęcia' : 'zakończenia'}:`}</label>
                            <p>{projectMilestone.planned_end}</p>
                          </div>
                          <div className="labeled-info">
                            <label>{`Rzeczywista data ${index === 0 ? 'rozpoczęcia' : 'zakończenia'}:`}</label>
                            <p>{projectMilestone.actual_end}</p>
                          </div>
                          <div className="labeled-info">
                            <label>Realizator:</label>
                            <p>{projectMilestone.executor}</p>
                          </div>
                          <Popover
                            overlayClassName="popover-standard"
                            placement="right"
                            trigger="click"
                            zIndex={milestoneFormModalState.visible ? 0 : 1000}
                            content={
                              <React.Fragment>
                                <button onClick={() => openEdit(projectMilestone)}>Edytuj</button>
                                <span className="spacer"></span>
                                <button onClick={() => deleteProjectMilestoneMutation.mutate(projectMilestone)}>
                                  Usuń
                                </button>
                              </React.Fragment>
                            }
                          >
                            <div className="actions">
                              <OptionsIcon />
                            </div>
                          </Popover>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Empty description="Brak zdefiniowanych kamieni milowych" />
      )}
      <ProjectMilestoneCreateModal
        onCreate={handleMilestoneFormClose}
        onCancel={handleMilestoneFormClose}
        projectMilestone={milestoneFormModalState.projectMilestone}
        onEdit={handleMilestoneFormClose}
        visible={milestoneFormModalState.visible}
      />
    </Card>
  );
};

export default ProjectMilestones;
