import { Col, Pagination, Row, Table } from 'antd';
import React from 'react';
import { Activity, Entity } from '../../../../types';
import { find as lodashFind } from 'lodash-es';
import { currency } from '../../../../utils/formatters';
import { getQuarter } from '../../../../utils/dates';
import { SorterResult } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';
import { RealizationStatusEnum } from '../../../../enums';
import RealizationStatus from '../../../Common/RealizationStatus/RealizationStatus';

interface Props {
  activities: Activity[];
  entities: Entity[];
  pagination: {
    page: number;
    total: number;
  };
  onSorterChange: (sorter: SorterResult<Activity> | SorterResult<Activity>[]) => void;
  onPageChange: (page: number, pageSize?: number) => void;
  onPageSizeChange: (currentSize: number, newSize: number) => void;
}

const ActivityListTable: React.FC<Props> = ({
  activities,
  pagination,
  entities,
  onSorterChange,
  onPageChange,
  onPageSizeChange,
}) => {
  const columnConfig = [
    {
      title: 'Tytuł',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (title: string, { id }: Activity) => <Link to={`/activity/${id}`}>{title}</Link>,
    },
    {
      title: 'Rodzaj',
      dataIndex: 'kind',
      key: 'kind',
      sorter: true,
    },
    {
      title: 'Typ',
      dataIndex: 'is_primary',
      key: 'is_primary',
      render: (value: boolean) => (value ? 'Podstawowe' : 'Uzupełniające'),
    },
    {
      title: 'Lider działania',
      dataIndex: 'activity_leader',
      key: 'activity_leader__name',
      align: 'center' as 'center',
      sorter: true,
      render: (value: number): React.ReactNode => {
        const executor = lodashFind(entities, { id: value });
        return executor ? executor.name : '';
      },
    },
    {
      title: 'Początek',
      dataIndex: 'beginning',
      key: 'beginning',
      render: (text_date: string) => text_date && getQuarter(text_date),
    },
    {
      title: 'Koniec',
      dataIndex: 'end',
      key: 'end',
      render: (text_date: string) => text_date && getQuarter(text_date),
    },
    {
      title: 'Koszt',
      dataIndex: 'cost',
      key: 'cost',
      render: currency,
    },
    {
      title: 'Status',
      dataIndex: 'realization_status',
      key: 'realization_status',
      align: 'left' as 'left',
      render: (value: RealizationStatusEnum) => <RealizationStatus status={value} />,
    },
    {
      title: 'Wykonanie fin.',
      dataIndex: 'percent_executed_expenses',
      key: 'percent_executed_expenses',
      align: 'center' as 'center',
      render: (value: number | null) => (value ? `${value.toFixed(0)}%` : ''),
    },
    {
      title: 'Archiwalne',
      dataIndex: 'archived',
      key: 'archived',
      sorter: true,
      render: (value: boolean) => (value ? 'Tak' : 'Nie'),
      align: 'right' as 'right',
    },
  ];

  return (
    <div className="object-list">
      <Row>
        <Col span={24}>
          <Table
            showSorterTooltip={{ title: 'Kliknij aby sortować' }}
            dataSource={activities}
            columns={columnConfig}
            rowKey="id"
            pagination={false}
            onChange={(pagination, filters, sorter) => onSorterChange(sorter)}
          />
        </Col>
      </Row>
      <Row className="pagination-row">
        <Col span={24}>
          <Pagination
            current={pagination.page}
            total={pagination.total}
            showSizeChanger
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ActivityListTable;
