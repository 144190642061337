import React, { useEffect } from 'react';
import { Button, Col, Dropdown, Pagination, Row, Table, Collapse, Modal } from 'antd';
import {
  PlusOutlined,
  SettingTwoTone,
  EyeTwoTone,
  EyeInvisibleTwoTone,
  FileOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  changeOpenProjectsPagination,
  fetchAllActivities,
  fetchEntities,
  fetchProjectOptions,
  fetchOpenProjects,
  setOpenProjectListOrdering,
  setOpenProjectListSearch,
} from '../../../redux/actions';
import { Activity, Entity, OpenProject } from '../../../types';
import { Link } from 'react-router-dom';
import { handleTableOrdering } from '../../../utils/sorting';
import SearchBar from '../../Common/Filters/SearchBar';
import { openProjectList } from '../../../redux/selectors/openProject/openProjectList';
import { getQuarter } from '../../../utils/dates';
import { currency } from '../../../utils/formatters';
import TypeOpenProjectFilter from './Filters/TypeOpenProjectFilter';
import ProgressStatusOpenProjectFilter from './Filters/ProgressStatusOpenProjectFilter';
import { translateConstants } from '../../../utils/constants';
import { useState } from 'react';
import OpenProjectProgressStatus from '../utilComponents/OpenProjectProgressStatus';
import ExecutorsOpenProjectFilter from './Filters/ExecutorsOpenProjectFilter';
import { API_URL } from '../../../utils/config';
import exportIcon from '../../../assets/export.svg';
import FileSaver from 'file-saver';
import OpenProjectMap from './OpenProjectMap/index';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/notifications';

const { Panel } = Collapse;
const { confirm } = Modal;

const defaultColumns = [
  {
    title: 'Tytuł',
    dataIndex: 'title',
    key: 'title',
    sorter: true,
    render: (title: string, project: OpenProject) => <Link to={`/open_project/${project.id}`}>{title}</Link>,
  },
  {
    title: 'Numer',
    dataIndex: 'number',
    key: 'number',
    align: 'center' as 'center',
    sorter: true,
    render: (number: number) => `PROJEKT-${number}`,
  },
  {
    title: 'Początek',
    dataIndex: 'beginning',
    key: 'beginning',
    align: 'center' as 'center',
    render: (text_date: string) => (text_date ? getQuarter(text_date) : '-'),
  },
  {
    title: 'Koniec',
    dataIndex: 'end',
    key: 'end',
    align: 'center' as 'center',
    render: (text_date: string) => (text_date ? getQuarter(text_date) : '-'),
  },
  {
    title: 'Koszt',
    dataIndex: 'cost',
    key: 'cost',
    render: currency,
  },
  {
    title: 'Realizator',
    dataIndex: 'executors',
    render: (executors: Array<Entity>) => {
      return executors.map((e) => e.name).join(',\n');
    },
  },
  {
    title: 'Etap',
    dataIndex: 'progress_status',
    key: 'progress_status',
    sorter: true,
    align: 'center' as 'center',
    render: (value: string) => <OpenProjectProgressStatus progressStatus={value} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    align: 'center' as 'center',
    render: (value: string) => translateConstants(value),
  },
];

const geolocationColumns = [
  {
    title: 'Lokalizacja',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Geolokalizacja 1: współrzędne geograficzne',
    dataIndex: 'geolocation1_latitude',
    key: 'geolocation1_latitude',
    align: 'center' as 'center',
    render: (_: string, project: OpenProject) =>
      project.geolocation1_latitude && project.geolocation1_longitude
        ? `${project.geolocation1_latitude}, ${project.geolocation1_longitude}`
        : '-',
  },
  {
    title: 'Geolokalizacja 2: adres administracyjny',
    dataIndex: 'geolocation2_administrative_address',
    key: 'geolocation2_administrative_address',
  },
  {
    title: 'Geolokalizacja 3: ID działki',
    dataIndex: 'geolocation3_plot_id',
    key: 'geolocation3_plot_id',
  },
  {
    title: 'Geolokacja 4: szkic',
    dataIndex: 'geolocation4_attachment',
    key: 'geolocation4_attachment',
    align: 'center' as 'center',
    render: (attachment: string) =>
      attachment ? (
        <a href={attachment} target="_blank" rel="noopener noreferrer">
          <FileOutlined style={{ fontSize: 20 }} />
        </a>
      ) : (
        '-'
      ),
  },
];

const columns = [...defaultColumns, ...geolocationColumns];

type Props = ReturnType<typeof openProjectList> & typeof mapDispatchToProps;

const OpenProjectList: React.FunctionComponent<Props> = ({
  fetchAllActivities,
  fetchEntities,
  fetchProjectOptions,
  fetchOpenProjects,
  projects,
  entities,
  pagination,
  filters,
  ordering,
  search,
  changeOpenProjectsPagination,
  setOpenProjectListOrdering,
  setOpenProjectListSearch,
}) => {
  useEffect(() => {
    fetchAllActivities();
    fetchEntities();
    fetchProjectOptions();
    fetchOpenProjects({ page: pagination.page, pageSize: pagination.pageSize, filters, ordering, search });
  }, [
    fetchAllActivities,
    fetchEntities,
    fetchProjectOptions,
    fetchOpenProjects,
    ordering,
    search,
    filters,
    pagination.page,
    pagination.pageSize,
  ]);

  const [visibleColumns, setVisibleColumns] = useState<any>(defaultColumns);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.Key>>([]);

  const downloadFile = () => {
    axios
      .get(`${API_URL}/open_project/export_selection`, { responseType: 'blob', params: { ids: selectedRowKeys } })
      .then((response) => {
        FileSaver.saveAs(
          new Blob([response.data], { type: response.headers['content-type'] }),
          `exported_open_projects.xlsx`,
        );
      });
  };

  const deleteRequest = () => {
    try {
      axios.post(`${API_URL}/open_project/delete_selection/`, { ids: selectedRowKeys }).then((response) => {
        displaySuccessNotification('Projekty usunięte');
        fetchOpenProjects({ page: pagination.page, pageSize: pagination.pageSize, filters, ordering, search });
      });
    } catch {
      displayErrorNotification('Błąd przy usuwaniu projektów');
    }
  };

  const handleDelete = () => {
    confirm({
      centered: true,
      title: 'Usuń projekty',
      content: 'Czy na pewno chcesz usunąć wybrane projekty?',
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: deleteRequest,
      onCancel() {},
    });
  };

  const columnsOrder = [
    'title',
    'number',
    'beginning',
    'end',
    'cost',
    'executors',
    'type',
    'progress_status',
    'status',
    'location',
    'geolocation1_latitude',
    'geolocation2_administrative_address',
    'geolocation3_plot_id',
    'geolocation4_attachment',
  ];

  const onPageChange = (page: number, pageSize?: number) => {
    changeOpenProjectsPagination({ page, pageSize: pageSize || pagination.pageSize });
  };

  const onPageSizeChange = (_currentSize: number, newSize: number) => {
    changeOpenProjectsPagination({ page: 1, pageSize: newSize });
  };

  const onChange = (_: any, __: any, sorter: any) => {
    handleTableOrdering(sorter, setOpenProjectListOrdering);
  };

  const expandedRowRender = (openProject: OpenProject) => {
    const columns = [
      {
        title: 'Nazwa przedsięwzięcia',
        dataIndex: 'title',
        render: (value: any, project: any) =>
          value && (
            <Link to={`/project/${project.id}`} target="_blank" rel="noopener noreferrer">
              {value}
            </Link>
          ),
      },
      {
        title: 'Realizator',
        dataIndex: 'executor',
        render: (executor: Entity) => executor && executor.name,
      },
      {
        title: 'Początek',
        dataIndex: 'beginning',
        align: 'center' as 'center',
        render: (value: string) => (value ? getQuarter(value) : '-'),
      },
      {
        title: 'Koniec',
        dataIndex: 'end',
        align: 'center' as 'center',
        width: 100,
        render: (value: string) => (value ? getQuarter(value) : '-'),
      },
      {
        title: 'Koszt',
        dataIndex: 'cost',
        render: currency,
        align: 'right' as 'right',
      },
      {
        title: 'Działanie',
        dataIndex: 'activity',
        render: (activity: Activity) =>
          activity && (
            <Link to={`/activity/${activity.id}`} target="_blank" rel="noopener noreferrer">
              {activity.title}
            </Link>
          ),
      },
    ];

    return (
      <Row>
        {openProject.comments && (
          <Col span={24}>
            <Collapse className="collapse-info" defaultActiveKey={[]} bordered={false}>
              <Panel className="collapse-info-panel" key="1" header="Uwagi">
                <p>{openProject.comments}</p>
              </Panel>
            </Collapse>
          </Col>
        )}
        {Boolean(openProject.projects.length) && (
          <Table
            columns={columns}
            dataSource={openProject.projects}
            pagination={false}
            scroll={{ x: true }}
            rowKey="id"
          />
        )}
      </Row>
    );
  };

  const menu = (
    <div
      style={{
        backgroundColor: 'white',
        padding: 10,
        minWidth: 120,
        borderRadius: 2,
        boxShadow: '5px 8px 24px 5px rgba(0, 0, 0, 0.1)',
      }}
    >
      {columns.map((column, i) => {
        return (
          <div
            key={column.dataIndex}
            style={{
              paddingBottom: i === columns.length - 1 ? 0 : 10,
              paddingLeft: 5,
              paddingRight: 5,
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (visibleColumns.find((c: any) => c.dataIndex === column.dataIndex)) {
                setVisibleColumns(visibleColumns.filter((c: any) => c.dataIndex !== column.dataIndex));
              } else {
                const cols = [...visibleColumns, column];
                cols.sort((a, b) => columnsOrder.indexOf(a.dataIndex) - columnsOrder.indexOf(b.dataIndex));
                setVisibleColumns(cols);
              }
            }}
          >
            {visibleColumns.find((c: any) => c.dataIndex === column.dataIndex) ? (
              <>
                <EyeTwoTone style={{ fontSize: 14 }} />
                <span style={{ marginLeft: 5 }}>{column.title}</span>
              </>
            ) : (
              <>
                <EyeInvisibleTwoTone style={{ fontSize: 14 }} />
                <span style={{ color: '#BBBBBB', marginLeft: 5 }}>{column.title}</span>
              </>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <Row className="title-bar list">
        <div>
          <Button type="primary">
            <Link to="/open_project/new/">
              <PlusOutlined /> Dodaj Projekt
            </Link>
          </Button>
          <p style={{ marginLeft: 15, marginTop: 0, marginBottom: 0 }}>
            Ten moduł służy przygotowaniu informacji o projektach w celu ich zaprezentowania na mapie miasta.
          </p>
        </div>
      </Row>
      <div className="object-list">
        <OpenProjectMap />
        <Row justify="end">
          <ExecutorsOpenProjectFilter />
          <TypeOpenProjectFilter />
          <ProgressStatusOpenProjectFilter />
        </Row>
        <Row align="middle">
          <SearchBar setSearch={setOpenProjectListSearch} />
          <Button disabled={!selectedRowKeys.length} size="large" onClick={downloadFile}>
            <img src={exportIcon} alt="export icon" />
            Eksportuj wybrane
          </Button>
          <Button disabled={!selectedRowKeys.length} size="large" onClick={handleDelete}>
            <DeleteOutlined />
            Usuń wybrane
          </Button>
          <Dropdown overlay={menu}>
            <Button>
              <SettingTwoTone />
              Ustawienia
            </Button>
          </Dropdown>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              dataSource={projects}
              columns={visibleColumns}
              rowKey="id"
              pagination={false}
              onChange={onChange}
              scroll={{ x: true }}
              rowSelection={{
                type: 'checkbox',
                preserveSelectedRowKeys: true,
                onChange: (selectedKeys: React.Key[], _selectedRows: OpenProject[]) => {
                  setSelectedRowKeys(selectedKeys);
                },
              }}
              expandRowByClick={true}
              expandable={{
                expandedRowRender,
                rowExpandable: (record) => Boolean(record.projects.length || record.comments),
              }}
              showSorterTooltip={{ title: 'Kliknij aby sortować' }}
            />
          </Col>
        </Row>
        <Row className="pagination-row">
          <Col span={24}>
            <Pagination
              current={pagination.page}
              total={pagination.total}
              showSizeChanger
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchAllActivities,
  fetchEntities,
  fetchOpenProjects,
  changeOpenProjectsPagination,
  setOpenProjectListOrdering,
  fetchProjectOptions,
  setOpenProjectListSearch,
};

export default connect(openProjectList, mapDispatchToProps)(OpenProjectList);
