import React, { useMemo } from 'react';
import AddIcon from '../../../../../../assets/icons/AddIcon/AddIcon';
import InfoIcon from '../../../../../../assets/icons/InfoIcon/InfoIcon';
import { Indicator, IndicatorsMonitoringIndicator } from '../../../../../../types';
import { IndicatorsStore, useIndicatorsMonitoringStore } from '../../indicatorsStore';
import IndicatorSearch from './IndicatorSearch';

interface Props {
  selectedIndicator?: Indicator;
  clearSelection: () => void;
  selectIndicator: (value: Indicator | undefined) => void;
  indicatorConfig?: IndicatorsMonitoringIndicator;
}

const addIndicatorModalSelector = (state: IndicatorsStore) => state.setIndicatorVisibility;

const IndicatorSelectSection: React.FC<Props> = ({ selectedIndicator, selectIndicator, indicatorConfig }) => {
  const setIndicatorModalVisibility = useIndicatorsMonitoringStore(addIndicatorModalSelector);
  const editMode = useMemo(() => typeof indicatorConfig !== 'undefined', [indicatorConfig]);

  return (
    <section className="indicator-select">
      <label>Wybór wskaźnika</label>
      <section className="actions">
        <IndicatorSearch onSelect={selectIndicator} selectedIndicator={selectedIndicator} disabled={editMode} />
        <button
          className="standard-button primary"
          disabled={editMode}
          onClick={() => setIndicatorModalVisibility(true)}
        >
          <AddIcon />
          <span>Utwórz nowy</span>
        </button>
      </section>
      {selectedIndicator || editMode ? null : (
        <div className="info-box">
          <InfoIcon />
          <p>Aby kontynuować, wybierz wskaźnik z powyższej listy lub utwórz nowy.</p>
        </div>
      )}
      {(selectedIndicator && selectedIndicator.name) || editMode ? (
        <div className="indicator-info">
          <label>Wskaźnik:</label>
          <p>{editMode && indicatorConfig ? indicatorConfig?.source_indicator.name : selectedIndicator?.name}</p>
          {/* <button onClick={() => clearSelection()}>
            <CloseIcon />
          </button> */}
        </div>
      ) : null}
    </section>
  );
};

export default IndicatorSelectSection;
