import { Card, Spin } from 'antd';
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { getProjectGantt } from '../../api';
import polish_locale from 'apexcharts/dist/locales/pl.json';
import './style.scss';
import { ProjectRealizationStatus } from '../../../../types';
import { useResizeDetector } from 'react-resize-detector';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface Params {
  id: string;
}

const getBoxColor = (status: ProjectRealizationStatus) => {
  switch (status) {
    case ProjectRealizationStatus.IN_PROGRESS:
      return '#C6E0B4';
    case ProjectRealizationStatus.FINISHED:
      return '#F4B084';
    case ProjectRealizationStatus.DELAYED:
      return '#F4B084';
    case ProjectRealizationStatus.UNKNOWN:
      return '#B4C7E7';
    default:
      return '#B4C7E7';
  }
};

const getMilestoneColor = (status: ProjectRealizationStatus) => {
  switch (status) {
    case ProjectRealizationStatus.IN_PROGRESS:
      return '#2F5597';
    case ProjectRealizationStatus.UNKNOWN:
      return '#2F5597';
    case ProjectRealizationStatus.DELAYED:
      return '#F37825';
    case ProjectRealizationStatus.FINISHED:
      return '#00B050';
  }
};

const getStatusLabel = (status: ProjectRealizationStatus) => {
  switch (status) {
    case ProjectRealizationStatus.IN_PROGRESS:
      return 'W realizacji';
    case ProjectRealizationStatus.FINISHED:
      return 'Zakończono';
    case ProjectRealizationStatus.DELAYED:
      return 'Opóźniony';
    case ProjectRealizationStatus.UNKNOWN:
      return 'Nieznany';
    default:
      return 'Nieznany';
  }
};

const GanttGraphSection: React.FC = () => {
  const { id } = useParams<Params>();
  const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);
  const { width: wrapperWidth, height: wrapperHeight, ref } = useResizeDetector<HTMLDivElement>();
  const [chartOptions, updateChartOptions] = useState<ApexOptions>({
    chart: {
      type: 'rangeBar',
      locales: [polish_locale],
      defaultLocale: 'pl',
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        maxWidth: 250,
      },
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: 'solid',
      opacity: 0.6,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      show: false,
    },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const seriesData = w.config.series[seriesIndex].data[dataPointIndex];
        const meta = seriesData.meta;
        const projectName = seriesData.meta.title;
        const activityName = seriesData.meta.activity;
        const project_status = meta.realization_status as ProjectRealizationStatus;
        const statusLabel = getStatusLabel(project_status);
        const delayCategory =
          project_status === ProjectRealizationStatus.DELAYED
            ? `<div><span class="category">Opóźnienie: </span><span class="value">${meta.delay} dni</span></div>`
            : '';
        return (
          '<div class="apexcharts-tooltip-rangebar"><div>' +
          `<span class="series-name" style="color: ${getBoxColor(project_status)}">${projectName}:</span></div>` +
          '<div>' +
          `<span class="category">Działanie: </span>` +
          `<span class="value">${activityName}</span>` +
          '</div>' +
          '<div>' +
          `<span class="category">Status: </span>` +
          `<span class="value">${statusLabel}</span>` +
          '<div>' +
          delayCategory
        );
      },
      fixed: {
        enabled: true,
        position: 'topRight',
      },
    },
  });

  const { data, isSuccess, isLoading } = useQuery(['getGanttData', id], () => getProjectGantt(id));

  const chartHeight = useMemo(() => {
    if (data) {
      const minHeight = data.length * 40;
      return _.max([minHeight, 600]);
    }
    return wrapperHeight;
  }, [data, wrapperHeight]);

  useEffect(() => {
    updateChartOptions((state) => ({
      ...state,
      chart: { ...state.chart, height: chartHeight, width: wrapperWidth },
    }));
  }, [wrapperWidth, chartHeight]);

  useEffect(() => {
    if (isSuccess && data) {
      const res: any = [
        {
          data: [],
        },
      ];
      const milestone_names: string[] = [];
      data.forEach((project) => {
        milestone_names.push(...project.milestones.map((m) => m.name));
        const boxColor = getBoxColor(project.realization_status);
        const delay =
          project.realization_status === ProjectRealizationStatus.DELAYED
            ? dayjs(project.end).diff(dayjs()).valueOf()
            : undefined;
        const result = {
          x: project.title,
          y: [new Date(project.beginning).getTime(), new Date(project.end).getTime()],
          fillColor: boxColor,
          strokeColor: boxColor,
          goals: project.milestones.map((milestone) => {
            const plannedEndDate = dayjs(milestone.planned_end);
            const actualEndDate = milestone.actual_end ? dayjs(milestone.actual_end) : undefined;
            const today = dayjs();
            let milestoneStatus = ProjectRealizationStatus.IN_PROGRESS;
            if (actualEndDate) {
              milestoneStatus = ProjectRealizationStatus.FINISHED;
            } else {
              if (!actualEndDate && plannedEndDate > today) {
                milestoneStatus = ProjectRealizationStatus.DELAYED;
              }
            }
            const milestoneValue = milestone.actual_end ? milestone.actual_end : milestone.planned_end;
            const milestoneColor = getMilestoneColor(milestoneStatus);
            return {
              name: milestone.name,
              value: new Date(milestoneValue).getTime(),
              strokeColor: milestoneColor,
              strokeWidth: 5,
            };
          }),
          meta: {
            realization_status: project.realization_status,
            delay: delay,
            title: project.title,
            activity: project.activity,
          },
        };
        res[0].data.push(result);
      });
      setChartSeries(res);
      updateChartOptions((state) => ({
        ...state,
        legend: { ...state.legend, customLegendItems: milestone_names },
      }));
    }
  }, [data, isSuccess]);

  return (
    <Card id="gantt-graph" className="tabs-card top-level-card" title="Wykres Gantta">
      <div className="chart-wrapper" ref={ref}>
        {isLoading ? (
          <Spin indicator={loadingIcon} />
        ) : (
          <Chart type="rangeBar" options={chartOptions} series={chartSeries} height={chartHeight} />
        )}
      </div>
    </Card>
  );
};

export default GanttGraphSection;
