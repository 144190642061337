import { createReducer } from '@reduxjs/toolkit';
import { ActivitySummary } from '../../../types';
import {
  fetchActivityFinancialEffectsOptionsSuccess,
  fetchActivityFundingSuccess,
  fetchActivitySummarySuccess,
  fetchRiskEntriesSuccess,
  fetchRiskInfluencesSuccess,
  fetchRiskProbabilitiesSuccess,
  locationChange,
} from '../../actions';

const DEFAULT_STATE = {
  activity: {} as ActivitySummary,
  activityFunding: [] as Array<any>,
  riskInfluences: [] as any,
  riskProbabilities: [] as any,
  riskEntries: [] as any,
  activityFinancialEffectsOptions: [] as Array<any>,
};

const activityDetailsReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(fetchActivitySummarySuccess, (state, action) => {
      state.activity = action.payload;
    })
    .addCase(fetchActivityFundingSuccess, (state, action) => {
      state.activityFunding = action.payload;
    })
    .addCase(locationChange, (state, action) => {
      if (action.payload) {
        // @ts-ignore
        if (!action.payload.location.hash) {
          return DEFAULT_STATE;
        }
      }
      return state;
    })
    .addCase(fetchRiskInfluencesSuccess, (state, action) => {
      state.riskInfluences = action.payload;
    })
    .addCase(fetchRiskProbabilitiesSuccess, (state, action) => {
      state.riskProbabilities = action.payload;
    })
    .addCase(fetchRiskEntriesSuccess, (state, action) => {
      state.riskEntries = action.payload;
    })
    .addCase(fetchActivityFinancialEffectsOptionsSuccess, (state, action: any) => {
      try {
        state.activityFinancialEffectsOptions = action.payload.actions.POST.financial_effect.choices;
      } catch (e) {
        console.log(e);
      }
    }),
);

export default activityDetailsReducer;
