import { Select } from 'antd';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { BehaviorSubject, from, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { Indicator } from '../../../../../../types';
import { serachIndicators as getIndicators } from '../../../../api';
import { find } from 'lodash-es';
import { IndicatorsStore, useIndicatorsMonitoringStore } from '../../indicatorsStore';

type SelectOption = {
  label: string;
  value: number;
};

const debounceTimeout = 500;

const lastlyAddedIndicatorSelector = (state: IndicatorsStore) => state.lastlyAddedIndicator;

interface Props {
  onSelect: (value: Indicator | undefined) => void;
  selectedIndicator?: Indicator;
  disabled: boolean;
}

const IndicatorSearch: React.FC<Props> = ({ onSelect: selectIndicator, disabled }) => {
  const [subject, setSubject] = useState<BehaviorSubject<string> | null>(null);
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availableIndicators, setAvailableIndicators] = useState<Indicator[]>([]);

  const lastlyAddedIndicator = useIndicatorsMonitoringStore(lastlyAddedIndicatorSelector);

  useEffect(() => {
    fetchDefault();
  }, [lastlyAddedIndicator]);

  useEffect(() => {
    if (availableIndicators.length) {
      const options: SelectOption[] = [];
      [...availableIndicators].forEach((indicator) => {
        if (indicator.id) {
          options.push({ label: indicator.name, value: indicator.id });
        }
      });
      setSelectOptions(options);
    }
  }, [availableIndicators]);

  useEffect(() => {
    if (subject === null) {
      const sub = new BehaviorSubject('');
      setSubject(sub);
    } else {
      subject
        .pipe(
          debounceTime(debounceTimeout),
          map((s) => s.trim()),
          distinctUntilChanged(),
          // filter((s) => s.length >= 3),
          tap(() => setIsLoading(true)),
          switchMap((term) => from(getIndicators(term)).pipe(catchError((err: AxiosError) => of(err.message)))),
        )
        .subscribe((response) => {
          if (typeof response !== 'string') {
            setAvailableIndicators(response.data);
          } else {
            console.error(response);
          }
          setIsLoading(false);
        });
    }
    return () => subject?.unsubscribe();
  }, [subject]);

  const fetchDefault = async () => {
    setIsLoading(true);
    getIndicators()
      .then((response) => {
        setAvailableIndicators(response.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <Select<SelectOption[]>
      placeholder="Wyszukaj wskaźnik"
      filterOption={false}
      onSearch={(value) => subject?.next(value)}
      onSelect={(_, option) => {
        const selected = find(availableIndicators, (val) => val.id === option.value);
        if (selected) {
          selectIndicator(selected);
        }
      }}
      options={selectOptions}
      showSearch={true}
      loading={isLoading}
      allowClear={true}
      onClear={() => {
        selectIndicator(undefined);
        fetchDefault();
      }}
      disabled={disabled}
      className="indicator-search-select"
    />
  );
};

export default IndicatorSearch;
