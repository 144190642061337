import { client } from '../../utils/apiClients';

export const getIndicators = (page = 1, pageSize = 10, sorter: any): Promise<any> =>
  sorter && sorter.order
    ? client
        .get(`indicators/?page=${page}&page_size=${pageSize}&order=${sorter?.order}&field=${sorter.field}`)
        .then((r) => r.data)
    : client.get(`indicators/?page=${page}&page_size=${pageSize}`).then((r) => r.data);

export const getMeasureUnits = (page = 1, pageSize = 10): Promise<any> =>
  client.get(`measure_units/?page=${page}&page_size=${pageSize}`).then((r) => r.data);

export const getVerificationSources = (page = 1, pageSize = 10): Promise<any> =>
  client.get(`verification_sources/?page=${page}&page_size=${pageSize}`).then((r) => r.data);

export const getUser = (): Promise<any> => client.get(`user/`).then((r) => r.data);

export const createIndicator = (values: any): Promise<any> => client.post(`indicators/`, values).then((r) => r.data);

export const updateIndicator = (values: any): Promise<any> =>
  client.patch(`indicators/${values?.id}/`, values).then((r) => r.data);

export const deleteIndicator = (indicatorId: number): Promise<any> =>
  client.delete(`indicators/${indicatorId}/`).then((r) => r.data);

export const saveMeasureUnit = (value: any): Promise<any> => client.post(`measure_units/`, value).then((r) => r.data);

export const updateMeasureUnit = (values: any): Promise<any> =>
  client.patch(`measure_units/${values?.id}/`, values).then((r) => r.data);

export const deleteMeasureUnit = (unitId: number): Promise<any> =>
  client.delete(`measure_units/${unitId}/`).then((r) => r.data);

export const saveVerificationSource = (value: any): Promise<any> =>
  client.post(`verification_sources/`, value).then((r) => r.data);

export const updateVerificationSource = (values: any): Promise<any> =>
  client.patch(`verification_sources/${values?.id}/`, values).then((r) => r.data);

export const deleteVerificationSource = (sourceId: number): Promise<any> =>
  client.delete(`verification_sources/${sourceId}/`).then((r) => r.data);
