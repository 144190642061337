import React from 'react';
import StatusCircleIcon from '../../../assets/icons/StatusCircleIcon/StatusCircleIcon';
import { RealizationStatusEnum } from '../../../enums';
import './style.scss';

interface Props {
  status: RealizationStatusEnum;
}

const getLabel = (status: RealizationStatusEnum) => {
  switch (status) {
    case RealizationStatusEnum.DELAYED:
      return 'Opóźnione';
    case RealizationStatusEnum.FINISHED:
      return 'Zakończono';
    case RealizationStatusEnum.IN_PROGRESS:
      return 'W realizacji';
    case RealizationStatusEnum.UNKNOWN:
      return 'Nieznany';
  }
};

const RealizationStatus: React.FC<Props> = ({ status }) => {
  return (
    <p className="realization-status">
      <StatusCircleIcon status={status} />
      <span>{getLabel(status)}</span>
    </p>
  );
};

export default RealizationStatus;
