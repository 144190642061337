import { createReducer } from '@reduxjs/toolkit';
import { PlanSummary } from '../../../types';
import { fetchPlanSummarySuccess, locationChange } from '../../actions';

const DEFAULT_STATE = {
  plan: {} as PlanSummary,
};

const planDetailsReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(fetchPlanSummarySuccess, (state, action) => {
      state.plan = action.payload;
    })
    .addCase(locationChange, (state, action) => {
      if (action.payload) {
        // @ts-ignore
        if (!action.payload.location.hash) {
          return DEFAULT_STATE;
        }
      }
      return state;
    }),
);

export default planDetailsReducer;
