import axios from 'axios';
import { Project, RealizationPatchPayload } from '../../types';
import { client } from '../../utils/apiClients';
import { API_URL } from '../../utils/config';

export const archiveProject = async (id: number) => client.post(`project/${id}/archive/`);
export const unarchiveProject = async (id: number) => client.post(`project/${id}/unarchive/`);
export const updateProjectRealization = async (values: RealizationPatchPayload): Promise<Project> => {
  const { id, ...rest } = values;
  return axios.patch(`${API_URL}/project/${values.id}/`, rest).then((r) => r.data);
};
