import { ArchiveFilter, ProgressStatusFilter, RealizationStatusEnum, TypeFilter } from './enums';
import { TASK_FUNDING_SOURCE_VALUES_N_YEARS } from './utils/config';

export class Project {
  id: number = 0;
  kind: 'PRL' | 'PRI' = 'PRL';
  beginning: string = '';
  end: string = '';
  real_beginning: string = '';
  real_end: string = '';
  type: string = '';
  number: string = '';
  title: string = '';
  executor: number | null = null;
  reporter_type: string = '';
  venue: string = '';
  main_impact: string = '';
  additional_impact: Array<string> = [];
  local_administration_development: boolean = false;
  raising_standards: boolean = false;
  building_citizens_trust: boolean = false;
  gender_equality: boolean = false;
  non_discrimination: boolean = false;
  sustainable_development: boolean = false;
  requires_public_aid: boolean = false;
  background: string = '';
  scope_description: string = '';
  accessibility_standards: string = '';
  depends_on: Array<number> = [];
  direct_effect: string = '';
  long_term_effect: string = '';
  strategic_goal_impacts: Array<StrategicGoalImpact> = [];
  direct_beneficiaries: string = '';
  eventual_beneficiaries: string = '';
  sustainability: string = '';
  related_entities: Array<string> = [];
  status: string = '';
  tasks: Array<number> = [];
  activity: number | null = null;
  public_aid_test: PublicAidTest | null = null;
  is_primary: boolean = false;
  archived: boolean = false;
  open_project: number | null = null;
  committed_expenses: string = '';
  executed_expenses: string = '';
  realization_status: RealizationStatusEnum = RealizationStatusEnum.UNKNOWN;
  cost: number | null = null;
  percent_executed_expenses: string = '';
  plan_minus_commitment: string = '';
  plan_minus_execution: string = '';
}

export class ProjectSummary {
  id: number | null = null;
  kind: 'PRL' | 'PRI' = 'PRL';
  beginning: string = '';
  end: string = '';
  real_beginning: string = '';
  real_end: string = '';
  type: string = '';
  number: string = '';
  title: string = '';
  executor: Entity = {} as Entity;
  reporter_type: ReporterType = {} as ReporterType;
  venue: string = '';
  main_impact: Impact = {} as Impact;
  additional_impact: Array<Impact> = [] as Array<Impact>;
  local_administration_development: boolean = false;
  raising_standards: boolean = false;
  building_citizens_trust: boolean = false;
  gender_equality: boolean = false;
  non_discrimination: boolean = false;
  sustainable_development: boolean = false;
  requires_public_aid: boolean = false;
  background: string = '';
  scope_description: string = '';
  accessibility_standards: string = '';
  depends_on: Array<Project> = [] as Array<Project>;
  direct_effect: string = '';
  long_term_effect: string = '';
  strategic_goal_impacts: Array<StrategicGoalImpact> = [] as Array<StrategicGoalImpact>;
  direct_beneficiaries: string = '';
  eventual_beneficiaries: string = '';
  sustainability: string = '';
  related_entities: Array<Entity> = [] as Array<Entity>;
  status: string = '';
  tasks: Array<Task> = [] as Array<Task>;
  activity: Activity = {} as Activity;
  cost: number | null = null;
  project_funding_sources: any = {};
  public_aid_test: PublicAidTest | null = null;
  is_primary: boolean = false;
  archived: boolean = false;
  open_project: OpenProject = {} as OpenProject;
  committed_expenses: string = '';
  executed_expenses: string = '';
  percent_executed_expenses: string = '';
  plan_minus_commitment: string = '';
  plan_minus_execution: string = '';
}

export class PublicAidTest {
  is_business: string = '';
  is_business_reason: string = '';
  is_ancillary_business: string = '';
  is_ancillary_business_reason: string = '';
  state_aid: string = 'YES';
  state_aid_reason: string = '';
  selective_aid: string = 'YES';
  selective_aid_reason: string = '';
  better_than_market: string = '';
  better_than_market_reason: string = '';
  competition_distortion: string = '';
  competition_distortion_reason: string = '';
  affects_trade: string = '';
  affects_trade_reason: string = '';
  passed: boolean = false;
}

export class Entity {
  id: number | null = null;
  name: string = '';
  type: string = '';
}

export class ReporterType {
  id: number | null = null;
  name: string = '';
}

export class Impact {
  id: number | null = null;
  name: string = '';
}

export class StrategicGoal {
  id: number | null = null;
  kind: 'PRL' | 'PRI' | null = null;
  name: string = '';
}

export class StrategicGoalImpact {
  id: number | null = null;
  project: number | null = null;
  strategic_goal: number | null = null;
  description: string = '';
}

export class Task {
  id: number = 0;
  name: string = '';
  stage: string = '';
  cost: string = '';
  total_funding: string = '';
  is_fully_funded: boolean = true;
  beginning: string = '';
  end: string = '';
  task_funding_sources: Array<TaskFundingSource> = [];
  executor: number | null = null;
  expense_type: string = '';
}

export class FundingSource {
  id: number | null = null;
  name: string = '';
  description: string = '';
}

export class TaskFundingSource {
  id: number | null = null;
  funding_source: number | null = null;
  values: Array<string> = new Array(TASK_FUNDING_SOURCE_VALUES_N_YEARS * 4).fill('0.00');
  jst: boolean = false;
}

export type TaskFundingSourceSummary = {
  id: number;
  funding_source: FundingSource;
  values: Array<string>;
  total_value: string;
  jst: boolean;
};

export class Activity {
  kind: 'PRL' | 'PRI' = 'PRL';
  id: number | null = null;
  title: string = '';
  activity_leader: number | null = null;
  re_granting: boolean = false;
  main_impact: number | null = null;
  cost: number = 0;
  beginning: string = '';
  end: string = '';
  additional_impacts: Array<number> = [];
  strategic_goals: Array<number> = [];
  description: string = '';
  products: string = '';
  results: string = '';
  plan: number | null = null;
  direct_beneficiaries: string = '';
  eventual_beneficiaries: string = '';
  accessibility_standards: string = '';
  community_engagement: string = '';
  promotion: string = '';
  sustainability: string = '';
  projects: Array<number> = [];
  risks: Array<Risk> = [];
  local_administration_development: boolean = false;
  raising_standards: boolean = false;
  building_citizens_trust: boolean = false;
  financial_effects: ActivityFinancialEffects | Array<any> = [];
  plan_time: Array<string> = [];
  archived: boolean = false;
  realization_status: RealizationStatusEnum = RealizationStatusEnum.UNKNOWN;
}

export class ActivitySummary {
  id: number | null = null;
  title: string = '';
  activity_leader: Entity = {} as Entity;
  re_granting: boolean = false;
  main_impact: Impact = {} as Impact;
  gender_equality: boolean = false;
  non_discrimination: boolean = false;
  sustainable_development: boolean = false;
  requires_public_aid: boolean = false;
  cost: number = 0;
  beginning: string = '';
  end: string = '';
  additional_impacts: Array<Impact> = [] as Array<Impact>;
  strategic_goals: Array<StrategicGoal> = [] as Array<StrategicGoal>;
  description: string = '';
  products: string = '';
  results: string = '';
  direct_beneficiaries: string = '';
  eventual_beneficiaries: string = '';
  accessibility_standards: string = '';
  community_engagement: string = '';
  promotion: string = '';
  sustainability: string = '';
  projects: Array<ProjectSummary> = [] as Array<ProjectSummary>;
  plan: Plan = {} as Plan;
  risks: Array<Risk> = [];
  local_administration_development: boolean = false;
  raising_standards: boolean = false;
  building_citizens_trust: boolean = false;
  financial_effects: ActivityFinancialEffects = {} as ActivityFinancialEffects;
  archived: boolean = false;
}

export type RiskCategory = {
  id: number | null;
  title: string;
};

export type RiskProbability = {
  id: number | null;
  description: string;
  symbol: string;
  title: string;
};

export type RiskInfluence = {
  id: number | null;
  description: string;
  symbol: string;
  title: string;
};

export type RiskEntry = {
  id: number | null;
  category: RiskCategory;
  title: string;
};

export class Risk {
  id: number | null = null;
  entry: number | null = null;
  probability: number | null = null;
  influence: number | null = null;
}

export class FinancialEffect {
  activity: number | null = null;
  financial_effect: string = '';
  values: Array<number> = new Array(TASK_FUNDING_SOURCE_VALUES_N_YEARS).fill(0.0);
}

export class ActivityFinancialEffects {
  financial_effects: Array<FinancialEffect> = [] as Array<FinancialEffect>;
}

export type Plan = {
  id: number | null;
  title: string;
  kind: 'PRL' | 'PRI';
  beginning: string;
  end: string;
  activities: Array<number>;
  archived: boolean;
};

export type MinimalPlan = {
  id: number;
  title: string;
};

export type PlanSummary = Omit<Plan, 'activities'> & {
  primary_activities: Array<ActivitySummary>;
  complementary_activities: Array<ActivitySummary>;
};

export type City = {
  id: number;
  name: string;
};

export type User = {
  username: string;
  city: City;
  cities: City[];
  is_superuser: boolean;
  is_staff: boolean;
  is_ministry: boolean;
};

export type ValidationErrors<T> = {
  [P in keyof T]?: Array<string>;
} & { non_field_errors: Array<string> };

export type Filters = {
  archive: ArchiveFilter;
  type?: TypeFilter;
  progress_status?: ProgressStatusFilter;
  executors?: Array<number> | null;
  realizationStatus?: RealizationStatusEnum[];
};

export class OpenProject {
  id: number | null = null;
  city: number | null = null;
  number: string = '';
  title: string = '';
  description: string = '';
  beginning: string = '';
  end: string = '';
  cost: number = 0;
  comments: string = '';
  status: 'DRAFT' | 'APPROVED' = 'DRAFT';
  progress_status: 'IN_PREPARATION' | 'IN_PROGRESS' | 'REALIZED' = 'IN_PREPARATION';
  executors: Array<Entity> = [] as Array<Entity>;
  projects: Array<ProjectSummary> = [] as Array<ProjectSummary>;
  activities: Array<Activity> = [] as Array<Activity>;
  location: string = '';
  geolocation1_latitude: number | null = null;
  geolocation1_longitude: number | null = null;
  geolocation2_administrative_address: string = '';
  geolocation3_plot_id: string = '';
  geolocation4_attachment: any;
}

export class OpenProjectSummary {
  id: number | null = null;
  number: string = '';
  title: string = '';
  description: string = '';
  beginning: string = '';
  end: string = '';
  cost: number = 0;
  // TODO: try to use enum
  comments: string = '';
  status: 'DRAFT' | 'APPROVED' = 'DRAFT';
  progress_status: 'IN_PREPARATION' | 'IN_PROGRESS' | 'REALIZED' = 'IN_PREPARATION';
  executors: Array<Entity> = [] as Array<Entity>;
  projects: Array<ProjectSummary> = [] as Array<ProjectSummary>;
  activities: Array<Activity> = [] as Array<Activity>;
  location: string = '';
  geolocation1_latitude: number | null = null;
  geolocation1_longitude: number | null = null;
  geolocation2_administrative_address: string = '';
  geolocation3_plot_id: string = '';
  geolocation4_attachment: any;
}

export class OpenProjectMapped {
  id: number | null = null;
  title: string = '';
  description: string = '';
  beginning: string = '';
  end: string = '';
  cost: number = 0;
  status: 'DRAFT' | 'APPROVED' = 'DRAFT';
  progress_status: 'IN_PREPARATION' | 'IN_PROGRESS' | 'REALIZED' = 'IN_PREPARATION';
  executors: Array<Entity> = [] as Array<Entity>;
  geolocation1_latitude: number = 0;
  geolocation1_longitude: number = 0;
}

export class MeasureUnit {
  id: number | null = null;
  name: string = '';
}

export class Indicator {
  id: number | null = null;
  name: string = '';
  measure_unit: MeasureUnit = {} as MeasureUnit;
  verification_source: VerificationSource = {} as VerificationSource;
  created_at?: '';
  updated_at?: '';
  user?: User;
  added_by?: number;
  is_binary: boolean = false;
}

export class VerificationSource {
  id: number | null = null;
  name: string = '';
}

export interface ScheduleRealizationTableData {
  key: number;
  planned_cost: number;
  committed_expenses: number;
  executed_expenses: number;
  execution_indicator: number;
  plan_minus_commitment: number;
  plan_minus_execution: number;
}

export type RealizationPatchPayload = Pick<Project, 'committed_expenses' | 'executed_expenses'> & {
  id: string;
};

export class ProjectMilestone {
  id: number = -1;
  name: string = '';
  final: boolean = false;
  executor: string = '';
  planned_end: string | undefined = undefined;
  actual_end: string | undefined = undefined;
  milestone: number = -1;
  project: number = -1;
  order: number = 0;
}

export interface EditProjectMilestoneProps extends Omit<ProjectMilestone, 'actual_end'> {
  actual_end: string | null;
}

export class Milestone {
  id: number = -1;
  name: string = '';
}

export interface PlanStatusResponse {
  strategic_goals: PlanStatus[];
}

export interface PlanStatus {
  id: number;
  name: string;
  percent_done?: number;
  percent_executed_expenses?: number;
  beginning?: string;
  end?: string;
  activities?: {
    id: number;
    name: string;
    percent_done?: number;
    percent_executed_expenses?: number;
    beginning?: string;
    end?: string;
    realization_status?: RealizationStatusEnum;
    projects?: {
      id: number;
      name: string;
      percent_done?: number;
      percent_executed_expenses?: number;
      beginning?: string;
      end?: string;
      realization_status?: RealizationStatusEnum;
      milestones?: {
        name: string;
        executor: string;
        planned_end: string;
        actual_end?: string;
      }[];
    }[];
  }[];
}

export interface StrategicGoalFiler {
  delayed: boolean;
  inProgress: boolean;
  notStarted: boolean;
}

export enum IndicatorReportFrequency {
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ONE_TIME = 'ONE_TIME',
}

export enum IndicatorConfigurationType {
  STRATEGIC_GOAL = 'STRATEGIC_GOAL',
  RESULT = 'RESULT',
  PRODUCT = 'PRODUCT',
}

export enum ProjectRealizationStatus {
  UNKNOWN = 'UNKNOWN',
  DELAYED = 'DELAYED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

interface ModelMinimal {
  id: number;
  title: string;
}

export interface IndicatorsMonitoringIndicator {
  id: number;
  report_frequency: IndicatorReportFrequency;
  base_year: number;
  target_year: number;
  precision: number;
  base_value: number;
  target_value: number;
  source_indicator: {
    id: number;
    name: string;
    measure_unit: string;
    verification_source: string;
    is_binary: boolean;
    precision: number;
  };
  activity?: ModelMinimal;
  project?: ModelMinimal;
  progress_percentage?: number;
  base_nominal_change?: number;
  target_nominal_change?: number;
  measurement_start: string;
  latest_measurement?: {
    measurement_date: Date;
    measured_value: number;
    note?: string;
  };
}

export interface IndicatorsMonitoringListItem {
  id: number;
  name: string;
  is_primary: boolean;
  indicators: IndicatorsMonitoringIndicator[];
}
export interface IndicatorsMonitoringList extends IndicatorsMonitoringListItem {
  results: Array<
    IndicatorsMonitoringListItem & {
      products: Array<IndicatorsMonitoringListItem>;
    }
  >;
}

export interface IndicatorMeasurementsListItem {
  id: number;
  precision: number;
  source_indicator: {
    id: number;
    name: string;
    measure_unit: string;
    verification_source: string;
    is_binary: boolean;
    precision: number;
  };
  type_label: string;
  type_name: string;
}

export interface IndicatorsMonitoringMeasurementsList {
  overdue: IndicatorMeasurementsListItem[];
  week: IndicatorMeasurementsListItem[];
  month: IndicatorMeasurementsListItem[];
  quarter: IndicatorMeasurementsListItem[];
}

export interface AddIndicatorConfigRequest {
  report_frequency: IndicatorReportFrequency;
  base_year: number;
  target_year: number;
  precision: number;
  base_value: number;
  target_value: number;
  indicator: number;
  plan: number;
  type: IndicatorConfigurationType;
  measurement_start: string;
  activity?: number | null;
  project?: number | null;
  strategic_goal?: number;
  result?: number;
  product?: number;
}

export interface EditIndicatorConfigRequest extends AddIndicatorConfigRequest {
  id: number;
}

export interface AddResultApiRequest {
  name: string;
  strategic_goal: number;
  plan: number;
}

export interface EditResultApiRequest {
  name: string;
  result: number;
  plan: number;
}

export interface AddProductApiRequest {
  name: string;
  plan: number;
  result: number;
}

export interface EditProductApiRequest {
  name: string;
  plan: number;
  product: number;
}

export interface ActivityMinimal {
  id: number;
  title: string;
}

export interface ProjectMinimal {
  id: number;
  title: string;
}

export interface IndicatorMeasurementApiRequest {
  indicator_config: number;
  measured_value: number;
  measurement_date: string;
  note?: string;
}

export interface PlanGanttData {
  beginning: string;
  end: string;
  id: number;
  milestones: {
    name: string;
    planned_end: string;
    actual_end?: string;
  }[];
  realization_status: ProjectRealizationStatus;
  title: string;
  activity: string;
}
export interface MfiPRReportRequest {
  report_date: string;
}
