import React from 'react';
import { Button, Card, Result, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { activityForm } from '../../../../redux/selectors/activity/activityForm';
import { connect } from 'react-redux';
import { ActivityFinancialEffects } from '../../../../types';
import { editFinancialEffect } from '../../../../redux/actions';

type Props = ReturnType<typeof activityForm> & typeof mapDispatchToProps;

const FinancialEffectsForm: React.FunctionComponent<Props> = (props) => {
  const { financialEffects, editFinancialEffect, planYears, financialEffectsDataSource, financialEffectsColumns } =
    props;

  const addNewEffectHandler = () => {
    editFinancialEffect(new ActivityFinancialEffects());
  };

  const editEffectHandler = () => {
    editFinancialEffect({ financial_effects: [...financialEffects] });
  };

  return (
    <Card className="top-level-card" title="Skutki finansowe dla budżetów lat przyszłych">
      {planYears.length !== 0 ? (
        financialEffects.length === 0 ? (
          <Result
            title="Działanie nie posiada skutków finansowych"
            extra={
              <Button type="primary" onClick={addNewEffectHandler}>
                <PlusOutlined /> Dodaj skutki
              </Button>
            }
          />
        ) : (
          <>
            <Table
              className="schedule-table"
              columns={financialEffectsColumns}
              dataSource={financialEffectsDataSource}
              size="small"
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            <p>
              <Button type="primary" onClick={editEffectHandler}>
                <PlusOutlined /> Edytuj skutki
              </Button>
            </p>
          </>
        )
      ) : (
        <Result status="warning" title="Działanie nie należy do żadnego projektu" />
      )}
    </Card>
  );
};

const mapDispatchToProps = { editFinancialEffect };

export default connect(activityForm, mapDispatchToProps)(FinancialEffectsForm);
