import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { createFetchObjectSaga, createFetchObjectSummarySaga, createFetchPaginatedListSaga } from './utils';
import { API_URL } from '../../utils/config';
import {
  Action,
  fetchPlan,
  fetchPlanActivities,
  fetchPlanActivitiesFailed,
  fetchPlanActivitiesSuccess,
  fetchPlanFailed,
  fetchPlans,
  fetchPlansSuccess,
  fetchPlanSuccess,
  fetchPlanSummary,
  fetchPlanSummaryFailed,
  fetchPlanSummarySuccess,
  savePlan,
  savePlanFailed,
  savePlanSuccess,
} from '../actions';
import { Plan } from '../../types';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/notifications';

const fetchPlansSaga = createFetchPaginatedListSaga(`${API_URL}/plan/`, fetchPlansSuccess, fetchPlanFailed);

const fetchPlanSaga = createFetchObjectSaga(`${API_URL}/plan`, fetchPlanSuccess, fetchPlanFailed);

const fetchPlanSummarySaga = createFetchObjectSummarySaga(
  `${API_URL}/plan`,
  fetchPlanSummarySuccess,
  fetchPlanSummaryFailed,
);

function* savePlanSaga({ payload }: Action<Plan>) {
  try {
    // if id is present then we're updating an existing activity (PUT)
    // otherwise we're creating a new one (POST).
    let response;
    if (payload.id) {
      response = yield axios.put(`${API_URL}/plan/${payload.id}/`, payload);
    } else {
      response = yield axios.post(`${API_URL}/plan/`, payload);
    }
    yield put(savePlanSuccess(response.data));
    displaySuccessNotification('Zapis planu zakończony pomyślnie.');
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.status === 400) {
        yield put(savePlanFailed(e.response.data));
      }
      displayErrorNotification('Błąd zapisu planu.');
    } else {
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  }
}

function* fetchPlanActivitiesSaga({ payload }: Action<number>) {
  try {
    const response = yield axios.get(`${API_URL}/plan/${payload}/activity/`);
    yield put(fetchPlanActivitiesSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(fetchPlanActivitiesFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

export default function* planSaga() {
  yield takeEvery(fetchPlans, fetchPlansSaga);
  yield takeEvery(fetchPlan, fetchPlanSaga);
  yield takeEvery(savePlan, savePlanSaga);
  yield takeEvery(fetchPlanSummary, fetchPlanSummarySaga);
  yield takeEvery(fetchPlanActivities, fetchPlanActivitiesSaga);
}
