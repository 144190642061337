import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import { Input, Select } from 'formik-antd';
import { PlusOutlined } from '@ant-design/icons';
import { VerificationSource } from '../../../types';
import { useQuery } from 'react-query';
import { getVerificationSources, saveVerificationSource } from '../api';
import { displaySuccessNotification } from '../../../utils/notifications';

const { Option } = Select;

const VerificationSourceSelect: React.FC = () => {
  const [verificationSourceAdd, setVerificationSourceAdd] = useState('');

  const { data: verificationSources, refetch: refetchSources } = useQuery('verificationSources', () =>
    getVerificationSources(),
  );

  const addVerificationSource = () => {
    const newVerificationSource = saveVerificationSource({
      name: verificationSourceAdd,
    });
    newVerificationSource.then((resp) => {
      refetchSources().then(() => displaySuccessNotification('Zapis źródła weryfikacji zakończony pomyślnie.'));
    });
  };

  const onVerificationSourceNameChange = (e: any) => {
    setVerificationSourceAdd(e.target.value);
  };

  const filterOption = (input: any, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Select
      name="verification_source"
      showSearch
      allowClear
      className="indicator-form-inputs"
      optionFilterProp="children"
      filterOption={filterOption}
      placeholder="Wybierz źródło weryfikacji"
      dropdownRender={(menu) => (
        <div className="verification-source-select-dropdown">
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              padding: 8,
            }}
          >
            <Input
              name="verification_source"
              style={{ flex: 'auto' }}
              value={verificationSourceAdd}
              onChange={onVerificationSourceNameChange}
            />
            <Button
              className="submit-button add-unit-btn"
              key="submit"
              htmlType="submit"
              type="primary"
              onClick={addVerificationSource}
            >
              <PlusOutlined /> Dodaj
            </Button>
          </div>
        </div>
      )}
    >
      {verificationSources?.results.map((source: VerificationSource) => (
        <Option value={source.id || -1} key={source.id || -1}>
          <div className="verification-source-select-option">
            <span>{source.name}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default VerificationSourceSelect;
