import React, { useEffect } from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Scrollspy from 'react-scrollspy';
import GeneralInfoSection from './GeneralInfoSection';
import DetailedInfoSection from './DetailedInfoSection';
import RiskAnalysisSection from './RiskAnalysisSection';
import ProjectsSection from './ProjectsSection';
import FinancialEffectsSection from './FinancialEffectsSection';
import { activityDetails } from '../../../redux/selectors/activity/activityDetails';
import {
  fetchActivityFunding,
  fetchActivitySummary,
  fetchRiskInfluences,
  fetchRiskProbabilities,
  fetchRiskEntries,
  fetchActivityFinancialEffectsOptions,
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { API_URL } from '../../../utils/config';
import FileSaver from 'file-saver';
import FundingSourcesSection from './FundingSourcesSection';
import editIcon from '../../../assets/edit.svg';
import exportIcon from '../../../assets/export.svg';
import ScrollToTop from '../../Common/ScrollToTop';
import ArchiveActivityButton from './ArchiveActivityButton';

const { Content, Sider } = Layout;

type Props = ReturnType<typeof activityDetails> & typeof mapDispatchToProps;

function ActivityDetails(props: Props) {
  const {
    activity,
    fetchActivitySummary,
    fetchActivityFunding,
    fetchRiskInfluences,
    fetchRiskProbabilities,
    fetchRiskEntries,
    fetchActivityFinancialEffectsOptions,
  } = props;

  const { id } = useParams();

  useEffect(() => {
    id && fetchActivitySummary(parseInt(id));
    id && fetchActivityFunding(parseInt(id));
    fetchRiskInfluences();
    fetchRiskProbabilities();
    fetchRiskEntries();
    fetchActivityFinancialEffectsOptions();
  }, [
    fetchActivitySummary,
    fetchActivityFunding,
    fetchRiskInfluences,
    fetchRiskProbabilities,
    fetchRiskEntries,
    id,
    fetchActivityFinancialEffectsOptions,
  ]);

  const downloadCard = () => {
    axios.get(`${API_URL}/activity/${id}/export/`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(new Blob([response.data], { type: response.headers['content-type'] }), `${activity.title}.docx`);
    });
  };

  return (
    <>
      <Row className="title-bar">
        <Col className="title" span={12}>
          <div>{activity.title}</div>
        </Col>
        <Col className="buttons" span={6}>
          <Button size="large" onClick={downloadCard}>
            <img src={exportIcon} alt="export icon" /> Eksportuj kartę
          </Button>
          <Link to={`/activity/${id}/edit`}>
            <Button type="primary" size="large">
              <img src={editIcon} alt="edit icon" />
              Edytuj
            </Button>
          </Link>
          <ArchiveActivityButton activity={activity} />
        </Col>
      </Row>
      <Layout className="inner-layout">
        <Sider className="navigation-sider">
          <Scrollspy
            className="side-menu"
            items={[
              'general-info',
              'detailed-info',
              'projects',
              'funding-sources',
              'risk-analysis',
              'financial-effects',
            ]}
            currentClassName="active-section"
          >
            <div>
              <a href="#general-info">Informacje ogólne</a>
            </div>
            <div>
              <a href="#detailed-info">Szczegóły</a>
            </div>
            <div>
              <a href="#projects-section">Przedsięwzięcia</a>
            </div>
            <div>
              <a href="#funding-sources">Finansowanie</a>
            </div>
            <div>
              <a href="#risk-analysis">Analiza ryzyka</a>
            </div>
            <div>
              <a href="#financial-effects">Skutki finansowe</a>
            </div>
          </Scrollspy>
        </Sider>
        <Content>
          {activity.archived && <p>Działanie zostało zarchiwizowane.</p>}
          <GeneralInfoSection />
          <DetailedInfoSection />
          <ProjectsSection />
          <FundingSourcesSection />
          <RiskAnalysisSection />
          <FinancialEffectsSection />
        </Content>
      </Layout>
      <ScrollToTop />
    </>
  );
}

const mapDispatchToProps = {
  fetchActivitySummary,
  fetchActivityFunding,
  fetchRiskInfluences,
  fetchRiskProbabilities,
  fetchRiskEntries,
  fetchActivityFinancialEffectsOptions,
};

export default connect(activityDetails, mapDispatchToProps)(ActivityDetails);
