import { Col, Row } from 'antd';
import { DatePicker, Form } from 'formik-antd';
import React, { FunctionComponent, useState } from 'react';
import { useFormikContext } from 'formik';
import moment, { Moment } from 'moment';

type Props = {
  errors?: {
    beginning?: Array<string>;
    end?: Array<string>;
    real_end?: Array<string>;
    real_beginning?: Array<string>;
  };
  real?: boolean;
};

const QuarterRangePicker: FunctionComponent<Props> = ({ errors, real }) => {
  const [isEndOpen, setEndOpen] = useState(false);
  const { values }: any = useFormikContext();
  const { beginning, end }: { beginning: Moment; end: Moment } = values;

  const disabledBeginning = (current: Moment) => end && current && current > moment(end);
  const disabledEnd = (current: Moment) => beginning && current && current < moment(beginning);

  const onBeginningChange = () => {
    setEndOpen(true);
  };

  const beginningProps =
    errors && errors.beginning
      ? ({
          validateStatus: 'error',
          help: errors.beginning.join(', '),
        } as any)
      : {};

  const endProps = errors && errors.end ? ({ validateStatus: 'error', help: errors.end.join(', ') } as any) : {};

  const realBeginningProps =
    errors && errors.real_beginning
      ? ({
          validateStatus: 'error',
          help: errors.real_beginning.join(', '),
        } as any)
      : {};

  const realEndProps =
    errors && errors.real_end ? ({ validateStatus: 'error', help: errors.real_end.join(', ') } as any) : {};

  return (
    <Row className="quarter-picker">
      <Col span={12}>
        <Form.Item label={real ? 'Planowany początek' : 'Początek'} name="beginning" {...beginningProps}>
          <DatePicker
            disabledDate={disabledBeginning}
            name="beginning"
            picker="quarter"
            onChange={onBeginningChange}
            placeholder="Wybierz kwartał"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={real ? 'Planowany koniec' : 'Koniec'} name="end" {...endProps}>
          <DatePicker
            disabledDate={disabledEnd}
            name="end"
            picker="quarter"
            open={isEndOpen}
            onOpenChange={setEndOpen}
            placeholder="Wybierz kwartał"
          />
        </Form.Item>
      </Col>
      {real ? (
        <React.Fragment>
          <Col span={12}>
            <Form.Item label="Rzeczywisty początek" name="real_beginning" {...realBeginningProps}>
              <DatePicker name="real_beginning" picker="quarter" placeholder="Wybierz kwartał" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Rzeczywisty koniec" name="real_end" {...realEndProps}>
              <DatePicker name="real_end" picker="quarter" placeholder="Wybierz kwartał" />
            </Form.Item>
          </Col>
        </React.Fragment>
      ) : null}
    </Row>
  );
};

export default QuarterRangePicker;
