import { Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Project, ProjectSummary, ScheduleRealizationTableData, Task } from '../../../../../types';
import { ColumnType } from 'antd/lib/table';
import './style.scss';
import { currency } from '../../../../../utils/formatters';

interface Props {
  project: ProjectSummary | Project;
  tasks: Task[];
}

const formatPercentageCell = (value: number | string): string => {
  return String(value).replace('.', ',');
};

const renderCellValueWithTooltip = (value: string | number, title: string) => (
  <Tooltip overlayClassName="tooltip-standard-dark" title={title} trigger="hover">
    <span className="cell-error">{value}</span>
  </Tooltip>
);

const ScheduleRealizationTable: React.FC<Props> = ({ project, tasks }) => {
  const [realizationTableValues, setRealizationTableValues] = useState({
    execution_indicator: 0,
    plan_minus_commitment: 0,
    plan_minus_execution: 0,
    planned_cost_total: 0,
  });

  useEffect(() => {
    const planned_cost_total = project.cost;
    if (planned_cost_total) {
      setRealizationTableValues({
        execution_indicator: Number(project.percent_executed_expenses),
        plan_minus_commitment: Number(project.plan_minus_commitment),
        plan_minus_execution: Number(project.plan_minus_execution),
        planned_cost_total,
      });
    }
  }, [
    tasks,
    project.cost,
    project.committed_expenses,
    project.percent_executed_expenses,
    project.plan_minus_execution,
    project.plan_minus_commitment,
  ]);

  const realizationTableColumns: ColumnType<ScheduleRealizationTableData>[] = [
    {
      title: 'Planowany koszt',
      dataIndex: 'planned_cost',
      align: 'center' as 'center',
      render: (value: number) => currency(value),
    },
    {
      title: 'Zaangażowanie wydatków (zł)',
      dataIndex: 'committed_expenses',
      align: 'center' as 'center',
      render: (value: number, record) => {
        if (value > record.planned_cost) {
          return renderCellValueWithTooltip(
            currency(value),
            'Zaangażowanie wydatków nie może być wyższe od planowanego kosztu',
          );
        }
        return currency(value);
      },
    },
    {
      title: 'Wykonanie wydatków (zł)',
      dataIndex: 'executed_expenses',
      align: 'center' as 'center',
      render: (value: number, record) => {
        if (value > record.committed_expenses) {
          return renderCellValueWithTooltip(
            currency(value),
            'Wykonanie wydatków nie może być wyższe od zaangażowania wydatków',
          );
        }
        return currency(value);
      },
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Stosunek wykonania wydatków do planowanego kosztu"
          overlayClassName="tooltip-standard-dark"
        >
          <span>Wskaźnik wykonania (%)</span>
        </Tooltip>
      ),
      dataIndex: 'execution_indicator',
      align: 'center' as 'center',
      render: (value) => (value ? `${formatPercentageCell(value.toFixed(2))}%` : '0%'),
    },
    {
      title: 'Plan minus zaangażowanie (zł)',
      dataIndex: 'plan_minus_commitment',
      align: 'center' as 'center',
      render: (value: number) => currency(value),
    },
    {
      title: 'Plan minus wykonanie (zł)',
      dataIndex: 'plan_minus_execution',
      align: 'center' as 'center',
      render: (value: number) => currency(value),
    },
  ];

  return (
    <Table
      columns={realizationTableColumns}
      dataSource={[
        {
          key: 1,
          planned_cost: realizationTableValues.planned_cost_total,
          committed_expenses: Number(project.committed_expenses),
          executed_expenses: Number(project.executed_expenses),
          execution_indicator: realizationTableValues.execution_indicator,
          plan_minus_commitment: realizationTableValues.plan_minus_commitment,
          plan_minus_execution: realizationTableValues.plan_minus_execution,
        },
      ]}
      size="small"
      pagination={false}
    />
  );
};

export default ScheduleRealizationTable;
