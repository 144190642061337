// translates pagination from API params to UI usable values
export const apiToUi = (offset: number, limit: number, count: number) => ({
  page: offset / limit + 1,
  pageSize: limit,
  total: count,
});

// translates pagination from UI to API params
export const uiToApi = (page: number, pageSize: number) => ({
  offset: (page - 1) * pageSize,
  limit: pageSize,
});
