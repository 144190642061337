import { Button, Form, DatePicker, Modal } from 'antd';
import React from 'react';
import { getMFiPRExcel } from '../api';
import { displayErrorNotification } from '../../../utils/notifications';
import AddIcon from '../../../assets/icons/AddIcon/AddIcon';
import CloseIcon from '../../../assets/icons/CloseIcon/CloseIcon';
import FileSaver from 'file-saver';
import { AxiosResponse } from 'axios';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export interface MFiPRFormInputs {
  date: string;
}
const save_excel = (response: AxiosResponse<any>) => {
  const blob_config = { type: response.headers['content-type'] };
  const blob = new Blob([response.data], blob_config);
  const filename = response.headers['content-disposition'].split(';')[1].replace(' ', '').split('=')[1];
  FileSaver.saveAs(blob, filename);
};

const MFiPRFormModal: React.FC<Props> = ({ visible, onClose }) => {
  const [form] = Form.useForm<MFiPRFormInputs>();

  const onFinish = (values: MFiPRFormInputs) => {
    getMFiPRExcel(values.date)
      .then((response) => {
        save_excel(response);
        onClose();
      })
      .catch(() => displayErrorNotification('Błąd pobierania raportu'));
  };

  const handleAfterClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title="Raport MFiPR"
      wrapClassName="standard-modal"
      onOk={onClose}
      onCancel={onClose}
      afterClose={handleAfterClose}
      destroyOnClose={true}
      footer={[
        <Button className="submit-button" key="submit" htmlType="submit" type="primary" onClick={() => form.submit()}>
          <AddIcon />
          Pobierz raport
        </Button>,
        <Button className="cancel-button" key="back" onClick={onClose}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} className="modal-form">
        <div className="form-row">
          <Form.Item
            name="date"
            rules={[{ required: true, message: 'Data wymagana' }]}
            label="Raport na dzień"
            className="full"
          >
            <DatePicker placeholder="Wybierz datę" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default MFiPRFormModal;
