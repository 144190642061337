import { createReducer } from '@reduxjs/toolkit';
import {
  changeProjectsPagination,
  fetchProjectsSuccess,
  setProjectListSearch,
  setProjectRealizationFilter,
  setProjectSearchArchiveFilter,
} from '../../actions';
import { Project } from '../../../types';
import { ArchiveFilter, RealizationStatusEnum } from '../../../enums';
import { setProjectListOrdering } from '../../actions/index';

const DEFAULT_STATE = {
  projects: [] as Array<Project>,
  projectsCount: 0,
  page: 1,
  pageSize: 10,
  filters: {
    archive: ArchiveFilter.Hide,
    realizationStatus: [] as RealizationStatusEnum[],
  },
  ordering: '',
  search: '',
};

const projectListReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(changeProjectsPagination, (state, action) => ({
      ...state,
      page: action.payload.page,
      pageSize: action.payload.pageSize,
    }))
    .addCase(fetchProjectsSuccess, (state, action) => ({
      ...state,
      projects: action.payload.results,
      projectsCount: action.payload.count,
    }))
    .addCase(setProjectSearchArchiveFilter, (state, action) => {
      state.filters.archive = action.payload;
    })
    .addCase(setProjectListOrdering, (state, action) => {
      state.ordering = action.payload;
    })
    .addCase(setProjectListSearch, (state, action) => {
      state.search = action.payload;
    })
    .addCase(setProjectRealizationFilter, (state, action) => {
      state.filters.realizationStatus = action.payload;
    }),
);

export default projectListReducer;
