import { Card, Tabs } from 'antd';
import React, { useMemo } from 'react';
import './style.scss';
import AddListElementIcon from '../../../../assets/icons/AddListElementIcon/AddListElementIcon';
import AddIndicatorConfigModal from './modals/AddIndicatorConfigModal/AddIndicatorConfigModal';
import IndicatorsList from './IndicatorsList/IndicatorsList';
import IndicatorsMeasurementList from './IndicatorsMeasurementList/IndicatorsMeasurementList';
import IndicatorMeasurementModal from './modals/IndicatorMeasurementModal/IndicatorMeasurementModal';
import { AddIndicatorForm } from '../../../Indicator/IndicatorFormModal';
import { useQuery, useQueryClient } from 'react-query';
import { planIndicators } from '../../api';
import { Indicator } from '../../../../types';
import { IndicatorsStore, useIndicatorsMonitoringStore } from './indicatorsStore';
import { useParams } from 'react-router';
import EditProductModal from './modals/EditProductModal';
import _ from 'lodash';
import EditResultModal from './modals/EditResultModal';

interface Params {
  id: string;
}

const modalsOptionsSelector = (state: IndicatorsStore) => ({
  goalId: state.goalId,
  resultId: state.resultId,
  indicatorModalConfig: state.indicatorConfig,
  indicatorMeasurementModalConfig: state.indicatorMeasurementModalContext,
});

const modalsVisibilitySelector = (state: IndicatorsStore) => state.modalsVisibility;

const indicatorVisibilitySelector = (state: IndicatorsStore) => state.setIndicatorVisibility;

const indicatorMeasurementVisibilitySelector = (state: IndicatorsStore) => state.setIndicatorMeasurementVisibility;

const indicatorConfigVisibilitySelector = (state: IndicatorsStore) => state.setIndicatorConfigVisibility;

const updateLastlyAddedIndicator = (state: IndicatorsStore) => state.setLastlyAddedIndicator;

const IndicatorsSection: React.FC = () => {
  // NOTE: applying onClick close not possible, use react-toastify ?

  const { id } = useParams<Params>();

  const planId = useMemo(() => Number(id), [id]);

  const queryClient = useQueryClient();

  const modalsVisibility = useIndicatorsMonitoringStore(modalsVisibilitySelector);

  const setIndicatorVisibility = useIndicatorsMonitoringStore(indicatorVisibilitySelector);

  const setIndicatorMeasurementVisibility = useIndicatorsMonitoringStore(indicatorMeasurementVisibilitySelector);

  const setIndicatorConfigVisibility = useIndicatorsMonitoringStore(indicatorConfigVisibilitySelector);

  const setLastlyAddedIndicator = useIndicatorsMonitoringStore(updateLastlyAddedIndicator);

  const modalsOptions = useIndicatorsMonitoringStore(modalsOptionsSelector);

  const { data: indicatorsListResponse } = useQuery(
    ['indicatorsMonitoringList', planId],
    () => planIndicators(planId),
    {
      enabled: !!planId,
      refetchOnWindowFocus: false,
    },
  );

  const indicators = useMemo(() => indicatorsListResponse?.data || [], [indicatorsListResponse]);

  const primaryIndicators = useMemo(() => _.remove(indicators, ({ is_primary }) => is_primary), [indicators]);

  return (
    <Card id="indicators-monitoring" className="tabs-card top-level-card" title="Monitoring wskaźników">
      <Tabs
        onChange={(key) => {
          if (key === '2') {
            queryClient.invalidateQueries(['indicatorsMonitoringMeasurementsList']);
          }
          if (key === '1') {
            queryClient.invalidateQueries(['indicatorsMonitoringList']);
          }
        }}
      >
        <Tabs.TabPane tab="Lista wskaźników" key="1">
          <div className="controls">
            {/* <SearchInput placeholder="Znajdź wskaźnik" /> */}
            {/* <button className="standard-button filter" onClick={() => { }}>
              <FilterIcon />
              <p>
                Filtry <span className="selected">(0)</span>
              </p>
            </button> */}
            <button className="standard-button primary" onClick={() => setIndicatorVisibility(true)}>
              <AddListElementIcon />
              <p>Dodaj wskaźnik</p>
            </button>
          </div>
          <section className="strategic-goals">
            {[...primaryIndicators, ...indicators].map((strategicGoal, index) => (
              <IndicatorsList
                index={index - primaryIndicators.length + 1}
                data={strategicGoal}
                key={strategicGoal.id}
              />
            ))}
          </section>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pomiar wskaźników" key="2">
          <IndicatorsMeasurementList />
        </Tabs.TabPane>
      </Tabs>
      <AddIndicatorConfigModal
        visible={modalsVisibility.indicatorConfig}
        setVisible={setIndicatorConfigVisibility}
        config={modalsOptions.indicatorModalConfig}
      />
      <IndicatorMeasurementModal
        isOpen={modalsVisibility.indicatorMeasurement}
        setIsOpen={setIndicatorMeasurementVisibility}
        indicatorConfig={modalsOptions.indicatorMeasurementModalConfig}
      />
      <AddIndicatorForm
        visible={modalsVisibility.indicator}
        onCancel={() => setIndicatorVisibility(false)}
        onCreate={(resp: Indicator) => {
          setIndicatorVisibility(false);
          setLastlyAddedIndicator(Number(resp.id));
        }}
        indicator={new Indicator()}
      />
      <EditResultModal planId={planId} />
      <EditProductModal planId={planId} />
    </Card>
  );
};

export default IndicatorsSection;
