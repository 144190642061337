import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ArchiveFilter } from '../../../enums';

type Props = {
  archiveFilter: ArchiveFilter;
  setSearchArchiveFilter: Function;
};

const ArchiveFilterDropdown = ({ setSearchArchiveFilter, archiveFilter }: Props) => {
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setSearchArchiveFilter(ArchiveFilter.Show);
        }}
      >
        Pokaż
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setSearchArchiveFilter(ArchiveFilter.Hide);
        }}
      >
        Ukryj
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setSearchArchiveFilter(ArchiveFilter.None);
        }}
      >
        Brak filtru
      </Menu.Item>
    </Menu>
  );

  const displayText = () => {
    switch (archiveFilter) {
      case ArchiveFilter.Show:
        return 'Pokaż';
      case ArchiveFilter.Hide:
        return 'Ukryj';
      case ArchiveFilter.None:
        return 'Brak filtru';
      default:
        return 'Brak filtru';
    }
  };

  return (
    <div>
      <span style={{ marginRight: 10, marginLeft: 15 }}>Archiwalne:</span>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button>
          {displayText()} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ArchiveFilterDropdown;
