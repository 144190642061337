import moment from 'moment';
import _ from 'lodash';
import { TASK_FUNDING_SOURCE_VALUES_YEAR_START } from './config';

/**
 * Returns string representing given date's quarter.
 * Formatting options: https://momentjs.com/docs/#/displaying/format/
 */
export const getQuarter = (text_date?: string, format = 'YYYY-[Q]Q') => {
  if (text_date) {
    return moment(text_date).format(format);
  }
  return '';
};

/**
 * Given start and end dates returns array of quarters they encapsulate
 * E.g.: 2020-01-01, 2020-04-01 => ["2020-Q1", "2020-Q2"]
 */
export const getQuarterRange = (start: string, end: string) => {
  let date = moment(start).startOf('quarter');
  const result = [];
  while (date <= moment(end).startOf('quarter')) {
    result.push(getQuarter(date.format('YYYY-MM-DD')));
    date = date.add(1, 'quarters');
  }
  return result;
};

/**
 * Given start and end dates returns array of years they encapsulate
 * E.g.: 2020-01-01, 2021-04-01 => [2020, 2021]
 */
export const getYearRange = (start: string, end: string) => {
  return _.range(moment(start).year(), moment(end).year() + 1);
};

/**
 * Calculates amount of quarters between two dates.
 */
export const quarterDiff = (from_date: string, to_date: string) => getQuarterRange(from_date, to_date).length - 1;

/**
 * Calculate a list of quarters in a given year that fit between two dates
 * Also returns an offset to identify given quarter in task funding source values array
 */
export const getValidQuartersInYear = (year: number, start: string, end: string) => {
  let date = moment.max([moment(start).startOf('quarter'), moment(`${year}-01-01`)]);
  const threshold = moment.min([moment(end).startOf('quarter'), moment(`${year}-12-31`)]);
  const result = [];
  while (date <= threshold) {
    result.push({
      label: date.format('Q [kwartał]'),
      offset: quarterDiff(`${TASK_FUNDING_SOURCE_VALUES_YEAR_START}-01-01`, date.format('YYYY-MM-DD')),
    });
    date = date.add(1, 'quarters');
  }
  return result;
};
