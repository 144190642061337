import { Button, DatePicker, Form, Input, message, Modal, InputNumber, Radio } from 'antd';
import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import CloseIcon from '../../../../../../assets/icons/CloseIcon/CloseIcon';
import OkIcon from '../../../../../../assets/icons/OkIcon/OkIcon';
import SuccessIcon from '../../../../../../assets/icons/SuccessIcon/SuccessIcon';
import { IndicatorMeasurementApiRequest, IndicatorMeasurementsListItem } from '../../../../../../types';
import { addIndicatorMeasurement } from '../../../../api';

interface Props {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  indicatorConfig?: IndicatorMeasurementsListItem;
}

interface FormInputs {
  measured_value: number;
  note?: string;
  measurement_date: string;
}

const showAddMeasurementMessage = () => {
  message.success({
    className: 'standard-toast',
    content: [<p className="title">Dodano pomiar</p>],
    duration: 1.5,
    icon: <SuccessIcon />,
  });
};

const IndicatorMeasurementModal: React.FC<Props> = ({ isOpen, setIsOpen, indicatorConfig }) => {
  const [form] = Form.useForm<FormInputs>();

  const isBinary = useMemo(() => indicatorConfig?.source_indicator.is_binary || false, [indicatorConfig]);

  const queryClient = useQueryClient();

  const addIndicatorMeasurementMutation = useMutation(addIndicatorMeasurement, {
    mutationKey: 'addIndicatorMeasurement',
    onSuccess: () => {
      queryClient.invalidateQueries(['indicatorsMonitoringMeasurementsList']);
      queryClient.invalidateQueries(['indicatorsMonitoringList']);
      setIsOpen(false);
      showAddMeasurementMessage();
    },
  });

  const onFinish = (values: FormInputs) => {
    if (indicatorConfig && indicatorConfig.id) {
      const requestData: IndicatorMeasurementApiRequest = {
        ...values,
        indicator_config: indicatorConfig?.id,
      };
      addIndicatorMeasurementMutation.mutate(requestData);
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={() => setIsOpen(false)}
      wrapClassName="standard-modal indicator-measurement"
      title="Pomiar wskaźnika"
      afterClose={() => form.resetFields()}
      footer={[
        <Button
          key={1}
          onClick={() => form.submit()}
          className="submit-button"
          loading={addIndicatorMeasurementMutation.isLoading}
        >
          <OkIcon />
          Zapisz pomiar
        </Button>,
        <Button key={2} className="cancel-button" onClick={() => setIsOpen(false)}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      {indicatorConfig ? (
        <section className="info">
          <div className="labeled-info title">
            <label>Wskaźnik:</label>
            <p>{indicatorConfig.source_indicator.name}</p>
          </div>
          <div className="labeled-info">
            <label>{indicatorConfig.type_label}:</label>
            <p>{indicatorConfig.type_name}</p>
          </div>
          <div className="labeled-info">
            <label>Źródło weryfikacji:</label>
            <p>{indicatorConfig.source_indicator.verification_source}</p>
          </div>
        </section>
      ) : null}
      <span className="divider" />
      <section className="details">
        <label>Szczegóły pomiaru:</label>
        <Form form={form} onFinish={onFinish} className="modal-form">
          <div className="form-row">
            <Form.Item
              name="measured_value"
              label="Wartość bieżącą:"
              className="half"
              rules={[{ required: true, message: 'Pole jest wymagane' }]}
            >
              {isBinary ? (
                <Radio.Group>
                  <Radio.Button value={1}>TAK</Radio.Button>
                  <Radio.Button value={0}>NIE</Radio.Button>
                </Radio.Group>
              ) : (
                <InputNumber
                  placeholder="Podaj wartość bieżącą"
                  decimalSeparator=","
                  precision={indicatorConfig?.precision}
                />
              )}
            </Form.Item>
          </div>
          <div className="form-row">
            <Form.Item
              name="measurement_date"
              label="Data przeprowadzenia pomiaru:"
              className="half"
              rules={[{ required: true, message: 'Pole jest wymagane' }]}
            >
              <DatePicker placeholder="Podaj datę pomiaru" />
            </Form.Item>
          </div>
          <div className="form-row">
            <Form.Item name="note" label="Uwagi:" className="full textarea" rules={[{ required: false }]}>
              <Input.TextArea placeholder="Wpisz uwagi" showCount={false} />
            </Form.Item>
          </div>
        </Form>
      </section>
    </Modal>
  );
};

export default IndicatorMeasurementModal;
