import { client } from '../../utils/apiClients';

export const getMFiPRExcel = async (date: string) =>
  client.post(
    'export_mfipr_report/',
    { date },
    {
      responseType: 'blob',
    },
  );
