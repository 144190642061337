import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row, Tooltip } from 'antd';
import { Form, Input } from 'formik-antd';
import { validateLength } from '../../../../utils/validation';
import { openProjectForm } from '../../../../redux/selectors/openProject/openProjectForm';
import Dropzone from 'react-dropzone';
import { useFormikContext } from 'formik';
import { DeleteOutlined, FileOutlined, RollbackOutlined, UploadOutlined } from '@ant-design/icons';
import { FRONTEND_URL } from '../../../../utils/constants';

import { LatLng } from 'leaflet';
import GeolocationMap from '../../GeolocationMap/index';
import { OpenProjectMapped } from '../../../../types';

type Props = ReturnType<typeof openProjectForm>;

const GeolocationForm: React.FunctionComponent<Props> = ({ city, project, projectErrors, isProjectApproved }) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [deleteAttachment, setDeleteAttachment] = useState<boolean>(false);

  const {
    values,
    setFieldValue,
  }: {
    values: any;
    setFieldValue: any;
  } = useFormikContext();

  useEffect(() => {
    setDeleteAttachment(false);
    setFiles([]);
    setFieldValue('geolocation4_attachment', undefined);
  }, [project.geolocation4_attachment, setFieldValue]);

  const cb = (position: LatLng) => {
    setFieldValue('geolocation1_latitude', position.lat);
    setFieldValue('geolocation1_longitude', position.lng);
  };

  return (
    <Card className="top-level-card" title="Lokalizacja">
      <Row>
        <Col span={24}>
          <Form.Item
            label="Lokalizacja opisowo"
            name="location"
            validateStatus={projectErrors.location ? 'error' : ''}
            help={projectErrors.location ? projectErrors.location.join(', ') : ''}
          >
            <Input disabled={isProjectApproved} name="location" />
          </Form.Item>
        </Col>
      </Row>
      {Object.keys(project).length ? (
        <>
          <div className="explanation" style={{ fontSize: 12, marginBottom: 10 }}>
            Zaznacz lokalizację wyszukując adres lub klikając na mapie. Współrzędne geograficzne są niezbędne do
            wyświetlenia projektu na mapie.
          </div>
          <div style={{ marginBottom: 20 }}>
            <GeolocationMap
              data={
                values.geolocation1_latitude && values.geolocation1_longitude
                  ? [
                      {
                        ...project,
                        geolocation1_latitude: values.geolocation1_latitude,
                        geolocation1_longitude: values.geolocation1_longitude,
                      } as OpenProjectMapped,
                    ]
                  : []
              }
              editMode={true}
              onMapClick={cb}
              initialLocation={city.name}
            />
          </div>
        </>
      ) : null}
      <Row>
        <Col span={11}>
          <Form.Item
            label="Geolokalizacja 1: szerokość geograficzna"
            name="geolocation1_latitude"
            validateStatus={projectErrors.geolocation1_latitude ? 'error' : ''}
            help={projectErrors.geolocation1_latitude ? projectErrors.geolocation1_latitude.join(', ') : ''}
          >
            <Input disabled={isProjectApproved} name="geolocation1_latitude" />
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item
            label="Geolokalizacja 1: długość geograficzna"
            name="geolocation1_longitude"
            validateStatus={projectErrors.geolocation1_longitude ? 'error' : ''}
            help={projectErrors.geolocation1_longitude ? projectErrors.geolocation1_longitude.join(', ') : ''}
          >
            <Input disabled={isProjectApproved} name="geolocation1_longitude" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item
            label={
              <div>
                Geolokalizacja 2: adres administracyjny{' '}
                <div className="explanation">
                  Proponowany format obejmuje dane w kolejności: kod pocztowy, miejscowość, ulica, numer.
                </div>
              </div>
            }
            name="geolocation2_administrative_address"
            validateStatus={projectErrors.geolocation2_administrative_address ? 'error' : ''}
            help={
              projectErrors.geolocation2_administrative_address
                ? projectErrors.geolocation2_administrative_address.join(', ')
                : ''
            }
          >
            <Input disabled={isProjectApproved} name="geolocation2_administrative_address" />
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item
            label="Geolokalizacja 3: ID działki"
            name="geolocation3_plot_id"
            validateStatus={projectErrors.geolocation3_plot_id ? 'error' : ''}
            help={projectErrors.geolocation3_plot_id ? projectErrors.geolocation3_plot_id.join(', ') : ''}
          >
            <Input disabled={isProjectApproved} name="geolocation3_plot_id" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            label={
              <div>
                Geolokalizacja 4: szkic
                <div className="explanation">
                  Wgranie nowego pliku i zapisanie zmian nadpisze poprzedni plik (maksymalny rozmiar pliku: 20MB).
                </div>
              </div>
            }
            name="geolocation4_attachment"
            validate={validateLength(3000)}
            validateStatus={projectErrors.comments ? 'error' : ''}
            help={projectErrors.comments ? projectErrors.comments.join(', ') : ''}
          >
            {project.geolocation4_attachment ? (
              <div
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {!isProjectApproved ? (
                  !deleteAttachment ? (
                    <Tooltip title="Usuń załącznik">
                      <DeleteOutlined
                        style={{
                          marginRight: 10,
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setDeleteAttachment(true);
                          setFieldValue('geolocation4_attachment', null);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Cofnij usunięcie załącznika">
                      <RollbackOutlined
                        style={{
                          marginRight: 10,
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setDeleteAttachment(false);
                          setFieldValue('geolocation4_attachment', undefined);
                        }}
                      />
                    </Tooltip>
                  )
                ) : null}
                <a
                  href={project.geolocation4_attachment}
                  style={{
                    textDecoration: deleteAttachment ? 'line-through' : undefined,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {FRONTEND_URL + project.geolocation4_attachment}
                </a>
              </div>
            ) : null}
            <Dropzone
              maxFiles={1}
              accept="image/*, application/pdf"
              maxSize={20971520}
              disabled={isProjectApproved}
              onDrop={async (acceptedFiles) => {
                if (!acceptedFiles.length) return;
                setFiles(acceptedFiles);
                setFieldValue('geolocation4_attachment', acceptedFiles[0]);
                return;
              }}
            >
              {({ getRootProps, getInputProps, isDragReject, fileRejections }) => (
                <div
                  style={{
                    border: '1px dashed #d9d9d9',
                    borderRadius: 2,
                    height: 150,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 12,
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />

                  {isDragReject ? (
                    'Akceptowane są tylko zdjęcia oraz pliki PDF.'
                  ) : fileRejections.length ? (
                    (() => {
                      const errors = fileRejections.flatMap((f) => f.errors.map((e) => e.code));
                      if (errors.includes('too-many-files')) {
                        return 'Można wgrać tylko jeden plik.';
                      }
                      if (errors.includes('file-too-large')) {
                        return 'Maksymalny rozmiar pliku wynosi 20MB.';
                      }
                      return 'Nie można wgrać pliku.';
                    })()
                  ) : files.length ? (
                    files.map((file: any) => (
                      <div
                        key={file.name}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <FileOutlined style={{ fontSize: 28, marginBottom: 10 }} />
                        <p>{file.name}</p>
                      </div>
                    ))
                  ) : (
                    <UploadOutlined style={{ fontSize: 28 }} />
                  )}
                </div>
              )}
            </Dropzone>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(openProjectForm)(GeolocationForm);
