import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Collapse, Row } from 'antd';

import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import { checkBoxIconMap, publicAidIconMap } from '../../Common/utils';
import exportIcon from '../../../assets/export.svg';
import axios from 'axios';
import { API_URL } from '../../../utils/config';
import FileSaver from 'file-saver';
import { useParams } from 'react-router-dom';

const { Panel } = Collapse;

type Props = ReturnType<typeof projectDetails>;

function PublicAidSection(props: Props) {
  const { project, publicAidTestAnswers } = props;

  const { id } = useParams();

  const downloadTest = () => {
    axios.get(`${API_URL}/project/${id}/export_test/`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], { type: response.headers['content-type'] }),
        `${project.title}_test.docx`,
      );
    });
  };

  return (
    <Card
      id="public-aid"
      className="top-level-card"
      title="Pomoc publiczna"
      extra={
        project.is_primary && (
          <Button onClick={downloadTest}>
            <img src={exportIcon} alt="export icon" /> Eksportuj test
          </Button>
        )
      }
    >
      {project.is_primary ? (
        <div className="public-aid-test-answers">
          <Row className="checkbox-info">
            <div>{checkBoxIconMap(project.public_aid_test && project.public_aid_test.passed)}</div>
            <div>
              <span>Przedsięwzięcie przewiduje udzielenie pomocy publicznej lub pomocy de minimis</span>
            </div>
          </Row>
          {publicAidTestAnswers
            .filter((question, index) => {
              if (index !== 1) return true;
              if (!project.public_aid_test) return false;
              return project.public_aid_test.is_business === 'PARTIALLY';
            })
            .map((question, index) => (
              <Collapse key={index} className="collapse-info" bordered={false}>
                <Panel
                  className="collapse-info-panel"
                  key="1"
                  header={question.question}
                  extra={publicAidIconMap(question.answer)}
                >
                  <p>{question.reason}</p>
                </Panel>
              </Collapse>
            ))}
        </div>
      ) : (
        <Row className="checkbox-info">
          <div>{checkBoxIconMap(project.requires_public_aid)}</div>
          <div>
            <span>Przedsięwzięcie przewiduje udzielenie pomocy publicznej lub pomocy de minimis</span>
          </div>
        </Row>
      )}
    </Card>
  );
}

export default connect(projectDetails)(PublicAidSection);
