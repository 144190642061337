import React from 'react';
import { connect } from 'react-redux';
import { Card, Row } from 'antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import { Field } from 'formik';
import YesNoToggle from '../../../Common/YesNoToggle';
import { Form } from 'formik-antd';
import PublicAidTest from './PublicAidTest';
import { checkBoxIconMap } from '../../../Common/utils';

type Props = ReturnType<typeof projectForm>;

const PublicAidForm: React.FunctionComponent<Props> = (props) => {
  const { project } = props;

  return project.is_primary ? (
    <Card className="top-level-card public-aid-test" title="Test pomocy publicznej">
      <Row className="checkbox-info">
        <div>{checkBoxIconMap(project.public_aid_test && project.public_aid_test.passed)}</div>
        <div>
          <span>Przedsięwzięcie przewiduje udzielenie pomocy publicznej lub pomocy de minimis</span>
        </div>
      </Row>
      <PublicAidTest />
    </Card>
  ) : (
    <Card className="top-level-card" title="Pomoc publiczna">
      <Form.Item name="requires_public_aid" valuePropName="checked">
        <Field name="requires_public_aid" component={YesNoToggle}>
          Przedsięwzięcie przewiduje udzielenie pomocy publicznej lub pomocy de minimis
        </Field>
      </Form.Item>
    </Card>
  );
};

export default connect(projectForm)(PublicAidForm);
