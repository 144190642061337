import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { LatLng } from 'leaflet';

type Props = {
  editMode?: boolean;
  setCoordinatesCallback?: Function;
};

const GeolocationSearchBar = ({ setCoordinatesCallback, editMode = false }: Props) => {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const search = GeoSearchControl({
      provider,
      style: 'bar',
      searchLabel: 'Wyszukaj adres',
      showMarker: false,
    });

    search.onSubmit = (results) => {
      if (results.data) {
        const { x, y } = results.data;

        search.centerMap(results.data);

        if (editMode && setCoordinatesCallback) {
          setCoordinatesCallback({ lat: y, lng: x } as LatLng);
        }
      }
    };

    map.addControl(search);

    return () => {
      map.removeControl(search);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default GeolocationSearchBar;
