import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { MeasureUnit } from '../../../types';
import AddIcon from '../../../assets/icons/AddIcon/AddIcon';
import CloseIcon from '../../../assets/icons/CloseIcon/CloseIcon';
import { displaySuccessNotification } from '../../../utils/notifications';
import { saveMeasureUnit, updateMeasureUnit } from '../api';

interface Props {
  unit: MeasureUnit;
  visible: boolean;
  onClose: () => void;
}

export interface MeasureUnitFormInputs {
  name: string;
}

const EditMeasureUnitModal: React.FC<Props> = ({ visible, onClose, unit }) => {
  const [form] = Form.useForm<MeasureUnitFormInputs>();
  form.setFieldsValue(unit);

  const queryClient = useQueryClient();

  const addUnitMutation = useMutation(saveMeasureUnit, {
    onSuccess: () => {
      queryClient.invalidateQueries('measureUnits');
      onClose();
      displaySuccessNotification('Dodano jednostkę miary');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const editUnitMutation = useMutation(updateMeasureUnit, {
    onSuccess: () => {
      queryClient.invalidateQueries('measureUnits');
      onClose();
      displaySuccessNotification('Zapisano jednostkę miary');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const onFinish = (values: MeasureUnitFormInputs) => {
    if (unit.id) {
      editUnitMutation.mutate({ ...values, id: unit.id });
    } else {
      addUnitMutation.mutate({ ...values, id: unit.id });
    }
  };

  const handleAfterClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title={unit.id ? 'Edytuj jednostkę miary' : 'Dodaj jednostkę miary'}
      wrapClassName="standard-modal add-measure-unit"
      onOk={onClose}
      onCancel={onClose}
      afterClose={handleAfterClose}
      destroyOnClose={true}
      footer={[
        <Button
          className="submit-button"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={addUnitMutation.isLoading || editUnitMutation.isLoading}
        >
          <AddIcon />
          {unit.id ? 'Zapisz jednostkę' : 'Dodaj jednostkę'}
        </Button>,
        <Button className="cancel-button" key="back" onClick={onClose}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} className="modal-form">
        <div className="form-row">
          <Form.Item name="name" rules={[{ required: true, message: 'Nazwa wymagana' }]} label="Nazwa" className="full">
            <Input placeholder="Podaj nazwę" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default EditMeasureUnitModal;
