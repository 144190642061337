import axios from 'axios';
import { takeEvery, put } from 'redux-saga/effects';
import { createFetchPaginatedListSaga, createFetchObjectSaga, createFetchObjectSummarySaga } from './utils';
import { API_URL } from '../../utils/config';
import {
  Action,
  fetchOpenProjects,
  fetchOpenProjectsFailed,
  fetchOpenProjectsSuccess,
  fetchOpenProject,
  fetchOpenProjectSuccess,
  fetchOpenProjectFailed,
  saveOpenProject,
  saveOpenProjectSuccess,
  saveOpenProjectFailed,
  fetchOpenProjectSummary,
  fetchOpenProjectSummarySuccess,
  fetchOpenProjectSummaryFailed,
  patchOpenProject,
} from '../actions/index';
import { OpenProject } from '../../types';
import { displaySuccessNotification, displayErrorNotification } from '../../utils/notifications';

const fetchOpenProjectsSaga = createFetchPaginatedListSaga(
  `${API_URL}/open_project/`,
  fetchOpenProjectsSuccess,
  fetchOpenProjectsFailed,
);

const fetchOpenProjectSaga = createFetchObjectSaga(
  `${API_URL}/open_project`,
  fetchOpenProjectSuccess,
  fetchOpenProjectFailed,
);

const fetchOpenProjectSummarySaga = createFetchObjectSummarySaga(
  `${API_URL}/open_project`,
  fetchOpenProjectSummarySuccess,
  fetchOpenProjectSummaryFailed,
);

function* saveOpenProjectSaga({ payload }: Action<OpenProject>) {
  try {
    const response = payload.id
      ? yield axios.put(`${API_URL}/open_project/${payload.id}/`, payload)
      : yield axios.post(`${API_URL}/open_project/`, payload);

    yield put(saveOpenProjectSuccess(response.data));
    displaySuccessNotification('Zapis projektu zakończony pomyślnie.');
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.status === 400) {
        yield put(saveOpenProjectFailed(e.response.data));
      }
      displayErrorNotification('Błąd zapisu projektu.');
    } else {
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  }
}

function* patchOpenProjectSaga({ payload }: Action<OpenProject>) {
  try {
    const attachment = payload.geolocation4_attachment;

    const getFormData = (data: any) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      });
      return formData;
    };

    const data =
      attachment !== undefined && attachment !== null
        ? getFormData(payload)
        : Object.assign(payload, { geolocation4_attachment: attachment === null ? null : undefined });

    const response = yield axios.patch(`${API_URL}/open_project/${payload.id}/`, data);

    yield put(saveOpenProjectSuccess(response.data));
    displaySuccessNotification('Zapis projektu zakończony pomyślnie.');
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.status === 400) {
        yield put(saveOpenProjectFailed(e.response.data));
      }
      displayErrorNotification('Błąd zapisu projektu.');
    } else {
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  }
}

export default function* projectSaga() {
  yield takeEvery(fetchOpenProjects, fetchOpenProjectsSaga);
  yield takeEvery(fetchOpenProject, fetchOpenProjectSaga);
  yield takeEvery(saveOpenProject, saveOpenProjectSaga);
  yield takeEvery(fetchOpenProjectSummary, fetchOpenProjectSummarySaga);
  yield takeEvery(patchOpenProject, patchOpenProjectSaga);
}
