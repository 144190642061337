import React from 'react';
import { Modal, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IndicatorConfigurationType, IndicatorsMonitoringListItem } from '../../../../../types';
import AddListElementIcon from '../../../../../assets/icons/AddListElementIcon/AddListElementIcon';
import IndicatorListItem from './IndicatorListItem/IndicatorListItem';
import Product from './Product';
import { IndicatorsStore, useIndicatorsMonitoringStore } from '../indicatorsStore';
import { parseIndicatorMeasurementContext } from './IndicatorsList';
import { deleteResult } from '../../../api';
import { useQueryClient } from 'react-query';
import { displaySuccessNotification } from '../../../../../utils/notifications';

const { confirm } = Modal;

interface Props {
  result: IndicatorsMonitoringListItem & {
    products: Array<IndicatorsMonitoringListItem>;
  };
  resultIndex: number;
  planId: string;
}

const Result: React.FC<Props> = ({ result, resultIndex, planId }) => {
  const setIndicatorConfigVisibility = useIndicatorsMonitoringStore(
    (state: IndicatorsStore) => state.setIndicatorConfigVisibility,
  );
  const setProductVisibility = useIndicatorsMonitoringStore((state: IndicatorsStore) => state.setProductVisibility);
  const setIndicatorMeasurementVisibility = useIndicatorsMonitoringStore(
    (state: IndicatorsStore) => state.setIndicatorMeasurementVisibility,
  );
  const openResultEditorModal = useIndicatorsMonitoringStore((state: IndicatorsStore) => state.setResultVisibility);

  const queryClient = useQueryClient();

  const onDelete = () => {
    deleteResult(planId, result.id)
      .then(() => {
        displaySuccessNotification('Usunięto rezultat');
        queryClient.invalidateQueries('indicatorsMonitoringList');
        queryClient.invalidateQueries('indicatorsMonitoringMeasurementsList');
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = () => {
    confirm({
      centered: true,
      title: 'Usuń rezultat',
      content: `Czy na pewno chcesz usunąć rezultat?`,
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: onDelete,
      onCancel() {},
    });
  };

  return (
    <React.Fragment>
      <div className="result">
        <header>
          <Tooltip
            placement="top"
            title={`Rezultat ${resultIndex + 1}`}
            trigger="hover"
            overlayClassName="tooltip-standard-dark"
          >
            <label className="status result">R {resultIndex + 1}</label>
          </Tooltip>
          <p className="title">{result.name}</p>
          <section className="controls">
            <Tooltip title="Edytuj" overlayClassName="tooltip-standard-dark">
              <button
                className="edit-button"
                onClick={() => openResultEditorModal(true, undefined, { id: result.id, name: result.name })}
              >
                <EditOutlined />
              </button>
            </Tooltip>
            <Tooltip title="Usuń" overlayClassName="tooltip-standard-dark">
              <button className="delete-button" onClick={handleDelete}>
                <DeleteOutlined />
              </button>
            </Tooltip>
            <button
              className="standard-button primary"
              onClick={() =>
                setIndicatorConfigVisibility(true, {
                  type: IndicatorConfigurationType.RESULT,
                  result: result.id,
                })
              }
            >
              <AddListElementIcon />
              <p>Dodaj wskaźnik</p>
            </button>
            <button className="standard-button secondary" onClick={() => setProductVisibility(true, result.id)}>
              <AddListElementIcon />
              <p>Dodaj produkt</p>
            </button>
          </section>
        </header>
        {result.indicators.length ? (
          <section className="content">
            {result.indicators.map((indicatorConfig) => (
              <IndicatorListItem
                key={indicatorConfig.id}
                indicatorConfig={indicatorConfig}
                planId={planId}
                onEdit={() =>
                  setIndicatorConfigVisibility(true, {
                    type: IndicatorConfigurationType.RESULT,
                    result: result.id,
                    edit: {
                      indicatorConfig: indicatorConfig,
                    },
                  })
                }
                onMeasure={() => {
                  const context = parseIndicatorMeasurementContext(
                    indicatorConfig,
                    IndicatorConfigurationType.RESULT,
                    result.name,
                  );
                  setIndicatorMeasurementVisibility(true, context);
                }}
              />
            ))}
          </section>
        ) : null}
      </div>
      {result.products.length
        ? result.products.map((product, productIndex) => (
            <Product
              key={product.id}
              product={product}
              productIndex={productIndex}
              resultIndex={resultIndex}
              planId={planId}
            />
          ))
        : null}
    </React.Fragment>
  );
};

export default Result;
