import React from 'react';
import { Card, Col, Collapse, Row, Tag } from 'antd';
import { connect } from 'react-redux';
import { getQuarter } from '../../../../utils/dates';
import { openProjectDetails } from '../../../../redux/selectors/openProject/openProjectDetails';
import { Link } from 'react-router-dom';
import { translateConstants } from '../../../../utils/constants';

const { Panel } = Collapse;

type Props = ReturnType<typeof openProjectDetails>;

function BasicInfoSection({ project }: Props) {
  return (
    <Card id="basic-info" className="top-level-card" title="Dane identyfikacyjne">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Tytuł">
            <p>{project.title}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Numer">
            <p>{project.number ? `PROJEKT-${project.number}` : '-'}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Status">
            <p>{translateConstants(project.status)}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Początek:">
            <p>{project.beginning ? getQuarter(project.beginning) : '-'}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Koniec:">
            <p>{project.end ? getQuarter(project.end) : '-'}</p>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Collapse className="collapse-info" defaultActiveKey={['1']} bordered={false}>
            <Panel className="collapse-info-panel" key="1" header="Opis">
              <p>{project.description}</p>
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {project.activities ? (
            <Card className="detail-card" size="small" title="Działania w ramach projektu">
              {project.activities.map(
                (activity) =>
                  activity && (
                    <Link
                      to={`/activity/${activity.id}`}
                      key={activity.id || -1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Tag style={{ cursor: 'pointer' }}>{activity.title}</Tag>
                    </Link>
                  ),
              )}
            </Card>
          ) : null}
        </Col>
      </Row>
    </Card>
  );
}

export default connect(openProjectDetails)(BasicInfoSection);
