import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React from 'react';
import { Task } from '../../../../types';
import { getQuarter } from '../../../../utils/dates';
import { renderTaskCostValidated } from '../../utils';

interface Props {
  tasks: Task[];
  taskFieldDescriptions: any;
}

const ScheduleRealizationTasksTable: React.FC<Props> = ({ tasks, taskFieldDescriptions }) => {
  const columns: ColumnType<Task>[] = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Faza',
      dataIndex: 'stage',
      render: (text: any) => {
        return getStageDisplayName(text);
      },
    },
    {
      title: 'Koszt',
      dataIndex: 'cost',
      render: renderTaskCostValidated,
      align: 'right',
      width: 135,
    },
    {
      title: 'Finansowanie',
      dataIndex: 'total_funding',
      render: renderTaskCostValidated,
      align: 'center',
      width: 135,
    },
    {
      title: 'Początek',
      dataIndex: 'beginning',
      render: (text_date: string) => getQuarter(text_date),
    },
    {
      title: 'Koniec',
      dataIndex: 'end',
      render: (text_date: string) => getQuarter(text_date),
    },
  ];

  const getStageDisplayName = (value: string): string => {
    const matchingChoices = taskFieldDescriptions.stage.choices.filter((stage: any) => stage.value === value);
    return matchingChoices.length > 0 ? matchingChoices[0].display_name : '';
  };

  return (
    <Table
      columns={columns}
      dataSource={tasks}
      size="small"
      pagination={false}
      rowKey={(task: Task) => `${task.name}-${task.id}`}
    />
  );
};

export default ScheduleRealizationTasksTable;
