import React from 'react';
import { Card, Col, Row } from 'antd';
import { connect } from 'react-redux';
import { openProjectDetails } from '../../../../redux/selectors/openProject/openProjectDetails';
import { translateConstants } from '../../../../utils/constants';
import { currency } from '../../../../utils/formatters';

type Props = ReturnType<typeof openProjectDetails>;

function ExecutionSection({ project }: Props) {
  return (
    <Card id="execution" className="top-level-card" title="Realizacja">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Etap">
            <p>{translateConstants(project.progress_status)}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Koszt">
            <p>{currency(project.cost)}</p>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {project.executors ? (
            <Card className="detail-card" size="small" title="Realizatorzy">
              {project.executors.map((executor) => executor && <p key={executor.id}>{executor.name}</p>)}
            </Card>
          ) : null}
        </Col>
      </Row>
    </Card>
  );
}

export default connect(openProjectDetails)(ExecutionSection);
