import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { Form, Input } from 'formik-antd';
import { validateLength } from '../../../../utils/validation';
import { openProjectForm } from '../../../../redux/selectors/openProject/openProjectForm';

type Props = ReturnType<typeof openProjectForm>;

const CommentForm: React.FunctionComponent<Props> = ({ projectErrors }) => {
  return (
    <Card className="top-level-card" title="Uwagi">
      <Row>
        <Col span={24}>
          <Form.Item
            label={
              <div>
                Uwagi: <div className="explanation">(max 3000 znaków)</div>
              </div>
            }
            name="comments"
            validate={validateLength(3000)}
            validateStatus={projectErrors.comments ? 'error' : ''}
            help={projectErrors.comments ? projectErrors.comments.join(', ') : ''}
          >
            <Input.TextArea name="comments" autoSize={{ minRows: 10 }} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(openProjectForm)(CommentForm);
