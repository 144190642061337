import React from 'react';
import { Button, Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { CheckOutlined } from '@ant-design/icons';
import { Prompt } from 'react-router-dom';

const FormSubmitSection: React.FunctionComponent = () => {
  const { dirty, isValid }: { dirty: boolean; isValid: boolean } = useFormikContext();
  return (
    <>
      <Row>
        <Col span={24} className="form-submit-section">
          <Button className="submit-button" key="1" type="default" htmlType="submit" disabled={!isValid || !dirty}>
            <CheckOutlined /> {'Zapisz zmiany'}
          </Button>
          {/*<Button key="2" type="primary">*/}
          {/*  Publikuj*/}
          {/*</Button>*/}
        </Col>
      </Row>
      <Prompt when={dirty} message="Niezapisane zmiany zostaną utracone, czy na pewno opuścić stronę?" />
    </>
  );
};

export default FormSubmitSection;
