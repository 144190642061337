import React from 'react';
import { Select } from 'antd';
import { Entity } from '../../../../types';
import { connect } from 'react-redux';
import { openProjectList } from '../../../../redux/selectors/openProject/openProjectList';
import { antFilterFunc } from '../../../../utils/forms';
import { setOpenProjectSearchExecutorsFilter } from '../../../../redux/actions/index';

type Props = ReturnType<typeof openProjectList> & typeof mapDispatchToProps;

const ExecutorsOpenProjectFilter = ({ filters, entities, setOpenProjectSearchExecutorsFilter }: Props) => {
  return (
    <Select
      placeholder="Realizator"
      dropdownStyle={{ minWidth: 300 }}
      style={{ width: 120 }}
      value={filters.executors.length ? filters.executors[0] : undefined}
      showSearch
      optionFilterProp="children"
      filterOption={antFilterFunc}
      allowClear
      onChange={(v) => {
        if (v) {
          setOpenProjectSearchExecutorsFilter([Number(v)]);
        } else {
          setOpenProjectSearchExecutorsFilter([]);
        }
      }}
    >
      {entities.map((entity: Entity) => (
        <Select.Option value={entity.id || -1} key={entity.id || -1}>
          {entity.name}
        </Select.Option>
      ))}
    </Select>
  );
};

const mapDispatchToProps = {
  setOpenProjectSearchExecutorsFilter,
};

export default connect(openProjectList, mapDispatchToProps)(ExecutorsOpenProjectFilter);
