import React, { SVGProps } from 'react';
import './style.scss';

const OkIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.828"
      height="8.828"
      viewBox="0 0 11.828 8.828"
      className="ok-icon"
      {...props}
    >
      <g transform="translate(1.414 1.413)">
        <line className="a" x2="3" y2="3" transform="translate(0 3.001)" />
        <line className="a" x1="6" y2="6" transform="translate(3 0.001)" />
      </g>
    </svg>
  );
};

export default OkIcon;
