import React from 'react';
import { connect } from 'react-redux';
import { fetchProjectSummarySuccess } from '../../../redux/actions/index';
import ArchiveButton from '../../Common/ArchiveButton';
import { archiveProject, unarchiveProject } from '../api';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/notifications';
import { ProjectSummary } from '../../../types';

type Props = {
  project: ProjectSummary;
} & typeof mapDispatchToProps;

const ArchiveProjectButton = ({ project, fetchProjectSummarySuccess }: Props) => {
  const archive = async () => {
    try {
      const response = await archiveProject(project.id!);
      fetchProjectSummarySuccess(response.data);
      displaySuccessNotification('Archiwizacja przedsięwzięcia zakończona pomyślnie.');
    } catch (e) {
      if (e.response) {
        displayErrorNotification('Błąd archiwizacji przedsięwzięcia.');
      } else {
        displayErrorNotification('Błąd komunikacji z serwerem.');
      }
      throw e;
    }
  };

  const unarchive = async () => {
    try {
      const response = await unarchiveProject(project.id!);
      fetchProjectSummarySuccess(response.data);
      displaySuccessNotification('Przywrócenie przedsięwzięcia z archiwum zakończone pomyślnie.');
    } catch (e) {
      if (e.response) {
        displayErrorNotification('Błąd przywrócenia przedsięwzięcia z archiwum.');
      } else {
        displayErrorNotification('Błąd komunikacji z serwerem.');
      }
      throw e;
    }
  };

  const confirmModalTitle = project.archived
    ? 'Czy na pewno chcesz przywrócić przedsięwzięcie z archiwum?'
    : 'Czy na pewno chcesz zarchiwizować przedsięwzięcie?';

  const confirmModalContent = project.archived
    ? ''
    : 'Zarchiwizowane przedsięwzięcie nie będzie uwzględniane w działaniach.';

  return (
    <ArchiveButton
      resourceId={project.id!}
      isArchived={project.archived}
      archive={archive}
      unarchive={unarchive}
      confirmModalTitle={confirmModalTitle}
      confirmModalContent={confirmModalContent}
    />
  );
};

const mapDispatchToProps = {
  fetchProjectSummarySuccess,
};

export default connect(null, mapDispatchToProps)(ArchiveProjectButton);
