import React, { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../utils/config';
import FileSaver from 'file-saver';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Layout, Row } from 'antd';

import { fetchOpenProjectSummary } from '../../../redux/actions';
import editIcon from '../../../assets/edit.svg';
import exportIcon from '../../../assets/export.svg';
import Scrollspy from 'react-scrollspy';
import ScrollToTop from '../../Common/ScrollToTop';
import { openProjectDetails } from '../../../redux/selectors/openProject/openProjectDetails';
import BasicInfoSection from './Tabs/BasicInfoSection';
import ExecutionSection from './Tabs/ExecutionSection';
import ProjectsSection from './Tabs/ProjectsSection';
import CommentSection from './Tabs/CommentSection';
import OpenProjectProgressStatus from '../utilComponents/OpenProjectProgressStatus';
import GeolocationSection from './Tabs/GeolocationSection';

const { Content, Sider } = Layout;

type Props = ReturnType<typeof openProjectDetails> & typeof mapDispatchToProps;

function OpenProjectDetails(props: Props) {
  const { project, fetchOpenProjectSummary } = props;

  const { id } = useParams();
  useEffect(() => {
    id && fetchOpenProjectSummary(parseInt(id));
  }, [fetchOpenProjectSummary, id]);

  const downloadFile = () => {
    axios.get(`${API_URL}/open_project/${id}/export_excel/`, { responseType: 'blob' }).then((response) => {
      FileSaver.saveAs(new Blob([response.data], { type: response.headers['content-type'] }), `${project.title}.xlsx`);
    });
  };

  return (
    <>
      <Row className="title-bar">
        <Col className="title" span={12}>
          <div>
            {project.progress_status ? (
              <div style={{ marginRight: 10 }}>
                <OpenProjectProgressStatus progressStatus={project.progress_status} size={25} />
              </div>
            ) : null}
            {project.title}
          </div>
        </Col>
        <Col className="buttons" span={6}>
          <Link to={`/open_project/${id}/edit`}>
            <Button type="primary" size="large">
              <img src={editIcon} alt="edit icon" />
              Edytuj
            </Button>
          </Link>
          <Button size="large" onClick={downloadFile}>
            <img src={exportIcon} alt="export icon" /> Eksportuj
          </Button>
          {/* <ArchiveProjectButton project={project} /> */}
        </Col>
      </Row>
      <Layout className="inner-layout">
        <Sider className="navigation-sider">
          <Scrollspy className="side-menu" items={['basic-info', 'execution']} currentClassName="active-section">
            <div>
              <a href="#basic-info">Dane identyfikacyjne</a>
            </div>
            <div>
              <a href="#execution">Realizacja</a>
            </div>
            <div>
              <a href="#projects">Przedsięwzięcia</a>
            </div>
            <div>
              <a href="#geolocation">Lokalizacja</a>
            </div>
            <div>
              <a href="#comments">Uwagi</a>
            </div>
          </Scrollspy>
        </Sider>
        <Content>
          <BasicInfoSection />
          <ExecutionSection />
          <ProjectsSection />
          <GeolocationSection />
          <CommentSection />
        </Content>
      </Layout>
      <ScrollToTop />
    </>
  );
}

const mapDispatchToProps = {
  fetchOpenProjectSummary,
};

export default connect(openProjectDetails, mapDispatchToProps)(OpenProjectDetails);
