import { Popover, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import FilterIcon from '../../../assets/icons/FilterIcon/FilterIcon';
import { RealizationStatusEnum } from '../../../enums';

interface Props {
  onChange: (value: RealizationStatusEnum[]) => void;
  defaultValue?: RealizationStatusEnum[];
}

const RealizationStatusFilter: React.FC<Props> = ({ onChange, defaultValue = [] as RealizationStatusEnum[] }) => {
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<RealizationStatusEnum[]>(defaultValue);

  const unknownState = useMemo(() => {
    if (filterState.find((status) => status === RealizationStatusEnum.UNKNOWN)) {
      return true;
    }
    return false;
  }, [filterState]);

  const finishedState = useMemo(() => {
    if (filterState.find((status) => status === RealizationStatusEnum.FINISHED)) {
      return true;
    }
    return false;
  }, [filterState]);

  const delayedState = useMemo(() => {
    if (filterState.find((status) => status === RealizationStatusEnum.DELAYED)) {
      return true;
    }
    return false;
  }, [filterState]);

  const inProgressState = useMemo(() => {
    if (filterState.find((status) => status === RealizationStatusEnum.IN_PROGRESS)) {
      return true;
    }
    return false;
  }, [filterState]);

  const setState = (current: boolean, status: RealizationStatusEnum) => {
    if (current) {
      setFilterState(filterState.filter((s) => s !== status));
    } else {
      setFilterState([...filterState, status]);
    }
  };

  useEffect(() => {
    onChange(filterState);
  }, [filterState, onChange]);

  return (
    <Popover
      overlayClassName="popover-options-list"
      placement="bottom"
      trigger="click"
      visible={filterVisible}
      onVisibleChange={() => setFilterVisible((state) => !state)}
      content={() => (
        <ul>
          <li>
            <Checkbox checked={unknownState} onChange={() => setState(unknownState, RealizationStatusEnum.UNKNOWN)}>
              Nieznane
            </Checkbox>
          </li>
          <li>
            <Checkbox
              checked={inProgressState}
              onChange={() => setState(inProgressState, RealizationStatusEnum.IN_PROGRESS)}
            >
              W realizacji
            </Checkbox>
          </li>
          <li>
            <Checkbox checked={delayedState} onChange={() => setState(delayedState, RealizationStatusEnum.DELAYED)}>
              Opóźnione
            </Checkbox>
          </li>
          <li>
            <Checkbox checked={finishedState} onChange={() => setState(finishedState, RealizationStatusEnum.FINISHED)}>
              Zakończone
            </Checkbox>
          </li>
        </ul>
      )}
    >
      <button className="standard-button filter">
        <FilterIcon />
        <p>
          Filtry
          <span className="selected">({filterState.length})</span>
        </p>
      </button>
    </Popover>
  );
};

export default RealizationStatusFilter;
