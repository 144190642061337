import { OpenProject } from '../../../../types';

export const tabFieldsMap: { [key: string]: Array<keyof OpenProject> } = {
  basic: ['title', 'status', 'description', 'activities'],
  execution: ['progress_status', 'executors'],
  projects: ['projects'],
  geolocation: [
    'location',
    'geolocation1_latitude',
    'geolocation1_longitude',
    'geolocation2_administrative_address',
    'geolocation3_plot_id',
    'geolocation4_attachment',
  ],
};
