import { createReducer } from '@reduxjs/toolkit';
import {
  changeCity,
  fetchChangelogFailed,
  fetchChangelogSuccess,
  fetchCurrentUserFailed,
  fetchCurrentUserSuccess,
  loginFailed,
  loginSuccess,
  logout,
} from '../actions';
import { User } from '../../types';

const DEFAULT_STATE = {
  token: null as string | null,
  didLoginFail: false,
  user: {} as User,
  changelog: [] as Array<any>,
};

const authReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(loginSuccess, (state, action) => ({
      ...state,
      didLoginFail: false,
      token: action.payload.token,
    }))
    .addCase(loginFailed, (state, action) => ({
      ...state,
      didLoginFail: true,
      token: null,
    }))
    .addCase(logout, (state, action) => ({
      ...state,
      didLoginFail: false,
      token: null,
    }))
    .addCase(fetchChangelogSuccess, (state, action) => ({
      ...state,
      changelog: action.payload,
    }))
    .addCase(fetchChangelogFailed, (state, action) => ({
      ...state,
      changelog: [],
    }))
    .addCase(fetchCurrentUserSuccess, (state, action) => {
      state.user = action.payload;
    })
    .addCase(fetchCurrentUserFailed, (state, action) => {
      state.token = null;
    })
    .addCase(changeCity, (state, action) => {
      state.user.city = action.payload.city;
    }),
);

export default authReducer;
