import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import AddIcon from '../../../assets/icons/AddIcon/AddIcon';
import CloseIcon from '../../../assets/icons/CloseIcon/CloseIcon';
import { displaySuccessNotification } from '../../../utils/notifications';
import { saveVerificationSource, updateVerificationSource } from '../api';
import { VerificationSource } from '../../../types';

interface Props {
  source: VerificationSource;
  visible: boolean;
  onClose: () => void;
}

export interface VerificationSourceFormInputs {
  name: string;
}

const EditVerificationSourceModal: React.FC<Props> = ({ visible, onClose, source }) => {
  const [form] = Form.useForm<VerificationSourceFormInputs>();
  form.setFieldsValue(source);

  const queryClient = useQueryClient();

  const addSourceMutation = useMutation(saveVerificationSource, {
    onSuccess: () => {
      queryClient.invalidateQueries('verificationSources');
      onClose();
      displaySuccessNotification('Dodano źródło weryfikacji');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const editSourceMutation = useMutation(updateVerificationSource, {
    onSuccess: () => {
      queryClient.invalidateQueries('verificationSources');
      onClose();
      displaySuccessNotification('Zapisano źródło weryfikacji');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const onFinish = (values: VerificationSourceFormInputs) => {
    if (source.id) {
      editSourceMutation.mutate({ ...values, id: source.id });
    } else {
      addSourceMutation.mutate({ ...values, id: source.id });
    }
  };

  const handleAfterClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title={source.id ? 'Edytuj źródło weryfikacji' : 'Dodaj źródło weryfikacji'}
      wrapClassName="standard-modal add-verification-source"
      onOk={onClose}
      onCancel={onClose}
      afterClose={handleAfterClose}
      destroyOnClose={true}
      footer={[
        <Button
          className="submit-button"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={addSourceMutation.isLoading || editSourceMutation.isLoading}
        >
          <AddIcon />
          {source.id ? 'Zapisz źródło' : 'Dodaj źródło'}
        </Button>,
        <Button className="cancel-button" key="back" onClick={onClose}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} className="modal-form">
        <div className="form-row">
          <Form.Item name="name" rules={[{ required: true, message: 'Nazwa wymagana' }]} label="Nazwa" className="full">
            <Input placeholder="Podaj nazwę" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default EditVerificationSourceModal;
