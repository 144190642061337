import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import auth from './authReducer';
import planDetails from './plan/planDetailsReducer';
import planForm from './plan/planFormReducer';
import planList from './plan/planListReducer';
import projectDetails from './project/projectDetailsReducer';
import projectForm from './project/projectFormReducer';
import projectList from './project/projectListReducer';
import activityDetails from './activity/activityDetailsReducer';
import activityForm from './activity/activityFormReducer';
import activityList from './activity/activityListReducer';
import dictionaries from './dictionariesReducer';
import cityGoals from './city/cityGoalsReducer';
import openProjectForm from './openProject/openProjectFormReducer';
import openProjectList from './openProject/openProjectListReducer';
import openProjectDetails from './openProject/openProjectDetailsReducer';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    dictionaries,
    cityGoals,

    planDetails,
    planForm,
    planList,

    projectDetails,
    projectForm,
    projectList,

    activityDetails,
    activityForm,
    activityList,

    openProjectDetails,
    openProjectForm,
    openProjectList,
  });

export default createRootReducer;
