import React from 'react';
import { connect } from 'react-redux';
import { Card, Row } from 'antd';

import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import { checkBoxIconMap } from '../../Common/utils';

type Props = ReturnType<typeof projectDetails>;

function PoliciesSection(props: Props) {
  const { project } = props;

  const PRLCheckBoxes = [
    {
      key: 1,
      value: project.gender_equality,
      description: 'Równość mężczyzn i kobiet',
    },
    {
      key: 2,
      value: project.non_discrimination,
      description:
        'Niedyskryminację ze względu na płeć, rasę, pochodzenie etniczne, narodowość, religię, wyznanie, światopogląd, niepełnosprawność, wiek lub orientację seksualną',
    },
    {
      key: 3,
      value: project.sustainable_development,
      description: 'Zrównoważony rozwój, w szczególności pozytywny wpływ na realizację zasady 4R',
    },
  ];

  return (
    <Card id="policies" className="top-level-card" title="Zgodność z politykami">
      {PRLCheckBoxes.map((checkbox) => (
        <Row key={checkbox.key} className="checkbox-info">
          <div>{checkBoxIconMap(checkbox.value)}</div>
          <div>
            <span>{checkbox.description}</span>
          </div>
        </Row>
      ))}
    </Card>
  );
}

export default connect(projectDetails)(PoliciesSection);
