import React from 'react';
import { Button, Form, Input } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { Milestone } from '../../../../../../types';
import { createMilestone } from '../../../api';

const MilestoneForm: React.FC = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const createMilestoneMutation = useMutation(createMilestone, {
    onSuccess: () => {
      queryClient.invalidateQueries('getMilestones');
      form.resetFields();
    },
  });

  const onFinish = (milestone: Milestone) => {
    createMilestoneMutation.mutate(milestone);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item name="name">
        <Input />
      </Form.Item>
      <Form.Item shouldUpdate>
        <Button type="link" htmlType="submit">
          Dodaj
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MilestoneForm;
