import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getMapOpenProjects } from './api';
import { OpenProjectMapped } from '../../../../types';
import { useState } from 'react';

import { Row } from 'antd';
import { Col } from 'antd';
import OpenProjectProgressStatus from '../../utilComponents/OpenProjectProgressStatus';
import { currency } from '../../../../utils/formatters';
import { useSelector } from 'react-redux';
import { city as citySelector } from '../../../../redux/selectors/auth';
import GeolocationMap from '../../GeolocationMap/index';
import { Button } from 'antd';
import { Card } from 'antd';
import { getQuarter } from '../../../../utils/dates';
import { Collapse } from 'antd';
import { connect } from 'react-redux';

const { Panel } = Collapse;

const OpenProjectMap = (props: any) => {
  const [selectedOpenProject, setSelectedOpenProject] = useState<OpenProjectMapped | undefined>();
  const [mapFilter, setMapFilter] = useState<'IN_PREPARATION' | 'IN_PROGRESS' | 'REALIZED' | null>(null);

  const city = useSelector(citySelector);

  const { isLoading, isError, data, refetch } = useQuery<OpenProjectMapped[], Error>(
    'openProjectMap',
    getMapOpenProjects,
  );

  useEffect(() => {
    refetch();
  }, [props.state.auth.user.city, refetch]);

  const statusFilterOptions = [
    {
      text: 'W przygotowaniu',
      value: 'IN_PREPARATION',
      onClick: () => setMapFilter(mapFilter === 'IN_PREPARATION' ? null : 'IN_PREPARATION'),
    },
    {
      text: 'W realizacji',
      value: 'IN_PROGRESS',
      onClick: () => setMapFilter(mapFilter === 'IN_PROGRESS' ? null : 'IN_PROGRESS'),
    },
    {
      text: 'Zrealizowany',
      value: 'REALIZED',
      onClick: () => setMapFilter(mapFilter === 'REALIZED' ? null : 'REALIZED'),
    },
    {
      text: 'Wszystkie',
      value: null,
      onClick: () => setMapFilter(null),
    },
  ];

  return (
    <Row className="map-container">
      <Col xl={12} xs={24}>
        <GeolocationMap
          data={mapFilter ? data?.filter((i) => i.progress_status === mapFilter) : data}
          initialLocation={city.name}
          onMarkerClick={setSelectedOpenProject}
          isLoading={isLoading}
          isError={isError}
        />
        <div className="map-filter-container">
          {statusFilterOptions.map((option) => (
            <Button
              key={option.text}
              className="map-filter-button"
              type={mapFilter === option.value ? 'primary' : 'default'}
              onClick={option.onClick}
            >
              {option.value ? (
                <OpenProjectProgressStatus progressStatus={option.value} size={18} marginTop={2} showTooltip={false} />
              ) : null}
              <span style={{ marginRight: 5 }}>{option.text}</span>
            </Button>
          ))}
        </div>
      </Col>
      <Col xl={11} xs={23} offset={1}>
        <Row className={selectedOpenProject ? 'map-details-row' : 'map-details-row-empty'}>
          <Card className="map-details-card">
            {!selectedOpenProject ? (
              <Row style={{ display: 'flex', height: 402, justifyContent: 'center', alignContent: 'center' }}>
                Wybierz znacznik na mapie, żeby wyświetlić szczegóły projektu.
              </Row>
            ) : (
              <>
                <Row>
                  <Col span={24}>
                    <Card className="detail-card" size="small" title="Tytuł">
                      {selectedOpenProject.progress_status ? (
                        <span style={{ marginRight: 10 }}>
                          <OpenProjectProgressStatus progressStatus={selectedOpenProject.progress_status} size={25} />
                        </span>
                      ) : null}
                      <span>{selectedOpenProject.title}</span>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Card className="detail-card" size="small" title="Początek:">
                      <p>{selectedOpenProject.beginning ? getQuarter(selectedOpenProject.beginning) : '-'}</p>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card className="detail-card" size="small" title="Koniec:">
                      <p>{selectedOpenProject.end ? getQuarter(selectedOpenProject.end) : '-'}</p>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card className="detail-card" size="small" title="Koszt:">
                      <span>{currency(selectedOpenProject.cost)}</span>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card className="detail-card" size="small" title="Realizator:">
                      <span>{selectedOpenProject.executors.map((i) => i.name).join(', ')}</span>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Collapse className="collapse-info" defaultActiveKey={['1']} bordered={false}>
                      <Panel className="collapse-info-panel" key="1" header="Opis">
                        <p>{selectedOpenProject.description}</p>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(OpenProjectMap);
