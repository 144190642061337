import { Col, Table, Pagination, Row } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import { Activity, Entity, Project } from '../../../../types';
import { getQuarter } from '../../../../utils/dates';
import RealizationStatus from '../../../Common/RealizationStatus/RealizationStatus';
import { find as lodashFind } from 'lodash-es';
import { RealizationStatusEnum } from '../../../../enums';

interface Props {
  pagination: {
    page: number;
    total: number;
  };
  onPageChange: (page: number, pageSize?: number) => void;
  onSorterChange: (sorter: SorterResult<Project> | SorterResult<Project>[]) => void;
  onPageSizeChange: (current: number, newSize: number) => void;
  projects: Project[];
  entities: Entity[];
  activities: Activity[];
}

const ProjectListTable: React.FC<Props> = ({
  pagination,
  onPageChange,
  onPageSizeChange,
  onSorterChange,
  projects,
  activities,
  entities,
}) => {
  const columnConfig = [
    {
      title: 'Tytuł',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (title: string, project: Project) => <Link to={`/project/${project.id}`}>{title}</Link>,
    },
    {
      title: 'Numer',
      dataIndex: 'number',
      key: 'number',
      align: 'center' as 'center',
      sorter: true,
      render: (number: number, project: Project) => `${project.kind}-${number + 1}`,
    },
    {
      title: 'Początek',
      dataIndex: 'beginning',
      key: 'beginning',
      render: (text_date: string) => getQuarter(text_date),
    },
    {
      title: 'Koniec',
      dataIndex: 'end',
      key: 'end',
      render: (text_date: string) => getQuarter(text_date),
    },
    {
      title: 'Realizator',
      dataIndex: 'executor',
      key: 'executor__name',
      align: 'center' as 'center',
      sorter: true,
      render: (value: number) => {
        const executor = lodashFind(entities, { id: value });
        return executor ? executor.name : '-';
      },
    },
    {
      title: 'Miejsce realizacji',
      dataIndex: 'venue',
      key: 'venue',
      sorter: true,
      align: 'center' as 'center',
    },
    {
      title: 'Działanie',
      dataIndex: 'activity',
      key: 'activity__title',
      align: 'center' as 'center',
      sorter: true,
      render: (value: number) => {
        const activity = lodashFind(activities, { id: value });
        return activity ? <Link to={`/activity/${activity.id}`}>{activity.title}</Link> : '-';
      },
    },
    {
      title: 'Rodzaj',
      dataIndex: 'kind',
      key: 'kind',
      sorter: true,
      align: 'center' as 'center',
    },
    {
      title: 'Typ',
      dataIndex: 'is_primary',
      key: 'is_primary',
      align: 'center' as 'center',
      render: (value: boolean) => (value ? 'Podstawowe' : 'Uzupełniające'),
    },
    {
      title: 'Status',
      dataIndex: 'realization_status',
      key: 'realization_status',
      align: 'left' as 'left',
      render: (value: RealizationStatusEnum) => <RealizationStatus status={value} />,
    },
    {
      title: 'Wykonanie fin.',
      dataIndex: 'percent_executed_expenses',
      key: 'percent_executed_expenses',
      align: 'center' as 'center',
      render: (value: string | null) => (value ? `${Number(value).toFixed(0)}%` : ''),
    },
    {
      title: 'Archiwalne',
      dataIndex: 'archived',
      key: 'archived',
      sorter: true,
      render: (value: boolean) => (value ? 'Tak' : 'Nie'),
      align: 'center' as 'center',
    },
  ];

  return (
    <div className="object-list">
      <Row>
        <Col span={24}>
          <Table
            dataSource={projects}
            columns={columnConfig}
            rowKey="id"
            pagination={false}
            onChange={(pagination, filter, sorter, extra) => {
              onSorterChange(sorter);
            }}
            showSorterTooltip={{ title: 'Kliknij aby sortować' }}
          />
        </Col>
      </Row>
      <Row className="pagination-row">
        <Col span={24}>
          <Pagination
            current={pagination.page}
            total={pagination.total}
            showSizeChanger
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProjectListTable;
