import React from 'react';
import { Card, Tag } from 'antd';
import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import { connect } from 'react-redux';

type Props = ReturnType<typeof projectDetails>;

function RelatedEntitiesSection(props: Props) {
  const { relatedEntities } = props;

  return (
    <Card id="related-entities" className="top-level-card" title="Podmioty zaangażowane">
      <Card
        className="detail-card"
        size="small"
        title="Inne podmioty zaangażowane w realizację przedsięwzięcia, w tym w szczególności partnerzy projektu"
      >
        {relatedEntities.map((entity) => entity && <Tag key={entity.id || -1}>{entity.name}</Tag>)}
      </Card>
    </Card>
  );
}

export default connect(projectDetails)(RelatedEntitiesSection);
