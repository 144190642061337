import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Form, Select } from 'formik-antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import { Entity } from '../../../../types';
import AddExecutorForm from '../../../Common/ModalForms/AddEntityForm';
import AddObjectDropdown from '../../../Common/AddObjectModals/AddObjectDropdown';
import { antFilterGroupedFunc } from '../../../../utils/forms';

type Props = ReturnType<typeof projectForm>;

const RelatedEntitiesForm: React.FunctionComponent<Props> = (props) => {
  const { entitiesByType, entityTypeVerboseNames } = props;

  return (
    <Card className="top-level-card" title="Podmioty zaangażowane">
      <Form.Item
        label="Inne podmioty zaangażowane w realizację przedsięwzięcia, w tym w szczególności partnerzy projektu"
        name="related_entities"
      >
        <Select
          mode="multiple"
          name="related_entities"
          dropdownRender={(menu) => (
            <AddObjectDropdown menu={menu} title={'Dodaj podmiot zaangażowany'} form={<AddExecutorForm />} />
          )}
          filterOption={antFilterGroupedFunc}
        >
          {Object.keys(entitiesByType).map((entityType: string) => (
            <Select.OptGroup label={entityTypeVerboseNames[entityType]} key={entityType}>
              {entitiesByType[entityType].map((entity: Entity) => (
                <Select.Option value={entity.id || -1} key={entity.id || -1}>
                  {entity.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Form.Item>
    </Card>
  );
};

export default connect(projectForm)(RelatedEntitiesForm);
