import React from 'react';
import { Card, Table } from 'antd';
import { connect } from 'react-redux';
import { activityDetails } from '../../../redux/selectors/activity/activityDetails';
import { TaskFundingSourceSummary } from '../../../types';
import { currency } from '../../../utils/formatters';
import { Link } from 'react-router-dom';

type Props = ReturnType<typeof activityDetails>;

function ProjectsSection(props: Props) {
  const { projectsTable } = props;

  const columns = [
    {
      title: 'Nazwa przedsięwzięcia w ramach działania',
      dataIndex: 'project',
      render: (value: any, row: any) => {
        if (row.key === 'summary') {
          return {
            children: value,
            props: {
              colSpan: 4,
            },
          };
        }
        return {
          children: value && <Link to={`/project/${value.id}`}>{value.name}</Link>,
          props: {
            rowSpan: row.projectSpan,
          },
        };
      },
    },
    {
      title: 'Opis oraz zakres przedmiotowy przedsięwzięcia w ramach działania',
      dataIndex: 'name',
    },
    {
      title: 'Podmiot odpowiedzialny za przedsięwzięcie',
      dataIndex: 'executor',
    },
    {
      title: 'Zakres czasowy (kwartał i rok)',
      dataIndex: 'time_range',
      align: 'center' as 'center',
    },
    {
      title: 'Szacunkowy koszt (w zł)',
      dataIndex: 'cost',
      render: currency,
      align: 'right' as 'right',
    },
    {
      title: 'Planowane źródła finansowania',
      dataIndex: 'task_funding_sources',
      render: (sources: Array<TaskFundingSourceSummary>) =>
        sources &&
        sources.map((source) => (
          <div key={source.id}>
            {currency(source.total_value)} - {source.funding_source.name}
          </div>
        )),
    },
  ];

  return (
    <Card id="projects-section" className="top-level-card" title="Przedsięwzięcia">
      <Table
        className="activity-projects-table"
        columns={columns}
        dataSource={projectsTable}
        size="small"
        pagination={false}
      />
    </Card>
  );
}

export default connect(activityDetails)(ProjectsSection);
