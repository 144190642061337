import { Card, Checkbox, Popover, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import FilterIcon from '../../../../assets/icons/FilterIcon/FilterIcon';
import { strategicGoals } from '../../api';
import CompletionItem from './CompletionItem/CompletionItem';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';
import { pick } from 'lodash-es';

interface Params {
  id: string;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CompletionSection: React.FC = () => {
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<{ [key: string]: boolean }>({
    UNKNOWN: false,
    DELAYED: false,
    IN_PROGRESS: false,
    FINISHED: false,
  });

  const filterParam = useMemo(() => {
    const filters = [];
    for (let key in filterState) {
      if (filterState[key]) {
        filters.push(key);
      }
    }
    return filters;
  }, [filterState]);

  const { id } = useParams<Params>();

  const { data, isLoading } = useQuery(['strategicGoals', id, filterParam], () => strategicGoals(id, filterParam), {
    refetchOnWindowFocus: false,
  });

  const getFilterCount = useMemo(() => {
    let count = 0;
    for (let key in filterState) {
      if (filterState[key]) {
        count += 1;
      }
    }
    return count;
  }, [filterState]);

  return (
    <Card id="completion-monitoring" className="standard-card" title="Monitoring rzeczowy">
      {false ? (
        <section className="controls">
          <Popover
            overlayClassName="popover-options-list"
            placement="bottom"
            trigger="click"
            visible={filterVisible}
            onVisibleChange={() => setFilterVisible((state) => !state)}
            content={() => (
              <ul>
                <li>
                  <Checkbox
                    checked={filterState.UNKNOWN}
                    onChange={() =>
                      setFilterState((state) => ({
                        ...state,
                        UNKNOWN: !state.UNKNOWN,
                      }))
                    }
                  >
                    Nieznane
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    checked={filterState.IN_PROGRESS}
                    onChange={() =>
                      setFilterState((state) => ({
                        ...state,
                        IN_PROGRESS: !state.IN_PROGRESS,
                      }))
                    }
                  >
                    W realizacji
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    checked={filterState.DELAYED}
                    onChange={() =>
                      setFilterState((state) => ({
                        ...state,
                        DELAYED: !state.DELAYED,
                      }))
                    }
                  >
                    Opóźnione
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    checked={filterState.FINISHED}
                    onChange={() =>
                      setFilterState((state) => ({
                        ...state,
                        FINISHED: !state.FINISHED,
                      }))
                    }
                  >
                    Zakończone
                  </Checkbox>
                </li>
              </ul>
            )}
          >
            <button className="standard-button">
              <FilterIcon />
              <p>
                Filtry
                <span className="selected">({getFilterCount})</span>
              </p>
            </button>
          </Popover>
        </section>
      ) : null}
      {isLoading ? (
        <Spin indicator={loadingIcon} />
      ) : data ? (
        <ul className="completion-list">
          {data.map((goal, goalIndex) => {
            return (
              <React.Fragment key={`${goal.name}-${goalIndex}`}>
                <CompletionItem.Goal
                  {...pick(goal, ['id', 'name', 'percent_done', 'percent_executed_expenses', 'beginning', 'end'])}
                />
                {goal.activities
                  ? goal.activities.map((activity, activityIndex) => (
                      <React.Fragment key={`${activity.name}-${activityIndex}`}>
                        <CompletionItem.Activity
                          {...pick(activity, [
                            'id',
                            'name',
                            'percent_done',
                            'percent_executed_expenses',
                            'beginning',
                            'end',
                            'realization_status',
                          ])}
                          index={activityIndex + 1}
                        />
                        {activity.projects
                          ? activity.projects.map((project, projectIndex) => (
                              <CompletionItem.Project
                                key={`${project.name}-${projectIndex}`}
                                {...project}
                                index={activityIndex + 1}
                                subindex={projectIndex + 1}
                              />
                            ))
                          : null}
                      </React.Fragment>
                    ))
                  : null}
              </React.Fragment>
            );
          })}
        </ul>
      ) : (
        <p className="no-data">Brak danych</p>
      )}
    </Card>
  );
};

export default CompletionSection;
