import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Pagination, Popover, Row, Table } from 'antd';
import { DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Indicator, User } from '../../../types';
import { useQuery, useQueryClient } from 'react-query';
import { deleteIndicator, getIndicators } from '../api';
import { AddIndicatorForm } from '../IndicatorFormModal';
import { connect } from 'react-redux';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/notifications';
import { AxiosError } from 'axios';

const { confirm } = Modal;

const IndicatorsList = (props: any) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [sorter, setSorter] = React.useState();
  const [indicatorToEdit, setIndicatorToEdit] = useState<Indicator>(new Indicator());

  const queryClient = useQueryClient();

  const { isError, error, data, refetch } = useQuery(
    ['indicators', page, pageSize, sorter],
    () => getIndicators(page, pageSize, sorter),
    { keepPreviousData: true },
  );

  useEffect(() => {
    refetch();
  }, [props.state.auth.user.city, refetch]);

  const editIndicator = (indicatorId: number) => {
    const indicator = data.results.find((i: Indicator) => i.id === indicatorId);
    setIndicatorToEdit(indicator);
    setModalVisible(true);
  };

  const handleDelete = (indicatorId: number) => {
    confirm({
      centered: true,
      title: 'Usuń wskaźnik',
      content: 'Czy na pewno chcesz usunąć wskaźnik?',
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: () => deleteIndicatorRequest(indicatorId),
      onCancel() {},
    });
  };

  const deleteIndicatorRequest = (indicatorId: number) => {
    deleteIndicator(indicatorId)
      .then(() => {
        displaySuccessNotification('Usunięto wskaźnik');
        queryClient.invalidateQueries('indicators');
      })
      .catch((err: AxiosError) => {
        console.log(err.toJSON());
        displayErrorNotification(
          err.response?.status === 409
            ? 'Wskaźnik jest wykorzystywany w monitoringu planów'
            : 'Błąd przy usuwaniu wskaźnika',
        );
      });
  };

  const columnConfig = [
    {
      title: 'Tytuł',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Utworzono',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (value: string) => value.split('T')[0],
    },
    {
      title: 'Osoba dodająca',
      dataIndex: 'user',
      key: 'user',
      sorter: false,
      render: (value: User) => value.username,
    },
    {
      title: 'Edycja',
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (id: number) => (
        <Popover content="Edytuj" placement="right">
          <SettingOutlined
            onClick={() => {
              editIndicator(id);
            }}
            style={{ fontSize: '19px' }}
          />
        </Popover>
      ),
      align: 'center' as 'center',
    },
    {
      title: 'Usuń',
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (id: number) => (
        <Popover content="Usuń" placement="right">
          <DeleteOutlined
            onClick={() => {
              handleDelete(id);
            }}
            style={{ fontSize: '19px' }}
          />
        </Popover>
      ),
      align: 'center' as 'center',
    },
  ];

  const onIndicatorCreate = (values: any) => {
    setModalVisible(false);
    setIndicatorToEdit(new Indicator());
  };

  const onChange = (_: any, __: any, sorter: any) => {
    setSorter(sorter);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onPageSizeChange = (_currentSize: number, newSize: number) => {
    setPageSize(newSize);
    setPage(1);
  };

  return (
    <>
      <Row className="title-bar list" justify="space-between">
        <h1 className="section-header">Wskaźniki</h1>
        <Button
          type="primary"
          onClick={() => {
            setIndicatorToEdit(new Indicator());
            setModalVisible(true);
          }}
          style={{ marginTop: '5px' }}
        >
          <PlusOutlined /> Dodaj Wskażnik
        </Button>
        {!indicatorToEdit.id && (
          <AddIndicatorForm
            visible={modalVisible}
            onCancel={() => {
              setIndicatorToEdit(new Indicator());
              setModalVisible(false);
            }}
            onCreate={onIndicatorCreate}
            indicator={new Indicator()}
          />
        )}
        {indicatorToEdit.id && (
          <AddIndicatorForm
            visible={modalVisible}
            onCancel={() => {
              setIndicatorToEdit(new Indicator());
              setModalVisible(false);
            }}
            onCreate={onIndicatorCreate}
            indicator={indicatorToEdit}
          />
        )}
      </Row>
      <div className="object-list">
        <Row>
          <Col span={24}>
            {isError ? (
              <div>Error: {error}</div>
            ) : (
              <Table
                dataSource={data?.results}
                columns={columnConfig}
                rowKey="id"
                pagination={false}
                onChange={onChange}
                showSorterTooltip={{ title: 'Kliknij aby sortować' }}
              />
            )}
          </Col>
        </Row>
        <Row className="pagination-row">
          <Col span={24}>
            <Pagination
              defaultPageSize={10}
              current={page}
              total={data?.count}
              showSizeChanger
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(IndicatorsList);
