import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import { Input, Select } from 'formik-antd';
import { PlusOutlined } from '@ant-design/icons';
import { MeasureUnit } from '../../../types';
import { useQuery } from 'react-query';
import { getMeasureUnits, saveMeasureUnit } from '../api';
import { displaySuccessNotification } from '../../../utils/notifications';

const { Option } = Select;

const MeasureUnitSelect: React.FC = () => {
  const [measureUnitAdd, setMeasureUnitAdd] = useState('');

  const { data: measureUnits, refetch: refetchUnits } = useQuery('measureUnits', () => getMeasureUnits());

  const addMeasureUnit = () => {
    const newMeasureUnit = saveMeasureUnit({ name: measureUnitAdd });
    newMeasureUnit.then((resp) => {
      refetchUnits().then(() => displaySuccessNotification('Zapis jednostki miary zakończony pomyślnie.'));
    });
  };

  const onMeasureUnitNameChange = (e: any) => {
    setMeasureUnitAdd(e.target.value);
  };

  const filterOption = (input: any, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Select
      name="measure_unit"
      showSearch
      allowClear
      optionFilterProp="children"
      filterOption={filterOption}
      placeholder="Wybierz jednostkę miary"
      dropdownRender={(menu) => (
        <div className="measure-unit-select-dropdown">
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              padding: 8,
            }}
          >
            <Input
              name="measureUnit"
              style={{ flex: 'auto' }}
              value={measureUnitAdd}
              onChange={onMeasureUnitNameChange}
            />
            <Button
              className="submit-button add-unit-btn"
              key="submit"
              htmlType="submit"
              type="primary"
              onClick={addMeasureUnit}
            >
              <PlusOutlined /> Dodaj
            </Button>
          </div>
        </div>
      )}
    >
      {measureUnits?.results.map((unit: MeasureUnit) => (
        <Option value={unit.id || -1} key={unit.id || -1}>
          <div className="measure-unit-select-option">
            <span>{unit.name}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default MeasureUnitSelect;
