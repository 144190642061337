import React from 'react';
import { Card, Col, Collapse, Row } from 'antd';
import { connect } from 'react-redux';
import { openProjectDetails } from '../../../../redux/selectors/openProject/openProjectDetails';

const { Panel } = Collapse;

type Props = ReturnType<typeof openProjectDetails>;

function CommentSection({ project }: Props) {
  return (
    <Card id="comments" className="top-level-card" title="Uwagi">
      <Row>
        <Col span={24}>
          {project.comments ? (
            <Collapse className="collapse-info" defaultActiveKey={['1']} bordered={false}>
              <Panel className="collapse-info-panel" key="1" header="Uwagi">
                <p>{project.comments}</p>
              </Panel>
            </Collapse>
          ) : (
            'Brak'
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default connect(openProjectDetails)(CommentSection);
