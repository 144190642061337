import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Col, Row, Tabs } from 'antd';

import { planForm } from '../../../redux/selectors/plan/planForm';
import { fetchAllActivities, fetchPlan, initNewPlan, savePlan, searchActivities } from '../../../redux/actions';
import { intersection, keys } from 'lodash-es';
import moment from 'moment';
import back from '../../../assets/back.svg';
import FormTabPane from '../../Common/FormTabPane';
import UnstyledLink from '../../Common/UnstyledLink';
import { tabFieldsMap } from '../../Plan/PlanForm/Tabs/tabFieldsMap';
import GeneralInfoForm from './Tabs/GeneralInfoForm';
import IndicatorsWarningModal from './Modals/IndicatorsWarningModal/IndicatorsWarningModal';
import { Plan } from '../../../types';

type Props = ReturnType<typeof planForm> & typeof mapDispatchToProps;

function PlanForm(props: Props) {
  const { plan, fetchPlan, fetchAllActivities, initNewPlan, planErrors, savePlan } = props;

  const { id, tab } = useParams();

  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [valuesToChange, setValuesToChange] = useState<Plan | undefined>();

  useEffect(() => {
    fetchAllActivities();
    if (id) {
      fetchPlan(parseInt(id));
    } else {
      initNewPlan();
    }
  }, [fetchPlan, fetchAllActivities, initNewPlan, id]);

  const onSubmit = (values: any) => {
    values.beginning = values.beginning ? moment(values.beginning).format('YYYY-MM-DD') : null;
    values.end = values.end ? moment(values.end).format('YYYY-MM-DD') : null;
    const changed: Plan = {
      ...plan,
      ...values,
    };
    let showWarning = false;
    plan.activities?.forEach((key) => {
      if (!values.activities.includes(key)) {
        showWarning = true;
      }
    });
    if (showWarning) {
      setWarningModalVisible(true);
      setValuesToChange(changed);
    } else {
      savePlan(changed);
    }
  };

  const onWarningAccept = () => {
    if (valuesToChange) {
      savePlan(valuesToChange);
    }
    setWarningModalVisible(false);
    setValuesToChange(undefined);
  };

  const url = id ? `/plan/${id}/edit` : '/plan/new';

  const history = useHistory();
  const goBack = () => {
    history.push(id ? `/plan/${id}` : '/plan');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Form validation failed:', errorInfo);
  };

  const hasErrors = (tabKey: string) => intersection(tabFieldsMap[tabKey], keys(planErrors)).length > 0;

  // FIXME: strict typing
  // show tab validation status: https://github.com/ant-design/ant-design/issues/17146
  const renderTabBar = (tabBarProps: any, DefaultTabBar: any) => (
    <DefaultTabBar {...tabBarProps}>
      {(node: any) => (
        <React.Fragment key={node.key}>
          {hasErrors(node.key)
            ? React.cloneElement(node, {
                className: `${node.props.className} tab-with-errors`,
              })
            : node}
        </React.Fragment>
      )}
    </DefaultTabBar>
  );

  return (
    <>
      <Row className="title-bar form">
        <Col className="title" span={24}>
          <Button className="back-button" size="large" onClick={goBack}>
            <img src={back} alt="edit icon" />
            Powrót
          </Button>
          <div>{plan.title}</div>
        </Col>
      </Row>
      <Tabs
        className="form-tabs"
        defaultActiveKey="general"
        activeKey={tab || 'general'}
        renderTabBar={renderTabBar}
        destroyInactiveTabPane={true}
        tabPosition="left"
        tabBarStyle={{ border: 'none' }}
      >
        <FormTabPane
          tab={<UnstyledLink to={`${url}/general`}>Informacje ogólne</UnstyledLink>}
          key="general"
          initialValues={plan}
          onSubmit={onSubmit}
          onFinishFailed={onFinishFailed}
        >
          <GeneralInfoForm />
        </FormTabPane>
      </Tabs>
      <IndicatorsWarningModal
        visible={warningModalVisible}
        setVisible={setWarningModalVisible}
        onAccept={onWarningAccept}
      />
    </>
  );
}

const mapDispatchToProps = {
  fetchPlan,
  initNewPlan,
  savePlan,
  searchActivities,
  fetchAllActivities,
};

export default connect(planForm, mapDispatchToProps)(PlanForm);
