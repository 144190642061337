import React, { useState } from 'react';
import MFiPRFormModal from './MFiPRFormModal/MFiPRFormModal';
import './style.scss';
import { Button } from 'antd';

import { Redirect } from 'react-router-dom';
import { user } from '../../redux/selectors/auth';
import { useSelector } from 'react-redux';

const MFiPR: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const currentUser = useSelector(user);
  if (currentUser.is_superuser === false && currentUser.is_ministry === false) {
    return <Redirect to="/project" />;
  }
  return (
    <div className="mfipr">
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Wygeneruj raport MFiPR
      </Button>
      <MFiPRFormModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </div>
  );
};

export default MFiPR;
