import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { getPlannedMeasurementsList } from '../../../api';
import IndicatorMeasurementsGroup from './IndicatorMeasurementsGroup';
import './style.scss';

interface Params {
  id: string;
}

const IndicatorsMeasurementList: React.FC = () => {
  const { id } = useParams<Params>();

  const { data } = useQuery(['indicatorsMonitoringMeasurementsList', id], () => getPlannedMeasurementsList(id), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <div className="groups">
      {data && data.overdue.length ? (
        <IndicatorMeasurementsGroup title="Zaległe pomiary:" configs={data.overdue} warning />
      ) : null}
      {data && data.week.length ? <IndicatorMeasurementsGroup title="W ciągu 7 dni:" configs={data.week} /> : null}
      {data && data.month.length ? <IndicatorMeasurementsGroup title="W ciągu 30 dni:" configs={data.month} /> : null}
      {data && data.quarter.length ? (
        <IndicatorMeasurementsGroup title="W tym kwartale:" configs={data.quarter} />
      ) : null}
    </div>
  );
};

export default IndicatorsMeasurementList;
