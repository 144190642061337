export const FRONTEND_URL = window.location.protocol + '//' + window.location.host;

const translations = {
  INVESTMENT: 'Inwestycyjny',
  NON_INVESTMENT: 'Nieinwestycyjny',
  IN_PREPARATION: 'W przygotowaniu',
  IN_PROGRESS: 'W realizacji',
  REALIZED: 'Zrealizowany',
  DRAFT: 'Wersja robocza',
  APPROVED: 'Zatwierdzony',
  DEFAULT: '-',
} as any;

export const translateConstants = (value = 'DEFAULT') => {
  if (value === '') return translations.DEFAULT;
  return translations[value];
};
