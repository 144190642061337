import React from 'react';
import { Card, Table } from 'antd';
import { connect } from 'react-redux';
import { Activity, Entity } from '../../../../types';
import { currency } from '../../../../utils/formatters';
import { Link } from 'react-router-dom';
import { openProjectDetails } from '../../../../redux/selectors/openProject/openProjectDetails';
import { getQuarter } from '../../../../utils/dates';

type Props = ReturnType<typeof openProjectDetails>;

function ProjectsSection({ project }: Props) {
  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'title',
      render: (value: any, project: any) =>
        value && (
          <Link to={`/project/${project.id}`} target="_blank" rel="noopener noreferrer">
            {value}
          </Link>
        ),
    },
    {
      title: 'Realizator',
      dataIndex: 'executor',
      render: (executor: Entity) => executor && executor.name,
    },
    {
      title: 'Początek',
      dataIndex: 'beginning',
      align: 'center' as 'center',
      render: (value: string) => (value ? getQuarter(value) : '-'),
    },
    {
      title: 'Koniec',
      dataIndex: 'end',
      align: 'center' as 'center',
      width: 100,
      render: (value: string) => (value ? getQuarter(value) : '-'),
    },
    {
      title: 'Koszt',
      dataIndex: 'cost',
      render: currency,
      align: 'right' as 'right',
    },
    {
      title: 'Działanie',
      dataIndex: 'activity',
      render: (activity: Activity) =>
        activity && (
          <Link to={`/activity/${activity.id}`} target="_blank" rel="noopener noreferrer">
            {activity.title}
          </Link>
        ),
    },
  ];

  return (
    <Card id="projects" className="top-level-card" title="Przedsięwzięcia">
      <Table
        columns={columns}
        dataSource={project.projects}
        size="small"
        pagination={false}
        scroll={{ x: true }}
        rowKey="id"
      />
    </Card>
  );
}

export default connect(openProjectDetails)(ProjectsSection);
