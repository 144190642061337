import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row, Tag } from 'antd';

import { planDetails } from '../../../redux/selectors/plan/planDetails';
import { getQuarter } from '../../../utils/dates';

type Props = ReturnType<typeof planDetails>;

function GeneralInfoSection(props: Props) {
  const { plan, primary_activities, complementary_activities } = props;

  return (
    <Card id="general-info" className="top-level-card" title="Informacje ogólne">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Tytuł">
            <p>{plan.title}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Rodzaj">
            <p>{plan.kind}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Początek:">
            <p>{getQuarter(plan.beginning)}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Koniec:">
            <p>{getQuarter(plan.end)}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Działania podstawowe w ramach planu">
            {primary_activities.map((activity) => activity && <Tag key={activity.id || -1}>{activity.title}</Tag>)}
          </Card>
        </Col>
        <Col span={12}>
          <Card className="detail-card" size="small" title="Działania uzupełniające w ramach planu">
            {complementary_activities.map(
              (activity) => activity && <Tag key={activity.id || -1}>{activity.title}</Tag>,
            )}
          </Card>
        </Col>
      </Row>
    </Card>
  );
}

export default connect(planDetails)(GeneralInfoSection);
