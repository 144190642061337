import React, { SVGProps } from 'react';
import './style.scss';

const OptionsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      className="options-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...props}
    >
      <g transform="translate(0 -0.077)">
        <rect className="a" width="18" height="18" transform="translate(0 0.077)" />
        <g transform="translate(7 2)">
          <circle className="b" cx="2" cy="2" r="2" transform="translate(0 0.077)" />
          <circle className="b" cx="2" cy="2" r="2" transform="translate(0 5.077)" />
          <circle className="b" cx="2" cy="2" r="2" transform="translate(0 10.077)" />
        </g>
      </g>
    </svg>
  );
};

export default OptionsIcon;
