import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { Form, Select } from 'formik-antd';
import { Entity } from '../../../../types';
import AddEntityForm from '../../../Common/ModalForms/AddEntityForm';
import AddObjectDropdown from '../../../Common/AddObjectModals/AddObjectDropdown';
import { antFilterFunc } from '../../../../utils/forms';
import { openProjectForm } from '../../../../redux/selectors/openProject/openProjectForm';

type Props = ReturnType<typeof openProjectForm>;

const ExecutionForm: React.FunctionComponent<Props> = (props) => {
  const { entities, projectErrors, isProjectApproved } = props;

  return (
    <Card className="top-level-card" title="Realizacja">
      <Row>
        <Col span={11}>
          <Form.Item
            label="Etap"
            name="progress_status"
            validateStatus={projectErrors.progress_status ? 'error' : ''}
            help={projectErrors.progress_status ? projectErrors.progress_status.join(', ') : ''}
          >
            <Select name="progress_status">
              <Select.Option value="IN_PREPARATION">W przygotowaniu</Select.Option>
              <Select.Option value="IN_PROGRESS">W realizacji</Select.Option>
              <Select.Option value="REALIZED">Zrealizowany</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Realizatorzy"
            name="executors"
            validateStatus={projectErrors.executors ? 'error' : ''}
            help={projectErrors.executors ? projectErrors.executors.join(', ') : ''}
          >
            <Select
              name="executors"
              mode="multiple"
              dropdownRender={(menu) => (
                <AddObjectDropdown menu={menu} title="Dodaj realizatora" form={<AddEntityForm />} />
              )}
              showSearch
              optionFilterProp="children"
              filterOption={antFilterFunc}
              disabled={isProjectApproved}
            >
              {entities.map((entity: Entity) => (
                <Select.Option value={entity.id || -1} key={entity.id || -1}>
                  {entity.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(openProjectForm)(ExecutionForm);
