import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Pagination, Popover, Row, Table } from 'antd';
import { DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { VerificationSource } from '../../../types';
import { useQuery } from 'react-query';
import { deleteVerificationSource, getVerificationSources } from '../api';
import { connect } from 'react-redux';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/notifications';
import { AxiosError } from 'axios';
import EditVerificationSourceModal from './EditVerificationSourceModal';

const { confirm } = Modal;

const VerificationSourceList = (props: any) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [sourceToEdit, setSourceToEdit] = useState<VerificationSource>(new VerificationSource());

  const {
    isError,
    error,
    data,
    refetch: refetchSources,
  } = useQuery(['verificationSources', page, pageSize], () => getVerificationSources(page, pageSize), {
    keepPreviousData: true,
  });

  useEffect(() => {
    refetchSources();
  }, [props.state.auth.user.city, refetchSources]);

  const editSource = (sourceId: number) => {
    const verificationSource = data.results.find((source: VerificationSource) => source.id === sourceId);
    setSourceToEdit(verificationSource);
    setModalVisible(true);
  };

  const handleDelete = (sourceId: number) => {
    confirm({
      centered: true,
      title: 'Usuń źródło weryfikacji',
      content: 'Czy na pewno chcesz usunąć źródło weryfikacji?',
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: () => deleteSource(sourceId),
      onCancel() {},
    });
  };

  const deleteSource = (sourceId: number) => {
    deleteVerificationSource(sourceId)
      .then(() => {
        refetchSources().then(() => {
          displaySuccessNotification('Usunięto źródło weryfikacji');
        });
      })
      .catch((err: AxiosError) => {
        console.log(err.toJSON());
        displayErrorNotification(
          err.response?.status === 409
            ? 'Źródło weryfikacji jest wykorzystywana przez wskaźnik'
            : 'Błąd przy usuwaniu źródła weryfikacji',
        );
      });
  };

  const columnConfig = [
    {
      title: 'Tytuł',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
    },
    {
      title: 'Edycja',
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (id: number) => (
        <Popover content="Edytuj" placement="right">
          <SettingOutlined
            onClick={() => {
              editSource(id);
            }}
            style={{ fontSize: '19px' }}
          />
        </Popover>
      ),
      align: 'center' as 'center',
      width: 70,
    },
    {
      title: 'Usuń',
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (id: number) => (
        <Popover content="Usuń" placement="right">
          <DeleteOutlined
            onClick={() => {
              handleDelete(id);
            }}
            style={{ fontSize: '19px' }}
          />
        </Popover>
      ),
      align: 'center' as 'center',
      width: 70,
    },
  ];

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onPageSizeChange = (_currentSize: number, newSize: number) => {
    setPageSize(newSize);
    setPage(1);
  };

  return (
    <>
      <Row className="title-bar list" justify="space-between">
        <h1 className="section-header">Źródła weryfikacji</h1>
        <Button
          type="primary"
          onClick={() => {
            setSourceToEdit(new VerificationSource());
            setModalVisible(true);
          }}
          style={{ marginTop: '5px' }}
        >
          <PlusOutlined /> Dodaj źródło
        </Button>
        {!sourceToEdit.id && (
          <EditVerificationSourceModal
            visible={modalVisible}
            onClose={() => {
              setSourceToEdit(new VerificationSource());
              setModalVisible(false);
            }}
            source={new VerificationSource()}
          />
        )}
        {sourceToEdit.id && (
          <EditVerificationSourceModal
            visible={modalVisible}
            onClose={() => {
              setSourceToEdit(new VerificationSource());
              setModalVisible(false);
            }}
            source={sourceToEdit}
          />
        )}
      </Row>
      <div className="object-list">
        <Row>
          <Col span={24}>
            {isError ? (
              <div>Error: {error}</div>
            ) : (
              <Table dataSource={data?.results} columns={columnConfig} rowKey="id" pagination={false} />
            )}
          </Col>
        </Row>
        <Row className="pagination-row">
          <Col span={24}>
            <Pagination
              defaultPageSize={10}
              current={page}
              total={data?.count}
              showSizeChanger
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(VerificationSourceList);
