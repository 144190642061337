import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { Form, Input, Select } from 'formik-antd';
import QuarterRangePicker from '../../../Common/QuarterRangePicker';
import { Activity, Plan } from '../../../../types';
import { planForm } from '../../../../redux/selectors/plan/planForm';
import { antFilterFunc } from '../../../../utils/forms';
import { useFormikContext } from 'formik';

type Props = ReturnType<typeof planForm>;

function GeneralInfoForm(props: Props) {
  const { activities, planErrors } = props;
  const { values }: { values: Plan } = useFormikContext();

  const activitiesFiltered = useMemo(() => {
    return activities.filter(
      (activity: Activity) => !activity.re_granting && activity.kind === values.kind && activity.archived === false,
    );
  }, [activities, values.kind]);

  return (
    <Card className="top-level-card" title="Informacje ogólne">
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label="Tytuł"
            name="title"
            validateStatus={planErrors.title ? 'error' : ''}
            help={planErrors.title ? planErrors.title.join(', ') : ''}
          >
            <Input name="title" />
          </Form.Item>
        </Col>
        <Col span={8} offset={1}>
          <Form.Item
            label="Rodzaj planu"
            name="kind"
            validateStatus={planErrors.kind ? 'error' : ''}
            help={planErrors.kind ? planErrors.kind.join(', ') : ''}
          >
            <Select name="kind">
              <Select.Option value={'PRL'} key={'PRL'}>
                PRL
              </Select.Option>
              <Select.Option value={'PRI'} key={'PRI'}>
                PRI
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} offset={1}>
          <QuarterRangePicker errors={planErrors} />
        </Col>
      </Row>
      <Form.Item label="Działania w ramach planu" name="activities">
        <Select
          name="activities"
          mode="multiple"
          placeholder="Wyszukaj działanie"
          showSearch
          notFoundContent={null}
          optionFilterProp="children"
          filterOption={antFilterFunc}
        >
          {activitiesFiltered.map((activity: Activity) => (
            <Select.Option value={activity.id || -1} key={activity.id || -1}>
              {activity.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Card>
  );
}

export default connect(planForm)(GeneralInfoForm);
