import React from 'react';
import { Modal, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IndicatorConfigurationType, IndicatorsMonitoringListItem } from '../../../../../types';
import AddListElementIcon from '../../../../../assets/icons/AddListElementIcon/AddListElementIcon';
import IndicatorListItem from './IndicatorListItem/IndicatorListItem';
import { IndicatorsStore, useIndicatorsMonitoringStore } from '../indicatorsStore';
import { parseIndicatorMeasurementContext } from './IndicatorsList';
import { deleteProduct } from '../../../api';
import { displaySuccessNotification } from '../../../../../utils/notifications';
import { useQueryClient } from 'react-query';

const { confirm } = Modal;

interface Props {
  product: IndicatorsMonitoringListItem;
  productIndex: number;
  resultIndex: number;
  planId: string;
}

const Product: React.FC<Props> = ({ product, productIndex, resultIndex, planId }) => {
  const setIndicatorConfigVisibility = useIndicatorsMonitoringStore(
    (state: IndicatorsStore) => state.setIndicatorConfigVisibility,
  );
  const setIndicatorMeasurementVisibility = useIndicatorsMonitoringStore(
    (state: IndicatorsStore) => state.setIndicatorMeasurementVisibility,
  );
  const openProductEditorModal = useIndicatorsMonitoringStore((state: IndicatorsStore) => state.setProductVisibility);

  const queryClient = useQueryClient();

  const onDelete = () => {
    deleteProduct(planId, product.id)
      .then(() => {
        displaySuccessNotification('Usunięto produkt');
        queryClient.invalidateQueries('indicatorsMonitoringList');
        queryClient.invalidateQueries('indicatorsMonitoringMeasurementsList');
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = () => {
    confirm({
      centered: true,
      title: 'Usuń produkt',
      content: `Czy na pewno chcesz usunąć produkt?`,
      cancelText: 'Anuluj',
      okText: 'Potwierdź',
      onOk: onDelete,
      onCancel() {},
    });
  };

  return (
    <div className="product">
      <header>
        <Tooltip
          placement="top"
          title={`Produkt ${productIndex + 1}`}
          trigger="hover"
          overlayClassName="tooltip-standard-dark"
        >
          <label className="status product">
            P {resultIndex + 1}.{productIndex + 1}
          </label>
        </Tooltip>
        <p className="title">{product.name}</p>
        <section className="controls">
          <Tooltip title="Edytuj" overlayClassName="tooltip-standard-dark">
            <button
              className="edit-button"
              onClick={() => openProductEditorModal(true, undefined, { id: product.id, name: product.name })}
            >
              <EditOutlined />
            </button>
          </Tooltip>
          <Tooltip title="Usuń" overlayClassName="tooltip-standard-dark">
            <button className="delete-button" onClick={handleDelete}>
              <DeleteOutlined />
            </button>
          </Tooltip>
          <button
            className="standard-button primary"
            onClick={() =>
              setIndicatorConfigVisibility(true, {
                type: IndicatorConfigurationType.PRODUCT,
                product: product.id,
              })
            }
          >
            <AddListElementIcon />
            <p>Dodaj wskaźnik</p>
          </button>
        </section>
      </header>
      {product.indicators.length ? (
        <section className="content">
          {product.indicators.map((indicatorConfig) => (
            <IndicatorListItem
              indicatorConfig={indicatorConfig}
              planId={planId}
              key={indicatorConfig.id}
              onEdit={() =>
                setIndicatorConfigVisibility(true, {
                  type: IndicatorConfigurationType.PRODUCT,
                  product: product.id,
                  edit: {
                    indicatorConfig: indicatorConfig,
                  },
                })
              }
              onMeasure={() => {
                const context = parseIndicatorMeasurementContext(
                  indicatorConfig,
                  IndicatorConfigurationType.PRODUCT,
                  product.name,
                );
                setIndicatorMeasurementVisibility(true, context);
              }}
            />
          ))}
        </section>
      ) : null}
    </div>
  );
};

export default Product;
