import React, { useEffect, useMemo } from 'react';
import { Button, DatePicker, Divider, Form, Input, Modal, Select } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createProjectMilestone, editProjectMilestone, getMilestones } from '../../../api';
import MilestoneForm from '../../ProjectMilestoneForms/ProjectMilestoneCreationForm/MilestoneForm';
import { ProjectMilestone } from '../../../../../../types';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import OkIcon from '../../../../../../assets/icons/OkIcon/OkIcon';
import CloseIcon from '../../../../../../assets/icons/CloseIcon/CloseIcon';
import { fetchProjectSummary } from '../../../../../../redux/actions';
import { useDispatch } from 'react-redux';

interface Params {
  id: string;
}

interface Props {
  visible: boolean;
  enableEndDate?: boolean;
  onCancel: (v?: unknown) => void;
  onEdit: (v?: unknown) => void;
  onCreate: () => void;
  projectMilestone?: ProjectMilestone;
}

export const ProjectMilestoneCreateModal: React.FC<Props> = ({
  visible,
  onCancel,
  onCreate,
  onEdit,
  projectMilestone,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data } = useQuery('getMilestones', getMilestones);
  const dateFormat = 'YYYY-MM-DD';
  const { id } = useParams<Params>();
  const editMode = useMemo(() => {
    if (projectMilestone) {
      return true;
    }
    return false;
  }, [projectMilestone]);

  useEffect(() => {
    form.resetFields();
  }, [visible, form]);

  const dispatch = useDispatch();

  const createProjectMilestoneMutation = useMutation(createProjectMilestone, {
    onSuccess: () => {
      id && dispatch(fetchProjectSummary(Number(id)));
      queryClient.invalidateQueries('getProjectMilestones');
      onCreate();
    },
  });

  const editProjectMilestoneMutation = useMutation(editProjectMilestone, {
    onSuccess: () => {
      id && dispatch(fetchProjectSummary(Number(id)));
      queryClient.invalidateQueries('getProjectMilestones');
      onEdit();
    },
  });

  const handleOk = () => {
    form.submit();
  };

  const handleFinish = (data: ProjectMilestone) => {
    if (editMode && projectMilestone) {
      editProjectMilestoneMutation.mutate({
        ...projectMilestone,
        ...data,
        planned_end: moment(data.planned_end).format(dateFormat),
        actual_end: data.actual_end ? moment(data.actual_end)?.format(dateFormat) : null,
        executor: data.executor,
      });
    } else {
      createProjectMilestoneMutation.mutate({
        ...data,
        planned_end: data.planned_end ? moment(data.planned_end)?.format(dateFormat) : undefined,
        actual_end: data.actual_end ? moment(data.actual_end)?.format(dateFormat) : undefined,
        project: Number(id),
      });
    }
  };

  return (
    <Modal
      visible={visible}
      title="Dodaj kamień milowy"
      onCancel={onCancel}
      onOk={handleOk}
      wrapClassName="milestone-create"
      footer={[
        <Button className="submit-button" key="submit" htmlType="submit" type="primary" onClick={handleOk}>
          <OkIcon />
          Dodaj
        </Button>,
        <Button className="cancel-button" key="back" onClick={onCancel}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          milestone: projectMilestone?.milestone ?? null,
          executor: projectMilestone?.executor ?? undefined,
          planned_end: projectMilestone?.planned_end ? moment(projectMilestone.planned_end, dateFormat) : null,
          actual_end: projectMilestone?.actual_end ? moment(projectMilestone.actual_end, dateFormat) : null,
        }}
      >
        <Form.Item
          name="milestone"
          label="Kamień milowy - zdarzenie w konkretnym punkcie na osi czasu, np. podpisanie umowy"
          rules={[
            {
              required: true,
              message: 'To pole jest wymagane.',
            },
          ]}
        >
          <Select
            placeholder="Wybierz lub dodaj kamień milowy"
            dropdownRender={(menu: React.ReactNode) => (
              <div className="add-milestone-option-dropdown">
                {menu}
                <Divider />
                <MilestoneForm />
              </div>
            )}
          >
            {data?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <span className="divider"></span>
        <div className="form-group">
          <label>Konfiguracja:</label>
          <div className="inputs">
            <Form.Item
              name="executor"
              label="Realizator"
              rules={[
                {
                  required: true,
                  message: 'To pole jest wymagane.',
                },
              ]}
            >
              <Input placeholder="Imię i nazwisko realizatora" />
            </Form.Item>
            <Form.Item
              name="planned_end"
              label="Planowana data osiągnięcia kamienia milowego"
              rules={[
                {
                  required: true,
                  message: 'To pole jest wymagane.',
                },
              ]}
            >
              <DatePicker format={dateFormat} placeholder="Wybierz datę" />
            </Form.Item>
            <Form.Item name="actual_end" label="Rzeczywista data osiągnięcia kamienia milowego">
              <DatePicker format={dateFormat} placeholder="Wybierz datę" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
