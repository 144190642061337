import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row } from 'antd';
import { activityForm } from '../../../redux/selectors/activity/activityForm';
import { closeEditFinancialEffectModal, saveActivity } from '../../../redux/actions';
import { ActivityFinancialEffects, FinancialEffect } from '../../../types';
import _ from 'lodash';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Select } from 'formik-antd';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { TASK_FUNDING_SOURCE_VALUES_YEAR_START } from '../../../utils/config';
import moment from 'moment';
import CurrencyInput from '../../Common/CurrencyInput';

type Props = ReturnType<typeof activityForm> & typeof mapDispatchToProps;

const EditFinancialEffectModal: React.FunctionComponent<Props> = (props) => {
  const {
    activity,
    editFinancialEffectModalOpened,
    closeEditFinancialEffectModal,
    editedFinancialEffect,
    planYears,
    saveActivity,
    activityFinancialEffectsOptions,
  } = props;

  const [currentYear, setCurrentYear] = useState(moment().year());

  useEffect(
    () => setCurrentYear(planYears && planYears[0] ? planYears[0] : moment().year()),
    [editFinancialEffectModalOpened, planYears],
  );

  const onSubmit = (values: ActivityFinancialEffects) => {
    const updated_activity = _.cloneDeep(activity);
    updated_activity.financial_effects = values.financial_effects;

    saveActivity(updated_activity);
  };

  const requiredFieldMsg = 'To pole jest wymagane.';

  const validationSchema = Yup.object().shape({
    financial_effects: Yup.array().of(
      Yup.object().shape({
        financial_effect: Yup.string().required(requiredFieldMsg),
        values: Yup.array().of(Yup.number().required(requiredFieldMsg)),
      }),
    ),
  });

  return (
    <Modal
      title="Skutki finansowe dla budżetów lat przyszłych"
      className="financial-effects-modal"
      centered
      closable={false}
      visible={editFinancialEffectModalOpened}
      onCancel={closeEditFinancialEffectModal}
      width="75%"
      destroyOnClose
      footer={
        <>
          <Button className="submit-button" type="primary" form="financialEffectsForm" htmlType="submit">
            <CheckOutlined /> Zapisz zmiany
          </Button>
          <Button className="cancel-button" form="financialEffectsForm" onClick={closeEditFinancialEffectModal}>
            <CloseOutlined /> Odrzuć zmiany
          </Button>
        </>
      }
    >
      <Formik
        initialValues={{
          ...editedFinancialEffect,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form id="financialEffectsForm" layout="vertical">
            <FieldArray name="financial_effects">
              {(arrayHelpers) => {
                const usedEffects = values.financial_effects.map((s: FinancialEffect) => s.financial_effect);
                return (
                  <>
                    <Row className="year-picker" key="year-picker">
                      <Col span={1} offset={9}>
                        <span className="label">Rok: </span>
                      </Col>
                      <Col span={14}>
                        {planYears.map((year: number) => (
                          <Button
                            key={year.toString()}
                            size="small"
                            className={year === currentYear ? 'active' : ''}
                            onClick={() => setCurrentYear(year)}
                          >
                            {year}
                          </Button>
                        ))}
                      </Col>
                    </Row>
                    {values.financial_effects.map((financialEffect: FinancialEffect, index: number) => (
                      <Row key={index} className="source-row">
                        <Col span={10}>
                          <Form.Item
                            className="effect-select"
                            name={`financial_effects[${index}].financial_effect`}
                            label={index === 0 ? 'Skutki finansowe:' : undefined}
                          >
                            <Select name={`financial_effects[${index}].financial_effect`} fast={true}>
                              {activityFinancialEffectsOptions.map((choice: any, optionIndex: number) => {
                                return (
                                  <Select.Option
                                    disabled={_.includes(usedEffects, choice.value)}
                                    value={choice.value || -1}
                                    key={optionIndex || -1}
                                  >
                                    {choice.display_name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8} offset={1}>
                          <Form.Item
                            className="value-input"
                            key={currentYear}
                            name={`financial_effects[${index}].values[${
                              currentYear - TASK_FUNDING_SOURCE_VALUES_YEAR_START
                            }]`}
                            label={index === 0 ? 'Wartość' : undefined}
                          >
                            <CurrencyInput
                              name={`financial_effects[${index}].values[${
                                currentYear - TASK_FUNDING_SOURCE_VALUES_YEAR_START
                              }]`}
                              fast={true}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button className="remove-button" onClick={() => arrayHelpers.remove(index)}>
                            <CloseOutlined /> Usuń
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Button
                        className="add-effect-button"
                        onClick={() => {
                          let new_effect = new FinancialEffect();
                          new_effect.activity = activity.id;
                          arrayHelpers.push(new_effect);
                        }}
                      >
                        <PlusOutlined /> Dodaj efekt finansowy
                      </Button>
                    </Row>
                  </>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  closeEditFinancialEffectModal,
  saveActivity,
};

export default connect(activityForm, mapDispatchToProps)(EditFinancialEffectModal);
