import axios, { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
  Action,
  ApiListResponse,
  deleteRisk,
  deleteRiskSuccess,
  fetchActivities,
  fetchActivitiesFailed,
  fetchActivitiesSuccess,
  fetchActivity,
  fetchActivityFailed,
  fetchActivityFinancialEffectsOptions,
  fetchActivityFinancialEffectsOptionsFailed,
  fetchActivityFinancialEffectsOptionsSuccess,
  fetchActivityFunding,
  fetchActivityFundingFailed,
  fetchActivityFundingSuccess,
  fetchActivityProjects,
  fetchActivityProjectsFailed,
  fetchActivityProjectsSuccess,
  fetchActivitySuccess,
  fetchActivitySummary,
  fetchActivitySummaryFailed,
  fetchActivitySummarySuccess,
  fetchAllActivities,
  fetchAllActivitiesFailed,
  fetchAllActivitiesSuccess,
  fetchRiskEntries,
  fetchRiskEntriesSuccess,
  fetchRiskInfluences,
  fetchRiskInfluencesSuccess,
  fetchRiskProbabilities,
  fetchRiskProbabilitiesSuccess,
  ListFetchPayload,
  saveActivity,
  saveActivityFailed,
  saveActivitySuccess,
  saveRisk,
  saveRiskFailed,
  saveRiskSuccess,
  searchActivities,
  searchActivitiesFailed,
  searchActivitiesSuccess,
} from '../actions';
import { API_URL } from '../../utils/config';
import { uiToApi } from '../../utils/pagination';
import { Activity, Risk } from '../../types';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/notifications';
import {
  createFetchObjectSaga,
  createFetchObjectSummarySaga,
  createFetchOptionsSaga,
  createSearchListSaga,
} from './utils';
import { translateFiltersToParams } from '../../utils/filters';
import * as Qs from 'query-string';

function* fetchActivitiesSaga({ payload }: Action<ListFetchPayload>) {
  const pagination = uiToApi(payload.page, payload.pageSize);
  const filters = translateFiltersToParams(payload.filters);
  const realizationStatusFilters = {
    realization_status: payload.filters.realizationStatus ? payload.filters.realizationStatus : [],
  };
  const ordering = payload.ordering || null;
  const search = payload.search || null;
  const params = { ...pagination, ...filters, ordering, search, ...realizationStatusFilters };

  try {
    const response: AxiosResponse<ApiListResponse<Activity>> = yield axios.get(`${API_URL}/activity/`, {
      params,
      paramsSerializer: (params) => Qs.stringify(params),
    });
    yield put(fetchActivitiesSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(fetchActivitiesFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

function* fetchAllActivitiesSaga() {
  try {
    const response = yield axios.get(`${API_URL}/activity/`);
    yield put(fetchAllActivitiesSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(fetchAllActivitiesFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

const searchActivitiesSaga = createSearchListSaga(
  `${API_URL}/activity/`,
  searchActivitiesSuccess,
  searchActivitiesFailed,
);

const fetchActivitySaga = createFetchObjectSaga(`${API_URL}/activity`, fetchActivitySuccess, fetchActivityFailed);

const fetchActivitySummarySaga = createFetchObjectSummarySaga(
  `${API_URL}/activity`,
  fetchActivitySummarySuccess,
  fetchActivitySummaryFailed,
);

const fetchActivityFinancialEffectsOptionsSaga = createFetchOptionsSaga(
  `${API_URL}/financial_effects`,
  fetchActivityFinancialEffectsOptionsSuccess,
  fetchActivityFinancialEffectsOptionsFailed,
);

function* saveActivitySaga({ payload }: Action<Activity>) {
  try {
    // if id is present then we're updating an existing activity (PUT)
    // otherwise we're creating a new one (POST).
    let response;
    if (payload.id) {
      response = yield axios.put(`${API_URL}/activity/${payload.id}/`, payload);
    } else {
      response = yield axios.post(`${API_URL}/activity/`, payload);
    }
    yield put(saveActivitySuccess(response.data));
    displaySuccessNotification('Zapis działania zakończony pomyślnie.');
  } catch (e) {
    console.log(e);
    if (e.response) {
      if (e.response.status === 400) {
        yield put(saveActivityFailed(e.response.data));
      }
      displayErrorNotification('Błąd zapisu działania.');
    } else {
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  }
}

function* fetchActivityProjectsSaga({ payload }: Action<number>) {
  try {
    const response = yield axios.get(`${API_URL}/activity/${payload}/project/`);
    yield put(fetchActivityProjectsSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(fetchActivityProjectsFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

function* fetchActivityFundingSaga({ payload }: Action<number>) {
  try {
    const response = yield axios.get(`${API_URL}/activity/${payload}/funding/`);
    yield put(fetchActivityFundingSuccess(response.data));
  } catch (e) {
    console.log(e);
    yield put(fetchActivityFundingFailed());
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

function* fetchRiskInfluencesSaga() {
  try {
    const response = yield axios.get(`${API_URL}/risk_influence/`);
    yield put(fetchRiskInfluencesSuccess(response.data.results));
  } catch (e) {
    console.log(e);
    displayErrorNotification('Błąd komunikacji z serwerem');
  }
}

function* fetchRiskProbabilitiesSaga() {
  try {
    const response = yield axios.get(`${API_URL}/risk_probability/`);
    yield put(fetchRiskProbabilitiesSuccess(response.data.results));
  } catch (e) {
    console.log(e);
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

function* fetchRiskEntriesSaga() {
  try {
    const response = yield axios.get(`${API_URL}/risk_entry/`);
    yield put(fetchRiskEntriesSuccess(response.data.results));
  } catch (e) {
    console.log(e);
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

function* saveRiskSaga({ payload }: Action<Risk>) {
  try {
    // if id is present then we're updating an existing project (PUT)
    // otherwise we're creating a new one (POST).
    let response;
    if (payload.id) {
      response = yield axios.put(`${API_URL}/risk_analysis/${payload.id}/`, payload);
    } else {
      response = yield axios.post(`${API_URL}/risk_analysis/`, payload);
    }
    yield put(saveRiskSuccess(response.data));
    displaySuccessNotification('Zapis ryzyka zakończony pomyślnie.');
  } catch (e) {
    console.log(e);
    yield put(saveRiskFailed());
    if (e.response) {
      if (e.response.status === 400 || e.response.status === 401) {
        displayErrorNotification('Błąd zapisu ryzyka.');
      }
    } else {
      displayErrorNotification('Błąd komunikacji z serwerem.');
    }
  }
}

function* deleteRiskSaga({ payload }: Action<number>) {
  try {
    yield axios.delete(`${API_URL}/risk_analysis/${payload}`);
    yield put(deleteRiskSuccess(payload));
  } catch (e) {
    console.log(e);
    displayErrorNotification('Błąd komunikacji z serwerem.');
  }
}

export default function* activitySaga() {
  yield takeEvery(fetchActivities, fetchActivitiesSaga);
  yield takeEvery(searchActivities, searchActivitiesSaga);
  yield takeEvery(fetchActivity, fetchActivitySaga);
  yield takeEvery(fetchActivitySummary, fetchActivitySummarySaga);
  yield takeEvery(saveActivity, saveActivitySaga);
  yield takeEvery(fetchAllActivities, fetchAllActivitiesSaga);
  yield takeEvery(fetchActivityProjects, fetchActivityProjectsSaga);
  yield takeEvery(fetchActivityFunding, fetchActivityFundingSaga);
  yield takeEvery(fetchRiskInfluences, fetchRiskInfluencesSaga);
  yield takeEvery(fetchRiskProbabilities, fetchRiskProbabilitiesSaga);
  yield takeEvery(fetchRiskEntries, fetchRiskEntriesSaga);
  yield takeEvery(saveRisk, saveRiskSaga);
  yield takeEvery(deleteRisk, deleteRiskSaga);
  yield takeEvery(fetchActivityFinancialEffectsOptions, fetchActivityFinancialEffectsOptionsSaga);
}
