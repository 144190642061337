import React from 'react';
import { Card, Col, Row } from 'antd';
import { connect } from 'react-redux';
import { openProjectDetails } from '../../../../redux/selectors/openProject/openProjectDetails';
import { FRONTEND_URL } from '../../../../utils/constants';
import GeolocationMap from '../../GeolocationMap/index';
import { OpenProjectMapped } from '../../../../types';

type Props = ReturnType<typeof openProjectDetails>;

function GeolocationSection({ project }: Props) {
  return (
    <Card id="geolocation" className="top-level-card" title="Lokalizacja">
      {project.location ? (
        <Row>
          <Col>
            <Card className="detail-card" size="small" title="Lokalizacja opisowo">
              <p>{project.location}</p>
            </Card>
          </Col>
        </Row>
      ) : null}

      {project.geolocation1_latitude && project.geolocation1_longitude ? (
        <>
          <div style={{ marginBottom: 20 }}>
            <GeolocationMap data={[project as OpenProjectMapped]} showSearch />
          </div>
          <Row>
            <Col>
              <Card className="detail-card" size="small" title="Geolokalizacja 1: współrzędne geograficzne">
                <p>
                  {project.geolocation1_latitude}, {project.geolocation1_longitude}
                </p>
              </Card>
            </Col>
          </Row>
        </>
      ) : null}

      <Row gutter={[16, 16]}>
        {project.geolocation2_administrative_address ? (
          <Col span={12}>
            <Card className="detail-card" size="small" title="Geolokalizacja 2: adres administracyjny">
              <p>{project.geolocation2_administrative_address}</p>
            </Card>
          </Col>
        ) : null}
        {project.geolocation3_plot_id ? (
          <Col span={12}>
            <Card className="detail-card" size="small" title="Geolokalizacja 3: ID działki">
              <p>{project.geolocation3_plot_id}</p>
            </Card>
          </Col>
        ) : null}
      </Row>

      {project.geolocation4_attachment ? (
        <Row>
          <Col span={24}>
            <Card className="detail-card" size="small" title="Geolokalizacja 4: szkic">
              <a
                href={project.geolocation4_attachment}
                style={{ fontSize: 18 }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {FRONTEND_URL + project.geolocation4_attachment}
              </a>
            </Card>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
}

export default connect(openProjectDetails)(GeolocationSection);
