import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { activities, entities } from '../dictionaries';

export const projectListBranch = (state: RootStateType) => state.openProjectList;
export const projects = createSelector(projectListBranch, (projectListBranch) => projectListBranch.projects || []);

export const pagination = createSelector(projectListBranch, (projectListBranch) => ({
  page: projectListBranch.page,
  pageSize: projectListBranch.pageSize,
  total: projectListBranch.projectsCount,
}));

export const filters = createSelector(projectListBranch, (branch) => branch.filters);
export const archiveFilter = createSelector(filters, (filters) => ({ archiveFilter: filters.archive }));
export const typeFilter = createSelector(filters, (filters) => ({ typeFilter: filters.type }));
export const progressStatusFilter = createSelector(filters, (filters) => ({
  progressStatusFilter: filters.progress_status,
}));

export const ordering = createSelector(projectListBranch, (branch) => branch.ordering);
export const search = createSelector(projectListBranch, (branch) => branch.search);

export const openProjectList = createSelector(
  [projects, pagination, activities, entities, filters, ordering, search],
  (projects, pagination, activities, entities, filters, ordering, search) => ({
    projects,
    pagination,
    activities,
    entities,
    filters,
    ordering,
    search,
  }),
);
