import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { history } from '../store';
import {
  Action,
  fetchChangelog,
  fetchChangelogFailed,
  fetchChangelogSuccess,
  fetchCurrentUser,
  fetchCurrentUserFailed,
  fetchCurrentUserSuccess,
  login,
  loginFailed,
  LoginPayload,
  loginSuccess,
  LoginSuccessPayload,
  logout,
} from '../actions';
import { API_URL } from '../../utils/config';
import { removeAuthToken, setAuthToken } from '../../utils/storage';
import { displaySuccessNotification } from '../../utils/notifications';

function* loginSaga({ payload }: Action<LoginPayload>) {
  // FIXME: can we get this type directly from login action?
  try {
    const response = yield axios.post(`${API_URL}/login/`, payload);
    yield put(loginSuccess({ ...response.data }));
  } catch (e) {
    yield put(loginFailed());
  }
}

function* loginSuccessSaga({ payload }: Action<LoginSuccessPayload>) {
  setAuthToken(payload.token);
  axios.defaults.headers.common = {
    Authorization: `Token ${payload.token}`,
    'Accept-Language': 'pl-PL',
  };
  yield put(fetchChangelog());
  history.push('/');
  displaySuccessNotification('Zalogowano do systemu.');
  yield put(fetchCurrentUser());
}

function logoutSaga() {
  axios.defaults.headers.common = {};
  removeAuthToken();
  history.push('/');
  displaySuccessNotification('Wylogowano z systemu.');
}

function* fetchCurrentUserSaga() {
  try {
    const response = yield axios.get(`${API_URL}/user/`);
    yield put(fetchCurrentUserSuccess(response.data));
  } catch (e) {
    axios.defaults.headers.common = {};
    yield put(fetchCurrentUserFailed());
  }
}

function* fetchChangelogSaga() {
  try {
    const response = yield axios.get(`${API_URL}/changelog/`);
    yield put(fetchChangelogSuccess(response.data));
  } catch (e) {
    yield put(fetchChangelogFailed());
  }
}

export default function* authSaga() {
  yield takeEvery(login, loginSaga);
  yield takeEvery(loginSuccess, loginSuccessSaga);
  yield takeEvery(logout, logoutSaga);
  yield takeEvery(fetchCurrentUser, fetchCurrentUserSaga);
  yield takeEvery(fetchChangelog, fetchChangelogSaga);
}
