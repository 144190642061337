import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Form, Input } from 'formik-antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import { validateLength } from '../../../../utils/validation';

type Props = ReturnType<typeof projectForm>;

const DescriptionForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Card className="top-level-card" title="Opis">
      <Form.Item
        label={
          <div>
            Geneza:{' '}
            <div className="explanation">
              proszę opisać przyczynę/problem, z którego wynika potrzeba realizacji przedsięwzięcia (max 3000 znaków)
            </div>
          </div>
        }
        name="background"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="background" autoSize={{ minRows: 10 }} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Opis oraz zakres przedmiotowy przedsięwzięcia, w tym proponowane zadania/etapy:{' '}
            <div className="explanation">
              proszę zwięźle opisać proponowane przedsięwzięcie w podziale na zadania/etapy, przypisując im orientacyjne
              terminy realizacji – kwartał/rok (max 3000 znaków)
            </div>
          </div>
        }
        name="scope_description"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="scope_description" autoSize={{ minRows: 10 }} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Standardy dostępności przedsięwzięcia:{' '}
            <div className="explanation">wymiary i formy, patrz: p. 8.3. Regulaminu naboru (max 3000 znaków)</div>
          </div>
        }
        name="accessibility_standards"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="accessibility_standards" autoSize={{ minRows: 10 }} />
      </Form.Item>
    </Card>
  );
};

export default connect(projectForm)(DescriptionForm);
