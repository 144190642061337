import React from 'react';
import { IndicatorMeasurementsListItem } from '../../../../../types';
import IndicatorMeasurementListItem from './IndicatorMeasurementListItem';

interface Props {
  title: string;
  configs: IndicatorMeasurementsListItem[];
  warning?: boolean;
}

const IndicatorMeasurementsGroup: React.FC<Props> = ({ warning, configs, title }) => {
  return (
    <div className="group">
      <header>
        <label className={warning ? 'group warning' : 'group'}>{configs.length}</label>
        <p className="title">{title}</p>
      </header>
      <section className="content">
        {configs.map((indicatorConfig) => (
          <IndicatorMeasurementListItem key={indicatorConfig.id} indicatorConfig={indicatorConfig} />
        ))}
      </section>
    </div>
  );
};

export default IndicatorMeasurementsGroup;
