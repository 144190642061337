import { createReducer } from '@reduxjs/toolkit';
import { Activity, Entity, Impact, Project, StrategicGoal } from '../../types';
import {
  fetchAllActivitiesSuccess,
  fetchAllProjectsSuccess,
  fetchEntitiesSuccess,
  fetchEntityOptionsSuccess,
  fetchImpactsSuccess,
  fetchStrategicGoalsSuccess,
  saveEntitySuccess,
} from '../actions';

const DEFAULT_STATE = {
  entities: [] as Array<Entity>,
  entityOptions: {} as any,
  impacts: [] as Array<Impact>,
  strategicGoals: [] as Array<StrategicGoal>,
  activities: [] as Array<Activity>,
  projects: [] as Array<Project>,
};

const dictionariesReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(fetchEntitiesSuccess, (state, action) => {
      state.entities = action.payload.results;
    })
    .addCase(fetchImpactsSuccess, (state, action) => {
      state.impacts = action.payload.results;
    })
    .addCase(fetchStrategicGoalsSuccess, (state, action) => {
      state.strategicGoals = action.payload.results;
    })
    .addCase(fetchAllActivitiesSuccess, (state, action) => {
      state.activities = action.payload.results;
    })
    .addCase(fetchAllProjectsSuccess, (state, action) => {
      state.projects = action.payload.results;
    })
    .addCase(saveEntitySuccess, (state, action) => {
      state.entities.push(action.payload);
    })
    .addCase(fetchEntityOptionsSuccess, (state, action) => {
      state.entityOptions = action.payload;
    }),
);

export default dictionariesReducer;
