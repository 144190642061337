import { createBrowserHistory, History } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import rootSaga from './sagas';

export const history: History = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = createRootReducer(history);
export type RootStateType = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware, routerMiddleware(history)],
  devTools: true,
});
sagaMiddleware.run(rootSaga);
export type AppDispatchType = typeof store.dispatch;
