import React, { SVGProps } from 'react';
import './style.scss';

const InProgressStatusIcon: React.FC<SVGProps<SVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.947"
      height="14"
      viewBox="0 0 13.947 14"
      className="in-progress-status-icon"
    >
      <g transform="translate(-0.58)">
        <path
          className="a"
          d="M.924,7.115h.919a.344.344,0,0,0,.344-.345,5.168,5.168,0,0,1,8.306-4.1,5.194,5.194,0,0,1,.809.769l-1.7-.3a.344.344,0,0,0-.4.279l-.16.9a.344.344,0,0,0,.279.4l4.07.718a.344.344,0,0,0,.4-.279l.718-4.07a.344.344,0,0,0-.279-.4l-.9-.16a.344.344,0,0,0-.4.279l-.3,1.713A6.8,6.8,0,0,0,11.47,1.392a6.779,6.779,0,0,0-9.5,1.261A6.711,6.711,0,0,0,.58,6.771a.344.344,0,0,0,.344.344Z"
          transform="translate(0)"
        />
        <path
          className="a"
          d="M14.57,150.039a.344.344,0,0,0-.243-.1h-.919a.344.344,0,0,0-.344.345,5.168,5.168,0,0,1-8.306,4.1,5.194,5.194,0,0,1-.809-.769l1.7.3a.344.344,0,0,0,.4-.279l.16-.9a.344.344,0,0,0-.279-.4l-4.07-.718a.344.344,0,0,0-.4.279l-.718,4.07a.344.344,0,0,0,.279.4l.9.16a.344.344,0,0,0,.4-.279l.3-1.713a6.8,6.8,0,0,0,1.159,1.132,6.779,6.779,0,0,0,9.495-1.261,6.711,6.711,0,0,0,1.4-4.118A.344.344,0,0,0,14.57,150.039Z"
          transform="translate(-0.145 -143.053)"
        />
      </g>
    </svg>
  );
};

export default InProgressStatusIcon;
