import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Form, Input } from 'formik-antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import { validateLength } from '../../../../utils/validation';

type Props = ReturnType<typeof projectForm>;

const EffectsForm: React.FunctionComponent<Props> = (props) => {
  return (
    <Card className="top-level-card" title="Efekty realizacji">
      <Form.Item
        label={
          <div>
            Produkty przedsięwzięcia:{' '}
            <div className="explanation">Proszę opisać co będzie produktem przedsięwzięcia (max 3000 znaków)</div>
          </div>
        }
        name="direct_effect"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="direct_effect" autoSize={{ minRows: 10 }} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Rezultaty przedsięwzięcia:{' '}
            <div className="explanation">Proszę opisać co będzie rezultatem przedsięwzięcia (max 3000 znaków)</div>
          </div>
        }
        name="long_term_effect"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="long_term_effect" autoSize={{ minRows: 10 }} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Trwałość przedsięwzięcia:{' '}
            <div className="explanation">
              Opis utrzymania trwałości przedsięwzięcia - jeżeli dotyczy (max 3000 znaków)
            </div>
          </div>
        }
        name="sustainability"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="sustainability" autoSize={{ minRows: 10 }} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Beneficjenci bezpośredni:{' '}
            <div className="explanation">Związany z samą realizacją przedsięwzięcia i jego produktami</div>
          </div>
        }
        name="direct_beneficiaries"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="direct_beneficiaries" autoSize={{ minRows: 10 }} />
      </Form.Item>
      <Form.Item
        label={
          <div>
            Beneficjenci końcowi: <div className="explanation">Związany z oczekiwanymi rezultatami</div>
          </div>
        }
        name="eventual_beneficiaries"
        validate={validateLength(3000)}
      >
        <Input.TextArea name="eventual_beneficiaries" autoSize={{ minRows: 10 }} />
      </Form.Item>
    </Card>
  );
};

export default connect(projectForm)(EffectsForm);
