import { Button, Col, Modal, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Input, Checkbox } from 'formik-antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createIndicator, getUser, updateIndicator } from '../api';
import { Formik } from 'formik';
import { Indicator } from '../../../types';
import * as Yup from 'yup';
import { displaySuccessNotification } from '../../../utils/notifications';
import './style.scss';
import VerificationSourceSelect from './VerificationSourceSelect';
import MeasureUnitSelect from './MeasureUnitSelect';

export const AddIndicatorForm = ({
  visible,
  onCancel,
  onCreate,
  indicator,
}: {
  visible: boolean;
  onCancel: any;
  onCreate: any;
  indicator: Indicator;
}) => {
  const { TextArea } = Input;
  const queryClient = useQueryClient();

  const { data: user } = useQuery('user', getUser);

  // Mutations
  const mutation = useMutation(updateIndicator, {
    onSuccess: (resp) => {
      queryClient.setQueryData(['indicators'], resp);
      queryClient.invalidateQueries('indicators');
      onCreate(resp);
    },
  });

  const { id } = indicator;
  const isAddMode = !id;

  const initialValues = isAddMode
    ? {
        name: '',
        measure_unit: '',
        verification_source: '',
        added_by: user?.id,
        id: '',
        is_binary: false,
      }
    : {
        name: indicator.name,
        measure_unit: indicator.measure_unit,
        verification_source: indicator.verification_source,
        added_by: user?.id,
        id: indicator.id,
        is_binary: indicator.is_binary,
      };

  const [confirmLoading, setConfirmLoading] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required('To pole jest wymagane.'),
    measure_unit: Yup.string().required('To pole jest wymagane.'),
    verification_source: Yup.string().required('To pole jest wymagane.'),
    is_binary: Yup.boolean(),
  });

  const onSubmit = (values: any, actions: any) => {
    setConfirmLoading(true);
    if (isAddMode) {
      values.added_by = user.id;
      createIndicator(values).then((newIndicator) => {
        onCreate(newIndicator);
        queryClient.invalidateQueries('indicators');
      });
    } else {
      console.log(values);
      mutation.mutate(values);
    }
    displaySuccessNotification('Zapis wskaźniku zakończony pomyślnie.');
    setConfirmLoading(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
      {(props) => (
        <Modal
          className="add-indicator-modal"
          title={isAddMode ? 'Nowy wskaźnik' : 'Edytuj wskaźnik'}
          visible={visible}
          onOk={props.submitForm}
          afterClose={props.resetForm}
          confirmLoading={confirmLoading}
          destroyOnClose={true}
          onCancel={() => {
            props.resetForm();
            onCancel();
          }}
          footer={[
            <Row key="footer">
              <Col span={6}>
                <Button
                  className="submit-button"
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  loading={confirmLoading}
                  onClick={props.submitForm}
                >
                  {<CheckOutlined />}
                  Zapisz wskaźnik
                </Button>
              </Col>
              <Col span={8} offset={10}>
                <Button
                  className="cancel-button"
                  key="back"
                  onClick={() => {
                    props.resetForm();
                    onCancel();
                  }}
                >
                  {<CloseOutlined />}
                  Anuluj
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form labelCol={{ span: 6 }} labelAlign="left" wrapperCol={{ span: 18 }}>
            <Form.Item label="Nazwa wskaźnika" name="name">
              <TextArea name="name" rows={4} placeholder="Podaj nazwę wskaźnika" />
            </Form.Item>

            <Form.Item label="Jednostka miary" name="measure_unit">
              <MeasureUnitSelect />
            </Form.Item>

            <Form.Item label="Źródło weryfikacji" name="verification_source">
              <VerificationSourceSelect />
            </Form.Item>

            <Form.Item name="is_binary" label="Wskaźnik TAK/NIE">
              <Checkbox name="is_binary" />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
