import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { Form, Input, Select } from 'formik-antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import { Entity, Project, ReporterType } from '../../../../types';
import AddEntityForm from '../../../Common/ModalForms/AddEntityForm';
import AddObjectDropdown from '../../../Common/AddObjectModals/AddObjectDropdown';
import { antFilterFunc } from '../../../../utils/forms';
import QuarterRangePicker from '../../../Common/QuarterRangePicker';
import { displayInfoNotification } from '../../../../utils/notifications';

type Props = ReturnType<typeof projectForm>;

const BasicInfoForm: React.FunctionComponent<Props> = (props) => {
  const { projects, projectErrors, projectFieldDescriptions } = props;

  return (
    <Card className="top-level-card" title="Dane identyfikacyjne">
      <Row>
        <Col span={11}>
          <Form.Item
            label="Tytuł"
            name="title"
            validateStatus={projectErrors.title ? 'error' : ''}
            help={projectErrors.title ? projectErrors.title.join(', ') : ''}
          >
            <Input name="title" />
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <QuarterRangePicker errors={projectErrors} />
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item
            label="Typ"
            name="type"
            validateStatus={projectErrors.type ? 'error' : ''}
            help={projectErrors.type ? projectErrors.type.join(', ') : ''}
          >
            <Select name="type">
              {projectFieldDescriptions.type.choices.map((choice: any) => (
                <Select.Option value={choice.value} key={choice.value}>
                  {choice.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item
            label="Realizator"
            name="executor"
            validateStatus={projectErrors.executor ? 'error' : ''}
            help={projectErrors.executor ? projectErrors.executor.join(', ') : ''}
          >
            <Select
              name="executor"
              dropdownRender={(menu) => (
                <AddObjectDropdown menu={menu} title={'Dodaj realizatora'} form={<AddEntityForm />} />
              )}
              showSearch
              optionFilterProp="children"
              filterOption={antFilterFunc}
            >
              {props.entities.map((entity: Entity) => (
                <Select.Option value={entity.id || -1} key={entity.id || -1}>
                  {entity.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item
            label="Rodzaj"
            name="kind"
            validateStatus={projectErrors.kind ? 'error' : ''}
            help={projectErrors.kind ? projectErrors.kind.join(', ') : ''}
          >
            <Select
              name="kind"
              onChange={() => {
                displayInfoNotification(
                  'Cele strategiczne powinny zostać zaktualizowane za względu na zmianę rodzaju przedsięwzięcia.',
                  'Uzupełnij cele strategiczne',
                );
              }}
            >
              <Select.Option value="PRL">PRL</Select.Option>
              <Select.Option value="PRI">PRI</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item
            label="Typ instytucji zgłaszającej przedsięwzięcie"
            name="reporter_type"
            validateStatus={projectErrors.reporter_type ? 'error' : ''}
            help={projectErrors.reporter_type ? projectErrors.reporter_type.join(', ') : ''}
          >
            <Select name="reporter_type" showSearch optionFilterProp="children" filterOption={antFilterFunc}>
              {props.reporterTypes.map((reporterType: ReporterType) => (
                <Select.Option value={reporterType.id || -1} key={reporterType.id || -1}>
                  {reporterType.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item
            label="Miejsce realizacji"
            name="venue"
            validateStatus={projectErrors.venue ? 'error' : ''}
            help={projectErrors.venue ? projectErrors.venue.join(', ') : ''}
          >
            <Input name="venue" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Proszę wskazać przedsięwzięcia, z którymi wprowadzane przedsięwzięcie jest wzajemnie powiązane"
            name="depends_on"
          >
            <Select
              name="depends_on"
              mode="multiple"
              placeholder="Wyszukaj przedsięwzięcie"
              showSearch
              notFoundContent={null}
              optionFilterProp="children"
              filterOption={antFilterFunc}
            >
              {projects.map((dependantProject: Project) => (
                <Select.Option value={dependantProject.id || -1} key={dependantProject.id || -1}>
                  {dependantProject.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(projectForm)(BasicInfoForm);
