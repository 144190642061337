export const translateTableSorting = (sorter: any) => {
  if (!sorter.order) return '';
  const orderPrefix = sorter.order === 'descend' ? '-' : '';
  return orderPrefix + sorter.columnKey;
};

export const handleTableOrdering = (sorter: any, saveToStore: any) => {
  const sortingChanged = Boolean(Object.keys(sorter).length);
  if (sortingChanged) {
    const ordering = translateTableSorting(sorter);
    saveToStore(ordering);
  }
};
