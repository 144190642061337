import React from 'react';
import { connect } from 'react-redux';
import { setOpenProjectSearchProgressStatusFilter } from '../../../../redux/actions/index';
import { progressStatusFilter } from '../../../../redux/selectors/openProject/openProjectList';
import ProgressStatusFilterDropdown from '../../../Common/Filters/ProgressStatusFilterDropdown';

type Props = ReturnType<typeof progressStatusFilter> & typeof mapDispatchToProps;

const ProgressStatusOpenProjectFilter = ({ setOpenProjectSearchProgressStatusFilter, progressStatusFilter }: Props) => {
  return (
    <ProgressStatusFilterDropdown
      progressStatusFilter={progressStatusFilter}
      setSearchProgressStatusFilter={setOpenProjectSearchProgressStatusFilter}
    />
  );
};

const mapDispatchToProps = {
  setOpenProjectSearchProgressStatusFilter,
};

export default connect(progressStatusFilter, mapDispatchToProps)(ProgressStatusOpenProjectFilter);
