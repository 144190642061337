export type Question = {
  question: string;
  field: string;
  tooltip?: string;
  default_value?: string;
  default_reason?: string;
};

export const PublicAidTestQuestions: Array<Question> = [
  {
    question:
      '1. Czy wnioskodawca jest przedsiębiorcą (dzięki udzielonemu wsparciu będzie oferował towary lub usługi na rynku)?',
    field: 'is_business',
    tooltip:
      'Pomoc kontekstowa: Zobacz w szczególności sekcję II.3 Istnienie przedsiębiorstwa Przewodnika dla wnioskodawców Programu Rozwój Lokalny  w zakresie analizy przedsięwzięć  pod kątem przesłanek pomocy publicznej',
  },
  {
    question:
      '1a. W przypadku udzielenia odpowiedzi „CZĘŚCIOWO” na powyższe pytanie, czy działalność gospodarcza ma charakter pomocniczy?',
    field: 'is_ancillary_business',
    tooltip:
      'Zobacz w szczególności sekcję II.3.1 Podmioty o charakterze mieszanym, infrastruktura podwójnego wykorzystania (gospodarczego i niegospodarczego) Przewodnika dla wnioskodawców Programu Rozwój Lokalny  w zakresie analizy przedsięwzięć  pod kątem przesłanek pomocy publicznej',
  },
  {
    question: '2. Czy udzielone wsparcie może być przypisane państwu i odbywa się przy użyciu zasobów państwowych?',
    field: 'state_aid',
    default_value: 'YES',
    default_reason:
      'Z pkt 60 Zawiadomienia Komisji w sprawie pojęcia pomocy państwa w rozumieniu art. 107 ust. 1 Traktatu o funkcjonowaniu Unii Europejskiej wynika, że zasoby pochodzące z Unii Europejskiej (np. z funduszy strukturalnych), z Europejskiego Banku Inwestycyjnego lub Europejskiego Funduszu Inwestycyjnego lub z międzynarodowych instytucji finansowych, takich jak Międzynarodowy Fundusz Walutowy lub Europejski Bank Odbudowy i Rozwoju, należy również uznawać za zasoby państwowe, jeżeli organy krajowe dysponują swobodą ich wykorzystania (w szczególności w zakresie wyboru beneficjentów). Powyższe należy także rozciągnąć na środki Mechanizmów Finansowych jako, że krajowe organy publiczne faktycznie decydują o udzieleniu wsparcia w ramach rozpatrywanego naboru.',
  },
  {
    question: '3. Czy wsparcie ma charakter selektywny?',
    field: 'selective_aid',
    default_value: 'YES',
    default_reason:
      'Środek uznaje się za selektywny, jeśli sprzyja „niektórym przedsiębiorstwom lub produkcji niektórych towarów”. Selektywność oznacza więc, że dany środek ma zastosowanie wyłącznie do określonych (grup) przedsiębiorstw lub określonych sektorów gospodarki w danym państwie członkowskim. W niniejszym przypadku można uznać, że wsparcie ma charakter selektywny ponieważ z góry określono, jakie podmioty mogą ubiegać się o wsparcie - małe i średnie miasta zakwalifikowane przez Strategię na Rzecz Odpowiedzialnego Rozwoju do grona 255 miast z najtrudniejszą  sytuacją społeczno-gospodarczą.',
  },
  {
    question: '4. Czy dzięki wsparciu wnioskodawca uzyska korzyść na warunkach atrakcyjniejszych niż rynkowe?',
    field: 'better_than_market',
    tooltip:
      'Zobacz w szczególności sekcję  II.4.1 Korzyść na tzw. pierwszym poziomie Przewodnika dla wnioskodawców Programu Rozwój Lokalny  w zakresie analizy przedsięwzięć  pod kątem przesłanek pomocy publicznej',
  },
  {
    question: '5. Czy wsparcie wpływa lub może mieć wpływ na zakłócenie konkurencji?',
    field: 'competition_distortion',
    tooltip:
      'Zobacz w szczególności sekcję II.5 Zakłócenie konkurencji Przewodnika dla wnioskodawców Programu Rozwój Lokalny  w zakresie analizy przedsięwzięć  pod kątem przesłanek pomocy publicznej',
  },
  {
    question: '6. Czy wsparcie wpływa lub może mieć wpływ na wymianę handlową pomiędzy państwami członkowskimi UE?',
    field: 'affects_trade',
    tooltip:
      'Zobacz w szczególności sekcję II.6 Wpływ na wymianę handlową pomiędzy państwami członkowskimi UE, sekcję II.6.1 Środki o czysto lokalnej skali odziaływania, sekcję II.6.2 Typowe udogodnienia Przewodnika dla wnioskodawców Programu Rozwój Lokalny  w zakresie analizy przedsięwzięć  pod kątem przesłanek pomocy publicznej',
  },
];
