import React, { SVGProps } from 'react';
import './style.scss';

const ArrowGray: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.828"
      height="6.828"
      viewBox="0 0 10.828 6.828"
      className="arrow-gray-icon"
    >
      <g transform="translate(-1697.086 -41.086)">
        <line className="a" x2="4" y2="4" transform="translate(1698.5 42.5)" />
        <line className="a" y1="4" x2="4" transform="translate(1702.5 42.5)" />
      </g>
    </svg>
  );
};

export default ArrowGray;
