import { createReducer } from '@reduxjs/toolkit';
import { changePlansPagination, fetchPlansSuccess } from '../../actions';
import { Plan } from '../../../types';
import { ArchiveFilter } from '../../../enums';
import { setPlanListOrdering, setPlanListSearch, setPlanSearchArchiveFilter } from '../../actions/index';

const DEFAULT_STATE = {
  plans: [] as Array<Plan>,
  plansCount: 0,
  page: 1,
  pageSize: 10,
  filters: {
    archive: ArchiveFilter.Hide,
  },
  ordering: '',
  search: '',
};

const activityListReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(changePlansPagination, (state, action) => {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    })
    .addCase(fetchPlansSuccess, (state, action) => {
      state.plans = action.payload.results;
      state.plansCount = action.payload.count;
    })
    .addCase(setPlanSearchArchiveFilter, (state, action) => {
      state.filters.archive = action.payload;
    })
    .addCase(setPlanListOrdering, (state, action) => {
      state.ordering = action.payload;
    })
    .addCase(setPlanListSearch, (state, action) => {
      state.search = action.payload;
    }),
);

export default activityListReducer;
