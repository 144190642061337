import { ArchiveFilter, ProgressStatusFilter, RealizationStatusEnum, TypeFilter } from '../enums';
import { Filters } from '../types';
import * as qs from 'query-string';

const translateArchiveFilter = (filter: ArchiveFilter) => {
  switch (filter) {
    case ArchiveFilter.Show:
      return { archived: true };
    case ArchiveFilter.Hide:
      return { archived: false };
    default:
      return null;
  }
};

const translateTypeFilter = (filter: TypeFilter) => {
  switch (filter) {
    case TypeFilter.Investment:
      return { type: 'INVESTMENT' };
    case TypeFilter.NonInvestment:
      return { type: 'NON_INVESTMENT' };
    default:
      return null;
  }
};

const translateProgressStatusFilter = (filter: ProgressStatusFilter) => {
  switch (filter) {
    case ProgressStatusFilter.InPreparation:
      return { progress_status: 'IN_PREPARATION' };
    case ProgressStatusFilter.InProgress:
      return { progress_status: 'IN_PROGRESS' };
    case ProgressStatusFilter.Realized:
      return { progress_status: 'REALIZED' };
    default:
      return null;
  }
};

const translateExecutorsFilter = (executors: Array<number>) => {
  return { executors };
};

export const translateFiltersToParams = (filters: Filters) => {
  const translatedFilters = [
    translateArchiveFilter(filters.archive),
    filters.type && translateTypeFilter(filters.type),
    filters.progress_status && translateProgressStatusFilter(filters.progress_status),
    filters.executors && Boolean(filters.executors.length) && translateExecutorsFilter(filters.executors),
  ];

  return Object.assign({}, ...translatedFilters.filter(Boolean));
};

export const translateRealizationStatusFilterToParams = (filterState: RealizationStatusEnum[]) => {
  return qs.stringify({ realization_status: filterState }, { arrayFormat: 'bracket' });
};
