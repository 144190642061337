import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { StrategicGoal } from '../../../types';
import { city } from '../auth';
import _ from 'lodash';

export const cityGoalsBranch = (state: RootStateType) => state.cityGoals;
export const strategicGoals = createSelector(
  cityGoalsBranch,
  (branch) => branch.strategicGoals || ([] as Array<StrategicGoal>),
);

export const strategicGoalsByKind = createSelector(strategicGoals, (goal) => _.groupBy(goal, (goal) => goal.kind));

export const cityGoals = createSelector(strategicGoalsByKind, city, (strategicGoalsByKind, city) => ({
  strategicGoalsByKind,
  city,
}));
