import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row, Tooltip } from 'antd';
import { Form, Input, Select } from 'formik-antd';
import { Activity } from '../../../../types';
import { antFilterFunc } from '../../../../utils/forms';
import { validateLength } from '../../../../utils/validation';
import { openProjectForm } from '../../../../redux/selectors/openProject/openProjectForm';

type Props = ReturnType<typeof openProjectForm>;

const BasicInfoForm: React.FunctionComponent<Props> = ({
  projectErrors,
  activities,
  isProjectApproved,
  canUserChangeProjectStatus,
}) => {
  const isStatusChangeDisabled = isProjectApproved && !canUserChangeProjectStatus;

  const renderStatusSelect = () => {
    return (
      <Select name="status" disabled={isStatusChangeDisabled}>
        <Select.Option value="DRAFT">Wersja robocza</Select.Option>
        <Select.Option value="APPROVED">Zatwierdzony</Select.Option>
      </Select>
    );
  };

  return (
    <Card className="top-level-card" title="Dane identyfikacyjne">
      <Row>
        <Col span={11}>
          <Form.Item
            label="Tytuł"
            name="title"
            validateStatus={projectErrors.title ? 'error' : ''}
            help={projectErrors.title ? projectErrors.title.join(', ') : ''}
          >
            <Input disabled={isProjectApproved} name="title" />
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item
            label="Status"
            name="status"
            validateStatus={projectErrors.status ? 'error' : ''}
            help={projectErrors.status ? projectErrors.status.join(', ') : ''}
          >
            {isStatusChangeDisabled ? (
              <Tooltip title="Tylko administrator miasta może cofnąć status projektu do wersji roboczej.">
                {renderStatusSelect()}
              </Tooltip>
            ) : (
              renderStatusSelect()
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label={
              <div>
                Opis projektu: <div className="explanation">(max 1500 znaków)</div>
              </div>
            }
            name="description"
            validate={validateLength(1500)}
            validateStatus={projectErrors.description ? 'error' : ''}
            help={projectErrors.description ? projectErrors.description.join(', ') : ''}
          >
            <Input.TextArea name="description" autoSize={{ minRows: 10 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Działania"
            name="activities"
            validateStatus={projectErrors.activities ? 'error' : ''}
            help={projectErrors.activities ? projectErrors.activities.join(', ') : ''}
          >
            <Select
              name="activities"
              mode="multiple"
              placeholder="Wyszukaj działanie"
              showSearch
              notFoundContent={null}
              optionFilterProp="children"
              filterOption={antFilterFunc}
              disabled={isProjectApproved}
            >
              {activities.map((activity: Activity) => (
                <Select.Option value={activity.id || -1} key={activity.id || -1}>
                  {activity.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(openProjectForm)(BasicInfoForm);
