import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { OpenProject, ValidationErrors } from '../../../types';
import { entities, projects, activities } from '../dictionaries';
import { user, city } from '../auth';

export const projectFormBranch = (state: RootStateType) => state.openProjectForm;
export const project = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.openProject || new OpenProject(),
);
export const projectErrors = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.projectErrors || ({} as ValidationErrors<OpenProject>),
);

export const unmatchedProjectsWithinSelectedActivities = createSelector([project, projects], (project, projects) => {
  // When editing `project.activities` is a list of ids rather than a list of fetched objects
  const activities = project.activities as any as Array<number>;

  if (!activities) return [];
  // show projects that don't have open project assigned and are assigned to activities within this project
  return projects.filter(
    (p) => (p.open_project === project.id || !p.open_project) && p.activity && activities.includes(p.activity),
  );
});

export const isProjectApproved = createSelector([project], (project) => project.status === 'APPROVED');

export const canUserChangeProjectStatus = createSelector([project, user], (project, user) =>
  Boolean(user.city && user.city.id === project.city && (user.is_superuser || user.is_staff)),
);

export const openProjectForm = createSelector(
  [
    project,
    projectErrors,
    entities,
    unmatchedProjectsWithinSelectedActivities,
    activities,
    isProjectApproved,
    canUserChangeProjectStatus,
    city,
  ],
  // @ts-ignore
  (project, projectErrors, entities, projects, activities, isProjectApproved, canUserChangeProjectStatus, city) => ({
    project,
    projectErrors,
    entities,
    projects,
    activities,
    isProjectApproved,
    canUserChangeProjectStatus,
    city,
  }),
);
