import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { Plan } from '../../../types';

export const planListBranch = (state: RootStateType) => state.planList;
export const plans = createSelector(planListBranch, (branch) => branch.plans || ([] as Array<Plan>));

export const pagination = createSelector(planListBranch, (branch) => ({
  page: branch.page,
  pageSize: branch.pageSize,
  total: branch.plansCount,
}));

export const filters = createSelector(planListBranch, (branch) => branch.filters);
export const archiveFilter = createSelector(filters, (filters) => ({ archiveFilter: filters.archive }));

export const ordering = createSelector(planListBranch, (branch) => branch.ordering);
export const search = createSelector(planListBranch, (branch) => branch.search);

export const planList = createSelector(
  [plans, pagination, filters, ordering, search],
  (plans, pagination, filters, ordering, search) => ({
    plans,
    pagination,
    filters,
    ordering,
    search,
  }),
);
