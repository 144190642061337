import { RootStateType } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { Activity, Entity, Impact, Project, StrategicGoal } from '../../types';
import _ from 'lodash';
import { lookupTable } from './utils';

export const dictionariesBranch = (state: RootStateType) => state.dictionaries;

export const entities = createSelector(dictionariesBranch, (branch) => branch.entities || ([] as Array<Entity>));
export const entityOptions = createSelector(dictionariesBranch, (branch) => branch.entityOptions || {});
export const impacts = createSelector(dictionariesBranch, (branch) => branch.impacts || ([] as Array<Impact>));
export const strategicGoals = createSelector(
  dictionariesBranch,
  (branch) => branch.strategicGoals || ([] as Array<StrategicGoal>),
);
export const projects = createSelector(dictionariesBranch, (branch) => branch.projects || ([] as Array<Project>));
export const activities = createSelector(dictionariesBranch, (branch) => branch.activities || ([] as Array<Activity>));

export const entityFieldDescriptions = createSelector(entityOptions, (entityOptions) =>
  entityOptions.actions && entityOptions.actions.POST
    ? entityOptions.actions.POST
    : {
        ...new Entity(),
      },
);
export const entityTypeVerboseNames = createSelector(entityFieldDescriptions, (entityFieldDescriptions) =>
  entityFieldDescriptions.type && entityFieldDescriptions.type.choices
    ? lookupTable(entityFieldDescriptions.type.choices, 'value', 'display_name')
    : {},
);
export const entitiesByType = createSelector(entities, (entities) => _.groupBy(entities, (entity) => entity.type));

export const strategicGoalsByKind = createSelector(strategicGoals, (goal) => _.groupBy(goal, (goal) => goal.kind));
