import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { Form, Select } from 'formik-antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import { Impact } from '../../../../types';
import { antFilterFunc } from '../../../../utils/forms';
import { useFormikContext } from 'formik';

type Props = ReturnType<typeof projectForm>;

/**
 * Form fields present in PRL project only.
 */
const PrlParameters: React.FunctionComponent<Props> = ({ impacts }) => {
  const { values }: { values: any } = useFormikContext();

  const mainImpacts = values.additional_impact
    ? impacts.filter((impact: Impact) => !values.additional_impact.includes(impact.id))
    : impacts;
  const additionalImpacts = values.main_impact
    ? impacts.filter((impact: Impact) => impact.id !== values.main_impact)
    : impacts;

  return (
    <Row>
      <Col span={11}>
        <Form.Item label="Kluczowy wpływ przedsięwzięcia" name="main_impact">
          <Select name="main_impact" showSearch optionFilterProp="children" filterOption={antFilterFunc}>
            {mainImpacts.map((impact: Impact) => (
              <Select.Option value={impact.id || -1} key={impact.id || -1}>
                {impact.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={11} offset={2}>
        <Form.Item label="Uzupełniające wpływy przedsięwzięcia" name="additional_impact">
          <Select
            mode="multiple"
            name="additional_impact"
            showSearch
            optionFilterProp="children"
            filterOption={antFilterFunc}
          >
            {additionalImpacts.map((impact: Impact) => (
              <Select.Option value={impact.id || -1} key={impact.id || -1}>
                {impact.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default connect(projectForm)(PrlParameters);
