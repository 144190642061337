import { RootStateType } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { City, User } from '../../types';

export const authBranch = (state: RootStateType) => state.auth;
export const token = createSelector(authBranch, (authBranch) => authBranch.token);
export const didLoginFail = createSelector(authBranch, (authBranch) => authBranch.didLoginFail);
export const user = createSelector(authBranch, (authBranch) => authBranch.user || ({} as User));
export const changelog = createSelector(authBranch, (authBranch) => authBranch.changelog || ([] as Array<any>));
export const userName = createSelector(user, (user) => user.username || '');
export const city = createSelector(user, (user) => user.city || ({} as City));
export const cities = createSelector(user, (user) => user.cities || ([] as City[]));

export const auth = createSelector(
  token,
  didLoginFail,
  userName,
  city,
  cities,
  changelog,
  user,
  (token, didLoginFail, userName, city, cities, changelog, user) => ({
    token,
    didLoginFail,
    userName,
    city,
    cities,
    changelog,
    user,
  }),
);
