import { Button, Form, Modal } from 'antd';
import { AxiosError } from 'axios';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AddIcon from '../../../../../../assets/icons/AddIcon/AddIcon';
import CloseIcon from '../../../../../../assets/icons/CloseIcon/CloseIcon';
import { addProduct, editProduct } from '../../../../api';
import ProductModalForm from './ProductModalForm';
import { displaySuccessNotification } from '../../../../../../utils/notifications';
import { useIndicatorsMonitoringStore } from '../../indicatorsStore';

interface Props {
  planId: number;
}

export interface ProductModalFormValues {
  name: string;
}

const EditProductModal: React.FC<Props> = ({ planId }) => {
  const visible = useIndicatorsMonitoringStore((state) => state.modalsVisibility.editProduct);
  const setVisible = useIndicatorsMonitoringStore((state) => state.setProductVisibility);
  const [resultId, context] = useIndicatorsMonitoringStore((state) => [state.resultId, state.productModalContext]);

  const queryClient = useQueryClient();
  const addProductMutation = useMutation(addProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries('indicatorsMonitoringList');
      setVisible(false);
      displaySuccessNotification('Dodano produkt');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const editProductMutation = useMutation(editProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries('indicatorsMonitoringList');
      setVisible(false);
      displaySuccessNotification('Zapisano produkt');
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        const data = error.response.data;
        const errors = Object.keys(data.errors);
        if (errors.includes('name')) {
          form.setFields([{ name: 'name', errors: ['Nazwa jest już zajęta'] }]);
        }
      }
    },
  });

  const [form] = Form.useForm<ProductModalFormValues>();
  context && form.setFieldsValue(context);

  const onFinish = (values: ProductModalFormValues) => {
    if (context) {
      editProductMutation.mutate({ ...values, product: context.id, plan: planId });
    } else {
      addProductMutation.mutate({ ...values, result: resultId, plan: planId });
    }
  };

  const afterClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title={context ? 'Edytuj produkt' : 'Dodaj produkt'}
      wrapClassName="standard-modal add-result"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
      afterClose={afterClose}
      footer={[
        <Button className="submit-button" key="submit" htmlType="submit" type="primary" onClick={() => form.submit()}>
          <AddIcon />
          {context ? 'Zapisz produkt' : 'Dodaj produkt'}
        </Button>,
        <Button className="cancel-button" key="back" onClick={() => setVisible(false)}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <ProductModalForm form={form} onFinish={onFinish} />
    </Modal>
  );
};

export default EditProductModal;
