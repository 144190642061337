import React from 'react';
import { Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { TypeFilter } from '../../../enums';

type Props = {
  typeFilter: TypeFilter;
  setSearchTypeFilter: Function;
};

const TypeFilterDropdown = ({ setSearchTypeFilter, typeFilter }: Props) => {
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setSearchTypeFilter(TypeFilter.Investment);
        }}
      >
        Inwestycyjny
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setSearchTypeFilter(TypeFilter.NonInvestment);
        }}
      >
        Nieinwestycyjny
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setSearchTypeFilter(TypeFilter.None);
        }}
      >
        Brak filtru
      </Menu.Item>
    </Menu>
  );

  const displayText = () => {
    switch (typeFilter) {
      case TypeFilter.Investment:
        return 'Inwestycyjny';
      case TypeFilter.NonInvestment:
        return 'Nieinwestycyjny';
      default:
        return 'Brak filtru';
    }
  };

  return (
    <div>
      <span style={{ marginRight: 10, marginLeft: 15 }}>Typ:</span>
      <Dropdown overlay={menu}>
        <Button>
          {displayText()} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default TypeFilterDropdown;
