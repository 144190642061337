import * as React from 'react';
import { InputNumber } from 'formik-antd';
import { currencyInput } from '../../utils/formatters';

export type CurrencyInputProps = {
  name: string;
  fast?: boolean;
};

export const CurrencyInput = ({ name, fast = false }: CurrencyInputProps) => (
  <InputNumber
    name={name}
    fast={fast}
    formatter={(value) => currencyInput(value)}
    parser={(value) => (value ? Number.parseFloat(value.replace(/\s/g, '').replace('zł', '').replace(',', '.')) : '')}
    decimalSeparator=","
    precision={2}
  />
);

export default CurrencyInput;
