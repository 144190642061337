import React from 'react';
import { Button } from 'antd';

function YesNoToggle(props: any) {
  const { children, field, form } = props;

  const { name, value } = field;
  const { setFieldValue } = form;

  const choiceEnum = {
    YES: true,
    NO: false,
    NULL: null,
  };

  return (
    <div className="yes-no-toggle">
      <div className="buttons">
        <Button
          size="small"
          onClick={() =>
            value === choiceEnum.YES ? setFieldValue(name, choiceEnum.NULL) : setFieldValue(name, choiceEnum.YES)
          }
          className={`yes-button ${value === choiceEnum.YES && 'active'}`}
        >
          TAK
        </Button>
        <Button
          size="small"
          onClick={() =>
            value === choiceEnum.NO ? setFieldValue(name, choiceEnum.NULL) : setFieldValue(name, choiceEnum.NO)
          }
          className={`no-button ${value === choiceEnum.NO && 'active'}`}
        >
          NIE
        </Button>
      </div>
      <div className="label">
        <span>{children}</span>
      </div>
    </div>
  );
}

export default YesNoToggle;
