import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { Plan, ValidationErrors } from '../../../types';
import { activities } from '../dictionaries';

export const planFormBranch = (state: RootStateType) => state.planForm;

export const plan = createSelector(planFormBranch, (branch) => branch.plan || ({} as Plan));
export const planErrors = createSelector(
  planFormBranch,
  (branch) => branch.planErrors || ({} as ValidationErrors<Plan>),
);

export const planForm = createSelector([plan, planErrors, activities], (plan, planErrors, activities) => ({
  plan,
  planErrors,
  activities,
}));
