import React from 'react';
import { connect } from 'react-redux';
import { Card, Result, Table } from 'antd';
import { activityDetails } from '../../../redux/selectors/activity/activityDetails';

type Props = ReturnType<typeof activityDetails>;

function FinancialEffectsSection(props: Props) {
  const { financialEffects, planYears, financialEffectsDataSource, financialEffectsColumns } = props;
  return (
    <Card id="financial-effects" className="top-level-card" title="Skutki finansowe dla budżetów lat przyszłych">
      {planYears.length !== 0 && financialEffects.length !== 0 ? (
        <Table
          size="small"
          pagination={false}
          columns={financialEffectsColumns}
          dataSource={financialEffectsDataSource}
          scroll={{ x: 'max-content' }}
        />
      ) : (
        <Result title="Działanie nie posiada skutków finansowych dla budżetów lat przyszłych" />
      )}
    </Card>
  );
}

export default connect(activityDetails)(FinancialEffectsSection);
