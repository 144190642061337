import React from 'react';
import { Tooltip } from 'antd';
import { FolderTwoTone, HourglassTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { translateConstants } from '../../../utils/constants';

type Props = {
  progressStatus: string;
  size?: number;
  marginTop?: number;
  showTooltip?: boolean;
};

const OpenProjectProgressStatus = ({ progressStatus, showTooltip = true, size = 20, marginTop = 0 }: Props) => {
  const icon = () => {
    switch (progressStatus) {
      case 'IN_PREPARATION':
        return <FolderTwoTone twoToneColor="#bababa" style={{ fontSize: size, marginTop }} />;
      case 'IN_PROGRESS':
        return <HourglassTwoTone style={{ fontSize: size, marginTop }} />;
      case 'REALIZED':
        return <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: size, marginTop }} />;
      default:
        return <span>-</span>;
    }
  };

  return showTooltip && progressStatus ? (
    <Tooltip title={translateConstants(progressStatus)}>{icon()}</Tooltip>
  ) : (
    icon()
  );
};

export default OpenProjectProgressStatus;
