import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Divider, Popconfirm, Table } from 'antd';
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { activityForm } from '../../../../redux/selectors/activity/activityForm';
import { Risk, RiskInfluence, RiskProbability } from '../../../../types';
import { deleteRisk, editRisk } from '../../../../redux/actions';
import _ from 'lodash';

type Props = ReturnType<typeof activityForm> & typeof mapDispatchToProps;

const RiskAnalysisForm: React.FunctionComponent<Props> = (props) => {
  const { activity, riskInfluences, riskProbabilities, riskEntries, editRisk, deleteRisk, riskList } = props;

  const addNewRiskHandler = () => {
    editRisk(new Risk());
  };

  const getEntryDisplayName = (value: number): string => {
    const entry = _.find(riskEntries, ['id', value]);
    return entry ? entry.title : '';
  };

  const getInfluenceDisplayName = (value: number): string => {
    const influence = _.find(riskInfluences, ['id', value]);
    return influence ? influence.title : '';
  };

  const getProbabilityDisplayName = (value: number): string => {
    const probability = _.find(riskProbabilities, ['id', value]);
    return probability ? probability.title : '';
  };

  const getCategoryDisplayName = (value: number): string => {
    const entry = _.find(riskEntries, ['id', value]);
    if (entry && entry.category && entry.category.title) {
      return entry.category.title;
    }
    return '';
  };

  const helpColumns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      align: 'left' as 'left',
    },
    {
      title: 'Nazwa',
      dataIndex: 'title',
      key: 'title',
      align: 'left' as 'left',
    },
    {
      title: 'Opis',
      dataIndex: 'description',
      key: 'description',
      align: 'left' as 'left',
    },
  ];

  const influenceDataSource = riskInfluences.map((influence: RiskInfluence) => ({
    key: influence.id,
    symbol: influence.symbol,
    title: influence.title,
    description: influence.description,
  }));

  const probabilityDataSource = riskProbabilities.map((probability: RiskProbability) => ({
    key: probability.id,
    symbol: probability.symbol,
    title: probability.title,
    description: probability.description,
  }));

  const columns = [
    {
      title: 'Ryzyko',
      dataIndex: 'entry',
      key: 'entry',
      align: 'left' as 'left',
      render: (value: any) => {
        return getEntryDisplayName(value);
      },
    },
    {
      title: 'Kategoria ryzyka',
      dataIndex: 'category',
      key: 'category',
      align: 'left' as 'left',
      render: (value: any) => {
        return getCategoryDisplayName(value);
      },
    },
    {
      title: 'Wpływ na projekt',
      dataIndex: 'influence',
      key: 'influence',
      align: 'left' as 'left',
      render: (value: any) => {
        return getInfluenceDisplayName(value);
      },
    },
    {
      title: 'Prawdopodobieństwo',
      dataIndex: 'probability',
      key: 'probability',
      align: 'left' as 'left',
      render: (value: any) => {
        return getProbabilityDisplayName(value);
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      width: '2%',
      render: (_text: string, record: Risk) => <EditFilled onClick={() => record && editRisk(record)} />,
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      width: '2%',
      render: (_text: string, record: Risk) => (
        <Popconfirm
          title="Czy napewno usunąć ryzyko?"
          okText="Usuń"
          cancelText="Anuluj"
          onConfirm={() => record && record.id && deleteRisk(record.id)}
        >
          <DeleteFilled />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Card className="top-level-card" title="Analiza ryzyka" id="risks">
      <Divider>Ryzyka</Divider>
      <Table columns={columns} size="small" pagination={false} dataSource={riskList} />
      <br />
      <p>
        <Button type="primary" onClick={addNewRiskHandler} disabled={!activity.id}>
          <PlusOutlined /> Dodaj ryzyko
        </Button>
      </p>
      <Divider>Wpływ na projekt</Divider>
      <Table columns={helpColumns} dataSource={influenceDataSource} size="small" pagination={false} />
      <Divider>Prawdopodobieństwo ryzyka</Divider>
      <Table columns={helpColumns} dataSource={probabilityDataSource} size="small" pagination={false} />
    </Card>
  );
};

const mapDispatchToProps = { editRisk, deleteRisk };

export default connect(activityForm, mapDispatchToProps)(RiskAnalysisForm);
