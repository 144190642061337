import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import _ from 'lodash';
import ModalContext from '../AddObjectModals/ModalContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { saveEntity } from '../../../redux/actions';
import { Entity } from '../../../types';
import { addExecutorForm } from '../../../redux/selectors/modals';

type Props = ReturnType<typeof addExecutorForm> & { saveEntity: any };

function AddEntityForm(props: Props) {
  const { closeModal } = useContext(ModalContext);
  const { entityTypeVerboseNames, saveEntity } = props;

  const onSubmit = (values: any, actions: any) => {
    const { name, type } = values;
    saveEntity({ id: null, name, type });
    closeModal && closeModal();
    actions.resetForm();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane.'),
    type: Yup.string().required('To pole jest wymagane.'),
  });

  return (
    <Formik initialValues={{ ...new Entity() }} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form>
        <Form.Item label="Nazwa" name="name">
          <Input name="name" />
        </Form.Item>
        <Form.Item label="Typ" name="type">
          <Select name="type">
            {_.map(entityTypeVerboseNames, (value, key) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <SubmitButton>Dodaj</SubmitButton>
      </Form>
    </Formik>
  );
}

export default connect(addExecutorForm, { saveEntity })(AddEntityForm);
