import React from 'react';
import { cityGoals } from '../../../redux/selectors/city/cityGoals';
import { connect } from 'react-redux';
import { Card, Divider, Table } from 'antd';

type Props = ReturnType<typeof cityGoals>;

function GoalList(props: Props) {
  const { strategicGoalsByKind, city } = props;

  const columns = [
    {
      title: 'Cel',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as 'center',
    },
  ];

  return (
    <div className="city-site-layout-content">
      <Card className="top-level-card" title={`${city.name} - Cele strategiczne`} headStyle={{ textAlign: 'center' }}>
        <Divider>Cele strategiczne PRI</Divider>
        <Table columns={columns} dataSource={strategicGoalsByKind.PRI || []} size="small" pagination={false} />
        <Divider>Cele strategiczne PRL</Divider>
        <Table columns={columns} dataSource={strategicGoalsByKind.PRL || []} size="small" pagination={false} />
      </Card>
    </div>
  );
}

export default connect(cityGoals)(GoalList);
