import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { Entity, Impact, Project, ProjectSummary, PublicAidTest, ReporterType, Task } from '../../../types';
import _ from 'lodash';
import { strategicGoals } from '../dictionaries';
import { PublicAidTestQuestions, Question } from '../../../components/Project/PublicAidTestQuestions';

export const projectDetailsBranch = (state: RootStateType) => state.projectDetails;

export const project = createSelector(projectDetailsBranch, (branch) => branch.project || new ProjectSummary());
export const tasks = createSelector(project, (project) => project.tasks || ([] as Array<Task>));
export const projectOptions = createSelector(projectDetailsBranch, (branch) => branch.projectOptions || {});

export const projectFieldDescriptions = createSelector(projectOptions, (projectOptions) =>
  projectOptions.actions && projectOptions.actions.POST
    ? projectOptions.actions.POST
    : {
        ...new Project(),
        type: {
          choices: [],
        },
        executor: {},
        reporter_type: {},
        main_impact: {},
        depends_on: {},
      },
);

export const taskOptions = createSelector(projectDetailsBranch, (branch) => branch.taskOptions || {});
export const taskFieldDescriptions = createSelector(taskOptions, (taskOptions) =>
  taskOptions.actions && taskOptions.actions.POST
    ? taskOptions.actions.POST
    : {
        ...new Task(),
        stage: {
          choices: [],
        },
        executor: {},
      },
);

export const projectType = createSelector(
  project,
  projectFieldDescriptions,
  ({ type }, { type: { choices } }) => _.find(choices, (choice) => choice.value === type) || {},
);

export const executor = createSelector(project, ({ executor }) => executor || new Entity());
export const reporterType = createSelector(project, ({ reporter_type }) => reporter_type || new ReporterType());
export const mainImpact = createSelector(project, ({ main_impact }) => main_impact || new Impact());
export const additionalImpacts = createSelector(
  project,
  ({ additional_impact }) => additional_impact || ([] as Array<Impact>),
);
export const relatedEntities = createSelector(
  project,
  ({ related_entities }) => related_entities || ([] as Array<Entity>),
);

export const strategicGoalImpacts = createSelector(
  project,
  strategicGoals,
  ({ strategic_goal_impacts }, strategicGoals) =>
    strategic_goal_impacts
      ? strategic_goal_impacts.map((impact) => ({
          description: impact.description,
          strategicGoal: _.find(strategicGoals, (goal) => goal.id === impact.strategic_goal),
        }))
      : [],
);

export const publicAidTest = createSelector(project, (project) => project.public_aid_test || new PublicAidTest());

export const publicAidTestAnswers = createSelector(publicAidTest, (test) =>
  PublicAidTestQuestions.map((question: Question) => ({
    question: question.question || '',
    answer: (test as any)[question.field] || '',
    reason: (test as any)[`${question.field}_reason`] || '',
  })),
);

export const projectDetails = createSelector(
  [
    project,
    tasks,
    executor,
    reporterType,
    mainImpact,
    additionalImpacts,
    relatedEntities,
    taskFieldDescriptions,
    projectType,
    strategicGoalImpacts,
    publicAidTestAnswers,
  ],
  (
    project,
    tasks,
    executor,
    reporterType,
    mainImpact,
    additionalImpacts,
    relatedEntities,
    taskFieldDescriptions,
    projectType,
    strategicGoalImpacts,
    publicAidTestAnswers,
  ) => ({
    project,
    tasks,
    executor,
    reporterType,
    mainImpact,
    additionalImpacts,
    relatedEntities,
    taskFieldDescriptions,
    projectType,
    strategicGoalImpacts,
    publicAidTestAnswers,
  }),
);

export const projectScheduleSelector = createSelector(
  [tasks, taskFieldDescriptions, project],
  (tasks, taskFieldDescriptions, project) => ({
    tasks,
    taskFieldDescriptions,
    project,
  }),
);

export const projectRealizationSelector = createSelector([project], (project) => ({
  committed_expenses: project.committed_expenses,
  executed_expenses: project.executed_expenses,
}));
