import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Entity, FinancialEffect, Impact, ProjectSummary, Risk, StrategicGoal } from '../../../types';
import { getQuarter, getYearRange } from '../../../utils/dates';
import { currency } from '../../../utils/formatters';
import { TASK_FUNDING_SOURCE_VALUES_YEAR_START } from '../../../utils/config';

export const activityDetailsBranch = (state: RootStateType) => state.activityDetails;

export const activity = createSelector(activityDetailsBranch, (branch) => branch.activity || ({} as any));
export const activityFunding = createSelector(
  activityDetailsBranch,
  (branch) => branch.activityFunding || ([] as Array<any>),
);
export const riskInfluences = createSelector(
  activityDetailsBranch,
  (branch) => branch.riskInfluences || ([] as Array<any>),
);
export const riskProbabilities = createSelector(
  activityDetailsBranch,
  (branch) => branch.riskProbabilities || ([] as Array<any>),
);
export const riskEntries = createSelector(activityDetailsBranch, (branch) => branch.riskEntries || ([] as Array<any>));
export const activityFinancialEffectsOptions = createSelector(
  activityDetailsBranch,
  (branch) => branch.activityFinancialEffectsOptions || ([] as Array<any>),
);

export const activityLeader = createSelector(activity, ({ activity_leader }) => activity_leader || ({} as Entity));
export const mainImpact = createSelector(activity, ({ main_impact }) => main_impact || ({} as Impact));
export const additionalImpacts = createSelector(
  activity,
  ({ additional_impacts }) => additional_impacts || ([] as Array<Impact>),
);
export const strategicGoals = createSelector(
  activity,
  ({ strategic_goals }) => strategic_goals || ([] as Array<StrategicGoal>),
);
export const projects = createSelector(activity, (activity) => activity.projects || ([] as Array<ProjectSummary>));

export const risks = createSelector(activity, (activity) => activity.risks || ([] as Array<Risk>));

export const riskList = createSelector(risks, (risks) =>
  risks.map((risk: Risk) => ({
    id: risk.id,
    entry: risk.entry,
    category: risk.entry,
    influence: risk.influence,
    probability: risk.probability,
  })),
);

export const projectsTable = createSelector(projects, (projects) => {
  const tasks = _.flatten(
    projects.map((project) =>
      project.tasks.map((task, index) => ({
        ...task,
        cost: task.cost,
        key: task.id,
        projectSpan: index === 0 ? project.tasks.length : 0,
        project: { name: project.title, id: project.id },
        executor: project.executor.name,
        time_range: `${getQuarter(task.beginning)} - ${getQuarter(task.end)}`,
      })),
    ),
  );

  const total_cost = _.sumBy(tasks, (task) => parseFloat(task.cost)).toFixed(2);

  return [...tasks, { key: 'sum', projectName: 'Razem', cost: total_cost }];
});

export const projectFunding = createSelector(activityFunding, (activityFunding) =>
  activityFunding.map((project: any) => ({
    project: project.project,
    columns: [
      {
        title: 'Rok',
        dataIndex: 'year',
        render: (value: string, row: any) => {
          return {
            children: value,
            props: {
              rowSpan: row.row_span,
            },
          };
        },
      },
      {
        title: 'Źródło finansowania',
        dataIndex: 'funding_source',
      },
      ..._.map(project.executors, (executor: string) => ({
        title: executor,
        dataIndex: executor,
        render: currency,
        align: 'right' as 'right',
      })),
      {
        title: 'Razem',
        dataIndex: 'summary',
        render: currency,
        align: 'right' as 'right',
      },
    ],
    rows: _.map(project.rows, (row: any) => ({ key: `${row.year}-${row.funding_source}`, ...row })),
  })),
);

const projectsGenderEquality = createSelector(
  activity,
  (activity) =>
    activity.projects &&
    activity.projects.map(
      (project) =>
        project && {
          id: project.id || -1,
          name: project.title,
          value: project.gender_equality,
        },
    ),
);

const projectsNonDiscrimination = createSelector(
  activity,
  (activity) =>
    activity.projects &&
    activity.projects.map(
      (project) =>
        project && {
          id: project.id || -1,
          name: project.title,
          value: project.non_discrimination,
        },
    ),
);

const projectsSustainableDevelopment = createSelector(
  activity,
  (activity) =>
    activity.projects &&
    activity.projects.map(
      (project) =>
        project && {
          id: project.id || -1,
          name: project.title,
          value: project.sustainable_development,
        },
    ),
);

const projectsPublicAid = createSelector(
  activity,
  (activity) =>
    activity.projects &&
    activity.projects.map(
      (project) =>
        project && {
          id: project.id || -1,
          name: project.title,
          value: project.requires_public_aid,
        },
    ),
);

export const financialEffects = createSelector(
  activity,
  (activity) => activity.financial_effects || ([] as Array<FinancialEffect>),
);

export const planYears = createSelector(activity, (activity) =>
  activity.plan
    ? getYearRange(activity.plan.beginning, activity.plan.end)
    : ([] as Array<number>) || ([] as Array<number>),
);

export const financialEffectsDataSource = createSelector(
  financialEffects,
  planYears,
  (financialEffects: any, planYears) =>
    planYears.length > 0
      ? financialEffects.map((effect: FinancialEffect) => {
          const entry: any = {
            financial_effect: effect.financial_effect,
          };
          planYears.map(
            (year: number) => (entry[year.toString()] = effect.values[year - TASK_FUNDING_SOURCE_VALUES_YEAR_START]),
          );
          return entry;
        })
      : [],
);

export const financialEffectsColumns = createSelector(
  activityFinancialEffectsOptions,
  planYears,
  (activityFinancialEffectsOptions, planYears) => {
    const getConsequenceDisplayName = (value: any, options: Array<any>): string => {
      const option = _.find(options, ['value', value]);
      if (option && option.display_name) return option.display_name;
      return '';
    };
    const columns: any = [
      {
        title: 'Skutek finansowy',
        dataIndex: 'financial_effect',
        key: 'financial_effect',
        align: 'left' as 'left',
        width: 300,
        render: (value: string) => {
          return getConsequenceDisplayName(value, activityFinancialEffectsOptions);
        },
      },
    ];
    if (planYears.length > 0)
      planYears.map((year: number) =>
        columns.push({
          title: year.toString(),
          dataIndex: year.toString(),
          key: year.toString(),
          render: currency,
          align: 'left' as 'left',
        }),
      );
    return columns;
  },
);

export const activityDetails = createSelector(
  [
    activity,
    activityLeader,
    riskInfluences,
    riskProbabilities,
    riskEntries,
    mainImpact,
    additionalImpacts,
    strategicGoals,
    projects,
    risks,
    riskList,
    projectsTable,
    projectFunding,
    projectsGenderEquality,
    projectsNonDiscrimination,
    projectsSustainableDevelopment,
    projectsPublicAid,
    financialEffects,
    planYears,
    activityFinancialEffectsOptions,
    financialEffectsDataSource,
    financialEffectsColumns,
  ],
  (
    activity,
    activityLeader,
    riskInfluences,
    riskProbabilities,
    riskEntries,
    mainImpact,
    additionalImpacts,
    strategicGoals,
    projects,
    risks,
    riskList,
    projectsTable,
    projectFunding,
    projectsGenderEquality,
    projectsNonDiscrimination,
    projectsSustainableDevelopment,
    projectsPublicAid,
    financialEffects,
    planYears,
    activityFinancialEffectsOptions,
    financialEffectsDataSource,
    financialEffectsColumns,
  ) => ({
    activity,
    activityLeader,
    riskInfluences,
    riskProbabilities,
    riskEntries,
    mainImpact,
    additionalImpacts,
    strategicGoals,
    projects,
    risks,
    riskList,
    projectsTable,
    projectFunding,
    projectsGenderEquality,
    projectsNonDiscrimination,
    projectsSustainableDevelopment,
    projectsPublicAid,
    financialEffects,
    planYears,
    activityFinancialEffectsOptions,
    financialEffectsDataSource,
    financialEffectsColumns,
  }),
);
