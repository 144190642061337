import { createReducer } from '@reduxjs/toolkit';
import { StrategicGoal } from '../../../types';
import { fetchStrategicGoalsSuccess } from '../../actions';

const DEFAULT_STATE = {
  strategicGoals: [] as Array<StrategicGoal>,
};

const cityGoalsReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder.addCase(fetchStrategicGoalsSuccess, (state, action) => {
    state.strategicGoals = action.payload.results;
  }),
);

export default cityGoalsReducer;
