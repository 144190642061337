import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  changeActivitiesPagination,
  changeCity,
  fetchActivities,
  fetchEntities,
  setActivityListOrdering,
  setActivityRealizationFilter,
} from '../../../redux/actions';
import { activityList } from '../../../redux/selectors/activity/activityList';
import { Activity } from '../../../types';
import { Link } from 'react-router-dom';
import ArchiveActivityFilter from './Filters/ArchiveActivityFilter';
import { SorterResult } from 'antd/lib/table/interface';
import { handleTableOrdering } from '../../../utils/sorting';
import { setActivityListSearch } from '../../../redux/actions/index';
import ActivityListTable from './ActivityListTable/ActivityListTable';
import RealizationStatusFilter from '../../Common/Filters/RealizationStatusFilter';
import SearchInput from '../../Common/Form/SearchInput/SerachInput';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import './style.scss';

type Props = ReturnType<typeof activityList> & typeof mapDispatchToProps;

const ActivityList: React.FunctionComponent<Props> = ({
  fetchActivities,
  fetchEntities,
  entities,
  pagination,
  filters,
  ordering,
  search,
  setActivityListOrdering,
  setActivityListSearch,
  changeCity,
  activities,
  changeActivitiesPagination,
  setActivityRealizationFilter,
}) => {
  const [searchSubject, setSearchSubject] = useState<BehaviorSubject<string> | null>(null);

  useEffect(() => {
    if (!searchSubject) {
      const sub = new BehaviorSubject('');
      setSearchSubject(sub);
    } else {
      searchSubject
        .pipe(
          debounceTime(500),
          map((s) => s.trim()),
          distinctUntilChanged(),
          filter((s) => s.length >= 3 || s.length === 0),
        )
        .subscribe((term: string) => setActivityListSearch(term));
    }
    return () => searchSubject?.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSubject]);

  useEffect(() => {
    fetchEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchActivities({
      page: pagination.page,
      pageSize: pagination.pageSize,
      filters,
      ordering,
      search,
    });
  }, [fetchActivities, pagination.page, pagination.pageSize, filters, ordering, search, changeCity]);

  const onPageChange = (page: number, pageSize?: number) => {
    changeActivitiesPagination({
      page,
      pageSize: pageSize || pagination.pageSize,
    });
  };

  const onPageSizeChange = (currentSize: number, newSize: number) => {
    changeActivitiesPagination({ page: 1, pageSize: newSize });
  };

  const onSorterChange = (sorter: SorterResult<Activity> | SorterResult<Activity>[]) => {
    handleTableOrdering(sorter, setActivityListOrdering);
  };

  return (
    <section id="activities">
      <div className="controls">
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="link-prl">
                <Link key="1" to="/activity/new/prl">
                  PRL
                </Link>
              </Menu.Item>
              <Menu.Item key="link-pri">
                <Link key="2" to="/activity/new/pri">
                  PRI
                </Link>
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="primary">
            <PlusOutlined /> Dodaj Działanie
          </Button>
        </Dropdown>
        <SearchInput
          placeholder="Znajdź działanie"
          onChange={(event) => searchSubject?.next((event.target as HTMLInputElement).value)}
        />
        <ArchiveActivityFilter />
        <RealizationStatusFilter defaultValue={filters.realizationStatus} onChange={setActivityRealizationFilter} />
      </div>
      <ActivityListTable
        activities={activities}
        entities={entities}
        pagination={pagination}
        onSorterChange={onSorterChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </section>
  );
};

const mapDispatchToProps = {
  fetchActivities,
  fetchEntities,
  changeActivitiesPagination,
  setActivityListOrdering,
  setActivityListSearch,
  changeCity,
  setActivityRealizationFilter,
};

export default connect(activityList, mapDispatchToProps)(ActivityList);
