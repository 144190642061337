import React, { useEffect, useState } from 'react';
import { Button, Col, Pagination, Row, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { changePlansPagination, fetchPlans } from '../../../redux/actions';
import { planList } from '../../../redux/selectors/plan/planList';
import { Plan } from '../../../types';
import { Link } from 'react-router-dom';
import { getQuarter } from '../../../utils/dates';
import WPFExport from '../../Activity/ActivityDetails/WPFExport';
import ArchivePlanFilter from './Filters/ArchivePlanFilter';
import { handleTableOrdering } from '../../../utils/sorting';
import { setPlanListOrdering, setPlanListSearch } from '../../../redux/actions/index';
import SearchBar from '../../Common/Filters/SearchBar';

type Props = ReturnType<typeof planList> & typeof mapDispatchToProps;

const PlanList: React.FunctionComponent<Props> = (p) => {
  const { fetchPlans, pagination, filters, ordering, search, setPlanListOrdering, setPlanListSearch } = p;

  useEffect(() => {
    fetchPlans({ page: pagination.page, pageSize: pagination.pageSize, filters, ordering, search });
  }, [fetchPlans, pagination.page, pagination.pageSize, filters, ordering, search]);

  const [selectedPlans, setSelectedPlans] = useState([] as Array<Plan>);

  const columnConfig = [
    {
      title: 'Tytuł',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (title: string, plan: Plan) => <Link to={`/plan/${plan.id}`}>{title}</Link>,
    },
    {
      title: 'Rodzaj',
      dataIndex: 'kind',
      key: 'kind',
      sorter: true,
      align: 'center' as 'center',
    },
    {
      title: 'Początek',
      dataIndex: 'beginning',
      key: 'beginning',
      render: (text_date: string) => text_date && getQuarter(text_date),
    },
    {
      title: 'Koniec',
      dataIndex: 'end',
      key: 'end',
      render: (text_date: string) => text_date && getQuarter(text_date),
    },
    {
      title: 'Archiwalne',
      dataIndex: 'archived',
      key: 'archived',
      sorter: true,
      render: (value: boolean) => (value ? 'Tak' : 'Nie'),
      align: 'right' as 'right',
    },
  ];

  const onPageChange = (page: number, pageSize?: number) => {
    p.changePlansPagination({ page, pageSize: pageSize || p.pagination.pageSize });
  };

  const onPageSizeChange = (currentSize: number, newSize: number) => {
    p.changePlansPagination({ page: 1, pageSize: newSize });
  };

  const onChange = (_: any, __: any, sorter: any) => {
    handleTableOrdering(sorter, setPlanListOrdering);
  };

  const getPlanIds = (plans: Array<Plan>) => {
    const ids = [] as Array<number | null>;
    plans.forEach((plan: Plan) => ids.push(plan.id));
    return ids;
  };

  return (
    <>
      <Row className="title-bar list" justify="space-between">
        <Button type="primary">
          <Link to="/plan/new">
            <PlusOutlined /> Dodaj Plan
          </Link>
        </Button>
        <Row>
          {selectedPlans.length > 0 && (
            <WPFExport id={undefined} title={'Plany'} type="plan" data={getPlanIds(selectedPlans)} />
          )}
          <ArchivePlanFilter />
        </Row>
      </Row>
      <div className="object-list">
        <SearchBar setSearch={setPlanListSearch} />
        <Row>
          <Col span={24}>
            <Table
              dataSource={p.plans}
              columns={columnConfig}
              rowKey="id"
              pagination={false}
              onChange={onChange}
              rowSelection={{
                onChange: (_: any, selectedRows: Array<Plan>) => {
                  setSelectedPlans(selectedRows);
                },
              }}
              showSorterTooltip={{ title: 'Kliknij aby sortować' }}
            />
          </Col>
        </Row>
        <Row className="pagination-row">
          <Col span={24}>
            <Pagination
              current={p.pagination.page}
              total={p.pagination.total}
              showSizeChanger
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchPlans,
  changePlansPagination,
  setPlanListOrdering,
  setPlanListSearch,
};

export default connect(planList, mapDispatchToProps)(PlanList);
