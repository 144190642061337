import React from 'react';
import { Formik } from 'formik';
import { Input, Form } from 'formik-antd';
import { Row, Col, Button } from 'antd';

type Props = {
  setSearch: Function;
};

const SearchBar = ({ setSearch }: Props) => {
  const onSubmit = (values: any) => {
    setSearch(values.search);
  };

  return (
    <>
      <Formik initialValues={{ search: '' }} validateOnChange={false} validateOnBlur={false} onSubmit={onSubmit}>
        {({ submitForm }) => (
          <Form layout="inline" style={{ flex: 1 }}>
            <Row align="middle" gutter={[0, 16]} style={{ flex: 0.4 }}>
              <Col flex="1">
                <Form.Item name="search">
                  <Input name="search" />
                </Form.Item>
              </Col>
              <Button type="primary" onClick={submitForm}>
                Szukaj
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SearchBar;
