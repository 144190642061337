import {
  ActivityMinimal,
  AddIndicatorConfigRequest,
  AddProductApiRequest,
  AddResultApiRequest,
  EditIndicatorConfigRequest,
  EditProductApiRequest,
  EditResultApiRequest,
  Indicator,
  IndicatorMeasurementApiRequest,
  IndicatorsMonitoringList,
  IndicatorsMonitoringMeasurementsList,
  PlanGanttData,
  PlanStatusResponse,
  ProjectMinimal,
} from '../../types';
import { client } from '../../utils/apiClients';
import { pick } from 'lodash-es';
import * as Qs from 'query-string';

export const archivePlan = async (id: number) => client.post(`plan/${id}/archive/`);
export const unarchivePlan = async (id: number) => client.post(`plan/${id}/unarchive/`);

export const strategicGoals = async (id: string, filters: string[]) => {
  return client
    .get<PlanStatusResponse>(`plan/${id}/status/`, {
      params: {
        realization_status: filters,
      },
      paramsSerializer: (params) => Qs.stringify(params),
    })
    .then((res) => res.data.strategic_goals);
};

export const planIndicators = async (id: number, name?: string) => {
  return client.get<IndicatorsMonitoringList[]>(`plan/${id}/indicators/`, {
    params: {
      name: name,
    },
    paramsSerializer: (params) => Qs.stringify(params),
  });
};

export const addIndicator = async (data: AddIndicatorConfigRequest) =>
  client.post(`plan/${data.plan}/indicators/`, data);

export const editIndicator = async (data: EditIndicatorConfigRequest) =>
  client.put(`plan/${data.plan}/indicators/`, data);

export const serachIndicators = async (name?: string) => {
  return client.get<Indicator[]>(`indicators/search/`, {
    params: {
      name: name,
    },
    paramsSerializer: (params) => Qs.stringify(params),
  });
};

export const addResult = async (data: AddResultApiRequest) =>
  client.post(`plan/${data.plan}/results/`, pick(data, ['name', 'strategic_goal']));

export const editResult = async (data: EditResultApiRequest) =>
  client.put(`plan/${data.plan}/results/`, pick(data, ['name', 'result']));

export const deleteResult = async (planId: number | string, resultId: number | string) =>
  client.delete(`plan/${planId}/results/`, {
    data: {
      id: resultId,
    },
  });

export const addProduct = async (data: AddProductApiRequest) =>
  client.post(`plan/${data.plan}/products/`, pick(data, ['name', 'result']));

export const editProduct = async (data: EditProductApiRequest) =>
  client.put(`plan/${data.plan}/products/`, pick(data, ['name', 'product']));

export const deleteProduct = async (planId: number | string, productId: number | string) =>
  client.delete(`plan/${planId}/products/`, {
    data: {
      id: productId,
    },
  });

export const getActivitiesMinimalList = async () => client.get<ActivityMinimal[]>(`activity/minimal/`);

export const getProjectsMinimalList = async () => client.get<ProjectMinimal[]>(`project/minimal/`);

export const getPlannedMeasurementsList = async (plan_id: string) =>
  client.get<IndicatorsMonitoringMeasurementsList>(`plan/${plan_id}/measurements/`).then((res) => res.data);

export const deleteLatestIndicatorMeasurement = async (planId: number | string, configId: number | string) =>
  client.delete(`plan/${planId}/measurements/`, { data: { indicator_config: configId } });

export const addIndicatorMeasurement = async (data: IndicatorMeasurementApiRequest) =>
  client.post(`measurements/`, data);

export const getProjectGantt = async (planId: number | string) =>
  client.get<PlanGanttData[]>(`plan/${planId}/gantt/`).then((res) => res.data);

export const getPlanMonitoringExcel = async (planId: number | string) =>
  client.get(`plan/${planId}/export_monitoring/`, {
    responseType: 'blob',
  });

export const getPlanIndicatorsMonitoringExcel = async (planId: number | string, date: string) =>
  client.post(
    `plan/${planId}/export_indicators_monitoring/`,
    { date },
    {
      responseType: 'blob',
    },
  );

export const getPlanIndicatorsProgressExcel = async (planId: number | string) =>
  client.get(`plan/${planId}/export_indicators_progress/`, {
    responseType: 'blob',
  });

export const deleteIndicatorConfiguration = async (planId: number | string, configId: number | string) =>
  client.delete(`plan/${planId}/indicators/`, {
    data: {
      id: configId,
    },
  });
