import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { Project, Task, ValidationErrors } from '../../../types';
import {
  entities,
  entitiesByType,
  entityTypeVerboseNames,
  impacts,
  projects,
  strategicGoalsByKind,
} from '../dictionaries';

export const projectFormBranch = (state: RootStateType) => state.projectForm;
export const project = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.project || new Project(),
);
export const projectErrors = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.projectErrors || ({} as ValidationErrors<Project>),
);
export const projectOptions = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.projectOptions || {},
);
export const projectFieldDescriptions = createSelector(projectOptions, (projectOptions) =>
  projectOptions.actions && projectOptions.actions.POST
    ? projectOptions.actions.POST
    : {
        ...new Project(),
        type: {
          choices: [],
        },
        executor: {},
        reporter_type: {},
        main_impact: {},
        depends_on: {},
      },
);
export const reporterTypeFieldDescriptions = createSelector(projectFieldDescriptions, (projectFieldDescriptions) =>
  projectFieldDescriptions.tasks && projectFieldDescriptions.tasks.child
    ? projectFieldDescriptions.tasks.child.children
    : {
        ...new Task(),
        stage: {
          choices: [],
        },
      },
);
export const taskOptions = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.taskOptions || {},
);
export const taskFieldDescriptions = createSelector(taskOptions, (taskOptions) =>
  taskOptions.actions && taskOptions.actions.POST
    ? taskOptions.actions.POST
    : {
        ...new Task(),
        stage: {
          choices: [],
        },
        executor: {},
      },
);
export const taskErrors = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.taskErrors || ({} as ValidationErrors<Task>),
);
export const reporterTypes = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.reporterTypes || [],
);

export const editTaskModalOpened = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.editTaskModalOpened,
);
export const editTaskFundingSourcesModalOpened = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.editTaskFundingSourcesModalOpened,
);
export const tasks = createSelector(projectFormBranch, (projectFormBranch) => projectFormBranch.tasks || []);
export const editedTask = createSelector(projectFormBranch, (projectFormBranch) => projectFormBranch.editedTask || {});
export const fundingSources = createSelector(
  projectFormBranch,
  (projectFormBranch) => projectFormBranch.fundingSources || [],
);

export const projectForm = createSelector(
  [
    project,
    projectErrors,
    projectFieldDescriptions,
    taskFieldDescriptions,
    entities,
    entitiesByType,
    entityTypeVerboseNames,
    reporterTypes,
    impacts,
    projects,
    strategicGoalsByKind,
    editTaskModalOpened,
    editTaskFundingSourcesModalOpened,
    tasks,
    taskErrors,
    editedTask,
    fundingSources,
  ],
  // @ts-ignore
  (
    project,
    projectErrors,
    projectFieldDescriptions,
    taskFieldDescriptions,
    entities,
    entitiesByType,
    entityTypeVerboseNames,
    reporterTypes,
    impacts,
    projects,
    strategicGoalsByKind,
    editTaskModalOpened,
    editTaskFundingSourcesModalOpened,
    tasks,
    taskErrors,
    editedTask,
    fundingSources,
  ) => ({
    project,
    projectErrors,
    projectFieldDescriptions,
    taskFieldDescriptions,
    entities,
    entitiesByType,
    entityTypeVerboseNames,
    reporterTypes,
    impacts,
    projects,
    strategicGoalsByKind,
    editTaskModalOpened,
    editTaskFundingSourcesModalOpened,
    tasks,
    taskErrors,
    editedTask,
    fundingSources,
  }),
);

export const scheduleFormSelector = createSelector(
  [project, tasks, taskFieldDescriptions],
  (project, tasks, taskFieldDescriptions) => ({
    project,
    tasks,
    taskFieldDescriptions,
  }),
);
