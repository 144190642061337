import { client } from '../../../utils/apiClients';
import { EditProjectMilestoneProps, Milestone, ProjectMilestone } from '../../../types';

export const getProjectMilestones = (projectId: number): Promise<ProjectMilestone[]> =>
  client.get(`/project_milestones/?project=${projectId}`).then((r) =>
    r.data.map((d: ProjectMilestone, index: number) => {
      d.order = index;
      return d;
    }),
  );

export const getMilestones = (): Promise<ProjectMilestone[]> => client.get('/milestones/').then((r) => r.data);

export const getMilestone = async (id?: number): Promise<Milestone> => {
  if (!id) {
    return Promise.reject();
  }
  const r = await client.get(`/milestones/?id=${id}`);
  return r.data[0];
};

export const createMilestone = async (milestone: Milestone): Promise<Milestone> =>
  client.post('/milestones/', milestone);

export const createProjectMilestone = async (projectMilestone: ProjectMilestone): Promise<ProjectMilestone> =>
  client.post('/project_milestones/', projectMilestone);

export const editProjectMilestone = async (projectMilestone: EditProjectMilestoneProps) =>
  client.put(`/project_milestones/${projectMilestone.id}/?project=${projectMilestone.project}`, projectMilestone);

export const deleteProjectMilestone = async (projectMilestone: ProjectMilestone) =>
  client.delete(`/project_milestones/${projectMilestone.id}/?project=${projectMilestone.project}`);

export const editProjectMilestonesOrdering = async (order: number[]) =>
  client.put(`/project_milestones/ordering/`, { ordered_ids: order }).then((r) => r.data);
