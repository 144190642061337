import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { FundingSource, Project, ReporterType, StrategicGoalImpact, Task, ValidationErrors } from '../../../types';
import {
  closeEditTaskFundingSourcesModal,
  closeEditTaskModal,
  deleteTaskSuccess,
  editTask,
  editTaskFundingSources,
  fetchFundingSourcesSuccess,
  fetchProjectOptionsSuccess,
  fetchProjectSuccess,
  fetchProjectTasksSuccess,
  fetchReporterTypesSuccess,
  fetchTaskOptionsSuccess,
  initNewProject,
  locationChange,
  openEditTaskFundingSourcesModal,
  openEditTaskModal,
  saveFundingSourceSuccess,
  saveProjectFailed,
  saveProjectSuccess,
  saveReporterTypeSuccess,
  saveTaskFailed,
  saveTaskSuccess,
} from '../../actions';

const DEFAULT_STATE = {
  project: {} as Project,
  projectErrors: {} as ValidationErrors<Project>,
  projectOptions: {} as any,
  reporterTypes: [] as Array<ReporterType>,
  editTaskModalOpened: false as boolean,
  editTaskFundingSourcesModalOpened: false as boolean,
  taskOptions: {} as any,
  tasks: [] as Array<Task>,
  editedTask: {} as Task,
  taskErrors: {} as ValidationErrors<Task>,
  fundingSources: [] as Array<FundingSource>,
};

const projectFormReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(initNewProject, (state, action) => {
      state.project = {
        beginning: '',
        end: '',
        kind: action.payload.toUpperCase(),
        strategic_goal_impacts: [] as Array<StrategicGoalImpact>,
        tasks: [] as Array<number>,
      } as Project;
      state.projectErrors = {} as ValidationErrors<Project>;
      state.tasks = [] as Array<Task>;
    })
    .addCase(saveProjectSuccess, (state, action) => {
      state.project = action.payload;
      state.projectErrors = {} as ValidationErrors<Project>;
    })
    .addCase(fetchProjectSuccess, (state, action) => {
      state.project = action.payload;
      state.projectErrors = {} as ValidationErrors<Project>;
    })
    .addCase(saveProjectFailed, (state, action) => {
      state.projectErrors = action.payload;
    })
    .addCase(fetchProjectOptionsSuccess, (state, action) => {
      state.projectOptions = action.payload;
    })
    .addCase(fetchReporterTypesSuccess, (state, action) => {
      state.reporterTypes = action.payload.results;
    })
    .addCase(saveReporterTypeSuccess, (state, action) => {
      state.reporterTypes.push(action.payload);
    })
    .addCase(openEditTaskModal, (state, action) => {
      state.editTaskModalOpened = true;
    })
    .addCase(closeEditTaskModal, (state, action) => {
      state.editTaskModalOpened = false;
      state.taskErrors = {} as ValidationErrors<Task>;
    })
    .addCase(openEditTaskFundingSourcesModal, (state, action) => {
      state.editTaskFundingSourcesModalOpened = true;
    })
    .addCase(closeEditTaskFundingSourcesModal, (state, action) => {
      state.editTaskFundingSourcesModalOpened = false;
    })
    .addCase(saveTaskSuccess, (state, action) => {
      // update array of task ids (state.project.tasks)
      state.project.tasks = state.project.tasks.filter((task) => !_.isEqual(task, action.payload.id));
      action.payload.id && state.project.tasks.push(action.payload.id);

      // update array of task objects (state.tasks)
      const index = _.findIndex(state.tasks, { id: action.payload.id });
      if (index >= 0) state.tasks.splice(index, 1, action.payload);
      else state.tasks.push(action.payload);

      // remove error messages & close modals
      state.editTaskModalOpened = false;
      state.editTaskFundingSourcesModalOpened = false;
      state.taskErrors = {} as ValidationErrors<Task>;
    })
    .addCase(saveTaskFailed, (state, action) => {
      state.taskErrors = action.payload;
    })
    .addCase(deleteTaskSuccess, (state, action) => {
      state.project.tasks = state.project.tasks.filter((task) => !_.isEqual(task, action.payload));
      state.tasks = state.tasks.filter((task) => !_.isEqual(task.id, action.payload));
    })
    .addCase(editTask, (state, action) => {
      state.editedTask = action.payload;
      state.editTaskModalOpened = true;
    })
    .addCase(editTaskFundingSources, (state, action) => {
      state.editedTask = action.payload;
      state.editTaskFundingSourcesModalOpened = true;
    })
    .addCase(fetchTaskOptionsSuccess, (state, action) => {
      state.taskOptions = action.payload;
    })
    .addCase(fetchProjectTasksSuccess, (state, action) => {
      state.tasks = action.payload;
    })
    .addCase(fetchFundingSourcesSuccess, (state, action) => {
      state.fundingSources = action.payload.results;
    })
    .addCase(saveFundingSourceSuccess, (state, action) => {
      state.fundingSources.push(action.payload);
    })
    .addCase(locationChange, (state, action) => {
      state.editedTask = {} as Task;
      state.taskErrors = {} as ValidationErrors<Task>;
      // @ts-ignore
      const pathname = action.payload.location.pathname;
      if (pathname === '/project/new/prl' || pathname === '/project/new/pri' || !pathname.startsWith('/project/')) {
        state.project = {} as Project;
        state.projectErrors = {} as ValidationErrors<Project>;
      }
    }),
);

export default projectFormReducer;
