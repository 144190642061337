import { Button, Modal } from 'antd';
import React from 'react';
import AddIcon from '../../../../../assets/icons/AddIcon/AddIcon';
import CloseIcon from '../../../../../assets/icons/CloseIcon/CloseIcon';
import './style.scss';

interface Props {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onAccept: () => void;
}

const IndicatorsWarningModal: React.FC<Props> = ({ onAccept, setVisible, visible }) => {
  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      wrapClassName="standard-modal indicators-warning"
      onCancel={() => setVisible(false)}
      onOk={onAccept}
      title="Ostrzeżenie"
      footer={[
        <Button className="submit-button" key="submit" htmlType="submit" type="primary" onClick={onAccept}>
          <AddIcon />
          Zapisz zmiany
        </Button>,
        <Button className="cancel-button" key="back" onClick={() => setVisible(false)}>
          <CloseIcon />
          Anuluj
        </Button>,
      ]}
    >
      <p>
        Usunięcie działań z planu może spowodować&nbsp;
        <span className="warning">usunięcie</span> powiązanych z nimi konfiguracji{' '}
        <span className="warning">wskaźników</span>,&nbsp;
        <span className="warning">rezultatów</span>,&nbsp;
        <span className="warning">produktów</span> oraz ich&nbsp;
        <span className="warning">pomiarów</span> !
      </p>
      <p>Czy na pewno chcesz zapisać zmiany w planie ?</p>
    </Modal>
  );
};

export default IndicatorsWarningModal;
