import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Select } from 'antd';
import { activityForm } from '../../../redux/selectors/activity/activityForm';
import { closeEditRiskModal, saveRisk } from '../../../redux/actions';
import { RiskEntry, RiskInfluence, RiskProbability } from '../../../types';
import _ from 'lodash';

type Props = ReturnType<typeof activityForm> & typeof mapDispatchToProps;

const EditRiskModal: React.FunctionComponent<Props> = (props) => {
  const {
    activity,
    riskInfluences,
    riskProbabilities,
    editRiskModalOpened,
    closeEditRiskModal,
    saveRisk,
    riskEntries,
    editedRisk,
  } = props;

  const onSubmit = (values: any) => {
    saveRisk({
      ...editedRisk,
      ...values,
      activity: activity.id,
    });
  };

  const required = _.isEmpty(editedRisk) || editedRisk.id === null;
  const requiredFieldMessage = 'To pole jest wymagane.';

  return (
    <Modal
      className="edit-risk-modal"
      title="Ryzyko"
      centered
      visible={editRiskModalOpened}
      onCancel={closeEditRiskModal}
      destroyOnClose
      footer={
        <>
          <Button form="riskForm" onClick={closeEditRiskModal}>
            Anuluj
          </Button>
          <Button type="primary" form="riskForm" htmlType="submit">
            Zapisz
          </Button>
        </>
      }
    >
      <Form id="riskForm" layout="vertical" onFinish={onSubmit} initialValues={editedRisk}>
        <Form.Item label="Ryzyko" name="entry" rules={[{ required: required, message: requiredFieldMessage }]}>
          <Select defaultValue={editedRisk.entry}>
            {riskEntries.map((entry: RiskEntry) => (
              <Select.Option value={entry.id || -1} key={entry.id || -1}>
                {entry.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Wpływ na projekt"
          name="influence"
          rules={[{ required: required, message: requiredFieldMessage }]}
        >
          <Select defaultValue={editedRisk.influence}>
            {riskInfluences.map((influence: RiskInfluence) => (
              <Select.Option value={influence.id || -1} key={influence.id || -1}>
                {influence.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Prawdopodobieństwo wystąpienia"
          name="probability"
          rules={[{ required: required, message: requiredFieldMessage }]}
        >
          <Select defaultValue={editedRisk.probability}>
            {riskProbabilities.map((probability: RiskProbability) => (
              <Select.Option value={probability.id || -1} key={probability.id || -1}>
                {probability.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  closeEditRiskModal,
  saveRisk,
};

export default connect(activityForm, mapDispatchToProps)(EditRiskModal);
