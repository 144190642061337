import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Alert, Button, Card, Col, Empty, Row } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Project, StrategicGoal, StrategicGoalImpact } from '../../../../types';
import { Form, Input, Select } from 'formik-antd';
import { projectForm } from '../../../../redux/selectors/project/projectForm';
import S2PrlParameters from './ImpactFormPRL';
import PriParameters from './PoliciesFormPRI';

type Props = ReturnType<typeof projectForm>;

const ImpactForm: React.FunctionComponent<Props> = (props) => {
  const { project, strategicGoalsByKind, projectErrors } = props;

  const getImpactErrors = (index: number) =>
    projectErrors.strategic_goal_impacts && projectErrors.strategic_goal_impacts[index]
      ? projectErrors.strategic_goal_impacts[index]
      : ({} as any);

  const { values }: { values: Project } = useFormikContext();

  return (
    <Card className="top-level-card" title="Wpływ">
      {project.kind === 'PRL' && <S2PrlParameters />}
      {project.kind === 'PRI' && <PriParameters />}
      <Row>
        <Col span={24}>
          <Row>
            <Alert
              type="info"
              showIcon
              message="Wpływ na cele strategiczne"
              description="Proszę ograniczyć się do tych celów, na które przedsięwzięcie może mieć znaczący wpływ (max 3000 znaków)"
            />
          </Row>
          <Row>
            <Col span={24}>
              <FieldArray name="strategic_goal_impacts">
                {(arrayHelpers) => (
                  <React.Fragment>
                    {values.strategic_goal_impacts && values.strategic_goal_impacts.length > 0 ? (
                      <>
                        {values.strategic_goal_impacts.map((impact: StrategicGoalImpact, index: number) => (
                          <React.Fragment key={10 + index}>
                            <Form.Item
                              label="Cel strategiczny"
                              name={`strategic_goal_impacts[${index}].strategic_goal`}
                              validateStatus={getImpactErrors(index).strategic_goal ? 'error' : ''}
                              help={
                                getImpactErrors(index).strategic_goal
                                  ? getImpactErrors(index).strategic_goal.join(', ')
                                  : ''
                              }
                            >
                              <Select name={`strategic_goal_impacts[${index}].strategic_goal`}>
                                {_.keys(strategicGoalsByKind).map((kind: string) => (
                                  <Select.OptGroup label={kind} key={kind}>
                                    {strategicGoalsByKind[kind].map((goal: StrategicGoal) => (
                                      <Select.Option value={goal.id || -1} key={goal.id || -1}>
                                        {goal.name}
                                      </Select.Option>
                                    ))}
                                  </Select.OptGroup>
                                ))}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="Opis wpływu na cel strategiczny"
                              name={`strategic_goal_impacts[${index}].description`}
                              validateStatus={getImpactErrors(index).description ? 'error' : ''}
                              help={
                                getImpactErrors(index).description ? getImpactErrors(index).description.join(', ') : ''
                              }
                            >
                              <Input.TextArea
                                name={`strategic_goal_impacts[${index}].description`}
                                autoSize={{ minRows: 10 }}
                              />
                            </Form.Item>

                            <Row>
                              <Col span={24}>
                                <div style={{ float: 'right' }}>
                                  <Button className="remove-button" onClick={() => arrayHelpers.remove(index)}>
                                    <CloseOutlined /> Usuń
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                      </>
                    ) : (
                      <Empty />
                    )}
                    <Button type="primary" onClick={() => arrayHelpers.push({ strategic_goal: null, description: '' })}>
                      <PlusOutlined /> Dodaj
                    </Button>
                  </React.Fragment>
                )}
              </FieldArray>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(projectForm)(ImpactForm);
