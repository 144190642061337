import React from 'react';
import { connect } from 'react-redux';
import { setOpenProjectSearchTypeFilter } from '../../../../redux/actions/index';
import TypeFilterDropdown from '../../../Common/Filters/TypeFitlerDropdown';
import { typeFilter } from '../../../../redux/selectors/openProject/openProjectList';

type Props = ReturnType<typeof typeFilter> & typeof mapDispatchToProps;

const TypeOpenProjectFilter = ({ setOpenProjectSearchTypeFilter, typeFilter }: Props) => {
  return <TypeFilterDropdown typeFilter={typeFilter} setSearchTypeFilter={setOpenProjectSearchTypeFilter} />;
};

const mapDispatchToProps = {
  setOpenProjectSearchTypeFilter,
};

export default connect(typeFilter, mapDispatchToProps)(TypeOpenProjectFilter);
