import { RootStateType } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { OpenProjectSummary } from '../../../types';

export const projectDetailsBranch = (state: RootStateType) => state.openProjectDetails;

export const project = createSelector(projectDetailsBranch, (branch) => branch.project || new OpenProjectSummary());

export const openProjectDetails = createSelector([project], (project) => ({
  project,
}));
