import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { ArchiveFilter, TypeFilter, ProgressStatusFilter, RealizationStatusEnum } from '../../enums';
import {
  Activity,
  ActivitySummary,
  Entity,
  FundingSource,
  Impact,
  Plan,
  PlanSummary,
  Project,
  ProjectSummary,
  ReporterType,
  StrategicGoal,
  Task,
  User,
  Risk,
  ValidationErrors,
  ActivityFinancialEffects,
  Filters,
  OpenProject,
  OpenProjectSummary,
  City,
} from '../../types';

export type Action<T> = PayloadAction<T>;
export type ApiListResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<T>;
};
export type LoginPayload = { username: string; password: string };
export type ChangeCityPayload = { username: string; city: City };
export type LoginSuccessPayload = { token: string };
export type PaginationPayload = { page: number; pageSize: number };
export type FilterPayload = { filters: Filters };
export type OrderingPayload = { ordering: string };
export type SearchPayload = { search: string };
export type realizationStatusFilterPayload = { realizationStatusFilters?: RealizationStatusEnum[] };
export type ListFetchPayload = PaginationPayload & FilterPayload & OrderingPayload & SearchPayload;

export const locationChange = createAction('@@router/LOCATION_CHANGE');

export const login = createAction<LoginPayload>('LOGIN');
export const loginSuccess = createAction<LoginSuccessPayload>('LOGIN_SUCCESS');
export const loginFailed = createAction('LOGIN_FAILED');

export const logout = createAction('LOGOUT');
export const changeCity = createAction<ChangeCityPayload>('CHANGE_CITY');

export const fetchChangelog = createAction('FETCH_CHANGELOG');
export const fetchChangelogSuccess = createAction<any>('FETCH_CHANGELOG_SUCCESS');
export const fetchChangelogFailed = createAction('FETCH_CHANGELOG_FAILED');

export const fetchCurrentUser = createAction('FETCH_CURRENT_USER');
export const fetchCurrentUserSuccess = createAction<User>('FETCH_CURRENT_USER_SUCCESS');
export const fetchCurrentUserFailed = createAction('FETCH_CURRENT_USER_FAILED');

export const fetchProjects = createAction<ListFetchPayload & realizationStatusFilterPayload>('FETCH_PROJECTS');
export const fetchProjectsSuccess = createAction<ApiListResponse<Project>>('FETCH_PROJECTS_SUCCESS');
export const fetchProjectsFailed = createAction('FETCH_PROJECTS_FAILED');

export const fetchAllProjects = createAction('FETCH_ALL_PROJECTS');
export const fetchAllProjectsSuccess = createAction<ApiListResponse<Project>>('FETCH_ALL_PROJECTS_SUCCESS');
export const fetchAllProjectsFailed = createAction('FETCH_ALL_PROJECTS_FAILED');

export const searchProjects = createAction<string>('SEARCH_PROJECTS');
export const searchProjectsSuccess = createAction<ApiListResponse<Project>>('SEARCH_PROJECTS_SUCCESS');
export const searchProjectsFailed = createAction('SEARCH_PROJECTS_FAILED');

export const fetchProject = createAction<number>('FETCH_PROJECT');
export const fetchProjectSuccess = createAction<Project>('FETCH_PROJECT_SUCCESS');
export const fetchProjectFailed = createAction('FETCH_PROJECT_FAILED');

export const fetchRelatedProjects = createAction<number>('FETCH_RELATED_PROJECTS');
export const fetchRelatedProjectsSuccess = createAction<Array<Project>>('FETCH_RELATED_PROJECTS_SUCCESS');
export const fetchRelatedProjectsFailed = createAction('FETCH_RELATED_PROJECTS_FAILED');

export const saveProject = createAction<Project>('SAVE_PROJECT');
export const saveProjectSuccess = createAction<Project>('SAVE_PROJECT_SUCCESS');
export const saveProjectFailed = createAction<ValidationErrors<Project>>('SAVE_PROJECT_FAILED');

export const fetchProjectOptions = createAction('FETCH_PROJECT_OPTIONS');
export const fetchProjectOptionsSuccess = createAction<any>('FETCH_PROJECT_OPTIONS_SUCCESS');
export const fetchProjectOptionsFailed = createAction('FETCH_PROJECT_OPTIONS_FAILED');

export const fetchEntities = createAction('FETCH_ENTITIES');
export const fetchEntitiesSuccess = createAction<ApiListResponse<Entity>>('FETCH_ENTITIES_SUCCESS');
export const fetchEntitiesFailed = createAction('FETCH_ENTITIES_FAILED');

export const fetchStrategicGoals = createAction('FETCH_STRATEGIC_GOALS');
export const fetchStrategicGoalsSuccess = createAction<ApiListResponse<StrategicGoal>>('FETCH_STRATEGIC_GOALS_SUCCESS');
export const fetchStrategicGoalsFailed = createAction('FETCH_STRATEGIC_GOALS_FAILED');

export const fetchEntityOptions = createAction('FETCH_ENTITY_OPTIONS');
export const fetchEntityOptionsSuccess = createAction<any>('FETCH_ENTITY_OPTIONS_SUCCESS');
export const fetchEntityOptionsFailed = createAction('FETCH_ENTITY_OPTIONS_FAILED');

export const fetchReporterTypes = createAction('FETCH_REPORTER_TYPES');
export const fetchReporterTypesSuccess = createAction<ApiListResponse<ReporterType>>('FETCH_REPORTER_TYPES_SUCCESS');
export const fetchReporterTypesFailed = createAction('FETCH_REPORTER_TYPES_FAILED');

export const saveReporterType = createAction<ReporterType>('SAVE_REPORTER_TYPE');
export const saveReporterTypeSuccess = createAction<ReporterType>('SAVE_REPORTER_TYPE_SUCCESS');
export const saveReporterTypeFailed = createAction<ValidationErrors<ReporterType>>('SAVE_REPORTER_TYPE_FAILED');

export const fetchImpacts = createAction('FETCH_IMPACTS');
export const fetchImpactsSuccess = createAction<ApiListResponse<Impact>>('FETCH_IMPACTS_SUCCESS');
export const fetchImpactsFailed = createAction('FETCH_IMPACTS_FAILED');

export const initNewProject = createAction<string>('INIT_NEW_PROJECT');

export const changeProjectsPagination = createAction<PaginationPayload>('CHANGE_PROJECTS_PAGINATION');
export const changeOpenProjectsPagination = createAction<PaginationPayload>('CHANGE_OPEN_PROJECTS_PAGINATION');

export const openEditTaskModal = createAction('OPEN_EDIT_TASK_MODAL');
export const closeEditTaskModal = createAction('CLOSE_EDIT_TASK_MODAL');

export const openEditTaskFundingSourcesModal = createAction('OPEN_EDIT_TASK_FUNDING_SOURCE_MODAL');
export const closeEditTaskFundingSourcesModal = createAction('CLOSE_EDIT_TASK_FUNDING_SOURCE_MODAL');

export const saveTask = createAction<Task>('SAVE_TASK');
export const saveTaskSuccess = createAction<Task>('SAVE_TASK_SUCCESS');
export const saveTaskFailed = createAction<ValidationErrors<Task>>('SAVE_TASK_FAILED');

export const fetchTaskOptions = createAction('FETCH_TASK_OPTIONS');
export const fetchTaskOptionsSuccess = createAction<any>('FETCH_TASK_OPTIONS_SUCCESS');
export const fetchTaskOptionsFailed = createAction('FETCH_TASK_OPTIONS_FAILED');

export const fetchProjectTasks = createAction<number>('FETCH_PROJECT_TASKS');
export const fetchProjectTasksSuccess = createAction<Array<Task>>('FETCH_PROJECT_TASKS_SUCCESS');
export const fetchProjectTasksFailed = createAction('FETCH_PROJECT_TASKS_FAILED');

export const fetchFundingSources = createAction('FETCH_FUNDING_SOURCES');
export const fetchFundingSourcesSuccess = createAction<ApiListResponse<FundingSource>>('FETCH_FUNDING_SOURCES_SUCCESS');
export const fetchFundingSourcesFailed = createAction('FETCH_FUNDING_SOURCES_FAILED');

export const saveFundingSource = createAction<FundingSource>('SAVE_FUNDING_SOURCE');
export const saveFundingSourceSuccess = createAction<FundingSource>('SAVE_FUNDING_SOURCE_SUCCESS');
export const saveFundingSourceFailed = createAction<ValidationErrors<FundingSource>>('SAVE_FUNDING_SOURCE_FAILED');

export const deleteTask = createAction<number>('DELETE_TASK');
export const deleteTaskSuccess = createAction<number>('DELETE_TASK_SUCCESS');
export const deleteTaskFailed = createAction('DELETE_TASK_FAILED');

export const editTask = createAction<Task>('EDIT_TASK');
export const editTaskFundingSources = createAction<Task>('EDIT_TASK_FUNDING_SOURCES');

export const saveEntity = createAction<Entity>('SAVE_ENTITY');
export const saveEntitySuccess = createAction<Entity>('SAVE_ENTITY_SUCCESS');
export const saveEntityFailed = createAction<ValidationErrors<Entity>>('SAVE_ENTITY_FAILED');

export const saveImpact = createAction<Impact>('SAVE_IMPACT');
export const saveImpactSuccess = createAction<Impact>('SAVE_IMPACT_SUCCESS');
export const saveImpactFailed = createAction<ValidationErrors<Impact>>('SAVE_IMPACT_FAILED');

export const initNewActivity = createAction<string>('INIT_NEW_ACTIVITY');

export const fetchActivities = createAction<ListFetchPayload>('FETCH_ACTIVITIES');
export const fetchActivitiesSuccess = createAction<ApiListResponse<Activity>>('FETCH_ACTIVITIES_SUCCESS');
export const fetchActivitiesFailed = createAction('FETCH_ACTIVITIES_FAILED');

export const searchActivities = createAction<string>('SEARCH_ACTIVITIES');
export const searchActivitiesSuccess = createAction<ApiListResponse<Activity>>('SEARCH_ACTIVITIES_SUCCESS');
export const searchActivitiesFailed = createAction('SEARCH_ACTIVITIES_FAILED');

export const fetchActivity = createAction<number>('FETCH_ACTIVITY');
export const fetchActivitySuccess = createAction<Activity>('FETCH_ACTIVITY_SUCCESS');
export const fetchActivityFailed = createAction('FETCH_ACTIVITY_FAILED');

export const saveActivity = createAction<Activity>('SAVE_ACTIVITY');
export const saveActivitySuccess = createAction<Activity>('SAVE_ACTIVITY_SUCCESS');
export const saveActivityFailed = createAction<ValidationErrors<Activity>>('SAVE_ACTIVITY_FAILED');

export const editFinancialEffect = createAction<ActivityFinancialEffects>('EDIT_FINANCIAL_EFFECT');
export const closeEditFinancialEffectModal = createAction('CLOSE_EDIT_FINANCIAL_EFFECT_MODAL');

export const changeActivitiesPagination = createAction<PaginationPayload>('CHANGE_ACTIVITIES_PAGINATION');

export const fetchActivitySummary = createAction<number>('FETCH_ACTIVITY_SUMMARY');
export const fetchActivitySummarySuccess = createAction<ActivitySummary>('FETCH_ACTIVITY_SUMMARY_SUCCESS');
export const fetchActivitySummaryFailed = createAction('FETCH_ACTIVITY_SUMMARY_FAILED');

export const fetchActivityFinancialEffectsOptions = createAction('FETCH_ACTIVITY_FINANCIAL_EFFECTS_OPTIONS');
export const fetchActivityFinancialEffectsOptionsSuccess = createAction<Array<any>>(
  'FETCH_ACTIVITY_FINANCIAL_EFFECTS_OPTIONS_SUCCESS',
);
export const fetchActivityFinancialEffectsOptionsFailed = createAction(
  'FETCH_ACTIVITY_FINANCIAL_EFFECTS_OPTIONS_FAILED',
);

export const fetchActivityFunding = createAction<number>('FETCH_ACTIVITY_FUNDING');
export const fetchActivityFundingSuccess = createAction<any>('FETCH_ACTIVITY_FUNDING_SUCCESS');
export const fetchActivityFundingFailed = createAction('FETCH_ACTIVITY_FUNDING_FAILED');

export const fetchActivityProjects = createAction<number>('FETCH_ACTIVITY_PROJECTS');
export const fetchActivityProjectsSuccess = createAction<Array<Project>>('FETCH_ACTIVITY_PROJECTS_SUCCESS');
export const fetchActivityProjectsFailed = createAction('FETCH_ACTIVITY_PROJECTS_FAILED');

export const fetchRiskInfluences = createAction('FETCH_RISK_INFLUENCE');
export const fetchRiskInfluencesSuccess = createAction<Array<any>>('FETCH_RISK_INFLUENCE_SUCCESS');

export const fetchRiskProbabilities = createAction('FETCH_RISK_PROBABILITY');
export const fetchRiskProbabilitiesSuccess = createAction<Array<any>>('FETCH_RISK_PROBABILITY_SUCCESS');

export const fetchRiskEntries = createAction('FETCH_RISK_ENTRY');
export const fetchRiskEntriesSuccess = createAction<Array<any>>('FETCH_RISK_ENTRY_SUCCESS');

export const fetchProjectSummary = createAction<number>('FETCH_PROJECT_SUMMARY');
export const fetchProjectSummarySuccess = createAction<ProjectSummary>('FETCH_PROJECT_SUMMARY_SUCCESS');
export const fetchProjectSummaryFailed = createAction('FETCH_PROJECT_SUMMARY_FAILED');

export const initNewPlan = createAction('INIT_NEW_PLAN');
export const editRisk = createAction<Risk>('EDIT_RISK');
export const closeEditRiskModal = createAction('CLOSE_EDIT_RISK_MODAL');
export const saveRisk = createAction<any>('SAVE_RISK');
export const saveRiskSuccess = createAction<Array<any>>('SAVE_RISK_SUCCESS');
export const saveRiskFailed = createAction('SAVE_RISK_FAILED');

export const deleteRisk = createAction<number>('DELETE_RISK');
export const deleteRiskSuccess = createAction<number>('DELETE_RISK_SUCCESS');
export const deleteRiskFailed = createAction('DELETE_RISK_FAILED');

export const fetchPlans = createAction<ListFetchPayload>('FETCH_PLANS');
export const fetchPlansSuccess = createAction<ApiListResponse<Plan>>('FETCH_PLANS_SUCCESS');
export const fetchPlansFailed = createAction('FETCH_PLANS_FAILED');

export const fetchPlan = createAction<number>('FETCH_PLAN');
export const fetchPlanSuccess = createAction<Plan>('FETCH_PLAN_SUCCESS');
export const fetchPlanFailed = createAction('FETCH_PLAN_FAILED');

export const fetchPlanSummary = createAction<number>('FETCH_PLAN_SUMMARY');
export const fetchPlanSummarySuccess = createAction<PlanSummary>('FETCH_PLAN_SUMMARY_SUCCESS');
export const fetchPlanSummaryFailed = createAction('FETCH_PLAN_SUMMARY_FAILED');

export const savePlan = createAction<Plan>('SAVE_PLAN');
export const savePlanSuccess = createAction<Plan>('SAVE_PLAN_SUCCESS');
export const savePlanFailed = createAction<ValidationErrors<Plan>>('SAVE_PLAN_FAILED');

export const fetchPlanActivities = createAction<number>('FETCH_PLAN_ACTIVITIES');
export const fetchPlanActivitiesSuccess = createAction<Array<Activity>>('FETCH_PLAN_ACTIVITIES_SUCCESS');
export const fetchPlanActivitiesFailed = createAction('FETCH_PLAN_ACTIVITIES_FAILED');

export const changePlansPagination = createAction<PaginationPayload>('CHANGE_PLANS_PAGINATION');

export const fetchAllActivities = createAction('FETCH_ALL_ACTIVITIES');
export const fetchAllActivitiesSuccess = createAction<ApiListResponse<Activity>>('FETCH_ALL_ACTIVITIES_SUCCESS');
export const fetchAllActivitiesFailed = createAction('FETCH_ALL_ACTIVITIES_FAILED');

export const setActivityRealizationFilter = createAction<RealizationStatusEnum[]>(
  'SET_ACTIVITY_REALIZATION_STATUS_FILTER',
);
export const setActivitySearchArchiveFilter = createAction<ArchiveFilter>('SET_ACTIVITY_SEARCH_ARCHIVE_FILTER');
export const setProjectSearchArchiveFilter = createAction<ArchiveFilter>('SET_PROJECT_SEARCH_ARCHIVE_FILTER');
export const setPlanSearchArchiveFilter = createAction<ArchiveFilter>('SET_PLAN_SEARCH_ARCHIVE_FILTER');

export const setOpenProjectSearchTypeFilter = createAction<TypeFilter>('SET_OPEN_PROJECT_SEARCH_TYPE_FILTER');
export const setOpenProjectSearchProgressStatusFilter = createAction<ProgressStatusFilter>(
  'SET_OPEN_PROJECT_SEARCH_PROGRESS_STATUS_FILTER',
);
export const setOpenProjectSearchExecutorsFilter = createAction<Array<number>>(
  'SET_OPEN_PROJECT_SEARCH_EXECUTORS_FILTER',
);

export const setActivityListOrdering = createAction<string>('SET_ACTIVITY_LIST_ORDERING');
export const setProjectListOrdering = createAction<string>('SET_PROJECT_LIST_ORDERING');
export const setOpenProjectListOrdering = createAction<string>('SET_OPEN_PROJECT_LIST_ORDERING');
export const setPlanListOrdering = createAction<string>('SET_PLAN_LIST_ORDERING');

export const setActivityListSearch = createAction<string>('SET_ACTIVITY_LIST_SEARCH');
export const setProjectListSearch = createAction<string>('SET_PROJECT_LIST_SEARCH');
export const setProjectRealizationFilter = createAction<RealizationStatusEnum[]>('SET_PROJECT_REALIZATION_FILTER');
export const setOpenProjectListSearch = createAction<string>('SET_OPEN_PROJECT_LIST_SEARCH');
export const setPlanListSearch = createAction<string>('SET_PLAN_LIST_SEARCH');

// Open projects
export const fetchOpenProjects = createAction<ListFetchPayload>('FETCH_OPEN_PROJECTS');
export const fetchOpenProjectsSuccess = createAction<ApiListResponse<OpenProject>>('FETCH_OPEN_PROJECTS_SUCCESS');
export const fetchOpenProjectsFailed = createAction('FETCH_OPEN_PROJECTS_FAILED');

export const initNewOpenProject = createAction('INIT_NEW_OPEN_PROJECT');

export const saveOpenProject = createAction<OpenProject>('SAVE_OPEN_PROJECT');
export const saveOpenProjectSuccess = createAction<OpenProject>('SAVE_OPEN_PROJECT_SUCCESS');
export const saveOpenProjectFailed = createAction<ValidationErrors<OpenProject>>('SAVE_OPEN_PROJECT_FAILED');

export const patchOpenProject = createAction<OpenProject>('PATCH_OPEN_PROJECT');

export const fetchOpenProject = createAction<number>('FETCH_OPEN_PROJECT');
export const fetchOpenProjectSuccess = createAction<OpenProject>('FETCH_OPEN_PROJECT_SUCCESS');
export const fetchOpenProjectFailed = createAction('FETCH_OPEN_PROJECT_FAILED');

export const fetchOpenProjectSummary = createAction<number>('FETCH_OPEN_PROJECT_SUMMARY');
export const fetchOpenProjectSummarySuccess = createAction<OpenProjectSummary>('FETCH_OPEN_PROJECT_SUMMARY_SUCCESS');
export const fetchOpenProjectSummaryFailed = createAction('FETCH_OPEN_PROJECT_SUMMARY_FAILED');
