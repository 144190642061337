export enum ArchiveFilter {
  Show,
  Hide,
  None,
}

// No filter value should be declared first so that it has index 0.
export enum TypeFilter {
  None,
  Investment,
  NonInvestment,
}

export enum ProgressStatusFilter {
  None,
  InPreparation,
  InProgress,
  Realized,
}

export enum RealizationStatusEnum {
  UNKNOWN = 'UNKNOWN',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  DELAYED = 'DELAYED',
}
