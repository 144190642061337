import React from 'react';
import { Card, Collapse } from 'antd';
import { projectDetails } from '../../../redux/selectors/project/projectDetails';
import { connect } from 'react-redux';

const { Panel } = Collapse;

type Props = ReturnType<typeof projectDetails>;

function DescriptionSection(props: Props) {
  const { project } = props;

  const collapsePanels = [
    { header: 'Geneza', content: project.background },
    {
      header: 'Opis oraz zakres przedmiotowy przedsięwzięcia, w tym proponowane zadania/etapy',
      content: project.scope_description,
    },
    { header: 'Standardy dostępności', content: project.accessibility_standards },
  ];

  return (
    <Card id="description" className="top-level-card" title="Opis">
      {collapsePanels.map((panel, index) => (
        <Collapse key={index} className="collapse-info" defaultActiveKey={index === 0 ? ['1'] : []} bordered={false}>
          <Panel className="collapse-info-panel" key="1" header={panel.header}>
            <p>{panel.content}</p>
          </Panel>
        </Collapse>
      ))}
    </Card>
  );
}

export default connect(projectDetails)(DescriptionSection);
