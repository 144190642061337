import React from 'react';
import { useState } from 'react';
import { Collapse } from 'react-collapse';
import ArrowGray from '../../../../../../assets/icons/arrows/ArrowGray/ArrowGray';

interface Props {
  title: string;
  initialState?: boolean;
}

const collapseTheme = {
  collapse: 'collapse',
  content: 'collapse-content',
};

const SubCollapse: React.FC<Props> = ({ title, initialState = false, children }) => {
  const [isContentOpen, setIsContentOpened] = useState(initialState);
  return (
    <div className={isContentOpen ? 'sub-collapse open' : 'sub-collapse'}>
      <header>
        <button
          className={isContentOpen ? 'sub-collapse-button' : 'sub-collapse-button open'}
          onClick={() => setIsContentOpened((state) => !state)}
        >
          <ArrowGray />
        </button>
        <p className="title">{title}</p>
      </header>
      <Collapse theme={collapseTheme} isOpened={isContentOpen}>
        <div className="sub-collapse-content-wrapper">{children}</div>
      </Collapse>
    </div>
  );
};

export default SubCollapse;
